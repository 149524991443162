import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions
} from "react-query";
import { amplifyFetcher } from "../lib/fetcher";
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSTime: any;
  AWSDateTime: any;
  AWSTimestamp: any;
  AWSEmail: any;
  AWSJSON: any;
  AWSURL: any;
  AWSPhone: any;
  AWSIPAddress: any;
  BigInt: any;
  Double: any;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  email: Scalars["String"];
  enabled: Scalars["Boolean"];
  status: Scalars["String"];
  created: Scalars["AWSDateTime"];
  email_verified?: Maybe<Scalars["String"]>;
  given_name?: Maybe<Scalars["String"]>;
  family_name?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  customerID?: Maybe<Scalars["Int"]>;
  vendorID?: Maybe<Scalars["Int"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  items?: Maybe<Array<User>>;
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["Int"];
  shortName: Scalars["String"];
  fullName: Scalars["String"];
  active: Scalars["Boolean"];
  added: Scalars["AWSDateTime"];
  adminType: Scalars["Int"];
  isVendor: Scalars["Boolean"];
  emailAddressIdentity: Scalars["String"];
  streamingContent: Scalars["Boolean"];
  displayShowReports: Scalars["Boolean"];
  displayEpisodeReports: Scalars["Boolean"];
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  items?: Maybe<Array<Customer>>;
};

export type Show = {
  __typename?: "Show";
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  nameShort: Scalars["String"];
  nameLong: Scalars["String"];
  description: Scalars["String"];
  poster?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["AWSURL"]>;
  added: Scalars["AWSDateTime"];
  active?: Maybe<Scalars["Boolean"]>;
  hideInAdmin?: Maybe<Scalars["Boolean"]>;
  appID: Scalars["String"];
  /** @deprecated Field no longer supported */
  mainMenuType?: Maybe<Scalars["Int"]>;
  faqContent?: Maybe<Scalars["String"]>;
  faviconsURL?: Maybe<Scalars["String"]>;
  appData?: Maybe<ShowAppData>;
  channel?: Maybe<ShowChannel>;
  /** @deprecated Field no longer supported */
  demographicConfiguration?: Maybe<ShowDemographicConfiguration>;
  productRequestConfiguration?: Maybe<ShowProductRequestConfiguration>;
};

export type ShowManifest = {
  __typename?: "ShowManifest";
  name: Scalars["String"];
  short_name: Scalars["String"];
  start_url: Scalars["String"];
  display: Scalars["String"];
  background_color: Scalars["String"];
  theme_color: Scalars["String"];
  permissions?: Maybe<Array<Scalars["String"]>>;
  icons: Array<ManifestIcon>;
};

export type ManifestIcon = {
  __typename?: "ManifestIcon";
  sizes: Scalars["String"];
  src: Scalars["String"];
};

export type ShowAppData = {
  __typename?: "ShowAppData";
  appLogo?: Maybe<Scalars["String"]>;
  appLogoURL?: Maybe<Scalars["AWSURL"]>;
  primaryColor: Scalars["String"];
  textPrimaryColor: Scalars["String"];
  secondaryColor: Scalars["String"];
  tertiaryColor: Scalars["String"];
  accentColor: Scalars["String"];
  infoColor: Scalars["String"];
  headBackgroundColor: Scalars["String"];
  headTextColor: Scalars["String"];
  menuTextColor: Scalars["String"];
  productCaroselBackgroundColor: Scalars["String"];
  productButTextColor: Scalars["String"];
  productButBackColor: Scalars["String"];
  productBut2TextColor: Scalars["String"];
  productBut2BackColor: Scalars["String"];
  /** @deprecated Field no longer supported */
  autoplayEnabled?: Maybe<Scalars["Boolean"]>;
  /** @deprecated Field no longer supported */
  autoplayAfterFingerprint?: Maybe<Scalars["Boolean"]>;
  copyright?: Maybe<Scalars["String"]>;
  privacyPolicy?: Maybe<Scalars["String"]>;
  appPosterDisplayType?: Maybe<Scalars["Int"]>;
  /** @deprecated Field no longer supported */
  episodeTileType?: Maybe<Scalars["Int"]>;
  /** @deprecated Field no longer supported */
  productTileType?: Maybe<Scalars["Int"]>;
  witAIAppID?: Maybe<Scalars["String"]>;
  witAIAccessToken?: Maybe<Scalars["String"]>;
  witAILastUpdate?: Maybe<Scalars["AWSDateTime"]>;
  googleTrackingID?: Maybe<Scalars["String"]>;
  googlePlayAppEnabled?: Maybe<Scalars["Boolean"]>;
  googlePlayAppURL?: Maybe<Scalars["String"]>;
  facebookAppID?: Maybe<Scalars["String"]>;
  facebookPageURL?: Maybe<Scalars["String"]>;
  twitterUsername?: Maybe<Scalars["String"]>;
  iOSAppStoreID?: Maybe<Scalars["String"]>;
  videoGroupingName?: Maybe<Scalars["String"]>;
  videoGroupingNamePlural?: Maybe<Scalars["String"]>;
};

export type ShowChannel = {
  __typename?: "ShowChannel";
  rokuChannelEnabled: Scalars["Boolean"];
  rokuChannelType?: Maybe<Scalars["Int"]>;
  rokuChannelInstallURL?: Maybe<Scalars["String"]>;
  fireTVChannelEnabled: Scalars["Boolean"];
  fireTVInstallURL?: Maybe<Scalars["String"]>;
  fireTVDevURL?: Maybe<Scalars["String"]>;
};

export type ShowDemographicConfiguration = {
  __typename?: "ShowDemographicConfiguration";
  /** @deprecated Field no longer supported */
  introText?: Maybe<Scalars["String"]>;
  /** @deprecated Field no longer supported */
  ageEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  genderEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  ethnicityEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  educationEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  employmentEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  maritalStatusEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  incomeEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  zipcodeEnabled: Scalars["Boolean"];
  /** @deprecated Field no longer supported */
  emailEnabled: Scalars["Boolean"];
};

export type ShowProductRequestConfiguration = {
  __typename?: "ShowProductRequestConfiguration";
  enabled?: Maybe<Scalars["Boolean"]>;
  titleText?: Maybe<Scalars["String"]>;
  introText?: Maybe<Scalars["String"]>;
  episodeListEnabled?: Maybe<Scalars["Boolean"]>;
  episodeListText?: Maybe<Scalars["String"]>;
  photoGroupEnabled?: Maybe<Scalars["Boolean"]>;
  photoGroupText?: Maybe<Scalars["String"]>;
  categoryEnabled?: Maybe<Scalars["Boolean"]>;
  categoryText?: Maybe<Scalars["String"]>;
  emailEnabled?: Maybe<Scalars["Boolean"]>;
  emailText?: Maybe<Scalars["String"]>;
  requestText?: Maybe<Scalars["String"]>;
  submitThanksText?: Maybe<Scalars["String"]>;
  notificationsList?: Maybe<Scalars["String"]>;
};

export type ShowSeason = {
  __typename?: "ShowSeason";
  showID?: Maybe<Scalars["Int"]>;
  season: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type ShowSeasonConnection = {
  __typename?: "ShowSeasonConnection";
  items?: Maybe<Array<ShowSeason>>;
};

export type ShowDesignMenuItem = {
  __typename?: "ShowDesignMenuItem";
  id: Scalars["Int"];
  showID: Scalars["Int"];
  orderID: Scalars["Int"];
  title: Scalars["String"];
  description: Scalars["String"];
  icon: Scalars["String"];
  iconColor: Scalars["String"];
  url: Scalars["String"];
  showInPullOutMenu: Scalars["Boolean"];
  showInHomeMenu: Scalars["Boolean"];
  enabled: Scalars["Boolean"];
};

export type ShowDesignMenuConnection = {
  __typename?: "ShowDesignMenuConnection";
  items?: Maybe<Array<ShowDesignMenuItem>>;
};

export type ShowMetadataItem = {
  __typename?: "ShowMetadataItem";
  id: Scalars["Int"];
  showID: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ShowMetadataItemConnection = {
  __typename?: "ShowMetadataItemConnection";
  items?: Maybe<Array<ShowMetadataItem>>;
};

export type ShowMetadataItemInput = {
  showID: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type DeleteShowMetadataItemInput = {
  id: Scalars["Int"];
  showID: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type Episode = {
  __typename?: "Episode";
  id: Scalars["Int"];
  showID: Scalars["Int"];
  showName?: Maybe<Scalars["String"]>;
  season: Scalars["Int"];
  seasonName?: Maybe<Scalars["String"]>;
  number: Scalars["Int"];
  firstRelease: Scalars["AWSDateTime"];
  added: Scalars["AWSDateTime"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  posterType: Scalars["Int"];
  poster?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["AWSURL"]>;
  posterFileID?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  videoBucket?: Maybe<Scalars["String"]>;
  videoKey?: Maybe<Scalars["String"]>;
  videoQuality: Scalars["String"];
  videoType: Scalars["String"];
  videoWidth?: Maybe<Scalars["Int"]>;
  videoHeight?: Maybe<Scalars["Int"]>;
  mediaConvertJobID?: Maybe<Scalars["String"]>;
  mediaConvertJobStatus?: Maybe<Scalars["String"]>;
  audioFingerprintID?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  rokuAddBreaks?: Maybe<Scalars["String"]>;
  releaseToOTT: Scalars["Boolean"];
  releaseToApp: Scalars["Boolean"];
  liveChannelID?: Maybe<Scalars["Int"]>;
  enableHeaderButton: Scalars["Boolean"];
  headerButtonText: Scalars["String"];
  headerButtonURL: Scalars["String"];
  disableProductScroller: Scalars["Boolean"];
  liveStreamID: Scalars["String"];
  liveStreamEnabledOnStartMenuID: Scalars["Int"];
  videoToken?: Maybe<Scalars["String"]>;
};

export type EpisodeConnection = {
  __typename?: "EpisodeConnection";
  items?: Maybe<Array<Episode>>;
};

export type EpisodeMetadataItem = {
  __typename?: "EpisodeMetadataItem";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type EpisodeMetadataItemConnection = {
  __typename?: "EpisodeMetadataItemConnection";
  items?: Maybe<Array<EpisodeMetadataItem>>;
};

export type EpisodeProduct = {
  __typename?: "EpisodeProduct";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  productID: Scalars["Int"];
  startTimecode: Scalars["String"];
  /** @deprecated Field no longer supported */
  startTimeSeconds?: Maybe<Scalars["Int"]>;
  startTimeMilliseconds: Scalars["Int"];
  endTimecode: Scalars["String"];
  /** @deprecated Field no longer supported */
  endTimeSeconds?: Maybe<Scalars["Int"]>;
  endTimeMilliseconds: Scalars["Int"];
  brandName?: Maybe<Scalars["String"]>;
  product: Product;
  vendorLogo?: Maybe<Scalars["String"]>;
  vendorLogoBucket?: Maybe<Scalars["String"]>;
  vendorLogoKey?: Maybe<Scalars["String"]>;
};

export type EpisodeProductConnection = {
  __typename?: "EpisodeProductConnection";
  items?: Maybe<Array<EpisodeProduct>>;
};

export type EpisodeStream = {
  __typename?: "EpisodeStream";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  enabled: Scalars["Boolean"];
  type: Scalars["Int"];
  bandwidth: Scalars["Int"];
  averageBandwidth: Scalars["Int"];
  codecs: Scalars["String"];
  resolution: Scalars["String"];
  frameRate: Scalars["Float"];
  targetDuration: Scalars["Int"];
  targetBandwidth: Scalars["Int"];
  filename: Scalars["String"];
  partDuration: Scalars["Int"];
  partCount: Scalars["Int"];
  videoRoot?: Maybe<Scalars["String"]>;
  videoToken?: Maybe<Scalars["String"]>;
};

export type EpisodeStreamConnection = {
  __typename?: "EpisodeStreamConnection";
  items?: Maybe<Array<EpisodeStream>>;
};

export type ProductEpisode = {
  __typename?: "ProductEpisode";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  productID: Scalars["Int"];
  startTimecode: Scalars["String"];
  startTimeMilliseconds: Scalars["Int"];
  /** @deprecated Field no longer supported */
  startTimeSeconds?: Maybe<Scalars["Int"]>;
  endTimecode: Scalars["String"];
  endTimeMilliseconds: Scalars["Int"];
  /** @deprecated Field no longer supported */
  endTimeSeconds?: Maybe<Scalars["Int"]>;
  showName: Scalars["String"];
  episodeName: Scalars["String"];
  episodeSeasonName?: Maybe<Scalars["String"]>;
  episodeSeason?: Maybe<Scalars["Int"]>;
  episodeNumber?: Maybe<Scalars["Int"]>;
};

export type ProductEpisodeConnection = {
  __typename?: "ProductEpisodeConnection";
  items?: Maybe<Array<ProductEpisode>>;
};

export type EpisodePhotoGroup = {
  __typename?: "EpisodePhotoGroup";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
  enabled: Scalars["Boolean"];
};

export type EpisodePhotoGroupConnection = {
  __typename?: "EpisodePhotoGroupConnection";
  items?: Maybe<Array<EpisodePhotoGroup>>;
};

export type EpisodePhoto = {
  __typename?: "EpisodePhoto";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  groupID: Scalars["Int"];
  groupName: Scalars["String"];
  order: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  fileID: Scalars["String"];
  extension: Scalars["String"];
  url?: Maybe<Scalars["AWSURL"]>;
  thumbnailURL?: Maybe<Scalars["AWSURL"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
};

export type EpisodePhotoConnection = {
  __typename?: "EpisodePhotoConnection";
  items?: Maybe<Array<EpisodePhoto>>;
};

export type EpisodePhotoProduct = {
  __typename?: "EpisodePhotoProduct";
  id: Scalars["Int"];
  groupID: Scalars["Int"];
  productID: Scalars["Int"];
  episodeID?: Maybe<Scalars["Int"]>;
  brandName: Scalars["String"];
  product: Product;
  /** @deprecated Field no longer supported */
  vendorLogo?: Maybe<Scalars["String"]>;
  /** @deprecated Field no longer supported */
  vendorLogoBucket?: Maybe<Scalars["String"]>;
  /** @deprecated Field no longer supported */
  vendorLogoKey?: Maybe<Scalars["String"]>;
};

export type EpisodePhotoProductConnection = {
  __typename?: "EpisodePhotoProductConnection";
  items?: Maybe<Array<EpisodePhotoProduct>>;
};

export type ProductEpisodePhotoGroup = {
  __typename?: "ProductEpisodePhotoGroup";
  id: Scalars["Int"];
  groupID: Scalars["Int"];
  groupName: Scalars["String"];
  showName: Scalars["String"];
  episode: Episode;
};

export type ProductEpisodePhotoGroupConnection = {
  __typename?: "ProductEpisodePhotoGroupConnection";
  items?: Maybe<Array<ProductEpisodePhotoGroup>>;
};

export type Product = {
  __typename?: "Product";
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  upc?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  brandID?: Maybe<Scalars["Int"]>;
  sku?: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
  added: Scalars["AWSDateTime"];
  deleted: Scalars["Boolean"];
  approved: Scalars["Boolean"];
  replacementProduct?: Maybe<Scalars["Boolean"]>;
  mainPhotoURL?: Maybe<Scalars["String"]>;
  mainPhotoFileID?: Maybe<Scalars["String"]>;
  mainPhotoFileExt?: Maybe<Scalars["String"]>;
  mainPhotoSize?: Maybe<Scalars["Float"]>;
  issueCount?: Maybe<Scalars["Int"]>;
  photoBucket?: Maybe<Scalars["String"]>;
  photoKey?: Maybe<Scalars["String"]>;
  warnings?: Maybe<Array<Scalars["String"]>>;
  originalProductName?: Maybe<Scalars["String"]>;
  originalProductDescription?: Maybe<Scalars["String"]>;
  originalProductURL?: Maybe<Scalars["String"]>;
  originalProductPhotoBucket?: Maybe<Scalars["String"]>;
  originalProductPhotoKey?: Maybe<Scalars["String"]>;
  vendorID?: Maybe<Scalars["ID"]>;
  vendorLogo?: Maybe<Scalars["String"]>;
  vendorLogoBucket?: Maybe<Scalars["String"]>;
  vendorLogoKey?: Maybe<Scalars["String"]>;
};

export type ProductRequest = {
  __typename?: "ProductRequest";
  id: Scalars["Int"];
  showID: Scalars["Int"];
  appInstallID: Scalars["Int"];
  episodeID: Scalars["Int"];
  episodeSeason?: Maybe<Scalars["String"]>;
  episodeNumber?: Maybe<Scalars["String"]>;
  episodeName?: Maybe<Scalars["String"]>;
  photoGroup?: Maybe<Scalars["String"]>;
  category: Scalars["String"];
  email: Scalars["String"];
  request: Scalars["String"];
  added: Scalars["AWSDateTime"];
  ip: Scalars["String"];
  status: Scalars["Int"];
  lastChangedByAUID?: Maybe<Scalars["String"]>;
  userSub?: Maybe<Scalars["ID"]>;
  response?: Maybe<Scalars["String"]>;
};

export type ProductRequestConnection = {
  __typename?: "ProductRequestConnection";
  items?: Maybe<Array<ProductRequest>>;
};

export type ProductLink = {
  __typename?: "ProductLink";
  id: Scalars["Int"];
  productID: Scalars["Int"];
  storeID?: Maybe<Scalars["Int"]>;
  storeName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["Int"]>;
  fullURL?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  statusLastChecked?: Maybe<Scalars["AWSDateTime"]>;
};

export type ProductLinkConnection = {
  __typename?: "ProductLinkConnection";
  items?: Maybe<Array<ProductLink>>;
};

export type ProductCategory = {
  __typename?: "ProductCategory";
  productID: Scalars["Int"];
  categoryID: Scalars["Int"];
  categoryName: Scalars["String"];
};

export type ProductCategoryConnection = {
  __typename?: "ProductCategoryConnection";
  items?: Maybe<Array<ProductCategory>>;
};

export type ProductSearchMetadata = {
  __typename?: "ProductSearchMetadata";
  productID: Scalars["Int"];
  searchData?: Maybe<Scalars["String"]>;
  witAISearchData?: Maybe<Scalars["String"]>;
  searchColor?: Maybe<Scalars["String"]>;
  searchPeople?: Maybe<Scalars["String"]>;
  lastUpdate?: Maybe<Scalars["AWSDateTime"]>;
};

export type ProductMetadataItem = {
  __typename?: "ProductMetadataItem";
  id: Scalars["Int"];
  productID: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ProductMetadataConnection = {
  __typename?: "ProductMetadataConnection";
  items?: Maybe<Array<ProductMetadataItem>>;
};

export type ProductExistsResult = {
  __typename?: "ProductExistsResult";
  product: Product;
  storeID: Scalars["Int"];
};

export type AvailableProduct = {
  __typename?: "AvailableProduct";
  product: Product;
  customerName: Scalars["String"];
};

export type AvailableProductConnection = {
  __typename?: "AvailableProductConnection";
  items?: Maybe<Array<AvailableProduct>>;
};

export type ScrapedProduct = {
  __typename?: "ScrapedProduct";
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  keywords?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  imageURL?: Maybe<Scalars["String"]>;
  domainName?: Maybe<Scalars["String"]>;
  site_name?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  imageURLs?: Maybe<Array<Scalars["String"]>>;
};

export type Store = {
  __typename?: "Store";
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name: Scalars["String"];
  active: Scalars["Boolean"];
  added: Scalars["AWSDateTime"];
  domainName?: Maybe<Scalars["String"]>;
  searchURL?: Maybe<Scalars["String"]>;
  productCount?: Maybe<Scalars["Int"]>;
};

export type StoreConnection = {
  __typename?: "StoreConnection";
  items?: Maybe<Array<Store>>;
};

export type ShowConnection = {
  __typename?: "ShowConnection";
  items?: Maybe<Array<Show>>;
};

export type ProductConnection = {
  __typename?: "ProductConnection";
  items?: Maybe<Array<Product>>;
};

export type ReportProduct = {
  __typename?: "ReportProduct";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  brandName?: Maybe<Scalars["String"]>;
  upc?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  mainPhotoURL?: Maybe<Scalars["String"]>;
  mainPhotoFileId?: Maybe<Scalars["String"]>;
  mainPhotoFileExt?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Int"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
  imagePreviews?: Maybe<Scalars["Int"]>;
  detailViews?: Maybe<Scalars["Int"]>;
  clicks?: Maybe<Scalars["Int"]>;
};

export type ReportProductConnection = {
  __typename?: "ReportProductConnection";
  id: Scalars["ID"];
  items?: Maybe<Array<ReportProduct>>;
  nextToken?: Maybe<Scalars["Boolean"]>;
};

export type ReportProductInput = {
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  brandName?: Maybe<Scalars["String"]>;
  upc?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  mainPhotoURL?: Maybe<Scalars["String"]>;
  mainPhotoFileId?: Maybe<Scalars["String"]>;
  mainPhotoFileExt?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Int"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
  imagePreviews?: Maybe<Scalars["Int"]>;
  detailViews?: Maybe<Scalars["Int"]>;
  clicks?: Maybe<Scalars["Int"]>;
};

export type ReportProductConnectionInput = {
  id: Scalars["ID"];
  items?: Maybe<Array<ReportProductInput>>;
  nextToken?: Maybe<Scalars["Boolean"]>;
};

export type S3SignedUrl = {
  __typename?: "S3SignedURL";
  url: Scalars["String"];
};

export type S3SignedUrlInput = {
  operation: Scalars["String"];
  key: Scalars["String"];
  metadata?: Maybe<Array<Maybe<S3SignedUrlMetadata>>>;
};

export type S3SignedUrlMetadata = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ShowReport = {
  __typename?: "ShowReport";
  totalSeconds?: Maybe<Scalars["Float"]>;
  totalBytes?: Maybe<Scalars["Float"]>;
  ipCount?: Maybe<Scalars["Int"]>;
  appUsers?: Maybe<Scalars["Int"]>;
  totalParts?: Maybe<Scalars["Float"]>;
  averageViews?: Maybe<Scalars["Float"]>;
  minPartViews?: Maybe<Scalars["Int"]>;
  maxPartViews?: Maybe<Scalars["Int"]>;
  deviceInfo?: Maybe<Array<Maybe<DeviceInfo>>>;
  topEpisodes?: Maybe<Array<Maybe<Episode>>>;
  dateStreams?: Maybe<Array<Maybe<DateStream>>>;
  streamDataByBandwidth?: Maybe<Array<Maybe<StreamDataByBandwidth>>>;
  streamDataByWeekday?: Maybe<Array<Maybe<StreamDataByWeekday>>>;
  streamDataByHour?: Maybe<Array<Maybe<StreamDataByHour>>>;
};

export type EpisodeReport = {
  __typename?: "EpisodeReport";
  totalSeconds?: Maybe<Scalars["Float"]>;
  totalBytes?: Maybe<Scalars["Float"]>;
  ipCount?: Maybe<Scalars["Int"]>;
  appUsers?: Maybe<Scalars["Int"]>;
  totalParts?: Maybe<Scalars["Float"]>;
  averageViews?: Maybe<Scalars["Float"]>;
  minPartViews?: Maybe<Scalars["Int"]>;
  maxPartViews?: Maybe<Scalars["Int"]>;
  dateStreams?: Maybe<Array<Maybe<DateStream>>>;
  streamDataByBandwidth?: Maybe<Array<Maybe<StreamDataByBandwidth>>>;
};

export type DeviceInfo = {
  __typename?: "DeviceInfo";
  deviceType?: Maybe<Scalars["String"]>;
  OS?: Maybe<Scalars["String"]>;
  usercount?: Maybe<Scalars["Int"]>;
  totalbytes?: Maybe<Scalars["Float"]>;
};

export type DateStream = {
  __typename?: "DateStream";
  logDate?: Maybe<Scalars["AWSDateTime"]>;
  logday?: Maybe<Scalars["AWSDateTime"]>;
  totalSecs?: Maybe<Scalars["Float"]>;
  totalbytes?: Maybe<Scalars["Float"]>;
};

export type StreamDataByBandwidth = {
  __typename?: "StreamDataByBandwidth";
  streamKbps?: Maybe<Scalars["Float"]>;
  streamPart?: Maybe<Scalars["Int"]>;
  totalhits?: Maybe<Scalars["Int"]>;
};

export type StreamDataByWeekday = {
  __typename?: "StreamDataByWeekday";
  dayNumber?: Maybe<Scalars["Int"]>;
  weekName?: Maybe<Scalars["String"]>;
  totalSecs?: Maybe<Scalars["Float"]>;
  totalbytes?: Maybe<Scalars["Float"]>;
};

export type StreamDataByHour = {
  __typename?: "StreamDataByHour";
  hourNumber?: Maybe<Scalars["String"]>;
  totalSecs?: Maybe<Scalars["Float"]>;
  totalbytes?: Maybe<Scalars["Float"]>;
};

export type EngagementReport = {
  __typename?: "EngagementReport";
  totalViews?: Maybe<Scalars["Int"]>;
  ipCount?: Maybe<Scalars["Int"]>;
  appUsers?: Maybe<Scalars["Int"]>;
  topEpisodes?: Maybe<Array<Maybe<Episode>>>;
  dateStreams?: Maybe<Array<Maybe<DateStream>>>;
  dataByWeekday?: Maybe<Array<Maybe<StreamDataByWeekday>>>;
  dataByHour?: Maybe<Array<Maybe<StreamDataByHour>>>;
};

export type EngagementDeviceInfo = {
  __typename?: "EngagementDeviceInfo";
  deviceType?: Maybe<Scalars["String"]>;
  os?: Maybe<Scalars["String"]>;
  userCount?: Maybe<Scalars["Int"]>;
};

export type EngagementDeviceInfoConnection = {
  __typename?: "EngagementDeviceInfoConnection";
  items?: Maybe<Array<EngagementDeviceInfo>>;
};

export type EngagementTopLocation = {
  __typename?: "EngagementTopLocation";
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  totalVisits?: Maybe<Scalars["Int"]>;
};

export type EngagementTopLocationConnection = {
  __typename?: "EngagementTopLocationConnection";
  items?: Maybe<Array<EngagementTopLocation>>;
};

export type EngagementEpisode = {
  __typename?: "EngagementEpisode";
  ShowId: Scalars["Int"];
  EpisodeId: Scalars["Int"];
  ShowNameShort: Scalars["String"];
  Season?: Maybe<Scalars["Int"]>;
  EpisodeNo?: Maybe<Scalars["Int"]>;
  EpisodeName?: Maybe<Scalars["String"]>;
  EpisodePoster?: Maybe<Scalars["String"]>;
  PosterFileId?: Maybe<Scalars["String"]>;
  totalViews?: Maybe<Scalars["Int"]>;
};

export type EngagementEpisodeConnection = {
  __typename?: "EngagementEpisodeConnection";
  items?: Maybe<Array<EngagementEpisode>>;
};

export type EngagementActivityWeekday = {
  __typename?: "EngagementActivityWeekday";
  dayNumber?: Maybe<Scalars["Int"]>;
  day?: Maybe<Scalars["String"]>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type EngagementActivityWeekdayConnection = {
  __typename?: "EngagementActivityWeekdayConnection";
  items?: Maybe<Array<EngagementActivityWeekday>>;
};

export type EngagementActivityDate = {
  __typename?: "EngagementActivityDate";
  date?: Maybe<Scalars["String"]>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type EngagementActivityDateConnection = {
  __typename?: "EngagementActivityDateConnection";
  items?: Maybe<Array<EngagementActivityDate>>;
};

export type DashboardReport = {
  __typename?: "DashboardReport";
  totalSeconds?: Maybe<Scalars["Float"]>;
  totalBytes?: Maybe<Scalars["Float"]>;
  previousTotalSeconds?: Maybe<Scalars["Float"]>;
  previousTotalBytes?: Maybe<Scalars["Float"]>;
  ipAddressCount?: Maybe<Scalars["Int"]>;
  previousIPAddressCount?: Maybe<Scalars["Int"]>;
  totalViews?: Maybe<Scalars["Int"]>;
  totalClicks?: Maybe<Scalars["Int"]>;
};

export type DashboardTopEpisode = {
  __typename?: "DashboardTopEpisode";
  ShowId: Scalars["Int"];
  EpisodeId: Scalars["Int"];
  ShowNameShort: Scalars["String"];
  Season?: Maybe<Scalars["Int"]>;
  EpisodeNo?: Maybe<Scalars["Int"]>;
  EpisodeName?: Maybe<Scalars["String"]>;
  EpisodePoster?: Maybe<Scalars["String"]>;
  PosterFileId?: Maybe<Scalars["String"]>;
  totalViews?: Maybe<Scalars["Int"]>;
};

export type DashboardTopEpisodeConnection = {
  __typename?: "DashboardTopEpisodeConnection";
  items?: Maybe<Array<DashboardTopEpisode>>;
};

export type DashboardProductImpression = {
  __typename?: "DashboardProductImpression";
  productId: Scalars["Int"];
  BrandName?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  MainPhotoURL?: Maybe<Scalars["String"]>;
  MainPhotoFileId?: Maybe<Scalars["String"]>;
  MainPhotoFileExt?: Maybe<Scalars["String"]>;
  totalViews?: Maybe<Scalars["Int"]>;
};

export type DashboardProductImpressionConnection = {
  __typename?: "DashboardProductImpressionConnection";
  items?: Maybe<Array<DashboardProductImpression>>;
};

export type Category = {
  __typename?: "Category";
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name: Scalars["String"];
  image: Scalars["String"];
  imageURL: Scalars["AWSURL"];
  imageFileSize: Scalars["String"];
  active?: Maybe<Scalars["Boolean"]>;
  thumbnail: Scalars["String"];
  thumbnailURL: Scalars["AWSURL"];
  thumbnailFileSize: Scalars["String"];
  tag: Scalars["String"];
  description: Scalars["String"];
};

export type CategoryConnection = {
  __typename?: "CategoryConnection";
  items?: Maybe<Array<Category>>;
};

export type FontTarget = {
  __typename?: "FontTarget";
  id: Scalars["Int"];
  styleClassName: Scalars["String"];
  displayName: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
  added?: Maybe<Scalars["AWSDateTime"]>;
};

export type FontTargetConnection = {
  __typename?: "FontTargetConnection";
  items?: Maybe<Array<FontTarget>>;
};

export type WalmartProduct = {
  __typename?: "WalmartProduct";
  id: Scalars["Int"];
  parentID: Scalars["Int"];
  name: Scalars["String"];
  msrp?: Maybe<Scalars["Float"]>;
  salePrice?: Maybe<Scalars["Float"]>;
  upc?: Maybe<Scalars["String"]>;
  modelNumber?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  productURL?: Maybe<Scalars["String"]>;
  affiliateURL?: Maybe<Scalars["String"]>;
  thumbnailImageURL?: Maybe<Scalars["String"]>;
  imageURL?: Maybe<Scalars["String"]>;
};

export type WalmartProductConnection = {
  __typename?: "WalmartProductConnection";
  items?: Maybe<Array<WalmartProduct>>;
};

export type Brand = {
  __typename?: "Brand";
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name: Scalars["String"];
  productCount?: Maybe<Scalars["Int"]>;
};

export type BrandConnection = {
  __typename?: "BrandConnection";
  items?: Maybe<Array<Brand>>;
};

export type EmailReportSetting = {
  __typename?: "EmailReportSetting";
  id: Scalars["Int"];
  userID: Scalars["ID"];
  reportType: EmailReportType;
  daily: Scalars["Boolean"];
  weekly: Scalars["Boolean"];
  monthly: Scalars["Boolean"];
  yearly: Scalars["Boolean"];
  customerID: Scalars["Int"];
  customerName: Scalars["String"];
  showID: Scalars["Int"];
  showName: Scalars["String"];
  lastRun?: Maybe<Scalars["AWSDateTime"]>;
  lastRunTime?: Maybe<Scalars["Int"]>;
};

export type EmailReportSettingConnection = {
  __typename?: "EmailReportSettingConnection";
  items?: Maybe<Array<EmailReportSetting>>;
};

export type VendorProduct = {
  __typename?: "VendorProduct";
  vendorID: Scalars["ID"];
  productID: Scalars["Int"];
  owned: Scalars["Boolean"];
  assigned: Scalars["AWSDateTime"];
  unassigned?: Maybe<Scalars["AWSDateTime"]>;
  product: Product;
};

export type VendorProductConnection = {
  __typename?: "VendorProductConnection";
  items?: Maybe<Array<VendorProduct>>;
};

export type EpisodeCommercial = {
  __typename?: "EpisodeCommercial";
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
  commercialEpisodeID: Scalars["Int"];
  type: Scalars["Int"];
  spliceTime?: Maybe<Scalars["Int"]>;
  showID?: Maybe<Scalars["Int"]>;
  episodeName?: Maybe<Scalars["String"]>;
  episodePoster?: Maybe<Scalars["String"]>;
  episodePosterURL?: Maybe<Scalars["AWSURL"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  duration?: Maybe<Scalars["Int"]>;
};

export type EpisodeCommercialConnection = {
  __typename?: "EpisodeCommercialConnection";
  items?: Maybe<Array<EpisodeCommercial>>;
};

export type CreateEpisodeCommercialInput = {
  episodeID: Scalars["Int"];
  commercialEpisodeID: Scalars["Int"];
  type: Scalars["Int"];
  spliceTime?: Maybe<Scalars["Int"]>;
};

export type AppUsageLog = {
  __typename?: "AppUsageLog";
  id: Scalars["Int"];
  date?: Maybe<Scalars["AWSDateTime"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  userAgent?: Maybe<Scalars["String"]>;
  referer?: Maybe<Scalars["String"]>;
  userID?: Maybe<Scalars["Int"]>;
  appID?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  episodeID?: Maybe<Scalars["Int"]>;
  productID?: Maybe<Scalars["Int"]>;
  linkID?: Maybe<Scalars["Int"]>;
  logEntry?: Maybe<Scalars["String"]>;
  logData?: Maybe<Scalars["String"]>;
};

export type EpisodeMetaTags = {
  __typename?: "EpisodeMetaTags";
  episodeID: Scalars["Int"];
  OG_TITLE: Scalars["String"];
  OG_DESCRIPTION: Scalars["String"];
  OG_IMAGE: Scalars["String"];
  FAVICON: Scalars["String"];
  MANIFEST: Scalars["String"];
};

export type ProductMetaTags = {
  __typename?: "ProductMetaTags";
  productID: Scalars["Int"];
  OG_TITLE: Scalars["String"];
  OG_DESCRIPTION: Scalars["String"];
  OG_IMAGE: Scalars["String"];
  FAVICON: Scalars["String"];
  MANIFEST: Scalars["String"];
};

export type ShowFeed = {
  __typename?: "ShowFeed";
  showName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  imageURL?: Maybe<Scalars["String"]>;
  lastUpdated?: Maybe<Scalars["AWSDateTime"]>;
  providerName?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<ShowFeedCategory>>>;
  playlists?: Maybe<Array<Maybe<ShowFeedPlaylist>>>;
  movies?: Maybe<Array<Maybe<ShowFeedMovie>>>;
  series?: Maybe<Array<Maybe<ShowFeedSeries>>>;
  shortFormVideos?: Maybe<Array<Maybe<ShowFeedShortFormVideo>>>;
  tvSpecials?: Maybe<Array<Maybe<ShowFeedTvSpecial>>>;
};

export type ShowFeedCategory = {
  __typename?: "ShowFeedCategory";
  playlistName: Scalars["String"];
  name: Scalars["String"];
  order: Scalars["String"];
};

export type ShowFeedPlaylist = {
  __typename?: "ShowFeedPlaylist";
  name: Scalars["String"];
  itemIds: Array<Maybe<Scalars["String"]>>;
};

export type ShowFeedMovie = {
  __typename?: "ShowFeedMovie";
  id: Scalars["String"];
};

export type ShowFeedSeries = {
  __typename?: "ShowFeedSeries";
  id: Scalars["String"];
};

export type ShowFeedShortFormVideo = {
  __typename?: "ShowFeedShortFormVideo";
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  season?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  content: ShowFeedShortFormVideoContent;
  thumbnail: Scalars["String"];
  shortDescription: Scalars["String"];
  releaseDate: Scalars["String"];
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ShowFeedShortFormVideoContent = {
  __typename?: "ShowFeedShortFormVideoContent";
  dateAdded: Scalars["String"];
  videos?: Maybe<Array<Maybe<ShowFeedShortFormVideoContentVideo>>>;
  duration: Scalars["Int"];
  adBreaks?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ShowFeedShortFormVideoContentVideo = {
  __typename?: "ShowFeedShortFormVideoContentVideo";
  url: Scalars["String"];
  quality: Scalars["String"];
  videoType: Scalars["String"];
};

export type ShowFeedTvSpecial = {
  __typename?: "ShowFeedTVSpecial";
  id: Scalars["String"];
};

export type UserWishlist = {
  __typename?: "UserWishlist";
  userID: Scalars["ID"];
  productID: Scalars["Int"];
  added: Scalars["AWSDateTime"];
};

export type Query = {
  __typename?: "Query";
  getDashboardReport?: Maybe<DashboardReport>;
  getDashboardTopTenEpisodes?: Maybe<DashboardTopEpisodeConnection>;
  getDashboardTopTenProductsImpressions?: Maybe<
    DashboardProductImpressionConnection
  >;
  getDashboardTopTenProductsViews?: Maybe<DashboardProductImpressionConnection>;
  getDashboardTopTenProductsClicks?: Maybe<
    DashboardProductImpressionConnection
  >;
  listCustomers?: Maybe<CustomerConnection>;
  getCustomer?: Maybe<Customer>;
  listShows?: Maybe<ShowConnection>;
  getShow?: Maybe<Show>;
  getShowByHostname?: Maybe<Show>;
  getShowReport?: Maybe<ShowReport>;
  getShowSeasons?: Maybe<ShowSeasonConnection>;
  getShowDesignMenu?: Maybe<ShowDesignMenuConnection>;
  getShowMetadata?: Maybe<ShowMetadataItemConnection>;
  listEpisodes?: Maybe<EpisodeConnection>;
  listAppEpisodes?: Maybe<EpisodeConnection>;
  listOttEpisodes?: Maybe<EpisodeConnection>;
  listCustomerEpisodes?: Maybe<EpisodeConnection>;
  listCustomerEpisodesWithPhotoGroups?: Maybe<EpisodeConnection>;
  getEpisode?: Maybe<Episode>;
  listEpisodeCommercials?: Maybe<EpisodeCommercialConnection>;
  listCommercials?: Maybe<EpisodeConnection>;
  getEpisodeMetadata?: Maybe<EpisodeMetadataItemConnection>;
  getEpisodeReport?: Maybe<EpisodeReport>;
  getEpisodeProducts?: Maybe<EpisodeProductConnection>;
  getEpisodePhotoGroups?: Maybe<EpisodePhotoGroupConnection>;
  getEpisodePhotos?: Maybe<EpisodePhotoConnection>;
  getEpisodePhotosByGroup?: Maybe<EpisodePhotoConnection>;
  getEpisodePhotoProducts?: Maybe<EpisodePhotoProductConnection>;
  getEpisodePhotoProductsByGroup?: Maybe<EpisodePhotoProductConnection>;
  getEpisodeStreams?: Maybe<EpisodeStreamConnection>;
  getEpisodeStream?: Maybe<EpisodeStream>;
  listProducts?: Maybe<ProductConnection>;
  getProduct?: Maybe<Product>;
  listRelatedProducts?: Maybe<ProductConnection>;
  listShowFeaturedProducts?: Maybe<ProductConnection>;
  listAvailableProducts?: Maybe<AvailableProductConnection>;
  getProductLinks?: Maybe<ProductLinkConnection>;
  getProductCategories?: Maybe<ProductCategoryConnection>;
  getProductSearchMetadata?: Maybe<ProductSearchMetadata>;
  getProductMetadata?: Maybe<ProductMetadataConnection>;
  getProductEpisodes?: Maybe<ProductEpisodeConnection>;
  getProductFeaturedEpisodes?: Maybe<ProductEpisodeConnection>;
  getProductEpisodePhotoGroups?: Maybe<ProductEpisodePhotoGroupConnection>;
  getProductVendor?: Maybe<Vendor>;
  getProductRequestsByShow?: Maybe<ProductRequestConnection>;
  getProductRequestsByUser?: Maybe<ProductRequestConnection>;
  getProductsReport?: Maybe<Scalars["ID"]>;
  getVendorProductsReport?: Maybe<Scalars["ID"]>;
  getEngagementDeviceInfo?: Maybe<EngagementDeviceInfoConnection>;
  getEngagementTopTwentyLocations?: Maybe<EngagementTopLocationConnection>;
  getEngagementEpisodeViews?: Maybe<EngagementEpisodeConnection>;
  getEngagementActivityByWeekday?: Maybe<EngagementActivityWeekdayConnection>;
  getEngagementActivityByDate?: Maybe<EngagementActivityDateConnection>;
  checkIfProductExists?: Maybe<ProductExistsResult>;
  scrapeProductURL?: Maybe<ScrapedProduct>;
  walmartSearch?: Maybe<WalmartProductConnection>;
  listStores?: Maybe<StoreConnection>;
  listActiveStores?: Maybe<StoreConnection>;
  getStore?: Maybe<Store>;
  listCategories?: Maybe<CategoryConnection>;
  listActiveCategories?: Maybe<CategoryConnection>;
  listActiveCategoriesByShow?: Maybe<CategoryConnection>;
  getCategory?: Maybe<Category>;
  listBrands?: Maybe<BrandConnection>;
  getBrand?: Maybe<Brand>;
  listBrandProducts?: Maybe<ProductConnection>;
  listActiveFontTargets?: Maybe<FontTargetConnection>;
  listUsers?: Maybe<UserConnection>;
  listVoxiAdmins?: Maybe<UserConnection>;
  getUser?: Maybe<User>;
  listEmailReportSettings?: Maybe<EmailReportSettingConnection>;
  textSearch?: Maybe<ProductConnection>;
  getShowManifest?: Maybe<ShowManifest>;
  getEpisodeMetaTags?: Maybe<EpisodeMetaTags>;
  getProductMetaTags?: Maybe<ProductMetaTags>;
  getShowFeed?: Maybe<ShowFeed>;
  getUserWishlist?: Maybe<ProductConnection>;
  productInWishlist?: Maybe<Scalars["Boolean"]>;
  getExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  listShowSmallBusinessVendors?: Maybe<VendorConnection>;
  listVendorProducts?: Maybe<VendorProductConnection>;
  listVendorShowProducts?: Maybe<VendorProductConnection>;
  getNotification?: Maybe<Notification>;
  listNotifications?: Maybe<ModelNotificationConnection>;
  getCubejsDashboardItem?: Maybe<CubejsDashboardItem>;
  listCubejsDashboardItems?: Maybe<ModelCubejsDashboardItemConnection>;
  listUserDashboardItems?: Maybe<ModelCubejsDashboardItemConnection>;
  listExclusiveVideoContents?: Maybe<ModelExclusiveVideoContentConnection>;
  listExclusiveContentsByCustomer?: Maybe<ModelExclusiveVideoContentConnection>;
  listExclusiveContentsByShow?: Maybe<ModelExclusiveVideoContentConnection>;
  getShowMetaTags?: Maybe<ShowMetaTags>;
  listShowMetaTagss?: Maybe<ModelShowMetaTagsConnection>;
  getVendor?: Maybe<Vendor>;
  listVendors?: Maybe<ModelVendorConnection>;
  listVendorsByType?: Maybe<ModelVendorConnection>;
  getShowFontV2?: Maybe<ShowFontV2>;
  listShowFontV2s?: Maybe<ModelShowFontV2Connection>;
  listFontsByShow?: Maybe<ModelShowFontV2Connection>;
  getBannerAd?: Maybe<BannerAd>;
  listBannerAds?: Maybe<ModelBannerAdConnection>;
  listBannerAdsByShow?: Maybe<ModelBannerAdConnection>;
  listBannerAdsByEpisode?: Maybe<ModelBannerAdConnection>;
  getDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
  listDigitalAssetLinkss?: Maybe<ModelDigitalAssetLinksConnection>;
};

export type QueryGetDashboardReportArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetDashboardTopTenEpisodesArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetDashboardTopTenProductsImpressionsArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetDashboardTopTenProductsViewsArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetDashboardTopTenProductsClicksArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryListCustomersArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryGetCustomerArgs = {
  id: Scalars["Int"];
};

export type QueryListShowsArgs = {
  customerID: Scalars["Int"];
};

export type QueryGetShowArgs = {
  id: Scalars["Int"];
};

export type QueryGetShowByHostnameArgs = {
  hostname: Scalars["String"];
};

export type QueryGetShowReportArgs = {
  showID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetShowSeasonsArgs = {
  showID: Scalars["Int"];
};

export type QueryGetShowDesignMenuArgs = {
  showID: Scalars["Int"];
};

export type QueryGetShowMetadataArgs = {
  showID: Scalars["Int"];
};

export type QueryListEpisodesArgs = {
  showID: Scalars["Int"];
};

export type QueryListAppEpisodesArgs = {
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryListOttEpisodesArgs = {
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryListCustomerEpisodesArgs = {
  customerID: Scalars["Int"];
};

export type QueryListCustomerEpisodesWithPhotoGroupsArgs = {
  customerID: Scalars["Int"];
};

export type QueryGetEpisodeArgs = {
  id: Scalars["Int"];
};

export type QueryListEpisodeCommercialsArgs = {
  episodeID: Scalars["Int"];
};

export type QueryListCommercialsArgs = {
  showID: Scalars["Int"];
};

export type QueryGetEpisodeMetadataArgs = {
  episodeID: Scalars["Int"];
};

export type QueryGetEpisodeReportArgs = {
  showID: Scalars["Int"];
  episodeID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetEpisodeProductsArgs = {
  episodeID: Scalars["Int"];
  active?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetEpisodePhotoGroupsArgs = {
  episodeID: Scalars["Int"];
};

export type QueryGetEpisodePhotosArgs = {
  episodeID: Scalars["Int"];
};

export type QueryGetEpisodePhotosByGroupArgs = {
  groupID: Scalars["Int"];
};

export type QueryGetEpisodePhotoProductsArgs = {
  episodeID: Scalars["Int"];
};

export type QueryGetEpisodePhotoProductsByGroupArgs = {
  groupID: Scalars["Int"];
};

export type QueryGetEpisodeStreamsArgs = {
  episodeID: Scalars["Int"];
  showID: Scalars["Int"];
};

export type QueryGetEpisodeStreamArgs = {
  episodeID: Scalars["Int"];
  streamID?: Maybe<Scalars["Int"]>;
  targetBandwidth?: Maybe<Scalars["Int"]>;
};

export type QueryListProductsArgs = {
  customerID: Scalars["Int"];
};

export type QueryGetProductArgs = {
  id: Scalars["Int"];
};

export type QueryListRelatedProductsArgs = {
  categoryID: Scalars["Int"];
  showID: Scalars["Int"];
};

export type QueryListShowFeaturedProductsArgs = {
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryGetProductLinksArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductCategoriesArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductSearchMetadataArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductMetadataArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductEpisodesArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductFeaturedEpisodesArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductEpisodePhotoGroupsArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductVendorArgs = {
  productID: Scalars["Int"];
};

export type QueryGetProductRequestsByShowArgs = {
  showID: Scalars["Int"];
  status?: Maybe<Scalars["Int"]>;
};

export type QueryGetProductRequestsByUserArgs = {
  userID?: Maybe<Scalars["ID"]>;
};

export type QueryGetProductsReportArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetVendorProductsReportArgs = {
  vendorID: Scalars["ID"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetEngagementDeviceInfoArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetEngagementTopTwentyLocationsArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetEngagementEpisodeViewsArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetEngagementActivityByWeekdayArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryGetEngagementActivityByDateArgs = {
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
};

export type QueryCheckIfProductExistsArgs = {
  customerID: Scalars["Int"];
  productURL: Scalars["String"];
};

export type QueryScrapeProductUrlArgs = {
  productURL: Scalars["String"];
};

export type QueryWalmartSearchArgs = {
  query: Scalars["String"];
};

export type QueryListStoresArgs = {
  customerID: Scalars["Int"];
};

export type QueryListActiveStoresArgs = {
  customerID: Scalars["Int"];
};

export type QueryGetStoreArgs = {
  id: Scalars["Int"];
};

export type QueryListCategoriesArgs = {
  customerID: Scalars["Int"];
};

export type QueryListActiveCategoriesArgs = {
  customerID: Scalars["Int"];
};

export type QueryListActiveCategoriesByShowArgs = {
  showID: Scalars["Int"];
};

export type QueryGetCategoryArgs = {
  id: Scalars["Int"];
};

export type QueryListBrandsArgs = {
  customerID: Scalars["Int"];
};

export type QueryGetBrandArgs = {
  id: Scalars["Int"];
};

export type QueryListBrandProductsArgs = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
};

export type QueryGetUserArgs = {
  id: Scalars["ID"];
};

export type QueryTextSearchArgs = {
  appID: Scalars["String"];
  query: Scalars["String"];
};

export type QueryGetShowManifestArgs = {
  appID: Scalars["String"];
};

export type QueryGetEpisodeMetaTagsArgs = {
  episodeID: Scalars["Int"];
};

export type QueryGetProductMetaTagsArgs = {
  productID: Scalars["Int"];
  appID: Scalars["String"];
};

export type QueryGetShowFeedArgs = {
  appID: Scalars["String"];
};

export type QueryGetUserWishlistArgs = {
  userID: Scalars["ID"];
  showID?: Maybe<Scalars["Int"]>;
};

export type QueryProductInWishlistArgs = {
  productID: Scalars["Int"];
};

export type QueryGetExclusiveVideoContentArgs = {
  id: Scalars["ID"];
  filter?: Maybe<GetExclusiveVideoContentFilterInput>;
};

export type QueryListShowSmallBusinessVendorsArgs = {
  showID: Scalars["Int"];
};

export type QueryListVendorProductsArgs = {
  vendorID: Scalars["ID"];
};

export type QueryListVendorShowProductsArgs = {
  vendorID: Scalars["ID"];
  showID: Scalars["Int"];
};

export type QueryGetNotificationArgs = {
  id: Scalars["ID"];
};

export type QueryListNotificationsArgs = {
  filter?: Maybe<ModelNotificationFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryGetCubejsDashboardItemArgs = {
  id: Scalars["ID"];
};

export type QueryListCubejsDashboardItemsArgs = {
  filter?: Maybe<ModelCubejsDashboardItemFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListUserDashboardItemsArgs = {
  userID?: Maybe<Scalars["ID"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelCubejsDashboardItemFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListExclusiveVideoContentsArgs = {
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListExclusiveContentsByCustomerArgs = {
  legacyCustomerID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListExclusiveContentsByShowArgs = {
  legacyShowID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryGetShowMetaTagsArgs = {
  appID: Scalars["ID"];
};

export type QueryListShowMetaTagssArgs = {
  appID?: Maybe<Scalars["ID"]>;
  filter?: Maybe<ModelShowMetaTagsFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortDirection?: Maybe<ModelSortDirection>;
};

export type QueryGetVendorArgs = {
  id: Scalars["ID"];
};

export type QueryListVendorsArgs = {
  filter?: Maybe<ModelVendorFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListVendorsByTypeArgs = {
  type?: Maybe<VendorBusinessType>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelVendorFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryGetShowFontV2Args = {
  id: Scalars["ID"];
};

export type QueryListShowFontV2sArgs = {
  filter?: Maybe<ModelShowFontV2FilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListFontsByShowArgs = {
  showID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelShowFontV2FilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryGetBannerAdArgs = {
  id: Scalars["ID"];
};

export type QueryListBannerAdsArgs = {
  filter?: Maybe<ModelBannerAdFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListBannerAdsByShowArgs = {
  showID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelBannerAdFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryListBannerAdsByEpisodeArgs = {
  episodeID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelBannerAdFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryGetDigitalAssetLinksArgs = {
  appID: Scalars["String"];
};

export type QueryListDigitalAssetLinkssArgs = {
  appID?: Maybe<Scalars["String"]>;
  filter?: Maybe<ModelDigitalAssetLinksFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortDirection?: Maybe<ModelSortDirection>;
};

export type CreateCustomerInput = {
  shortName: Scalars["String"];
  fullName: Scalars["String"];
  active: Scalars["Boolean"];
  added?: Maybe<Scalars["AWSDateTime"]>;
  adminType?: Maybe<Scalars["Int"]>;
  isVendor?: Maybe<Scalars["Boolean"]>;
  emailAddressIdentity?: Maybe<Scalars["String"]>;
  streamingContent?: Maybe<Scalars["Boolean"]>;
  displayShowReports?: Maybe<Scalars["Boolean"]>;
  displayEpisodeReports?: Maybe<Scalars["Boolean"]>;
};

export type UpdateCustomerInput = {
  id: Scalars["Int"];
  shortName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
  adminType?: Maybe<Scalars["Int"]>;
  isVendor?: Maybe<Scalars["Boolean"]>;
  emailAddressIdentity?: Maybe<Scalars["String"]>;
  streamingContent?: Maybe<Scalars["Boolean"]>;
  displayShowReports?: Maybe<Scalars["Boolean"]>;
  displayEpisodeReports?: Maybe<Scalars["Boolean"]>;
};

export type CreateShowInput = {
  customerID: Scalars["Int"];
  nameShort: Scalars["String"];
  nameLong: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  hideInAdmin?: Maybe<Scalars["Boolean"]>;
  mainMenuType?: Maybe<Scalars["Int"]>;
  faqContent?: Maybe<Scalars["String"]>;
  appID: Scalars["String"];
};

export type UpdateShowInput = {
  id: Scalars["Int"];
  customerID?: Maybe<Scalars["Int"]>;
  nameShort?: Maybe<Scalars["String"]>;
  nameLong?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  hideInAdmin?: Maybe<Scalars["Boolean"]>;
  mainMenuType?: Maybe<Scalars["Int"]>;
  faqContent?: Maybe<Scalars["String"]>;
};

export type UpdateShowAppDataInput = {
  id: Scalars["Int"];
  primaryColor?: Maybe<Scalars["String"]>;
  textPrimaryColor?: Maybe<Scalars["String"]>;
  secondaryColor?: Maybe<Scalars["String"]>;
  tertiaryColor?: Maybe<Scalars["String"]>;
  accentColor?: Maybe<Scalars["String"]>;
  infoColor?: Maybe<Scalars["String"]>;
  headBackgroundColor?: Maybe<Scalars["String"]>;
  headTextColor?: Maybe<Scalars["String"]>;
  menuTextColor?: Maybe<Scalars["String"]>;
  productCaroselBackgroundColor?: Maybe<Scalars["String"]>;
  productButTextColor?: Maybe<Scalars["String"]>;
  productButBackColor?: Maybe<Scalars["String"]>;
  productBut2TextColor?: Maybe<Scalars["String"]>;
  productBut2BackColor?: Maybe<Scalars["String"]>;
  autoplayEnabled?: Maybe<Scalars["Boolean"]>;
  autoplayAfterFingerprint?: Maybe<Scalars["Boolean"]>;
  copyright?: Maybe<Scalars["String"]>;
  privacyPolicy?: Maybe<Scalars["String"]>;
  appPosterDisplayType?: Maybe<Scalars["Int"]>;
  episodeTileType?: Maybe<Scalars["Int"]>;
  productTileType?: Maybe<Scalars["Int"]>;
  witAIAppID?: Maybe<Scalars["String"]>;
  witAIAccessToken?: Maybe<Scalars["String"]>;
  witAILastUpdate?: Maybe<Scalars["AWSDateTime"]>;
  googleTrackingID?: Maybe<Scalars["String"]>;
  googlePlayAppEnabled?: Maybe<Scalars["Boolean"]>;
  googlePlayAppURL?: Maybe<Scalars["String"]>;
  facebookAppID?: Maybe<Scalars["String"]>;
  facebookPageURL?: Maybe<Scalars["String"]>;
  twitterUsername?: Maybe<Scalars["String"]>;
  iOSAppStoreID?: Maybe<Scalars["String"]>;
  videoGroupingName?: Maybe<Scalars["String"]>;
  videoGroupingNamePlural?: Maybe<Scalars["String"]>;
};

export type UpdateShowChannelInput = {
  id: Scalars["Int"];
  rokuChannelEnabled?: Maybe<Scalars["Boolean"]>;
  rokuChannelType?: Maybe<Scalars["Int"]>;
  rokuChannelInstallURL?: Maybe<Scalars["String"]>;
  fireTVChannelEnabled?: Maybe<Scalars["Boolean"]>;
  fireTVInstallURL?: Maybe<Scalars["String"]>;
  fireTVDevURL?: Maybe<Scalars["String"]>;
};

export type UpdateShowProductRequestConfigurationInput = {
  id: Scalars["Int"];
  titleText?: Maybe<Scalars["String"]>;
  introText?: Maybe<Scalars["String"]>;
  episodeListEnabled?: Maybe<Scalars["Boolean"]>;
  episodeListText?: Maybe<Scalars["String"]>;
  photoGroupEnabled?: Maybe<Scalars["Boolean"]>;
  photoGroupText?: Maybe<Scalars["String"]>;
  categoryEnabled?: Maybe<Scalars["Boolean"]>;
  categoryText?: Maybe<Scalars["String"]>;
  emailEnabled?: Maybe<Scalars["Boolean"]>;
  emailText?: Maybe<Scalars["String"]>;
  requestText?: Maybe<Scalars["String"]>;
  submitThanksText?: Maybe<Scalars["String"]>;
  notificationsList?: Maybe<Scalars["String"]>;
};

export type UpdateShowImageInput = {
  showID: Scalars["Int"];
  customerID: Scalars["Int"];
  newFilename: Scalars["String"];
};

export type CreateEpisodeInput = {
  showID: Scalars["Int"];
  season: Scalars["Int"];
  number: Scalars["Int"];
  name: Scalars["String"];
  description: Scalars["String"];
  added: Scalars["AWSDateTime"];
  firstRelease: Scalars["AWSDateTime"];
  video?: Maybe<Scalars["String"]>;
  videoURL?: Maybe<Scalars["String"]>;
  audioFingerprintID?: Maybe<Scalars["String"]>;
};

export type UpdateEpisodeInput = {
  id: Scalars["Int"];
  season?: Maybe<Scalars["Int"]>;
  number?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  firstRelease?: Maybe<Scalars["AWSDateTime"]>;
  posterType?: Maybe<Scalars["Int"]>;
  poster?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["AWSURL"]>;
  posterFileID?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  videoURL?: Maybe<Scalars["String"]>;
  videoBucket?: Maybe<Scalars["String"]>;
  videoKey?: Maybe<Scalars["String"]>;
  videoQuality?: Maybe<Scalars["String"]>;
  videoType?: Maybe<Scalars["String"]>;
  videoWidth?: Maybe<Scalars["Int"]>;
  videoHeight?: Maybe<Scalars["Int"]>;
  mediaConvertJobID?: Maybe<Scalars["String"]>;
  mediaConvertJobStatus?: Maybe<Scalars["String"]>;
  audioFingerprintID?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  rokuAddBreaks?: Maybe<Scalars["String"]>;
  releaseToOTT?: Maybe<Scalars["Boolean"]>;
  releaseToApp?: Maybe<Scalars["Boolean"]>;
  liveChannelID?: Maybe<Scalars["Int"]>;
  enableHeaderButton?: Maybe<Scalars["Boolean"]>;
  headerButtonText?: Maybe<Scalars["String"]>;
  headerButtonURL?: Maybe<Scalars["String"]>;
  disableProductScroller?: Maybe<Scalars["Boolean"]>;
  liveStreamID?: Maybe<Scalars["String"]>;
  liveStreamEnabledOnStartMenuID?: Maybe<Scalars["Int"]>;
};

export type UpdateEpisodeImageInput = {
  episodeID: Scalars["Int"];
  showID: Scalars["Int"];
  customerID: Scalars["Int"];
  newFilename: Scalars["String"];
};

export type ImageUrl = {
  __typename?: "ImageUrl";
  url?: Maybe<Scalars["AWSURL"]>;
};

export type CreateProductInput = {
  customerID: Scalars["Int"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  upc?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  brandID?: Maybe<Scalars["Int"]>;
  sku?: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
  deleted: Scalars["Boolean"];
  approved: Scalars["Boolean"];
  replacementProduct?: Maybe<Scalars["Boolean"]>;
  mainPhotoURL?: Maybe<Scalars["String"]>;
  searchKeywords?: Maybe<Scalars["String"]>;
  colors?: Maybe<Array<Scalars["String"]>>;
  categories?: Maybe<Array<Scalars["Int"]>>;
};

export type UpdateProductInput = {
  id: Scalars["Int"];
  customerID?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  upc?: Maybe<Scalars["String"]>;
  brandName?: Maybe<Scalars["String"]>;
  brandID?: Maybe<Scalars["Int"]>;
  sku?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  approved?: Maybe<Scalars["Boolean"]>;
  replacementProduct?: Maybe<Scalars["Boolean"]>;
  mainPhotoURL?: Maybe<Scalars["String"]>;
  mainPhotoFileID?: Maybe<Scalars["String"]>;
  mainPhotoFileExt?: Maybe<Scalars["String"]>;
  mainPhotoSize?: Maybe<Scalars["Float"]>;
  photoBucket?: Maybe<Scalars["String"]>;
  photoKey?: Maybe<Scalars["String"]>;
  originalProductName?: Maybe<Scalars["String"]>;
  originalProductDescription?: Maybe<Scalars["String"]>;
  originalProductURL?: Maybe<Scalars["String"]>;
  originalProductPhotoBucket?: Maybe<Scalars["String"]>;
  originalProductPhotoKey?: Maybe<Scalars["String"]>;
};

export type CreateProductLinkInput = {
  customerID?: Maybe<Scalars["Int"]>;
  productID: Scalars["Int"];
  storeName: Scalars["String"];
  storeID?: Maybe<Scalars["Int"]>;
  type: Scalars["Int"];
  fullURL: Scalars["String"];
  status?: Maybe<Scalars["Int"]>;
  statusLastChecked?: Maybe<Scalars["AWSDateTime"]>;
};

export type CreateProductCategoryInput = {
  productID: Scalars["Int"];
  categories: Array<Scalars["Int"]>;
};

export type CreateEpisodeProductInput = {
  episodeID: Scalars["Int"];
  productID: Scalars["Int"];
  startTimecode: Scalars["String"];
  startTimeSeconds?: Maybe<Scalars["Int"]>;
  startTimeMilliseconds: Scalars["Int"];
  endTimecode: Scalars["String"];
  endTimeSeconds?: Maybe<Scalars["Int"]>;
  endTimeMilliseconds: Scalars["Int"];
};

export type CreateVendorProductInput = {
  vendorID: Scalars["ID"];
  productID: Scalars["Int"];
  previousVendorID?: Maybe<Scalars["ID"]>;
};

export type CreateUserInput = {
  email: Scalars["String"];
  userType: UserType;
  vendorID?: Maybe<Scalars["Int"]>;
  customerID?: Maybe<Scalars["Int"]>;
};

export type CreateCategoryInput = {
  customerID: Scalars["Int"];
  name: Scalars["String"];
  active?: Maybe<Scalars["Boolean"]>;
  tag?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateCategoryInput = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  imageURL?: Maybe<Scalars["AWSURL"]>;
  imageFileSize?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  thumbnail?: Maybe<Scalars["String"]>;
  thumbnailURL?: Maybe<Scalars["AWSURL"]>;
  thumbnailFileSize?: Maybe<Scalars["String"]>;
  tag?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CreateStoreInput = {
  customerID: Scalars["Int"];
  name: Scalars["String"];
  domainName: Scalars["String"];
  active: Scalars["Boolean"];
};

export type UpdateStoreInput = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  domainName?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type CreateEpisodePhotoInput = {
  episodeID: Scalars["Int"];
  groupID: Scalars["Int"];
  groupName: Scalars["String"];
  fileID: Scalars["String"];
  extension: Scalars["String"];
  title: Scalars["String"];
  description: Scalars["String"];
};

export type CreateEpisodePhotoProductInput = {
  groupID: Scalars["Int"];
  productID: Scalars["Int"];
};

export type CreateShowDesignMenuItemInput = {
  showID: Scalars["Int"];
  orderID: Scalars["Int"];
  title: Scalars["String"];
  description: Scalars["String"];
  icon: Scalars["String"];
  iconColor: Scalars["String"];
  url: Scalars["String"];
  showInPullOutMenu: Scalars["Boolean"];
  showInHomeMenu: Scalars["Boolean"];
  enabled: Scalars["Boolean"];
};

export type UpdateShowDesignMenuItemInput = {
  id: Scalars["Int"];
  showID?: Maybe<Scalars["Int"]>;
  orderID?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  iconColor?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  showInPullOutMenu?: Maybe<Scalars["Boolean"]>;
  showInHomeMenu?: Maybe<Scalars["Boolean"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
};

export type CreateProductMetadataItem = {
  productID: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type CreateEmailReportSettingInput = {
  userID: Scalars["ID"];
  reportType: EmailReportType;
  daily: Scalars["Boolean"];
  weekly: Scalars["Boolean"];
  monthly: Scalars["Boolean"];
  yearly: Scalars["Boolean"];
  customerID: Scalars["Int"];
  showID: Scalars["Int"];
};

export type UpdateEmailReportSettingInput = {
  id: Scalars["Int"];
  daily: Scalars["Boolean"];
  weekly: Scalars["Boolean"];
  monthly: Scalars["Boolean"];
  yearly: Scalars["Boolean"];
};

export type CreateProductRequestInput = {
  showID: Scalars["Int"];
  email: Scalars["String"];
  request: Scalars["String"];
  episodeID: Scalars["Int"];
  episodeName?: Maybe<Scalars["String"]>;
  appInstallID?: Maybe<Scalars["Int"]>;
  photoGroup?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
  ipAddress?: Maybe<Scalars["String"]>;
};

export type UpdateProductRequestInput = {
  id: Scalars["Int"];
  showID: Scalars["Int"];
  episodeName?: Maybe<Scalars["String"]>;
  appInstallID?: Maybe<Scalars["Int"]>;
  episodeID?: Maybe<Scalars["Int"]>;
  photoGroup?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  request?: Maybe<Scalars["String"]>;
  added?: Maybe<Scalars["AWSDateTime"]>;
  ip?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
};

export type ReplyProductRequestInput = {
  productRequestID: Scalars["Int"];
  customerID: Scalars["Int"];
  showID: Scalars["Int"];
  email: Scalars["String"];
  message: Scalars["String"];
};

export type UpdateVendorLogoInput = {
  id: Scalars["ID"];
  logo: Scalars["String"];
  logoFileID: Scalars["String"];
  logoFileExtension: Scalars["String"];
  logoBucket: Scalars["String"];
  logoKey: Scalars["String"];
};

export type UpdateBrandInput = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type CreateAppInstallInput = {
  appID: Scalars["String"];
  userAgent?: Maybe<Scalars["String"]>;
  appVersion?: Maybe<Scalars["String"]>;
  lastIP?: Maybe<Scalars["String"]>;
};

export type CreateAppUsageLogInput = {
  appID: Scalars["String"];
  referer: Scalars["String"];
  date?: Maybe<Scalars["AWSDateTime"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  userAgent?: Maybe<Scalars["String"]>;
  userID?: Maybe<Scalars["Int"]>;
  userSub?: Maybe<Scalars["ID"]>;
  appInstallID?: Maybe<Scalars["Int"]>;
  appVersion?: Maybe<Scalars["String"]>;
  episodeID?: Maybe<Scalars["Int"]>;
  productID?: Maybe<Scalars["Int"]>;
  linkID?: Maybe<Scalars["Int"]>;
  logEntry?: Maybe<Scalars["String"]>;
  logData?: Maybe<Scalars["String"]>;
};

export type CreateShowSeasonInput = {
  showID: Scalars["Int"];
  name: Scalars["String"];
  season: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  createUser?: Maybe<User>;
  disableUser?: Maybe<User>;
  createCustomer?: Maybe<Customer>;
  updateCustomer?: Maybe<Customer>;
  createShow?: Maybe<Show>;
  updateShow?: Maybe<Show>;
  updateShowAppData?: Maybe<ShowAppData>;
  updateShowChannel?: Maybe<ShowChannel>;
  updateShowProductRequestConfiguration?: Maybe<
    ShowProductRequestConfiguration
  >;
  updateShowPoster?: Maybe<ImageUrl>;
  updateShowAppLogo?: Maybe<ImageUrl>;
  addShowMetadata?: Maybe<ShowMetadataItem>;
  deleteShowMetadata?: Maybe<ShowMetadataItem>;
  createShowSeason?: Maybe<ShowSeason>;
  createShowDesignMenuItem?: Maybe<ShowDesignMenuItem>;
  updateShowDesignMenuItem?: Maybe<ShowDesignMenuItem>;
  deleteShowDesignMenuItem?: Maybe<Scalars["Int"]>;
  createEpisode?: Maybe<Episode>;
  updateEpisode?: Maybe<Episode>;
  updateEpisodeVideoCleanup: Scalars["Boolean"];
  updateEpisodePoster?: Maybe<ImageUrl>;
  createEpisodePhoto?: Maybe<EpisodePhoto>;
  deleteEpisodePhoto: Scalars["Int"];
  createEpisodeCommercial?: Maybe<EpisodeCommercial>;
  deleteEpisodeCommercial?: Maybe<Scalars["Int"]>;
  createProduct?: Maybe<Product>;
  updateProduct?: Maybe<Product>;
  deleteProduct?: Maybe<Scalars["Int"]>;
  createProductLink?: Maybe<ProductLink>;
  deleteProductLink?: Maybe<Scalars["Int"]>;
  createProductCategory?: Maybe<ProductCategoryConnection>;
  deleteProductCategory?: Maybe<Scalars["Int"]>;
  createEpisodeProduct?: Maybe<Scalars["Int"]>;
  deleteEpisodeProduct?: Maybe<Scalars["Int"]>;
  createEpisodePhotoProduct?: Maybe<Scalars["Int"]>;
  deleteEpisodePhotoProduct?: Maybe<Scalars["Int"]>;
  createProductMetadataItem?: Maybe<ProductMetadataItem>;
  deleteProductMetadataItem?: Maybe<Scalars["Int"]>;
  updateBrand?: Maybe<Brand>;
  createCategory?: Maybe<Category>;
  updateCategory?: Maybe<Category>;
  deleteCategory?: Maybe<Scalars["Boolean"]>;
  createStore?: Maybe<Store>;
  updateStore?: Maybe<Store>;
  createVendorProduct?: Maybe<Scalars["ID"]>;
  deleteVendorProduct?: Maybe<Scalars["Boolean"]>;
  createEmailReportSetting?: Maybe<EmailReportSetting>;
  updateEmailReportSetting?: Maybe<EmailReportSetting>;
  deleteEmailReportSetting?: Maybe<Scalars["Int"]>;
  createProductRequest?: Maybe<Scalars["Int"]>;
  updateProductRequest?: Maybe<ProductRequest>;
  replyProductRequest?: Maybe<Scalars["Int"]>;
  updateVendorLogo?: Maybe<Scalars["String"]>;
  generatePresignedURL: S3SignedUrl;
  generatePresignedRawMediaURL: S3SignedUrl;
  generatePresignedRawVideoURL: S3SignedUrl;
  resolveProductsReport?: Maybe<ReportProductConnection>;
  resolveVendorProductsReport?: Maybe<ReportProductConnection>;
  /** @deprecated Field no longer supported */
  createAppInstall?: Maybe<Scalars["Int"]>;
  /** @deprecated Field no longer supported */
  updateAppInstall?: Maybe<Scalars["Int"]>;
  createAppUsageLog?: Maybe<AppUsageLog>;
  addProductToWishlist?: Maybe<UserWishlist>;
  deleteProductFromWishlist?: Maybe<Scalars["Int"]>;
  cleanupBannerAd?: Maybe<Scalars["Boolean"]>;
  deleteExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  deleteNotification?: Maybe<Notification>;
  createVendor?: Maybe<Vendor>;
  deleteVendor?: Maybe<Vendor>;
  createShowFontV2?: Maybe<ShowFontV2>;
  updateShowFontV2?: Maybe<ShowFontV2>;
  deleteShowFontV2?: Maybe<ShowFontV2>;
  createCubejsDashboardItem?: Maybe<CubejsDashboardItem>;
  updateCubejsDashboardItem?: Maybe<CubejsDashboardItem>;
  deleteCubejsDashboardItem?: Maybe<CubejsDashboardItem>;
  createDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
  updateDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
  deleteDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
  createExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  updateExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  createShowMetaTags?: Maybe<ShowMetaTags>;
  updateShowMetaTags?: Maybe<ShowMetaTags>;
  deleteShowMetaTags?: Maybe<ShowMetaTags>;
  createNotification?: Maybe<Notification>;
  updateNotification?: Maybe<Notification>;
  updateVendor?: Maybe<Vendor>;
  createBannerAd?: Maybe<BannerAd>;
  updateBannerAd?: Maybe<BannerAd>;
  deleteBannerAd?: Maybe<BannerAd>;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDisableUserArgs = {
  id: Scalars["ID"];
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationCreateShowArgs = {
  input: CreateShowInput;
};

export type MutationUpdateShowArgs = {
  input: UpdateShowInput;
};

export type MutationUpdateShowAppDataArgs = {
  input: UpdateShowAppDataInput;
};

export type MutationUpdateShowChannelArgs = {
  input: UpdateShowChannelInput;
};

export type MutationUpdateShowProductRequestConfigurationArgs = {
  input?: Maybe<UpdateShowProductRequestConfigurationInput>;
};

export type MutationUpdateShowPosterArgs = {
  input: UpdateShowImageInput;
};

export type MutationUpdateShowAppLogoArgs = {
  input: UpdateShowImageInput;
};

export type MutationAddShowMetadataArgs = {
  input: ShowMetadataItemInput;
};

export type MutationDeleteShowMetadataArgs = {
  input: DeleteShowMetadataItemInput;
};

export type MutationCreateShowSeasonArgs = {
  input: CreateShowSeasonInput;
};

export type MutationCreateShowDesignMenuItemArgs = {
  input: CreateShowDesignMenuItemInput;
};

export type MutationUpdateShowDesignMenuItemArgs = {
  input: UpdateShowDesignMenuItemInput;
};

export type MutationDeleteShowDesignMenuItemArgs = {
  id: Scalars["Int"];
};

export type MutationCreateEpisodeArgs = {
  input: CreateEpisodeInput;
};

export type MutationUpdateEpisodeArgs = {
  input: UpdateEpisodeInput;
};

export type MutationUpdateEpisodeVideoCleanupArgs = {
  id: Scalars["Int"];
};

export type MutationUpdateEpisodePosterArgs = {
  input: UpdateEpisodeImageInput;
};

export type MutationCreateEpisodePhotoArgs = {
  input: CreateEpisodePhotoInput;
};

export type MutationDeleteEpisodePhotoArgs = {
  id: Scalars["Int"];
};

export type MutationCreateEpisodeCommercialArgs = {
  input: CreateEpisodeCommercialInput;
};

export type MutationDeleteEpisodeCommercialArgs = {
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationDeleteProductArgs = {
  id: Scalars["Int"];
};

export type MutationCreateProductLinkArgs = {
  input: CreateProductLinkInput;
};

export type MutationDeleteProductLinkArgs = {
  id: Scalars["Int"];
  productID: Scalars["Int"];
};

export type MutationCreateProductCategoryArgs = {
  input: CreateProductCategoryInput;
};

export type MutationDeleteProductCategoryArgs = {
  productID: Scalars["Int"];
  categoryID: Scalars["Int"];
};

export type MutationCreateEpisodeProductArgs = {
  input: CreateEpisodeProductInput;
};

export type MutationDeleteEpisodeProductArgs = {
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
};

export type MutationCreateEpisodePhotoProductArgs = {
  input: CreateEpisodePhotoProductInput;
};

export type MutationDeleteEpisodePhotoProductArgs = {
  id: Scalars["Int"];
};

export type MutationCreateProductMetadataItemArgs = {
  input?: Maybe<CreateProductMetadataItem>;
};

export type MutationDeleteProductMetadataItemArgs = {
  id: Scalars["Int"];
};

export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type MutationDeleteCategoryArgs = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
};

export type MutationCreateStoreArgs = {
  input: CreateStoreInput;
};

export type MutationUpdateStoreArgs = {
  input: UpdateStoreInput;
};

export type MutationCreateVendorProductArgs = {
  input: CreateVendorProductInput;
};

export type MutationDeleteVendorProductArgs = {
  vendorID: Scalars["ID"];
  productID: Scalars["Int"];
};

export type MutationCreateEmailReportSettingArgs = {
  input: CreateEmailReportSettingInput;
};

export type MutationUpdateEmailReportSettingArgs = {
  input: UpdateEmailReportSettingInput;
};

export type MutationDeleteEmailReportSettingArgs = {
  id: Scalars["Int"];
};

export type MutationCreateProductRequestArgs = {
  input?: Maybe<CreateProductRequestInput>;
};

export type MutationUpdateProductRequestArgs = {
  input?: Maybe<UpdateProductRequestInput>;
};

export type MutationReplyProductRequestArgs = {
  input?: Maybe<ReplyProductRequestInput>;
};

export type MutationUpdateVendorLogoArgs = {
  input?: Maybe<UpdateVendorLogoInput>;
};

export type MutationGeneratePresignedUrlArgs = {
  input: S3SignedUrlInput;
};

export type MutationGeneratePresignedRawMediaUrlArgs = {
  input: S3SignedUrlInput;
};

export type MutationGeneratePresignedRawVideoUrlArgs = {
  input: S3SignedUrlInput;
};

export type MutationResolveProductsReportArgs = {
  input?: Maybe<ReportProductConnectionInput>;
};

export type MutationResolveVendorProductsReportArgs = {
  input?: Maybe<ReportProductConnectionInput>;
};

export type MutationCreateAppInstallArgs = {
  input?: Maybe<CreateAppInstallInput>;
};

export type MutationUpdateAppInstallArgs = {
  id: Scalars["Int"];
};

export type MutationCreateAppUsageLogArgs = {
  input: CreateAppUsageLogInput;
};

export type MutationAddProductToWishlistArgs = {
  productID: Scalars["Int"];
};

export type MutationDeleteProductFromWishlistArgs = {
  productID: Scalars["Int"];
};

export type MutationCleanupBannerAdArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteExclusiveVideoContentArgs = {
  input: DeleteExclusiveVideoContentInput;
};

export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput;
};

export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};

export type MutationDeleteVendorArgs = {
  input: DeleteVendorInput;
};

export type MutationCreateShowFontV2Args = {
  input: CreateShowFontV2Input;
};

export type MutationUpdateShowFontV2Args = {
  input: UpdateShowFontV2Input;
};

export type MutationDeleteShowFontV2Args = {
  input: DeleteShowFontV2Input;
};

export type MutationCreateCubejsDashboardItemArgs = {
  input: CreateCubejsDashboardItemInput;
};

export type MutationUpdateCubejsDashboardItemArgs = {
  input: UpdateCubejsDashboardItemInput;
};

export type MutationDeleteCubejsDashboardItemArgs = {
  input: DeleteCubejsDashboardItemInput;
};

export type MutationCreateDigitalAssetLinksArgs = {
  input: CreateDigitalAssetLinksInput;
};

export type MutationUpdateDigitalAssetLinksArgs = {
  input: UpdateDigitalAssetLinksInput;
};

export type MutationDeleteDigitalAssetLinksArgs = {
  input: DeleteDigitalAssetLinksInput;
};

export type MutationCreateExclusiveVideoContentArgs = {
  input: CreateExclusiveVideoContentInput;
};

export type MutationUpdateExclusiveVideoContentArgs = {
  input: UpdateExclusiveVideoContentInput;
};

export type MutationCreateShowMetaTagsArgs = {
  input: CreateShowMetaTagsInput;
};

export type MutationUpdateShowMetaTagsArgs = {
  input: UpdateShowMetaTagsInput;
};

export type MutationDeleteShowMetaTagsArgs = {
  input: DeleteShowMetaTagsInput;
};

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};

export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};

export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};

export type MutationCreateBannerAdArgs = {
  input: CreateBannerAdInput;
};

export type MutationUpdateBannerAdArgs = {
  input: UpdateBannerAdInput;
};

export type MutationDeleteBannerAdArgs = {
  input: DeleteBannerAdInput;
};

export type Subscription = {
  __typename?: "Subscription";
  onCreateCustomer?: Maybe<Customer>;
  onCreateShow?: Maybe<Show>;
  onUpdateShow?: Maybe<Show>;
  onCreateEpisode?: Maybe<Episode>;
  onUpdateShowEpisode?: Maybe<Episode>;
  onUpdateEpisode?: Maybe<Episode>;
  onCreateEpisodePhoto?: Maybe<EpisodePhoto>;
  onUpdateProduct?: Maybe<Product>;
  onUpdateCategory?: Maybe<Category>;
  onResolveProductsReport?: Maybe<ReportProductConnection>;
  onResolveVendorProductsReport?: Maybe<ReportProductConnection>;
  onCreateNotification?: Maybe<Notification>;
  onUpdateNotification?: Maybe<Notification>;
  onDeleteNotification?: Maybe<Notification>;
  onCreateExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  onUpdateExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  onDeleteExclusiveVideoContent?: Maybe<ExclusiveVideoContent>;
  onCreateShowMetaTags?: Maybe<ShowMetaTags>;
  onUpdateShowMetaTags?: Maybe<ShowMetaTags>;
  onDeleteShowMetaTags?: Maybe<ShowMetaTags>;
  onCreateVendor?: Maybe<Vendor>;
  onUpdateVendor?: Maybe<Vendor>;
  onDeleteVendor?: Maybe<Vendor>;
  onCreateBannerAd?: Maybe<BannerAd>;
  onUpdateBannerAd?: Maybe<BannerAd>;
  onDeleteBannerAd?: Maybe<BannerAd>;
  onCreateDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
  onUpdateDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
  onDeleteDigitalAssetLinks?: Maybe<DigitalAssetLinks>;
};

export type SubscriptionOnCreateShowArgs = {
  customerID: Scalars["Int"];
};

export type SubscriptionOnUpdateShowArgs = {
  id: Scalars["Int"];
};

export type SubscriptionOnCreateEpisodeArgs = {
  showID: Scalars["Int"];
};

export type SubscriptionOnUpdateShowEpisodeArgs = {
  showID: Scalars["Int"];
};

export type SubscriptionOnUpdateEpisodeArgs = {
  id: Scalars["Int"];
};

export type SubscriptionOnCreateEpisodePhotoArgs = {
  episodeID: Scalars["Int"];
};

export type SubscriptionOnUpdateProductArgs = {
  id: Scalars["Int"];
};

export type SubscriptionOnUpdateCategoryArgs = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
};

export type SubscriptionOnResolveProductsReportArgs = {
  id: Scalars["ID"];
};

export type SubscriptionOnResolveVendorProductsReportArgs = {
  id: Scalars["ID"];
};

export type SubscriptionOnCreateNotificationArgs = {
  userID: Scalars["String"];
};

export type SubscriptionOnUpdateNotificationArgs = {
  userID: Scalars["String"];
};

export type SubscriptionOnDeleteNotificationArgs = {
  userID: Scalars["String"];
};

export type SubscriptionOnCreateExclusiveVideoContentArgs = {
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
};

export type SubscriptionOnUpdateExclusiveVideoContentArgs = {
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
};

export type SubscriptionOnDeleteExclusiveVideoContentArgs = {
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
};

export type SubscriptionOnCreateVendorArgs = {
  id?: Maybe<Scalars["String"]>;
};

export type SubscriptionOnUpdateVendorArgs = {
  id?: Maybe<Scalars["String"]>;
};

export type SubscriptionOnDeleteVendorArgs = {
  id?: Maybe<Scalars["String"]>;
};

export enum UserType {
  Brand = "BRAND",
  ContentAdmin = "CONTENT_ADMIN",
  VoxiAdmin = "VOXI_ADMIN"
}

export enum EmailReportType {
  Dashboard = "DASHBOARD",
  Showreport = "SHOWREPORT"
}

export enum VideoQuality {
  Hd = "HD",
  Fhd = "FHD",
  Uhd = "UHD"
}

export enum VideoType {
  Hls = "HLS",
  YouTube = "YouTube",
  Vimeo = "Vimeo"
}

export type GetExclusiveVideoContentFilterInput = {
  onlyActiveProducts?: Maybe<Scalars["Boolean"]>;
};

export type ExclusiveVideoContent = {
  __typename?: "ExclusiveVideoContent";
  id: Scalars["ID"];
  /** @deprecated Field no longer supported */
  legacyCustomerID: Scalars["Int"];
  /** @deprecated Field no longer supported */
  legacyCustomerIDString: Scalars["String"];
  /** @deprecated Field no longer supported */
  legacyShowID: Scalars["Int"];
  name: Scalars["String"];
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  firstRelease: Scalars["AWSDateTime"];
  posterBucket?: Maybe<Scalars["String"]>;
  posterKey?: Maybe<Scalars["String"]>;
  videoBucket?: Maybe<Scalars["String"]>;
  videoKey?: Maybe<Scalars["String"]>;
  videoURL?: Maybe<Scalars["String"]>;
  videoQuality?: Maybe<VideoQuality>;
  videoType?: Maybe<VideoType>;
  videoWidth?: Maybe<Scalars["Int"]>;
  videoHeight?: Maybe<Scalars["Int"]>;
  mediaConvertJobID?: Maybe<Scalars["String"]>;
  mediaConvertJobStatus?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  releaseToOTT?: Maybe<Scalars["Boolean"]>;
  releaseToApp?: Maybe<Scalars["Boolean"]>;
  videoToken?: Maybe<Scalars["String"]>;
  disableProductScroller?: Maybe<Scalars["Boolean"]>;
  productDefinitions?: Maybe<Array<ExclusiveVideoContentProductDefinition>>;
  videoProducts?: Maybe<Array<ExclusiveVideoProduct>>;
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export type ExclusiveVideoContentProductDefinition = {
  __typename?: "ExclusiveVideoContentProductDefinition";
  productID: Scalars["Int"];
  startTimeMilliseconds: Scalars["Int"];
  endTimeMilliseconds: Scalars["Int"];
};

export type ExclusiveVideoProduct = {
  __typename?: "ExclusiveVideoProduct";
  brandName?: Maybe<Scalars["String"]>;
  product: Product;
  startTimeMilliseconds: Scalars["Int"];
  endTimeMilliseconds: Scalars["Int"];
};

export type ShowMetaTags = {
  __typename?: "ShowMetaTags";
  appID: Scalars["ID"];
  showID: Scalars["Int"];
  OG_TITLE: Scalars["String"];
  OG_DESCRIPTION: Scalars["String"];
  OG_IMAGE: Scalars["String"];
  FAVICON: Scalars["String"];
  MANIFEST: Scalars["String"];
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export type Notification = {
  __typename?: "Notification";
  id: Scalars["ID"];
  userID: Scalars["ID"];
  description: Scalars["String"];
  time: Scalars["AWSDateTime"];
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export enum VendorBusinessType {
  None = "NONE",
  Enterprise = "ENTERPRISE",
  NonProfit = "NON_PROFIT",
  SmallBusiness = "SMALL_BUSINESS"
}

export type Vendor = {
  __typename?: "Vendor";
  id: Scalars["ID"];
  name: Scalars["String"];
  type?: Maybe<VendorBusinessType>;
  active: Scalars["Boolean"];
  salesOwnedByUserID: Scalars["ID"];
  balance: Scalars["Float"];
  balanceUpdated?: Maybe<Scalars["AWSDateTime"]>;
  logo?: Maybe<Scalars["String"]>;
  logoFileID?: Maybe<Scalars["String"]>;
  logoFileExtension?: Maybe<Scalars["String"]>;
  logoBucket?: Maybe<Scalars["String"]>;
  logoKey?: Maybe<Scalars["String"]>;
  pixelTrackingID?: Maybe<Scalars["ID"]>;
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export type VendorConnection = {
  __typename?: "VendorConnection";
  items?: Maybe<Array<Vendor>>;
};

export type ShowFontV2 = {
  __typename?: "ShowFontV2";
  id: Scalars["ID"];
  showFontLocationComposite: Scalars["String"];
  showID: Scalars["Int"];
  family: FontFamily;
  location: FontLocation;
  weight: FontWeight;
  style: FontStyle;
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export enum FontFamily {
  Montserrat = "MONTSERRAT",
  Oswald = "OSWALD",
  PtSans = "PT_SANS",
  Quicksand = "QUICKSAND",
  RobotoCondensed = "ROBOTO_CONDENSED",
  TitilliumWeb = "TITILLIUM_WEB",
  CrimsonText = "CRIMSON_TEXT",
  DmSerifDisplay = "DM_SERIF_DISPLAY",
  Lora = "LORA",
  Merriweather = "MERRIWEATHER",
  RobotoSlab = "ROBOTO_SLAB",
  Arial = "ARIAL",
  Roboto = "ROBOTO",
  SegoeUi = "SEGOE_UI",
  TimesNewRoman = "TIMES_NEW_ROMAN"
}

export enum FontLocation {
  AppHeader = "APP_HEADER",
  MenuButton = "MENU_BUTTON",
  PageTitleText = "PAGE_TITLE_TEXT",
  CardInfo = "CARD_INFO",
  ButtonText = "BUTTON_TEXT",
  Default = "DEFAULT"
}

export enum FontWeight {
  Normal = "NORMAL",
  Bold = "BOLD"
}

export enum FontStyle {
  Normal = "NORMAL",
  Italic = "ITALIC"
}

export type CubejsDashboardItem = {
  __typename?: "CubejsDashboardItem";
  id: Scalars["ID"];
  userID: Scalars["ID"];
  layout?: Maybe<Scalars["String"]>;
  vizState?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export type BannerAd = {
  __typename?: "BannerAd";
  id: Scalars["ID"];
  customerID: Scalars["Int"];
  showID?: Maybe<Scalars["Int"]>;
  episodeID?: Maybe<Scalars["Int"]>;
  imageBucket?: Maybe<Scalars["String"]>;
  imageKey?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export type DigitalAssetLinks = {
  __typename?: "DigitalAssetLinks";
  appID: Scalars["String"];
  legacyShowID: Scalars["Int"];
  legacyCustomerID: Scalars["Int"];
  android: Scalars["AWSJSON"];
  createdAt: Scalars["AWSDateTime"];
  updatedAt: Scalars["AWSDateTime"];
};

export type ExclusiveVideoContentProductDefinitionInput = {
  productID: Scalars["Int"];
  startTimeMilliseconds: Scalars["Int"];
  endTimeMilliseconds: Scalars["Int"];
};

export type ExclusiveVideoProductInput = {
  brandName?: Maybe<Scalars["String"]>;
  product: ProductInput;
  startTimeMilliseconds: Scalars["Int"];
  endTimeMilliseconds: Scalars["Int"];
};

export type ProductInput = {
  id: Scalars["Int"];
  customerID: Scalars["Int"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  upc?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  brandID?: Maybe<Scalars["Int"]>;
  sku?: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
  added: Scalars["AWSDateTime"];
  deleted: Scalars["Boolean"];
  approved: Scalars["Boolean"];
  replacementProduct?: Maybe<Scalars["Boolean"]>;
  mainPhotoURL?: Maybe<Scalars["String"]>;
  mainPhotoFileID?: Maybe<Scalars["String"]>;
  mainPhotoFileExt?: Maybe<Scalars["String"]>;
  mainPhotoSize?: Maybe<Scalars["Float"]>;
  issueCount?: Maybe<Scalars["Int"]>;
  photoBucket?: Maybe<Scalars["String"]>;
  photoKey?: Maybe<Scalars["String"]>;
  warnings?: Maybe<Array<Scalars["String"]>>;
  originalProductName?: Maybe<Scalars["String"]>;
  originalProductDescription?: Maybe<Scalars["String"]>;
  originalProductURL?: Maybe<Scalars["String"]>;
  originalProductPhotoBucket?: Maybe<Scalars["String"]>;
  originalProductPhotoKey?: Maybe<Scalars["String"]>;
  vendorID?: Maybe<Scalars["ID"]>;
  vendorLogo?: Maybe<Scalars["String"]>;
  vendorLogoBucket?: Maybe<Scalars["String"]>;
  vendorLogoKey?: Maybe<Scalars["String"]>;
};

export enum ModelSortDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export type ModelExclusiveVideoContentConnection = {
  __typename?: "ModelExclusiveVideoContentConnection";
  items?: Maybe<Array<Maybe<ExclusiveVideoContent>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelStringFilterInput = {
  ne?: Maybe<Scalars["String"]>;
  eq?: Maybe<Scalars["String"]>;
  le?: Maybe<Scalars["String"]>;
  lt?: Maybe<Scalars["String"]>;
  ge?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  notContains?: Maybe<Scalars["String"]>;
  between?: Maybe<Array<Maybe<Scalars["String"]>>>;
  beginsWith?: Maybe<Scalars["String"]>;
};

export type ModelIdFilterInput = {
  ne?: Maybe<Scalars["ID"]>;
  eq?: Maybe<Scalars["ID"]>;
  le?: Maybe<Scalars["ID"]>;
  lt?: Maybe<Scalars["ID"]>;
  ge?: Maybe<Scalars["ID"]>;
  gt?: Maybe<Scalars["ID"]>;
  contains?: Maybe<Scalars["ID"]>;
  notContains?: Maybe<Scalars["ID"]>;
  between?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  beginsWith?: Maybe<Scalars["ID"]>;
};

export type ModelIntFilterInput = {
  ne?: Maybe<Scalars["Int"]>;
  eq?: Maybe<Scalars["Int"]>;
  le?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  ge?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  between?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type ModelFloatFilterInput = {
  ne?: Maybe<Scalars["Float"]>;
  eq?: Maybe<Scalars["Float"]>;
  le?: Maybe<Scalars["Float"]>;
  lt?: Maybe<Scalars["Float"]>;
  ge?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  between?: Maybe<Array<Maybe<Scalars["Float"]>>>;
};

export type ModelBooleanFilterInput = {
  ne?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Boolean"]>;
};

export type ModelVideoQualityFilterInput = {
  eq?: Maybe<VideoQuality>;
  ne?: Maybe<VideoQuality>;
};

export type ModelVideoTypeFilterInput = {
  eq?: Maybe<VideoType>;
  ne?: Maybe<VideoType>;
};

export type ModelExclusiveVideoContentFilterInput = {
  id?: Maybe<ModelIdFilterInput>;
  legacyCustomerID?: Maybe<ModelIntFilterInput>;
  legacyCustomerIDString?: Maybe<ModelStringFilterInput>;
  legacyShowID?: Maybe<ModelIntFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  description?: Maybe<ModelStringFilterInput>;
  enabled?: Maybe<ModelBooleanFilterInput>;
  firstRelease?: Maybe<ModelStringFilterInput>;
  posterBucket?: Maybe<ModelStringFilterInput>;
  posterKey?: Maybe<ModelStringFilterInput>;
  videoBucket?: Maybe<ModelStringFilterInput>;
  videoKey?: Maybe<ModelStringFilterInput>;
  videoURL?: Maybe<ModelStringFilterInput>;
  videoQuality?: Maybe<ModelVideoQualityFilterInput>;
  videoType?: Maybe<ModelVideoTypeFilterInput>;
  videoWidth?: Maybe<ModelIntFilterInput>;
  videoHeight?: Maybe<ModelIntFilterInput>;
  mediaConvertJobID?: Maybe<ModelStringFilterInput>;
  mediaConvertJobStatus?: Maybe<ModelStringFilterInput>;
  duration?: Maybe<ModelFloatFilterInput>;
  releaseToOTT?: Maybe<ModelBooleanFilterInput>;
  releaseToApp?: Maybe<ModelBooleanFilterInput>;
  videoToken?: Maybe<ModelStringFilterInput>;
  disableProductScroller?: Maybe<ModelBooleanFilterInput>;
  and?: Maybe<Array<Maybe<ModelExclusiveVideoContentFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelExclusiveVideoContentFilterInput>>>;
  not?: Maybe<ModelExclusiveVideoContentFilterInput>;
};

export type CreateExclusiveVideoContentInput = {
  id?: Maybe<Scalars["ID"]>;
  legacyCustomerID: Scalars["Int"];
  legacyCustomerIDString: Scalars["String"];
  legacyShowID: Scalars["Int"];
  name: Scalars["String"];
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  firstRelease: Scalars["AWSDateTime"];
  posterBucket?: Maybe<Scalars["String"]>;
  posterKey?: Maybe<Scalars["String"]>;
  videoBucket?: Maybe<Scalars["String"]>;
  videoKey?: Maybe<Scalars["String"]>;
  videoURL?: Maybe<Scalars["String"]>;
  videoQuality?: Maybe<VideoQuality>;
  videoType?: Maybe<VideoType>;
  videoWidth?: Maybe<Scalars["Int"]>;
  videoHeight?: Maybe<Scalars["Int"]>;
  mediaConvertJobID?: Maybe<Scalars["String"]>;
  mediaConvertJobStatus?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  releaseToOTT?: Maybe<Scalars["Boolean"]>;
  releaseToApp?: Maybe<Scalars["Boolean"]>;
  videoToken?: Maybe<Scalars["String"]>;
  disableProductScroller?: Maybe<Scalars["Boolean"]>;
  productDefinitions?: Maybe<
    Array<ExclusiveVideoContentProductDefinitionInput>
  >;
  videoProducts?: Maybe<Array<ExclusiveVideoProductInput>>;
};

export type UpdateExclusiveVideoContentInput = {
  id: Scalars["ID"];
  legacyCustomerID?: Maybe<Scalars["Int"]>;
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
  legacyShowID?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  firstRelease?: Maybe<Scalars["AWSDateTime"]>;
  posterBucket?: Maybe<Scalars["String"]>;
  posterKey?: Maybe<Scalars["String"]>;
  videoBucket?: Maybe<Scalars["String"]>;
  videoKey?: Maybe<Scalars["String"]>;
  videoURL?: Maybe<Scalars["String"]>;
  videoQuality?: Maybe<VideoQuality>;
  videoType?: Maybe<VideoType>;
  videoWidth?: Maybe<Scalars["Int"]>;
  videoHeight?: Maybe<Scalars["Int"]>;
  mediaConvertJobID?: Maybe<Scalars["String"]>;
  mediaConvertJobStatus?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  releaseToOTT?: Maybe<Scalars["Boolean"]>;
  releaseToApp?: Maybe<Scalars["Boolean"]>;
  videoToken?: Maybe<Scalars["String"]>;
  disableProductScroller?: Maybe<Scalars["Boolean"]>;
  productDefinitions?: Maybe<
    Array<ExclusiveVideoContentProductDefinitionInput>
  >;
  videoProducts?: Maybe<Array<ExclusiveVideoProductInput>>;
};

export type DeleteExclusiveVideoContentInput = {
  id?: Maybe<Scalars["ID"]>;
};

export type ModelShowMetaTagsConnection = {
  __typename?: "ModelShowMetaTagsConnection";
  items?: Maybe<Array<Maybe<ShowMetaTags>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelShowMetaTagsFilterInput = {
  appID?: Maybe<ModelIdFilterInput>;
  showID?: Maybe<ModelIntFilterInput>;
  OG_TITLE?: Maybe<ModelStringFilterInput>;
  OG_DESCRIPTION?: Maybe<ModelStringFilterInput>;
  OG_IMAGE?: Maybe<ModelStringFilterInput>;
  FAVICON?: Maybe<ModelStringFilterInput>;
  MANIFEST?: Maybe<ModelStringFilterInput>;
  and?: Maybe<Array<Maybe<ModelShowMetaTagsFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelShowMetaTagsFilterInput>>>;
  not?: Maybe<ModelShowMetaTagsFilterInput>;
};

export type CreateShowMetaTagsInput = {
  appID: Scalars["ID"];
  showID: Scalars["Int"];
  OG_TITLE: Scalars["String"];
  OG_DESCRIPTION: Scalars["String"];
  OG_IMAGE: Scalars["String"];
  FAVICON: Scalars["String"];
  MANIFEST: Scalars["String"];
};

export type UpdateShowMetaTagsInput = {
  appID: Scalars["ID"];
  showID?: Maybe<Scalars["Int"]>;
  OG_TITLE?: Maybe<Scalars["String"]>;
  OG_DESCRIPTION?: Maybe<Scalars["String"]>;
  OG_IMAGE?: Maybe<Scalars["String"]>;
  FAVICON?: Maybe<Scalars["String"]>;
  MANIFEST?: Maybe<Scalars["String"]>;
};

export type DeleteShowMetaTagsInput = {
  appID: Scalars["ID"];
};

export type ModelNotificationConnection = {
  __typename?: "ModelNotificationConnection";
  items?: Maybe<Array<Maybe<Notification>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelNotificationFilterInput = {
  id?: Maybe<ModelIdFilterInput>;
  userID?: Maybe<ModelIdFilterInput>;
  description?: Maybe<ModelStringFilterInput>;
  time?: Maybe<ModelStringFilterInput>;
  and?: Maybe<Array<Maybe<ModelNotificationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelNotificationFilterInput>>>;
  not?: Maybe<ModelNotificationFilterInput>;
};

export type CreateNotificationInput = {
  id?: Maybe<Scalars["ID"]>;
  userID: Scalars["ID"];
  description: Scalars["String"];
  time: Scalars["AWSDateTime"];
};

export type UpdateNotificationInput = {
  id: Scalars["ID"];
  userID?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["AWSDateTime"]>;
};

export type DeleteNotificationInput = {
  id?: Maybe<Scalars["ID"]>;
};

export type ModelVendorConnection = {
  __typename?: "ModelVendorConnection";
  items?: Maybe<Array<Maybe<Vendor>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelVendorBusinessTypeFilterInput = {
  eq?: Maybe<VendorBusinessType>;
  ne?: Maybe<VendorBusinessType>;
};

export type ModelVendorFilterInput = {
  id?: Maybe<ModelIdFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  type?: Maybe<ModelVendorBusinessTypeFilterInput>;
  active?: Maybe<ModelBooleanFilterInput>;
  salesOwnedByUserID?: Maybe<ModelIdFilterInput>;
  balance?: Maybe<ModelFloatFilterInput>;
  balanceUpdated?: Maybe<ModelStringFilterInput>;
  logo?: Maybe<ModelStringFilterInput>;
  logoFileID?: Maybe<ModelStringFilterInput>;
  logoFileExtension?: Maybe<ModelStringFilterInput>;
  logoBucket?: Maybe<ModelStringFilterInput>;
  logoKey?: Maybe<ModelStringFilterInput>;
  pixelTrackingID?: Maybe<ModelIdFilterInput>;
  and?: Maybe<Array<Maybe<ModelVendorFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelVendorFilterInput>>>;
  not?: Maybe<ModelVendorFilterInput>;
};

export type CreateVendorInput = {
  id?: Maybe<Scalars["ID"]>;
  name: Scalars["String"];
  type?: Maybe<VendorBusinessType>;
  active: Scalars["Boolean"];
  salesOwnedByUserID: Scalars["ID"];
  balance: Scalars["Float"];
  balanceUpdated?: Maybe<Scalars["AWSDateTime"]>;
  logo?: Maybe<Scalars["String"]>;
  logoFileID?: Maybe<Scalars["String"]>;
  logoFileExtension?: Maybe<Scalars["String"]>;
  logoBucket?: Maybe<Scalars["String"]>;
  logoKey?: Maybe<Scalars["String"]>;
  pixelTrackingID?: Maybe<Scalars["ID"]>;
};

export type UpdateVendorInput = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<VendorBusinessType>;
  active?: Maybe<Scalars["Boolean"]>;
  salesOwnedByUserID?: Maybe<Scalars["ID"]>;
  balance?: Maybe<Scalars["Float"]>;
  balanceUpdated?: Maybe<Scalars["AWSDateTime"]>;
  logo?: Maybe<Scalars["String"]>;
  logoFileID?: Maybe<Scalars["String"]>;
  logoFileExtension?: Maybe<Scalars["String"]>;
  logoBucket?: Maybe<Scalars["String"]>;
  logoKey?: Maybe<Scalars["String"]>;
  pixelTrackingID?: Maybe<Scalars["ID"]>;
};

export type DeleteVendorInput = {
  id?: Maybe<Scalars["ID"]>;
};

export type ModelShowFontV2Connection = {
  __typename?: "ModelShowFontV2Connection";
  items?: Maybe<Array<Maybe<ShowFontV2>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelFontFamilyFilterInput = {
  eq?: Maybe<FontFamily>;
  ne?: Maybe<FontFamily>;
};

export type ModelFontLocationFilterInput = {
  eq?: Maybe<FontLocation>;
  ne?: Maybe<FontLocation>;
};

export type ModelFontWeightFilterInput = {
  eq?: Maybe<FontWeight>;
  ne?: Maybe<FontWeight>;
};

export type ModelFontStyleFilterInput = {
  eq?: Maybe<FontStyle>;
  ne?: Maybe<FontStyle>;
};

export type ModelShowFontV2FilterInput = {
  id?: Maybe<ModelIdFilterInput>;
  showFontLocationComposite?: Maybe<ModelStringFilterInput>;
  showID?: Maybe<ModelIntFilterInput>;
  family?: Maybe<ModelFontFamilyFilterInput>;
  location?: Maybe<ModelFontLocationFilterInput>;
  weight?: Maybe<ModelFontWeightFilterInput>;
  style?: Maybe<ModelFontStyleFilterInput>;
  and?: Maybe<Array<Maybe<ModelShowFontV2FilterInput>>>;
  or?: Maybe<Array<Maybe<ModelShowFontV2FilterInput>>>;
  not?: Maybe<ModelShowFontV2FilterInput>;
};

export type CreateShowFontV2Input = {
  id?: Maybe<Scalars["ID"]>;
  showFontLocationComposite: Scalars["String"];
  showID: Scalars["Int"];
  family: FontFamily;
  location: FontLocation;
  weight: FontWeight;
  style: FontStyle;
};

export type UpdateShowFontV2Input = {
  id: Scalars["ID"];
  showFontLocationComposite?: Maybe<Scalars["String"]>;
  showID?: Maybe<Scalars["Int"]>;
  family?: Maybe<FontFamily>;
  location?: Maybe<FontLocation>;
  weight?: Maybe<FontWeight>;
  style?: Maybe<FontStyle>;
};

export type DeleteShowFontV2Input = {
  id?: Maybe<Scalars["ID"]>;
};

export type ModelCubejsDashboardItemConnection = {
  __typename?: "ModelCubejsDashboardItemConnection";
  items?: Maybe<Array<Maybe<CubejsDashboardItem>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelCubejsDashboardItemFilterInput = {
  id?: Maybe<ModelIdFilterInput>;
  userID?: Maybe<ModelIdFilterInput>;
  layout?: Maybe<ModelStringFilterInput>;
  vizState?: Maybe<ModelStringFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  and?: Maybe<Array<Maybe<ModelCubejsDashboardItemFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelCubejsDashboardItemFilterInput>>>;
  not?: Maybe<ModelCubejsDashboardItemFilterInput>;
};

export type CreateCubejsDashboardItemInput = {
  id?: Maybe<Scalars["ID"]>;
  userID: Scalars["ID"];
  layout?: Maybe<Scalars["String"]>;
  vizState?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type UpdateCubejsDashboardItemInput = {
  id: Scalars["ID"];
  userID?: Maybe<Scalars["ID"]>;
  layout?: Maybe<Scalars["String"]>;
  vizState?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type DeleteCubejsDashboardItemInput = {
  id?: Maybe<Scalars["ID"]>;
};

export type ModelBannerAdConnection = {
  __typename?: "ModelBannerAdConnection";
  items?: Maybe<Array<Maybe<BannerAd>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelBannerAdFilterInput = {
  id?: Maybe<ModelIdFilterInput>;
  customerID?: Maybe<ModelIntFilterInput>;
  showID?: Maybe<ModelIntFilterInput>;
  episodeID?: Maybe<ModelIntFilterInput>;
  imageBucket?: Maybe<ModelStringFilterInput>;
  imageKey?: Maybe<ModelStringFilterInput>;
  url?: Maybe<ModelStringFilterInput>;
  and?: Maybe<Array<Maybe<ModelBannerAdFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelBannerAdFilterInput>>>;
  not?: Maybe<ModelBannerAdFilterInput>;
};

export type CreateBannerAdInput = {
  id?: Maybe<Scalars["ID"]>;
  customerID: Scalars["Int"];
  showID?: Maybe<Scalars["Int"]>;
  episodeID?: Maybe<Scalars["Int"]>;
  imageBucket?: Maybe<Scalars["String"]>;
  imageKey?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type UpdateBannerAdInput = {
  id: Scalars["ID"];
  customerID?: Maybe<Scalars["Int"]>;
  showID?: Maybe<Scalars["Int"]>;
  episodeID?: Maybe<Scalars["Int"]>;
  imageBucket?: Maybe<Scalars["String"]>;
  imageKey?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type DeleteBannerAdInput = {
  id?: Maybe<Scalars["ID"]>;
};

export type ModelDigitalAssetLinksConnection = {
  __typename?: "ModelDigitalAssetLinksConnection";
  items?: Maybe<Array<Maybe<DigitalAssetLinks>>>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ModelDigitalAssetLinksFilterInput = {
  appID?: Maybe<ModelStringFilterInput>;
  legacyShowID?: Maybe<ModelIntFilterInput>;
  legacyCustomerID?: Maybe<ModelIntFilterInput>;
  android?: Maybe<ModelStringFilterInput>;
  and?: Maybe<Array<Maybe<ModelDigitalAssetLinksFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDigitalAssetLinksFilterInput>>>;
  not?: Maybe<ModelDigitalAssetLinksFilterInput>;
};

export type CreateDigitalAssetLinksInput = {
  appID: Scalars["String"];
  legacyShowID: Scalars["Int"];
  legacyCustomerID: Scalars["Int"];
  android: Scalars["AWSJSON"];
};

export type UpdateDigitalAssetLinksInput = {
  appID: Scalars["String"];
  legacyShowID?: Maybe<Scalars["Int"]>;
  legacyCustomerID?: Maybe<Scalars["Int"]>;
  android?: Maybe<Scalars["AWSJSON"]>;
};

export type DeleteDigitalAssetLinksInput = {
  appID: Scalars["String"];
};

export type GetShowByHostnameCustomQueryVariables = Exact<{
  hostname: Scalars["String"];
}>;

export type GetShowByHostnameCustomQuery = { __typename?: "Query" } & {
  getShowByHostname?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "appID"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "googleTrackingID"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type ListAppEpisodesCustomQueryVariables = Exact<{
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
}>;

export type ListAppEpisodesCustomQuery = { __typename?: "Query" } & {
  listAppEpisodes?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "duration"
            | "releaseToApp"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type GetProductCustomQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetProductCustomQuery = { __typename?: "Query" } & {
  getProduct?: Maybe<
    { __typename?: "Product" } & Pick<
      Product,
      | "id"
      | "name"
      | "description"
      | "upc"
      | "brand"
      | "brandID"
      | "sku"
      | "active"
      | "added"
      | "deleted"
      | "approved"
      | "replacementProduct"
      | "mainPhotoURL"
      | "mainPhotoFileID"
      | "mainPhotoFileExt"
      | "originalProductName"
      | "originalProductDescription"
      | "originalProductURL"
      | "originalProductPhotoBucket"
      | "originalProductPhotoKey"
      | "vendorID"
      | "vendorLogo"
      | "vendorLogoBucket"
      | "vendorLogoKey"
    >
  >;
};

export type ListRelatedProductsCustomQueryVariables = Exact<{
  categoryID: Scalars["Int"];
  showID: Scalars["Int"];
}>;

export type ListRelatedProductsCustomQuery = { __typename?: "Query" } & {
  listRelatedProducts?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type GetVendorCustomQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetVendorCustomQuery = { __typename?: "Query" } & {
  getVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
    >
  >;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: "Mutation" } & {
  createUser?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      | "id"
      | "email"
      | "enabled"
      | "status"
      | "created"
      | "email_verified"
      | "given_name"
      | "family_name"
      | "gender"
      | "phone_number"
      | "customerID"
      | "vendorID"
    >
  >;
};

export type DisableUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DisableUserMutation = { __typename?: "Mutation" } & {
  disableUser?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      | "id"
      | "email"
      | "enabled"
      | "status"
      | "created"
      | "email_verified"
      | "given_name"
      | "family_name"
      | "gender"
      | "phone_number"
      | "customerID"
      | "vendorID"
    >
  >;
};

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;

export type CreateCustomerMutation = { __typename?: "Mutation" } & {
  createCustomer?: Maybe<
    { __typename?: "Customer" } & Pick<
      Customer,
      | "id"
      | "shortName"
      | "fullName"
      | "active"
      | "added"
      | "adminType"
      | "isVendor"
      | "emailAddressIdentity"
      | "streamingContent"
      | "displayShowReports"
      | "displayEpisodeReports"
    >
  >;
};

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = { __typename?: "Mutation" } & {
  updateCustomer?: Maybe<
    { __typename?: "Customer" } & Pick<
      Customer,
      | "id"
      | "shortName"
      | "fullName"
      | "active"
      | "added"
      | "adminType"
      | "isVendor"
      | "emailAddressIdentity"
      | "streamingContent"
      | "displayShowReports"
      | "displayEpisodeReports"
    >
  >;
};

export type CreateShowMutationVariables = Exact<{
  input: CreateShowInput;
}>;

export type CreateShowMutation = { __typename?: "Mutation" } & {
  createShow?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "hideInAdmin"
      | "appID"
      | "mainMenuType"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "autoplayEnabled"
            | "autoplayAfterFingerprint"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "episodeTileType"
            | "productTileType"
            | "witAIAppID"
            | "witAIAccessToken"
            | "witAILastUpdate"
            | "googleTrackingID"
            | "googlePlayAppEnabled"
            | "googlePlayAppURL"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        channel?: Maybe<
          { __typename?: "ShowChannel" } & Pick<
            ShowChannel,
            | "rokuChannelEnabled"
            | "rokuChannelType"
            | "rokuChannelInstallURL"
            | "fireTVChannelEnabled"
            | "fireTVInstallURL"
            | "fireTVDevURL"
          >
        >;
        demographicConfiguration?: Maybe<
          { __typename?: "ShowDemographicConfiguration" } & Pick<
            ShowDemographicConfiguration,
            | "introText"
            | "ageEnabled"
            | "genderEnabled"
            | "ethnicityEnabled"
            | "educationEnabled"
            | "employmentEnabled"
            | "maritalStatusEnabled"
            | "incomeEnabled"
            | "zipcodeEnabled"
            | "emailEnabled"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "enabled"
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type UpdateShowMutationVariables = Exact<{
  input: UpdateShowInput;
}>;

export type UpdateShowMutation = { __typename?: "Mutation" } & {
  updateShow?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "hideInAdmin"
      | "appID"
      | "mainMenuType"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "autoplayEnabled"
            | "autoplayAfterFingerprint"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "episodeTileType"
            | "productTileType"
            | "witAIAppID"
            | "witAIAccessToken"
            | "witAILastUpdate"
            | "googleTrackingID"
            | "googlePlayAppEnabled"
            | "googlePlayAppURL"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        channel?: Maybe<
          { __typename?: "ShowChannel" } & Pick<
            ShowChannel,
            | "rokuChannelEnabled"
            | "rokuChannelType"
            | "rokuChannelInstallURL"
            | "fireTVChannelEnabled"
            | "fireTVInstallURL"
            | "fireTVDevURL"
          >
        >;
        demographicConfiguration?: Maybe<
          { __typename?: "ShowDemographicConfiguration" } & Pick<
            ShowDemographicConfiguration,
            | "introText"
            | "ageEnabled"
            | "genderEnabled"
            | "ethnicityEnabled"
            | "educationEnabled"
            | "employmentEnabled"
            | "maritalStatusEnabled"
            | "incomeEnabled"
            | "zipcodeEnabled"
            | "emailEnabled"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "enabled"
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type UpdateShowAppDataMutationVariables = Exact<{
  input: UpdateShowAppDataInput;
}>;

export type UpdateShowAppDataMutation = { __typename?: "Mutation" } & {
  updateShowAppData?: Maybe<
    { __typename?: "ShowAppData" } & Pick<
      ShowAppData,
      | "appLogo"
      | "appLogoURL"
      | "primaryColor"
      | "textPrimaryColor"
      | "secondaryColor"
      | "tertiaryColor"
      | "accentColor"
      | "infoColor"
      | "headBackgroundColor"
      | "headTextColor"
      | "menuTextColor"
      | "productCaroselBackgroundColor"
      | "productButTextColor"
      | "productButBackColor"
      | "productBut2TextColor"
      | "productBut2BackColor"
      | "autoplayEnabled"
      | "autoplayAfterFingerprint"
      | "copyright"
      | "privacyPolicy"
      | "appPosterDisplayType"
      | "episodeTileType"
      | "productTileType"
      | "witAIAppID"
      | "witAIAccessToken"
      | "witAILastUpdate"
      | "googleTrackingID"
      | "googlePlayAppEnabled"
      | "googlePlayAppURL"
      | "facebookAppID"
      | "facebookPageURL"
      | "twitterUsername"
      | "iOSAppStoreID"
      | "videoGroupingName"
      | "videoGroupingNamePlural"
    >
  >;
};

export type UpdateShowChannelMutationVariables = Exact<{
  input: UpdateShowChannelInput;
}>;

export type UpdateShowChannelMutation = { __typename?: "Mutation" } & {
  updateShowChannel?: Maybe<
    { __typename?: "ShowChannel" } & Pick<
      ShowChannel,
      | "rokuChannelEnabled"
      | "rokuChannelType"
      | "rokuChannelInstallURL"
      | "fireTVChannelEnabled"
      | "fireTVInstallURL"
      | "fireTVDevURL"
    >
  >;
};

export type UpdateShowProductRequestConfigurationMutationVariables = Exact<{
  input?: Maybe<UpdateShowProductRequestConfigurationInput>;
}>;

export type UpdateShowProductRequestConfigurationMutation = {
  __typename?: "Mutation";
} & {
  updateShowProductRequestConfiguration?: Maybe<
    { __typename?: "ShowProductRequestConfiguration" } & Pick<
      ShowProductRequestConfiguration,
      | "enabled"
      | "titleText"
      | "introText"
      | "episodeListEnabled"
      | "episodeListText"
      | "photoGroupEnabled"
      | "photoGroupText"
      | "categoryEnabled"
      | "categoryText"
      | "emailEnabled"
      | "emailText"
      | "requestText"
      | "submitThanksText"
      | "notificationsList"
    >
  >;
};

export type UpdateShowPosterMutationVariables = Exact<{
  input: UpdateShowImageInput;
}>;

export type UpdateShowPosterMutation = { __typename?: "Mutation" } & {
  updateShowPoster?: Maybe<{ __typename?: "ImageUrl" } & Pick<ImageUrl, "url">>;
};

export type UpdateShowAppLogoMutationVariables = Exact<{
  input: UpdateShowImageInput;
}>;

export type UpdateShowAppLogoMutation = { __typename?: "Mutation" } & {
  updateShowAppLogo?: Maybe<
    { __typename?: "ImageUrl" } & Pick<ImageUrl, "url">
  >;
};

export type AddShowMetadataMutationVariables = Exact<{
  input: ShowMetadataItemInput;
}>;

export type AddShowMetadataMutation = { __typename?: "Mutation" } & {
  addShowMetadata?: Maybe<
    { __typename?: "ShowMetadataItem" } & Pick<
      ShowMetadataItem,
      "id" | "showID" | "name" | "value"
    >
  >;
};

export type DeleteShowMetadataMutationVariables = Exact<{
  input: DeleteShowMetadataItemInput;
}>;

export type DeleteShowMetadataMutation = { __typename?: "Mutation" } & {
  deleteShowMetadata?: Maybe<
    { __typename?: "ShowMetadataItem" } & Pick<
      ShowMetadataItem,
      "id" | "showID" | "name" | "value"
    >
  >;
};

export type CreateShowSeasonMutationVariables = Exact<{
  input: CreateShowSeasonInput;
}>;

export type CreateShowSeasonMutation = { __typename?: "Mutation" } & {
  createShowSeason?: Maybe<
    { __typename?: "ShowSeason" } & Pick<
      ShowSeason,
      "showID" | "season" | "name"
    >
  >;
};

export type CreateShowDesignMenuItemMutationVariables = Exact<{
  input: CreateShowDesignMenuItemInput;
}>;

export type CreateShowDesignMenuItemMutation = { __typename?: "Mutation" } & {
  createShowDesignMenuItem?: Maybe<
    { __typename?: "ShowDesignMenuItem" } & Pick<
      ShowDesignMenuItem,
      | "id"
      | "showID"
      | "orderID"
      | "title"
      | "description"
      | "icon"
      | "iconColor"
      | "url"
      | "showInPullOutMenu"
      | "showInHomeMenu"
      | "enabled"
    >
  >;
};

export type UpdateShowDesignMenuItemMutationVariables = Exact<{
  input: UpdateShowDesignMenuItemInput;
}>;

export type UpdateShowDesignMenuItemMutation = { __typename?: "Mutation" } & {
  updateShowDesignMenuItem?: Maybe<
    { __typename?: "ShowDesignMenuItem" } & Pick<
      ShowDesignMenuItem,
      | "id"
      | "showID"
      | "orderID"
      | "title"
      | "description"
      | "icon"
      | "iconColor"
      | "url"
      | "showInPullOutMenu"
      | "showInHomeMenu"
      | "enabled"
    >
  >;
};

export type DeleteShowDesignMenuItemMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteShowDesignMenuItemMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteShowDesignMenuItem">;

export type CreateEpisodeMutationVariables = Exact<{
  input: CreateEpisodeInput;
}>;

export type CreateEpisodeMutation = { __typename?: "Mutation" } & {
  createEpisode?: Maybe<
    { __typename?: "Episode" } & Pick<
      Episode,
      | "id"
      | "showID"
      | "showName"
      | "season"
      | "seasonName"
      | "number"
      | "firstRelease"
      | "added"
      | "name"
      | "description"
      | "disclaimer"
      | "enabled"
      | "posterType"
      | "poster"
      | "posterURL"
      | "posterFileID"
      | "video"
      | "videoBucket"
      | "videoKey"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "audioFingerprintID"
      | "duration"
      | "rokuAddBreaks"
      | "releaseToOTT"
      | "releaseToApp"
      | "liveChannelID"
      | "enableHeaderButton"
      | "headerButtonText"
      | "headerButtonURL"
      | "disableProductScroller"
      | "liveStreamID"
      | "liveStreamEnabledOnStartMenuID"
      | "videoToken"
    >
  >;
};

export type UpdateEpisodeMutationVariables = Exact<{
  input: UpdateEpisodeInput;
}>;

export type UpdateEpisodeMutation = { __typename?: "Mutation" } & {
  updateEpisode?: Maybe<
    { __typename?: "Episode" } & Pick<
      Episode,
      | "id"
      | "showID"
      | "showName"
      | "season"
      | "seasonName"
      | "number"
      | "firstRelease"
      | "added"
      | "name"
      | "description"
      | "disclaimer"
      | "enabled"
      | "posterType"
      | "poster"
      | "posterURL"
      | "posterFileID"
      | "video"
      | "videoBucket"
      | "videoKey"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "audioFingerprintID"
      | "duration"
      | "rokuAddBreaks"
      | "releaseToOTT"
      | "releaseToApp"
      | "liveChannelID"
      | "enableHeaderButton"
      | "headerButtonText"
      | "headerButtonURL"
      | "disableProductScroller"
      | "liveStreamID"
      | "liveStreamEnabledOnStartMenuID"
      | "videoToken"
    >
  >;
};

export type UpdateEpisodeVideoCleanupMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UpdateEpisodeVideoCleanupMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "updateEpisodeVideoCleanup">;

export type UpdateEpisodePosterMutationVariables = Exact<{
  input: UpdateEpisodeImageInput;
}>;

export type UpdateEpisodePosterMutation = { __typename?: "Mutation" } & {
  updateEpisodePoster?: Maybe<
    { __typename?: "ImageUrl" } & Pick<ImageUrl, "url">
  >;
};

export type CreateEpisodePhotoMutationVariables = Exact<{
  input: CreateEpisodePhotoInput;
}>;

export type CreateEpisodePhotoMutation = { __typename?: "Mutation" } & {
  createEpisodePhoto?: Maybe<
    { __typename?: "EpisodePhoto" } & Pick<
      EpisodePhoto,
      | "id"
      | "episodeID"
      | "groupID"
      | "groupName"
      | "order"
      | "title"
      | "description"
      | "fileID"
      | "extension"
      | "url"
      | "thumbnailURL"
      | "enabled"
      | "added"
    >
  >;
};

export type DeleteEpisodePhotoMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteEpisodePhotoMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteEpisodePhoto"
>;

export type CreateEpisodeCommercialMutationVariables = Exact<{
  input: CreateEpisodeCommercialInput;
}>;

export type CreateEpisodeCommercialMutation = { __typename?: "Mutation" } & {
  createEpisodeCommercial?: Maybe<
    { __typename?: "EpisodeCommercial" } & Pick<
      EpisodeCommercial,
      | "id"
      | "episodeID"
      | "commercialEpisodeID"
      | "type"
      | "spliceTime"
      | "showID"
      | "episodeName"
      | "episodePoster"
      | "episodePosterURL"
      | "enabled"
      | "duration"
    >
  >;
};

export type DeleteEpisodeCommercialMutationVariables = Exact<{
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
}>;

export type DeleteEpisodeCommercialMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteEpisodeCommercial">;

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type CreateProductMutation = { __typename?: "Mutation" } & {
  createProduct?: Maybe<
    { __typename?: "Product" } & Pick<
      Product,
      | "id"
      | "customerID"
      | "name"
      | "description"
      | "upc"
      | "brand"
      | "brandID"
      | "sku"
      | "active"
      | "added"
      | "deleted"
      | "approved"
      | "replacementProduct"
      | "mainPhotoURL"
      | "mainPhotoFileID"
      | "mainPhotoFileExt"
      | "mainPhotoSize"
      | "issueCount"
      | "photoBucket"
      | "photoKey"
      | "warnings"
      | "originalProductName"
      | "originalProductDescription"
      | "originalProductURL"
      | "originalProductPhotoBucket"
      | "originalProductPhotoKey"
      | "vendorID"
      | "vendorLogo"
      | "vendorLogoBucket"
      | "vendorLogoKey"
    >
  >;
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = { __typename?: "Mutation" } & {
  updateProduct?: Maybe<
    { __typename?: "Product" } & Pick<
      Product,
      | "id"
      | "customerID"
      | "name"
      | "description"
      | "upc"
      | "brand"
      | "brandID"
      | "sku"
      | "active"
      | "added"
      | "deleted"
      | "approved"
      | "replacementProduct"
      | "mainPhotoURL"
      | "mainPhotoFileID"
      | "mainPhotoFileExt"
      | "mainPhotoSize"
      | "issueCount"
      | "photoBucket"
      | "photoKey"
      | "warnings"
      | "originalProductName"
      | "originalProductDescription"
      | "originalProductURL"
      | "originalProductPhotoBucket"
      | "originalProductPhotoKey"
      | "vendorID"
      | "vendorLogo"
      | "vendorLogoBucket"
      | "vendorLogoKey"
    >
  >;
};

export type DeleteProductMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteProduct"
>;

export type CreateProductLinkMutationVariables = Exact<{
  input: CreateProductLinkInput;
}>;

export type CreateProductLinkMutation = { __typename?: "Mutation" } & {
  createProductLink?: Maybe<
    { __typename?: "ProductLink" } & Pick<
      ProductLink,
      | "id"
      | "productID"
      | "storeID"
      | "storeName"
      | "type"
      | "fullURL"
      | "status"
      | "statusLastChecked"
    >
  >;
};

export type DeleteProductLinkMutationVariables = Exact<{
  id: Scalars["Int"];
  productID: Scalars["Int"];
}>;

export type DeleteProductLinkMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteProductLink"
>;

export type CreateProductCategoryMutationVariables = Exact<{
  input: CreateProductCategoryInput;
}>;

export type CreateProductCategoryMutation = { __typename?: "Mutation" } & {
  createProductCategory?: Maybe<
    { __typename?: "ProductCategoryConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductCategory" } & Pick<
            ProductCategory,
            "productID" | "categoryID" | "categoryName"
          >
        >
      >;
    }
  >;
};

export type DeleteProductCategoryMutationVariables = Exact<{
  productID: Scalars["Int"];
  categoryID: Scalars["Int"];
}>;

export type DeleteProductCategoryMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteProductCategory"
>;

export type CreateEpisodeProductMutationVariables = Exact<{
  input: CreateEpisodeProductInput;
}>;

export type CreateEpisodeProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createEpisodeProduct"
>;

export type DeleteEpisodeProductMutationVariables = Exact<{
  id: Scalars["Int"];
  episodeID: Scalars["Int"];
}>;

export type DeleteEpisodeProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteEpisodeProduct"
>;

export type CreateEpisodePhotoProductMutationVariables = Exact<{
  input: CreateEpisodePhotoProductInput;
}>;

export type CreateEpisodePhotoProductMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "createEpisodePhotoProduct">;

export type DeleteEpisodePhotoProductMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteEpisodePhotoProductMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteEpisodePhotoProduct">;

export type CreateProductMetadataItemMutationVariables = Exact<{
  input?: Maybe<CreateProductMetadataItem>;
}>;

export type CreateProductMetadataItemMutation = { __typename?: "Mutation" } & {
  createProductMetadataItem?: Maybe<
    { __typename?: "ProductMetadataItem" } & Pick<
      ProductMetadataItem,
      "id" | "productID" | "name" | "value"
    >
  >;
};

export type DeleteProductMetadataItemMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteProductMetadataItemMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteProductMetadataItem">;

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;

export type UpdateBrandMutation = { __typename?: "Mutation" } & {
  updateBrand?: Maybe<
    { __typename?: "Brand" } & Pick<
      Brand,
      "id" | "customerID" | "name" | "productCount"
    >
  >;
};

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;

export type CreateCategoryMutation = { __typename?: "Mutation" } & {
  createCategory?: Maybe<
    { __typename?: "Category" } & Pick<
      Category,
      | "id"
      | "customerID"
      | "name"
      | "image"
      | "imageURL"
      | "imageFileSize"
      | "active"
      | "thumbnail"
      | "thumbnailURL"
      | "thumbnailFileSize"
      | "tag"
      | "description"
    >
  >;
};

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type UpdateCategoryMutation = { __typename?: "Mutation" } & {
  updateCategory?: Maybe<
    { __typename?: "Category" } & Pick<
      Category,
      | "id"
      | "customerID"
      | "name"
      | "image"
      | "imageURL"
      | "imageFileSize"
      | "active"
      | "thumbnail"
      | "thumbnailURL"
      | "thumbnailFileSize"
      | "tag"
      | "description"
    >
  >;
};

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars["Int"];
  customerID: Scalars["Int"];
}>;

export type DeleteCategoryMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteCategory"
>;

export type CreateStoreMutationVariables = Exact<{
  input: CreateStoreInput;
}>;

export type CreateStoreMutation = { __typename?: "Mutation" } & {
  createStore?: Maybe<
    { __typename?: "Store" } & Pick<
      Store,
      | "id"
      | "customerID"
      | "name"
      | "active"
      | "added"
      | "domainName"
      | "searchURL"
      | "productCount"
    >
  >;
};

export type UpdateStoreMutationVariables = Exact<{
  input: UpdateStoreInput;
}>;

export type UpdateStoreMutation = { __typename?: "Mutation" } & {
  updateStore?: Maybe<
    { __typename?: "Store" } & Pick<
      Store,
      | "id"
      | "customerID"
      | "name"
      | "active"
      | "added"
      | "domainName"
      | "searchURL"
      | "productCount"
    >
  >;
};

export type CreateVendorProductMutationVariables = Exact<{
  input: CreateVendorProductInput;
}>;

export type CreateVendorProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createVendorProduct"
>;

export type DeleteVendorProductMutationVariables = Exact<{
  vendorID: Scalars["ID"];
  productID: Scalars["Int"];
}>;

export type DeleteVendorProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteVendorProduct"
>;

export type CreateEmailReportSettingMutationVariables = Exact<{
  input: CreateEmailReportSettingInput;
}>;

export type CreateEmailReportSettingMutation = { __typename?: "Mutation" } & {
  createEmailReportSetting?: Maybe<
    { __typename?: "EmailReportSetting" } & Pick<
      EmailReportSetting,
      | "id"
      | "userID"
      | "reportType"
      | "daily"
      | "weekly"
      | "monthly"
      | "yearly"
      | "customerID"
      | "customerName"
      | "showID"
      | "showName"
      | "lastRun"
      | "lastRunTime"
    >
  >;
};

export type UpdateEmailReportSettingMutationVariables = Exact<{
  input: UpdateEmailReportSettingInput;
}>;

export type UpdateEmailReportSettingMutation = { __typename?: "Mutation" } & {
  updateEmailReportSetting?: Maybe<
    { __typename?: "EmailReportSetting" } & Pick<
      EmailReportSetting,
      | "id"
      | "userID"
      | "reportType"
      | "daily"
      | "weekly"
      | "monthly"
      | "yearly"
      | "customerID"
      | "customerName"
      | "showID"
      | "showName"
      | "lastRun"
      | "lastRunTime"
    >
  >;
};

export type DeleteEmailReportSettingMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteEmailReportSettingMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteEmailReportSetting">;

export type CreateProductRequestMutationVariables = Exact<{
  input?: Maybe<CreateProductRequestInput>;
}>;

export type CreateProductRequestMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createProductRequest"
>;

export type UpdateProductRequestMutationVariables = Exact<{
  input?: Maybe<UpdateProductRequestInput>;
}>;

export type UpdateProductRequestMutation = { __typename?: "Mutation" } & {
  updateProductRequest?: Maybe<
    { __typename?: "ProductRequest" } & Pick<
      ProductRequest,
      | "id"
      | "showID"
      | "appInstallID"
      | "episodeID"
      | "episodeSeason"
      | "episodeNumber"
      | "episodeName"
      | "photoGroup"
      | "category"
      | "email"
      | "request"
      | "added"
      | "ip"
      | "status"
      | "lastChangedByAUID"
      | "userSub"
      | "response"
    >
  >;
};

export type ReplyProductRequestMutationVariables = Exact<{
  input?: Maybe<ReplyProductRequestInput>;
}>;

export type ReplyProductRequestMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "replyProductRequest"
>;

export type UpdateVendorLogoMutationVariables = Exact<{
  input?: Maybe<UpdateVendorLogoInput>;
}>;

export type UpdateVendorLogoMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateVendorLogo"
>;

export type GeneratePresignedUrlMutationVariables = Exact<{
  input: S3SignedUrlInput;
}>;

export type GeneratePresignedUrlMutation = { __typename?: "Mutation" } & {
  generatePresignedURL: { __typename?: "S3SignedURL" } & Pick<
    S3SignedUrl,
    "url"
  >;
};

export type GeneratePresignedRawMediaUrlMutationVariables = Exact<{
  input: S3SignedUrlInput;
}>;

export type GeneratePresignedRawMediaUrlMutation = {
  __typename?: "Mutation";
} & {
  generatePresignedRawMediaURL: { __typename?: "S3SignedURL" } & Pick<
    S3SignedUrl,
    "url"
  >;
};

export type GeneratePresignedRawVideoUrlMutationVariables = Exact<{
  input: S3SignedUrlInput;
}>;

export type GeneratePresignedRawVideoUrlMutation = {
  __typename?: "Mutation";
} & {
  generatePresignedRawVideoURL: { __typename?: "S3SignedURL" } & Pick<
    S3SignedUrl,
    "url"
  >;
};

export type ResolveProductsReportMutationVariables = Exact<{
  input?: Maybe<ReportProductConnectionInput>;
}>;

export type ResolveProductsReportMutation = { __typename?: "Mutation" } & {
  resolveProductsReport?: Maybe<
    { __typename?: "ReportProductConnection" } & Pick<
      ReportProductConnection,
      "id" | "nextToken"
    > & {
        items?: Maybe<
          Array<
            { __typename?: "ReportProduct" } & Pick<
              ReportProduct,
              | "id"
              | "name"
              | "brandName"
              | "upc"
              | "sku"
              | "mainPhotoURL"
              | "mainPhotoFileId"
              | "mainPhotoFileExt"
              | "active"
              | "deleted"
              | "added"
              | "imagePreviews"
              | "detailViews"
              | "clicks"
            >
          >
        >;
      }
  >;
};

export type ResolveVendorProductsReportMutationVariables = Exact<{
  input?: Maybe<ReportProductConnectionInput>;
}>;

export type ResolveVendorProductsReportMutation = {
  __typename?: "Mutation";
} & {
  resolveVendorProductsReport?: Maybe<
    { __typename?: "ReportProductConnection" } & Pick<
      ReportProductConnection,
      "id" | "nextToken"
    > & {
        items?: Maybe<
          Array<
            { __typename?: "ReportProduct" } & Pick<
              ReportProduct,
              | "id"
              | "name"
              | "brandName"
              | "upc"
              | "sku"
              | "mainPhotoURL"
              | "mainPhotoFileId"
              | "mainPhotoFileExt"
              | "active"
              | "deleted"
              | "added"
              | "imagePreviews"
              | "detailViews"
              | "clicks"
            >
          >
        >;
      }
  >;
};

export type CreateAppInstallMutationVariables = Exact<{
  input?: Maybe<CreateAppInstallInput>;
}>;

export type CreateAppInstallMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createAppInstall"
>;

export type UpdateAppInstallMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UpdateAppInstallMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateAppInstall"
>;

export type CreateAppUsageLogMutationVariables = Exact<{
  input: CreateAppUsageLogInput;
}>;

export type CreateAppUsageLogMutation = { __typename?: "Mutation" } & {
  createAppUsageLog?: Maybe<
    { __typename?: "AppUsageLog" } & Pick<
      AppUsageLog,
      | "id"
      | "date"
      | "ipAddress"
      | "userAgent"
      | "referer"
      | "userID"
      | "appID"
      | "version"
      | "episodeID"
      | "productID"
      | "linkID"
      | "logEntry"
      | "logData"
    >
  >;
};

export type AddProductToWishlistMutationVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type AddProductToWishlistMutation = { __typename?: "Mutation" } & {
  addProductToWishlist?: Maybe<
    { __typename?: "UserWishlist" } & Pick<
      UserWishlist,
      "userID" | "productID" | "added"
    >
  >;
};

export type DeleteProductFromWishlistMutationVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type DeleteProductFromWishlistMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteProductFromWishlist">;

export type CleanupBannerAdMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CleanupBannerAdMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "cleanupBannerAd"
>;

export type DeleteExclusiveVideoContentMutationVariables = Exact<{
  input: DeleteExclusiveVideoContentInput;
}>;

export type DeleteExclusiveVideoContentMutation = {
  __typename?: "Mutation";
} & {
  deleteExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type DeleteNotificationMutationVariables = Exact<{
  input: DeleteNotificationInput;
}>;

export type DeleteNotificationMutation = { __typename?: "Mutation" } & {
  deleteNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type CreateVendorMutationVariables = Exact<{
  input: CreateVendorInput;
}>;

export type CreateVendorMutation = { __typename?: "Mutation" } & {
  createVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type DeleteVendorMutationVariables = Exact<{
  input: DeleteVendorInput;
}>;

export type DeleteVendorMutation = { __typename?: "Mutation" } & {
  deleteVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type CreateShowFontV2MutationVariables = Exact<{
  input: CreateShowFontV2Input;
}>;

export type CreateShowFontV2Mutation = { __typename?: "Mutation" } & {
  createShowFontV2?: Maybe<
    { __typename?: "ShowFontV2" } & Pick<
      ShowFontV2,
      | "id"
      | "showFontLocationComposite"
      | "showID"
      | "family"
      | "location"
      | "weight"
      | "style"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type UpdateShowFontV2MutationVariables = Exact<{
  input: UpdateShowFontV2Input;
}>;

export type UpdateShowFontV2Mutation = { __typename?: "Mutation" } & {
  updateShowFontV2?: Maybe<
    { __typename?: "ShowFontV2" } & Pick<
      ShowFontV2,
      | "id"
      | "showFontLocationComposite"
      | "showID"
      | "family"
      | "location"
      | "weight"
      | "style"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type DeleteShowFontV2MutationVariables = Exact<{
  input: DeleteShowFontV2Input;
}>;

export type DeleteShowFontV2Mutation = { __typename?: "Mutation" } & {
  deleteShowFontV2?: Maybe<
    { __typename?: "ShowFontV2" } & Pick<
      ShowFontV2,
      | "id"
      | "showFontLocationComposite"
      | "showID"
      | "family"
      | "location"
      | "weight"
      | "style"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type CreateCubejsDashboardItemMutationVariables = Exact<{
  input: CreateCubejsDashboardItemInput;
}>;

export type CreateCubejsDashboardItemMutation = { __typename?: "Mutation" } & {
  createCubejsDashboardItem?: Maybe<
    { __typename?: "CubejsDashboardItem" } & Pick<
      CubejsDashboardItem,
      | "id"
      | "userID"
      | "layout"
      | "vizState"
      | "name"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type UpdateCubejsDashboardItemMutationVariables = Exact<{
  input: UpdateCubejsDashboardItemInput;
}>;

export type UpdateCubejsDashboardItemMutation = { __typename?: "Mutation" } & {
  updateCubejsDashboardItem?: Maybe<
    { __typename?: "CubejsDashboardItem" } & Pick<
      CubejsDashboardItem,
      | "id"
      | "userID"
      | "layout"
      | "vizState"
      | "name"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type DeleteCubejsDashboardItemMutationVariables = Exact<{
  input: DeleteCubejsDashboardItemInput;
}>;

export type DeleteCubejsDashboardItemMutation = { __typename?: "Mutation" } & {
  deleteCubejsDashboardItem?: Maybe<
    { __typename?: "CubejsDashboardItem" } & Pick<
      CubejsDashboardItem,
      | "id"
      | "userID"
      | "layout"
      | "vizState"
      | "name"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type CreateDigitalAssetLinksMutationVariables = Exact<{
  input: CreateDigitalAssetLinksInput;
}>;

export type CreateDigitalAssetLinksMutation = { __typename?: "Mutation" } & {
  createDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type UpdateDigitalAssetLinksMutationVariables = Exact<{
  input: UpdateDigitalAssetLinksInput;
}>;

export type UpdateDigitalAssetLinksMutation = { __typename?: "Mutation" } & {
  updateDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type DeleteDigitalAssetLinksMutationVariables = Exact<{
  input: DeleteDigitalAssetLinksInput;
}>;

export type DeleteDigitalAssetLinksMutation = { __typename?: "Mutation" } & {
  deleteDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type CreateExclusiveVideoContentMutationVariables = Exact<{
  input: CreateExclusiveVideoContentInput;
}>;

export type CreateExclusiveVideoContentMutation = {
  __typename?: "Mutation";
} & {
  createExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateExclusiveVideoContentMutationVariables = Exact<{
  input: UpdateExclusiveVideoContentInput;
}>;

export type UpdateExclusiveVideoContentMutation = {
  __typename?: "Mutation";
} & {
  updateExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type CreateShowMetaTagsMutationVariables = Exact<{
  input: CreateShowMetaTagsInput;
}>;

export type CreateShowMetaTagsMutation = { __typename?: "Mutation" } & {
  createShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type UpdateShowMetaTagsMutationVariables = Exact<{
  input: UpdateShowMetaTagsInput;
}>;

export type UpdateShowMetaTagsMutation = { __typename?: "Mutation" } & {
  updateShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type DeleteShowMetaTagsMutationVariables = Exact<{
  input: DeleteShowMetaTagsInput;
}>;

export type DeleteShowMetaTagsMutation = { __typename?: "Mutation" } & {
  deleteShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type CreateNotificationMutationVariables = Exact<{
  input: CreateNotificationInput;
}>;

export type CreateNotificationMutation = { __typename?: "Mutation" } & {
  createNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = { __typename?: "Mutation" } & {
  updateNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type UpdateVendorMutationVariables = Exact<{
  input: UpdateVendorInput;
}>;

export type UpdateVendorMutation = { __typename?: "Mutation" } & {
  updateVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type CreateBannerAdMutationVariables = Exact<{
  input: CreateBannerAdInput;
}>;

export type CreateBannerAdMutation = { __typename?: "Mutation" } & {
  createBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type UpdateBannerAdMutationVariables = Exact<{
  input: UpdateBannerAdInput;
}>;

export type UpdateBannerAdMutation = { __typename?: "Mutation" } & {
  updateBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type DeleteBannerAdMutationVariables = Exact<{
  input: DeleteBannerAdInput;
}>;

export type DeleteBannerAdMutation = { __typename?: "Mutation" } & {
  deleteBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type GetDashboardReportQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetDashboardReportQuery = { __typename?: "Query" } & {
  getDashboardReport?: Maybe<
    { __typename?: "DashboardReport" } & Pick<
      DashboardReport,
      | "totalSeconds"
      | "totalBytes"
      | "previousTotalSeconds"
      | "previousTotalBytes"
      | "ipAddressCount"
      | "previousIPAddressCount"
      | "totalViews"
      | "totalClicks"
    >
  >;
};

export type GetDashboardTopTenEpisodesQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetDashboardTopTenEpisodesQuery = { __typename?: "Query" } & {
  getDashboardTopTenEpisodes?: Maybe<
    { __typename?: "DashboardTopEpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "DashboardTopEpisode" } & Pick<
            DashboardTopEpisode,
            | "ShowId"
            | "EpisodeId"
            | "ShowNameShort"
            | "Season"
            | "EpisodeNo"
            | "EpisodeName"
            | "EpisodePoster"
            | "PosterFileId"
            | "totalViews"
          >
        >
      >;
    }
  >;
};

export type GetDashboardTopTenProductsImpressionsQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetDashboardTopTenProductsImpressionsQuery = {
  __typename?: "Query";
} & {
  getDashboardTopTenProductsImpressions?: Maybe<
    { __typename?: "DashboardProductImpressionConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "DashboardProductImpression" } & Pick<
            DashboardProductImpression,
            | "productId"
            | "BrandName"
            | "Name"
            | "MainPhotoURL"
            | "MainPhotoFileId"
            | "MainPhotoFileExt"
            | "totalViews"
          >
        >
      >;
    }
  >;
};

export type GetDashboardTopTenProductsViewsQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetDashboardTopTenProductsViewsQuery = { __typename?: "Query" } & {
  getDashboardTopTenProductsViews?: Maybe<
    { __typename?: "DashboardProductImpressionConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "DashboardProductImpression" } & Pick<
            DashboardProductImpression,
            | "productId"
            | "BrandName"
            | "Name"
            | "MainPhotoURL"
            | "MainPhotoFileId"
            | "MainPhotoFileExt"
            | "totalViews"
          >
        >
      >;
    }
  >;
};

export type GetDashboardTopTenProductsClicksQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetDashboardTopTenProductsClicksQuery = { __typename?: "Query" } & {
  getDashboardTopTenProductsClicks?: Maybe<
    { __typename?: "DashboardProductImpressionConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "DashboardProductImpression" } & Pick<
            DashboardProductImpression,
            | "productId"
            | "BrandName"
            | "Name"
            | "MainPhotoURL"
            | "MainPhotoFileId"
            | "MainPhotoFileExt"
            | "totalViews"
          >
        >
      >;
    }
  >;
};

export type ListCustomersQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
}>;

export type ListCustomersQuery = { __typename?: "Query" } & {
  listCustomers?: Maybe<
    { __typename?: "CustomerConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Customer" } & Pick<
            Customer,
            | "id"
            | "shortName"
            | "fullName"
            | "active"
            | "added"
            | "adminType"
            | "isVendor"
            | "emailAddressIdentity"
            | "streamingContent"
            | "displayShowReports"
            | "displayEpisodeReports"
          >
        >
      >;
    }
  >;
};

export type GetCustomerQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetCustomerQuery = { __typename?: "Query" } & {
  getCustomer?: Maybe<
    { __typename?: "Customer" } & Pick<
      Customer,
      | "id"
      | "shortName"
      | "fullName"
      | "active"
      | "added"
      | "adminType"
      | "isVendor"
      | "emailAddressIdentity"
      | "streamingContent"
      | "displayShowReports"
      | "displayEpisodeReports"
    >
  >;
};

export type ListShowsQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListShowsQuery = { __typename?: "Query" } & {
  listShows?: Maybe<
    { __typename?: "ShowConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Show" } & Pick<
            Show,
            | "id"
            | "customerID"
            | "nameShort"
            | "nameLong"
            | "description"
            | "poster"
            | "posterURL"
            | "added"
            | "active"
            | "hideInAdmin"
            | "appID"
            | "mainMenuType"
            | "faqContent"
            | "faviconsURL"
          > & {
              appData?: Maybe<
                { __typename?: "ShowAppData" } & Pick<
                  ShowAppData,
                  | "appLogo"
                  | "appLogoURL"
                  | "primaryColor"
                  | "textPrimaryColor"
                  | "secondaryColor"
                  | "tertiaryColor"
                  | "accentColor"
                  | "infoColor"
                  | "headBackgroundColor"
                  | "headTextColor"
                  | "menuTextColor"
                  | "productCaroselBackgroundColor"
                  | "productButTextColor"
                  | "productButBackColor"
                  | "productBut2TextColor"
                  | "productBut2BackColor"
                  | "autoplayEnabled"
                  | "autoplayAfterFingerprint"
                  | "copyright"
                  | "privacyPolicy"
                  | "appPosterDisplayType"
                  | "episodeTileType"
                  | "productTileType"
                  | "witAIAppID"
                  | "witAIAccessToken"
                  | "witAILastUpdate"
                  | "googleTrackingID"
                  | "googlePlayAppEnabled"
                  | "googlePlayAppURL"
                  | "facebookAppID"
                  | "facebookPageURL"
                  | "twitterUsername"
                  | "iOSAppStoreID"
                  | "videoGroupingName"
                  | "videoGroupingNamePlural"
                >
              >;
              channel?: Maybe<
                { __typename?: "ShowChannel" } & Pick<
                  ShowChannel,
                  | "rokuChannelEnabled"
                  | "rokuChannelType"
                  | "rokuChannelInstallURL"
                  | "fireTVChannelEnabled"
                  | "fireTVInstallURL"
                  | "fireTVDevURL"
                >
              >;
              demographicConfiguration?: Maybe<
                { __typename?: "ShowDemographicConfiguration" } & Pick<
                  ShowDemographicConfiguration,
                  | "introText"
                  | "ageEnabled"
                  | "genderEnabled"
                  | "ethnicityEnabled"
                  | "educationEnabled"
                  | "employmentEnabled"
                  | "maritalStatusEnabled"
                  | "incomeEnabled"
                  | "zipcodeEnabled"
                  | "emailEnabled"
                >
              >;
              productRequestConfiguration?: Maybe<
                { __typename?: "ShowProductRequestConfiguration" } & Pick<
                  ShowProductRequestConfiguration,
                  | "enabled"
                  | "titleText"
                  | "introText"
                  | "episodeListEnabled"
                  | "episodeListText"
                  | "photoGroupEnabled"
                  | "photoGroupText"
                  | "categoryEnabled"
                  | "categoryText"
                  | "emailEnabled"
                  | "emailText"
                  | "requestText"
                  | "submitThanksText"
                  | "notificationsList"
                >
              >;
            }
        >
      >;
    }
  >;
};

export type GetShowQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetShowQuery = { __typename?: "Query" } & {
  getShow?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "hideInAdmin"
      | "appID"
      | "mainMenuType"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "autoplayEnabled"
            | "autoplayAfterFingerprint"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "episodeTileType"
            | "productTileType"
            | "witAIAppID"
            | "witAIAccessToken"
            | "witAILastUpdate"
            | "googleTrackingID"
            | "googlePlayAppEnabled"
            | "googlePlayAppURL"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        channel?: Maybe<
          { __typename?: "ShowChannel" } & Pick<
            ShowChannel,
            | "rokuChannelEnabled"
            | "rokuChannelType"
            | "rokuChannelInstallURL"
            | "fireTVChannelEnabled"
            | "fireTVInstallURL"
            | "fireTVDevURL"
          >
        >;
        demographicConfiguration?: Maybe<
          { __typename?: "ShowDemographicConfiguration" } & Pick<
            ShowDemographicConfiguration,
            | "introText"
            | "ageEnabled"
            | "genderEnabled"
            | "ethnicityEnabled"
            | "educationEnabled"
            | "employmentEnabled"
            | "maritalStatusEnabled"
            | "incomeEnabled"
            | "zipcodeEnabled"
            | "emailEnabled"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "enabled"
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type GetShowByHostnameQueryVariables = Exact<{
  hostname: Scalars["String"];
}>;

export type GetShowByHostnameQuery = { __typename?: "Query" } & {
  getShowByHostname?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "hideInAdmin"
      | "appID"
      | "mainMenuType"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "autoplayEnabled"
            | "autoplayAfterFingerprint"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "episodeTileType"
            | "productTileType"
            | "witAIAppID"
            | "witAIAccessToken"
            | "witAILastUpdate"
            | "googleTrackingID"
            | "googlePlayAppEnabled"
            | "googlePlayAppURL"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        channel?: Maybe<
          { __typename?: "ShowChannel" } & Pick<
            ShowChannel,
            | "rokuChannelEnabled"
            | "rokuChannelType"
            | "rokuChannelInstallURL"
            | "fireTVChannelEnabled"
            | "fireTVInstallURL"
            | "fireTVDevURL"
          >
        >;
        demographicConfiguration?: Maybe<
          { __typename?: "ShowDemographicConfiguration" } & Pick<
            ShowDemographicConfiguration,
            | "introText"
            | "ageEnabled"
            | "genderEnabled"
            | "ethnicityEnabled"
            | "educationEnabled"
            | "employmentEnabled"
            | "maritalStatusEnabled"
            | "incomeEnabled"
            | "zipcodeEnabled"
            | "emailEnabled"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "enabled"
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type GetShowReportQueryVariables = Exact<{
  showID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetShowReportQuery = { __typename?: "Query" } & {
  getShowReport?: Maybe<
    { __typename?: "ShowReport" } & Pick<
      ShowReport,
      | "totalSeconds"
      | "totalBytes"
      | "ipCount"
      | "appUsers"
      | "totalParts"
      | "averageViews"
      | "minPartViews"
      | "maxPartViews"
    > & {
        deviceInfo?: Maybe<
          Array<
            Maybe<
              { __typename?: "DeviceInfo" } & Pick<
                DeviceInfo,
                "deviceType" | "OS" | "usercount" | "totalbytes"
              >
            >
          >
        >;
        topEpisodes?: Maybe<
          Array<
            Maybe<
              { __typename?: "Episode" } & Pick<
                Episode,
                | "id"
                | "showID"
                | "showName"
                | "season"
                | "seasonName"
                | "number"
                | "firstRelease"
                | "added"
                | "name"
                | "description"
                | "disclaimer"
                | "enabled"
                | "posterType"
                | "poster"
                | "posterURL"
                | "posterFileID"
                | "video"
                | "videoBucket"
                | "videoKey"
                | "videoQuality"
                | "videoType"
                | "videoWidth"
                | "videoHeight"
                | "mediaConvertJobID"
                | "mediaConvertJobStatus"
                | "audioFingerprintID"
                | "duration"
                | "rokuAddBreaks"
                | "releaseToOTT"
                | "releaseToApp"
                | "liveChannelID"
                | "enableHeaderButton"
                | "headerButtonText"
                | "headerButtonURL"
                | "disableProductScroller"
                | "liveStreamID"
                | "liveStreamEnabledOnStartMenuID"
                | "videoToken"
              >
            >
          >
        >;
        dateStreams?: Maybe<
          Array<
            Maybe<
              { __typename?: "DateStream" } & Pick<
                DateStream,
                "logDate" | "logday" | "totalSecs" | "totalbytes"
              >
            >
          >
        >;
        streamDataByBandwidth?: Maybe<
          Array<
            Maybe<
              { __typename?: "StreamDataByBandwidth" } & Pick<
                StreamDataByBandwidth,
                "streamKbps" | "streamPart" | "totalhits"
              >
            >
          >
        >;
        streamDataByWeekday?: Maybe<
          Array<
            Maybe<
              { __typename?: "StreamDataByWeekday" } & Pick<
                StreamDataByWeekday,
                "dayNumber" | "weekName" | "totalSecs" | "totalbytes"
              >
            >
          >
        >;
        streamDataByHour?: Maybe<
          Array<
            Maybe<
              { __typename?: "StreamDataByHour" } & Pick<
                StreamDataByHour,
                "hourNumber" | "totalSecs" | "totalbytes"
              >
            >
          >
        >;
      }
  >;
};

export type GetShowSeasonsQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type GetShowSeasonsQuery = { __typename?: "Query" } & {
  getShowSeasons?: Maybe<
    { __typename?: "ShowSeasonConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ShowSeason" } & Pick<
            ShowSeason,
            "showID" | "season" | "name"
          >
        >
      >;
    }
  >;
};

export type GetShowDesignMenuQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type GetShowDesignMenuQuery = { __typename?: "Query" } & {
  getShowDesignMenu?: Maybe<
    { __typename?: "ShowDesignMenuConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ShowDesignMenuItem" } & Pick<
            ShowDesignMenuItem,
            | "id"
            | "showID"
            | "orderID"
            | "title"
            | "description"
            | "icon"
            | "iconColor"
            | "url"
            | "showInPullOutMenu"
            | "showInHomeMenu"
            | "enabled"
          >
        >
      >;
    }
  >;
};

export type GetShowMetadataQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type GetShowMetadataQuery = { __typename?: "Query" } & {
  getShowMetadata?: Maybe<
    { __typename?: "ShowMetadataItemConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ShowMetadataItem" } & Pick<
            ShowMetadataItem,
            "id" | "showID" | "name" | "value"
          >
        >
      >;
    }
  >;
};

export type ListEpisodesQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type ListEpisodesQuery = { __typename?: "Query" } & {
  listEpisodes?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "disclaimer"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "mediaConvertJobID"
            | "mediaConvertJobStatus"
            | "audioFingerprintID"
            | "duration"
            | "rokuAddBreaks"
            | "releaseToOTT"
            | "releaseToApp"
            | "liveChannelID"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "disableProductScroller"
            | "liveStreamID"
            | "liveStreamEnabledOnStartMenuID"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type ListAppEpisodesQueryVariables = Exact<{
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
}>;

export type ListAppEpisodesQuery = { __typename?: "Query" } & {
  listAppEpisodes?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "disclaimer"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "mediaConvertJobID"
            | "mediaConvertJobStatus"
            | "audioFingerprintID"
            | "duration"
            | "rokuAddBreaks"
            | "releaseToOTT"
            | "releaseToApp"
            | "liveChannelID"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "disableProductScroller"
            | "liveStreamID"
            | "liveStreamEnabledOnStartMenuID"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type ListOttEpisodesQueryVariables = Exact<{
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
}>;

export type ListOttEpisodesQuery = { __typename?: "Query" } & {
  listOttEpisodes?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "disclaimer"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "mediaConvertJobID"
            | "mediaConvertJobStatus"
            | "audioFingerprintID"
            | "duration"
            | "rokuAddBreaks"
            | "releaseToOTT"
            | "releaseToApp"
            | "liveChannelID"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "disableProductScroller"
            | "liveStreamID"
            | "liveStreamEnabledOnStartMenuID"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type ListCustomerEpisodesQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListCustomerEpisodesQuery = { __typename?: "Query" } & {
  listCustomerEpisodes?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "disclaimer"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "mediaConvertJobID"
            | "mediaConvertJobStatus"
            | "audioFingerprintID"
            | "duration"
            | "rokuAddBreaks"
            | "releaseToOTT"
            | "releaseToApp"
            | "liveChannelID"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "disableProductScroller"
            | "liveStreamID"
            | "liveStreamEnabledOnStartMenuID"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type ListCustomerEpisodesWithPhotoGroupsQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListCustomerEpisodesWithPhotoGroupsQuery = {
  __typename?: "Query";
} & {
  listCustomerEpisodesWithPhotoGroups?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "disclaimer"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "mediaConvertJobID"
            | "mediaConvertJobStatus"
            | "audioFingerprintID"
            | "duration"
            | "rokuAddBreaks"
            | "releaseToOTT"
            | "releaseToApp"
            | "liveChannelID"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "disableProductScroller"
            | "liveStreamID"
            | "liveStreamEnabledOnStartMenuID"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type GetEpisodeQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetEpisodeQuery = { __typename?: "Query" } & {
  getEpisode?: Maybe<
    { __typename?: "Episode" } & Pick<
      Episode,
      | "id"
      | "showID"
      | "showName"
      | "season"
      | "seasonName"
      | "number"
      | "firstRelease"
      | "added"
      | "name"
      | "description"
      | "disclaimer"
      | "enabled"
      | "posterType"
      | "poster"
      | "posterURL"
      | "posterFileID"
      | "video"
      | "videoBucket"
      | "videoKey"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "audioFingerprintID"
      | "duration"
      | "rokuAddBreaks"
      | "releaseToOTT"
      | "releaseToApp"
      | "liveChannelID"
      | "enableHeaderButton"
      | "headerButtonText"
      | "headerButtonURL"
      | "disableProductScroller"
      | "liveStreamID"
      | "liveStreamEnabledOnStartMenuID"
      | "videoToken"
    >
  >;
};

export type ListEpisodeCommercialsQueryVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type ListEpisodeCommercialsQuery = { __typename?: "Query" } & {
  listEpisodeCommercials?: Maybe<
    { __typename?: "EpisodeCommercialConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodeCommercial" } & Pick<
            EpisodeCommercial,
            | "id"
            | "episodeID"
            | "commercialEpisodeID"
            | "type"
            | "spliceTime"
            | "showID"
            | "episodeName"
            | "episodePoster"
            | "episodePosterURL"
            | "enabled"
            | "duration"
          >
        >
      >;
    }
  >;
};

export type ListCommercialsQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type ListCommercialsQuery = { __typename?: "Query" } & {
  listCommercials?: Maybe<
    { __typename?: "EpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Episode" } & Pick<
            Episode,
            | "id"
            | "showID"
            | "showName"
            | "season"
            | "seasonName"
            | "number"
            | "firstRelease"
            | "added"
            | "name"
            | "description"
            | "disclaimer"
            | "enabled"
            | "posterType"
            | "poster"
            | "posterURL"
            | "posterFileID"
            | "video"
            | "videoBucket"
            | "videoKey"
            | "videoQuality"
            | "videoType"
            | "videoWidth"
            | "videoHeight"
            | "mediaConvertJobID"
            | "mediaConvertJobStatus"
            | "audioFingerprintID"
            | "duration"
            | "rokuAddBreaks"
            | "releaseToOTT"
            | "releaseToApp"
            | "liveChannelID"
            | "enableHeaderButton"
            | "headerButtonText"
            | "headerButtonURL"
            | "disableProductScroller"
            | "liveStreamID"
            | "liveStreamEnabledOnStartMenuID"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type GetEpisodeMetadataQueryVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type GetEpisodeMetadataQuery = { __typename?: "Query" } & {
  getEpisodeMetadata?: Maybe<
    { __typename?: "EpisodeMetadataItemConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodeMetadataItem" } & Pick<
            EpisodeMetadataItem,
            "id" | "episodeID" | "name" | "value"
          >
        >
      >;
    }
  >;
};

export type GetEpisodeReportQueryVariables = Exact<{
  showID: Scalars["Int"];
  episodeID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetEpisodeReportQuery = { __typename?: "Query" } & {
  getEpisodeReport?: Maybe<
    { __typename?: "EpisodeReport" } & Pick<
      EpisodeReport,
      | "totalSeconds"
      | "totalBytes"
      | "ipCount"
      | "appUsers"
      | "totalParts"
      | "averageViews"
      | "minPartViews"
      | "maxPartViews"
    > & {
        dateStreams?: Maybe<
          Array<
            Maybe<
              { __typename?: "DateStream" } & Pick<
                DateStream,
                "logDate" | "logday" | "totalSecs" | "totalbytes"
              >
            >
          >
        >;
        streamDataByBandwidth?: Maybe<
          Array<
            Maybe<
              { __typename?: "StreamDataByBandwidth" } & Pick<
                StreamDataByBandwidth,
                "streamKbps" | "streamPart" | "totalhits"
              >
            >
          >
        >;
      }
  >;
};

export type GetEpisodeProductsQueryVariables = Exact<{
  episodeID: Scalars["Int"];
  active?: Maybe<Scalars["Boolean"]>;
}>;

export type GetEpisodeProductsQuery = { __typename?: "Query" } & {
  getEpisodeProducts?: Maybe<
    { __typename?: "EpisodeProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodeProduct" } & Pick<
            EpisodeProduct,
            | "id"
            | "episodeID"
            | "productID"
            | "startTimecode"
            | "startTimeSeconds"
            | "startTimeMilliseconds"
            | "endTimecode"
            | "endTimeSeconds"
            | "endTimeMilliseconds"
            | "brandName"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                | "id"
                | "customerID"
                | "name"
                | "description"
                | "upc"
                | "brand"
                | "brandID"
                | "sku"
                | "active"
                | "added"
                | "deleted"
                | "approved"
                | "replacementProduct"
                | "mainPhotoURL"
                | "mainPhotoFileID"
                | "mainPhotoFileExt"
                | "mainPhotoSize"
                | "issueCount"
                | "photoBucket"
                | "photoKey"
                | "warnings"
                | "originalProductName"
                | "originalProductDescription"
                | "originalProductURL"
                | "originalProductPhotoBucket"
                | "originalProductPhotoKey"
                | "vendorID"
                | "vendorLogo"
                | "vendorLogoBucket"
                | "vendorLogoKey"
              >;
            }
        >
      >;
    }
  >;
};

export type GetEpisodePhotoGroupsQueryVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type GetEpisodePhotoGroupsQuery = { __typename?: "Query" } & {
  getEpisodePhotoGroups?: Maybe<
    { __typename?: "EpisodePhotoGroupConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodePhotoGroup" } & Pick<
            EpisodePhotoGroup,
            "id" | "episodeID" | "name" | "order" | "enabled"
          >
        >
      >;
    }
  >;
};

export type GetEpisodePhotosQueryVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type GetEpisodePhotosQuery = { __typename?: "Query" } & {
  getEpisodePhotos?: Maybe<
    { __typename?: "EpisodePhotoConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodePhoto" } & Pick<
            EpisodePhoto,
            | "id"
            | "episodeID"
            | "groupID"
            | "groupName"
            | "order"
            | "title"
            | "description"
            | "fileID"
            | "extension"
            | "url"
            | "thumbnailURL"
            | "enabled"
            | "added"
          >
        >
      >;
    }
  >;
};

export type GetEpisodePhotosByGroupQueryVariables = Exact<{
  groupID: Scalars["Int"];
}>;

export type GetEpisodePhotosByGroupQuery = { __typename?: "Query" } & {
  getEpisodePhotosByGroup?: Maybe<
    { __typename?: "EpisodePhotoConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodePhoto" } & Pick<
            EpisodePhoto,
            | "id"
            | "episodeID"
            | "groupID"
            | "groupName"
            | "order"
            | "title"
            | "description"
            | "fileID"
            | "extension"
            | "url"
            | "thumbnailURL"
            | "enabled"
            | "added"
          >
        >
      >;
    }
  >;
};

export type GetEpisodePhotoProductsQueryVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type GetEpisodePhotoProductsQuery = { __typename?: "Query" } & {
  getEpisodePhotoProducts?: Maybe<
    { __typename?: "EpisodePhotoProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodePhotoProduct" } & Pick<
            EpisodePhotoProduct,
            | "id"
            | "groupID"
            | "productID"
            | "episodeID"
            | "brandName"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                | "id"
                | "customerID"
                | "name"
                | "description"
                | "upc"
                | "brand"
                | "brandID"
                | "sku"
                | "active"
                | "added"
                | "deleted"
                | "approved"
                | "replacementProduct"
                | "mainPhotoURL"
                | "mainPhotoFileID"
                | "mainPhotoFileExt"
                | "mainPhotoSize"
                | "issueCount"
                | "photoBucket"
                | "photoKey"
                | "warnings"
                | "originalProductName"
                | "originalProductDescription"
                | "originalProductURL"
                | "originalProductPhotoBucket"
                | "originalProductPhotoKey"
                | "vendorID"
                | "vendorLogo"
                | "vendorLogoBucket"
                | "vendorLogoKey"
              >;
            }
        >
      >;
    }
  >;
};

export type GetEpisodePhotoProductsByGroupQueryVariables = Exact<{
  groupID: Scalars["Int"];
}>;

export type GetEpisodePhotoProductsByGroupQuery = { __typename?: "Query" } & {
  getEpisodePhotoProductsByGroup?: Maybe<
    { __typename?: "EpisodePhotoProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodePhotoProduct" } & Pick<
            EpisodePhotoProduct,
            | "id"
            | "groupID"
            | "productID"
            | "episodeID"
            | "brandName"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                | "id"
                | "customerID"
                | "name"
                | "description"
                | "upc"
                | "brand"
                | "brandID"
                | "sku"
                | "active"
                | "added"
                | "deleted"
                | "approved"
                | "replacementProduct"
                | "mainPhotoURL"
                | "mainPhotoFileID"
                | "mainPhotoFileExt"
                | "mainPhotoSize"
                | "issueCount"
                | "photoBucket"
                | "photoKey"
                | "warnings"
                | "originalProductName"
                | "originalProductDescription"
                | "originalProductURL"
                | "originalProductPhotoBucket"
                | "originalProductPhotoKey"
                | "vendorID"
                | "vendorLogo"
                | "vendorLogoBucket"
                | "vendorLogoKey"
              >;
            }
        >
      >;
    }
  >;
};

export type GetEpisodeStreamsQueryVariables = Exact<{
  episodeID: Scalars["Int"];
  showID: Scalars["Int"];
}>;

export type GetEpisodeStreamsQuery = { __typename?: "Query" } & {
  getEpisodeStreams?: Maybe<
    { __typename?: "EpisodeStreamConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EpisodeStream" } & Pick<
            EpisodeStream,
            | "id"
            | "episodeID"
            | "enabled"
            | "type"
            | "bandwidth"
            | "averageBandwidth"
            | "codecs"
            | "resolution"
            | "frameRate"
            | "targetDuration"
            | "targetBandwidth"
            | "filename"
            | "partDuration"
            | "partCount"
            | "videoRoot"
            | "videoToken"
          >
        >
      >;
    }
  >;
};

export type GetEpisodeStreamQueryVariables = Exact<{
  episodeID: Scalars["Int"];
  streamID?: Maybe<Scalars["Int"]>;
  targetBandwidth?: Maybe<Scalars["Int"]>;
}>;

export type GetEpisodeStreamQuery = { __typename?: "Query" } & {
  getEpisodeStream?: Maybe<
    { __typename?: "EpisodeStream" } & Pick<
      EpisodeStream,
      | "id"
      | "episodeID"
      | "enabled"
      | "type"
      | "bandwidth"
      | "averageBandwidth"
      | "codecs"
      | "resolution"
      | "frameRate"
      | "targetDuration"
      | "targetBandwidth"
      | "filename"
      | "partDuration"
      | "partCount"
      | "videoRoot"
      | "videoToken"
    >
  >;
};

export type ListProductsQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListProductsQuery = { __typename?: "Query" } & {
  listProducts?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "customerID"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "mainPhotoSize"
            | "issueCount"
            | "photoBucket"
            | "photoKey"
            | "warnings"
            | "originalProductName"
            | "originalProductDescription"
            | "originalProductURL"
            | "originalProductPhotoBucket"
            | "originalProductPhotoKey"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type GetProductQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetProductQuery = { __typename?: "Query" } & {
  getProduct?: Maybe<
    { __typename?: "Product" } & Pick<
      Product,
      | "id"
      | "customerID"
      | "name"
      | "description"
      | "upc"
      | "brand"
      | "brandID"
      | "sku"
      | "active"
      | "added"
      | "deleted"
      | "approved"
      | "replacementProduct"
      | "mainPhotoURL"
      | "mainPhotoFileID"
      | "mainPhotoFileExt"
      | "mainPhotoSize"
      | "issueCount"
      | "photoBucket"
      | "photoKey"
      | "warnings"
      | "originalProductName"
      | "originalProductDescription"
      | "originalProductURL"
      | "originalProductPhotoBucket"
      | "originalProductPhotoKey"
      | "vendorID"
      | "vendorLogo"
      | "vendorLogoBucket"
      | "vendorLogoKey"
    >
  >;
};

export type ListRelatedProductsQueryVariables = Exact<{
  categoryID: Scalars["Int"];
  showID: Scalars["Int"];
}>;

export type ListRelatedProductsQuery = { __typename?: "Query" } & {
  listRelatedProducts?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "customerID"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "mainPhotoSize"
            | "issueCount"
            | "photoBucket"
            | "photoKey"
            | "warnings"
            | "originalProductName"
            | "originalProductDescription"
            | "originalProductURL"
            | "originalProductPhotoBucket"
            | "originalProductPhotoKey"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type ListShowFeaturedProductsQueryVariables = Exact<{
  showID: Scalars["Int"];
  limit?: Maybe<Scalars["Int"]>;
}>;

export type ListShowFeaturedProductsQuery = { __typename?: "Query" } & {
  listShowFeaturedProducts?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "customerID"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "mainPhotoSize"
            | "issueCount"
            | "photoBucket"
            | "photoKey"
            | "warnings"
            | "originalProductName"
            | "originalProductDescription"
            | "originalProductURL"
            | "originalProductPhotoBucket"
            | "originalProductPhotoKey"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type ListAvailableProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListAvailableProductsQuery = { __typename?: "Query" } & {
  listAvailableProducts?: Maybe<
    { __typename?: "AvailableProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "AvailableProduct" } & Pick<
            AvailableProduct,
            "customerName"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                | "id"
                | "customerID"
                | "name"
                | "description"
                | "upc"
                | "brand"
                | "brandID"
                | "sku"
                | "active"
                | "added"
                | "deleted"
                | "approved"
                | "replacementProduct"
                | "mainPhotoURL"
                | "mainPhotoFileID"
                | "mainPhotoFileExt"
                | "mainPhotoSize"
                | "issueCount"
                | "photoBucket"
                | "photoKey"
                | "warnings"
                | "originalProductName"
                | "originalProductDescription"
                | "originalProductURL"
                | "originalProductPhotoBucket"
                | "originalProductPhotoKey"
                | "vendorID"
                | "vendorLogo"
                | "vendorLogoBucket"
                | "vendorLogoKey"
              >;
            }
        >
      >;
    }
  >;
};

export type GetProductLinksQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductLinksQuery = { __typename?: "Query" } & {
  getProductLinks?: Maybe<
    { __typename?: "ProductLinkConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductLink" } & Pick<
            ProductLink,
            | "id"
            | "productID"
            | "storeID"
            | "storeName"
            | "type"
            | "fullURL"
            | "status"
            | "statusLastChecked"
          >
        >
      >;
    }
  >;
};

export type GetProductCategoriesQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductCategoriesQuery = { __typename?: "Query" } & {
  getProductCategories?: Maybe<
    { __typename?: "ProductCategoryConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductCategory" } & Pick<
            ProductCategory,
            "productID" | "categoryID" | "categoryName"
          >
        >
      >;
    }
  >;
};

export type GetProductSearchMetadataQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductSearchMetadataQuery = { __typename?: "Query" } & {
  getProductSearchMetadata?: Maybe<
    { __typename?: "ProductSearchMetadata" } & Pick<
      ProductSearchMetadata,
      | "productID"
      | "searchData"
      | "witAISearchData"
      | "searchColor"
      | "searchPeople"
      | "lastUpdate"
    >
  >;
};

export type GetProductMetadataQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductMetadataQuery = { __typename?: "Query" } & {
  getProductMetadata?: Maybe<
    { __typename?: "ProductMetadataConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductMetadataItem" } & Pick<
            ProductMetadataItem,
            "id" | "productID" | "name" | "value"
          >
        >
      >;
    }
  >;
};

export type GetProductEpisodesQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductEpisodesQuery = { __typename?: "Query" } & {
  getProductEpisodes?: Maybe<
    { __typename?: "ProductEpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductEpisode" } & Pick<
            ProductEpisode,
            | "id"
            | "episodeID"
            | "productID"
            | "startTimecode"
            | "startTimeMilliseconds"
            | "startTimeSeconds"
            | "endTimecode"
            | "endTimeMilliseconds"
            | "endTimeSeconds"
            | "showName"
            | "episodeName"
            | "episodeSeasonName"
            | "episodeSeason"
            | "episodeNumber"
          >
        >
      >;
    }
  >;
};

export type GetProductFeaturedEpisodesQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductFeaturedEpisodesQuery = { __typename?: "Query" } & {
  getProductFeaturedEpisodes?: Maybe<
    { __typename?: "ProductEpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductEpisode" } & Pick<
            ProductEpisode,
            | "id"
            | "episodeID"
            | "productID"
            | "startTimecode"
            | "startTimeMilliseconds"
            | "startTimeSeconds"
            | "endTimecode"
            | "endTimeMilliseconds"
            | "endTimeSeconds"
            | "showName"
            | "episodeName"
            | "episodeSeasonName"
            | "episodeSeason"
            | "episodeNumber"
          >
        >
      >;
    }
  >;
};

export type GetProductEpisodePhotoGroupsQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductEpisodePhotoGroupsQuery = { __typename?: "Query" } & {
  getProductEpisodePhotoGroups?: Maybe<
    { __typename?: "ProductEpisodePhotoGroupConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductEpisodePhotoGroup" } & Pick<
            ProductEpisodePhotoGroup,
            "id" | "groupID" | "groupName" | "showName"
          > & {
              episode: { __typename?: "Episode" } & Pick<
                Episode,
                | "id"
                | "showID"
                | "showName"
                | "season"
                | "seasonName"
                | "number"
                | "firstRelease"
                | "added"
                | "name"
                | "description"
                | "disclaimer"
                | "enabled"
                | "posterType"
                | "poster"
                | "posterURL"
                | "posterFileID"
                | "video"
                | "videoBucket"
                | "videoKey"
                | "videoQuality"
                | "videoType"
                | "videoWidth"
                | "videoHeight"
                | "mediaConvertJobID"
                | "mediaConvertJobStatus"
                | "audioFingerprintID"
                | "duration"
                | "rokuAddBreaks"
                | "releaseToOTT"
                | "releaseToApp"
                | "liveChannelID"
                | "enableHeaderButton"
                | "headerButtonText"
                | "headerButtonURL"
                | "disableProductScroller"
                | "liveStreamID"
                | "liveStreamEnabledOnStartMenuID"
                | "videoToken"
              >;
            }
        >
      >;
    }
  >;
};

export type GetProductVendorQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type GetProductVendorQuery = { __typename?: "Query" } & {
  getProductVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type GetProductRequestsByShowQueryVariables = Exact<{
  showID: Scalars["Int"];
  status?: Maybe<Scalars["Int"]>;
}>;

export type GetProductRequestsByShowQuery = { __typename?: "Query" } & {
  getProductRequestsByShow?: Maybe<
    { __typename?: "ProductRequestConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductRequest" } & Pick<
            ProductRequest,
            | "id"
            | "showID"
            | "appInstallID"
            | "episodeID"
            | "episodeSeason"
            | "episodeNumber"
            | "episodeName"
            | "photoGroup"
            | "category"
            | "email"
            | "request"
            | "added"
            | "ip"
            | "status"
            | "lastChangedByAUID"
            | "userSub"
            | "response"
          >
        >
      >;
    }
  >;
};

export type GetProductRequestsByUserQueryVariables = Exact<{
  userID?: Maybe<Scalars["ID"]>;
}>;

export type GetProductRequestsByUserQuery = { __typename?: "Query" } & {
  getProductRequestsByUser?: Maybe<
    { __typename?: "ProductRequestConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "ProductRequest" } & Pick<
            ProductRequest,
            | "id"
            | "showID"
            | "appInstallID"
            | "episodeID"
            | "episodeSeason"
            | "episodeNumber"
            | "episodeName"
            | "photoGroup"
            | "category"
            | "email"
            | "request"
            | "added"
            | "ip"
            | "status"
            | "lastChangedByAUID"
            | "userSub"
            | "response"
          >
        >
      >;
    }
  >;
};

export type GetProductsReportQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetProductsReportQuery = { __typename?: "Query" } & Pick<
  Query,
  "getProductsReport"
>;

export type GetVendorProductsReportQueryVariables = Exact<{
  vendorID: Scalars["ID"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetVendorProductsReportQuery = { __typename?: "Query" } & Pick<
  Query,
  "getVendorProductsReport"
>;

export type GetEngagementDeviceInfoQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetEngagementDeviceInfoQuery = { __typename?: "Query" } & {
  getEngagementDeviceInfo?: Maybe<
    { __typename?: "EngagementDeviceInfoConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EngagementDeviceInfo" } & Pick<
            EngagementDeviceInfo,
            "deviceType" | "os" | "userCount"
          >
        >
      >;
    }
  >;
};

export type GetEngagementTopTwentyLocationsQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetEngagementTopTwentyLocationsQuery = { __typename?: "Query" } & {
  getEngagementTopTwentyLocations?: Maybe<
    { __typename?: "EngagementTopLocationConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EngagementTopLocation" } & Pick<
            EngagementTopLocation,
            "country" | "state" | "totalVisits"
          >
        >
      >;
    }
  >;
};

export type GetEngagementEpisodeViewsQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetEngagementEpisodeViewsQuery = { __typename?: "Query" } & {
  getEngagementEpisodeViews?: Maybe<
    { __typename?: "EngagementEpisodeConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EngagementEpisode" } & Pick<
            EngagementEpisode,
            | "ShowId"
            | "EpisodeId"
            | "ShowNameShort"
            | "Season"
            | "EpisodeNo"
            | "EpisodeName"
            | "EpisodePoster"
            | "PosterFileId"
            | "totalViews"
          >
        >
      >;
    }
  >;
};

export type GetEngagementActivityByWeekdayQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetEngagementActivityByWeekdayQuery = { __typename?: "Query" } & {
  getEngagementActivityByWeekday?: Maybe<
    { __typename?: "EngagementActivityWeekdayConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EngagementActivityWeekday" } & Pick<
            EngagementActivityWeekday,
            "dayNumber" | "day" | "totalCount"
          >
        >
      >;
    }
  >;
};

export type GetEngagementActivityByDateQueryVariables = Exact<{
  customerID: Scalars["Int"];
  fromDate: Scalars["AWSDateTime"];
  toDate: Scalars["AWSDateTime"];
}>;

export type GetEngagementActivityByDateQuery = { __typename?: "Query" } & {
  getEngagementActivityByDate?: Maybe<
    { __typename?: "EngagementActivityDateConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EngagementActivityDate" } & Pick<
            EngagementActivityDate,
            "date" | "totalCount"
          >
        >
      >;
    }
  >;
};

export type CheckIfProductExistsQueryVariables = Exact<{
  customerID: Scalars["Int"];
  productURL: Scalars["String"];
}>;

export type CheckIfProductExistsQuery = { __typename?: "Query" } & {
  checkIfProductExists?: Maybe<
    { __typename?: "ProductExistsResult" } & Pick<
      ProductExistsResult,
      "storeID"
    > & {
        product: { __typename?: "Product" } & Pick<
          Product,
          | "id"
          | "customerID"
          | "name"
          | "description"
          | "upc"
          | "brand"
          | "brandID"
          | "sku"
          | "active"
          | "added"
          | "deleted"
          | "approved"
          | "replacementProduct"
          | "mainPhotoURL"
          | "mainPhotoFileID"
          | "mainPhotoFileExt"
          | "mainPhotoSize"
          | "issueCount"
          | "photoBucket"
          | "photoKey"
          | "warnings"
          | "originalProductName"
          | "originalProductDescription"
          | "originalProductURL"
          | "originalProductPhotoBucket"
          | "originalProductPhotoKey"
          | "vendorID"
          | "vendorLogo"
          | "vendorLogoBucket"
          | "vendorLogoKey"
        >;
      }
  >;
};

export type ScrapeProductUrlQueryVariables = Exact<{
  productURL: Scalars["String"];
}>;

export type ScrapeProductUrlQuery = { __typename?: "Query" } & {
  scrapeProductURL?: Maybe<
    { __typename?: "ScrapedProduct" } & Pick<
      ScrapedProduct,
      | "title"
      | "description"
      | "keywords"
      | "brand"
      | "sku"
      | "imageURL"
      | "domainName"
      | "site_name"
      | "url"
      | "imageURLs"
    >
  >;
};

export type WalmartSearchQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type WalmartSearchQuery = { __typename?: "Query" } & {
  walmartSearch?: Maybe<
    { __typename?: "WalmartProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "WalmartProduct" } & Pick<
            WalmartProduct,
            | "id"
            | "parentID"
            | "name"
            | "msrp"
            | "salePrice"
            | "upc"
            | "modelNumber"
            | "description"
            | "category"
            | "productURL"
            | "affiliateURL"
            | "thumbnailImageURL"
            | "imageURL"
          >
        >
      >;
    }
  >;
};

export type ListStoresQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListStoresQuery = { __typename?: "Query" } & {
  listStores?: Maybe<
    { __typename?: "StoreConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Store" } & Pick<
            Store,
            | "id"
            | "customerID"
            | "name"
            | "active"
            | "added"
            | "domainName"
            | "searchURL"
            | "productCount"
          >
        >
      >;
    }
  >;
};

export type ListActiveStoresQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListActiveStoresQuery = { __typename?: "Query" } & {
  listActiveStores?: Maybe<
    { __typename?: "StoreConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Store" } & Pick<
            Store,
            | "id"
            | "customerID"
            | "name"
            | "active"
            | "added"
            | "domainName"
            | "searchURL"
            | "productCount"
          >
        >
      >;
    }
  >;
};

export type GetStoreQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetStoreQuery = { __typename?: "Query" } & {
  getStore?: Maybe<
    { __typename?: "Store" } & Pick<
      Store,
      | "id"
      | "customerID"
      | "name"
      | "active"
      | "added"
      | "domainName"
      | "searchURL"
      | "productCount"
    >
  >;
};

export type ListCategoriesQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListCategoriesQuery = { __typename?: "Query" } & {
  listCategories?: Maybe<
    { __typename?: "CategoryConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Category" } & Pick<
            Category,
            | "id"
            | "customerID"
            | "name"
            | "image"
            | "imageURL"
            | "imageFileSize"
            | "active"
            | "thumbnail"
            | "thumbnailURL"
            | "thumbnailFileSize"
            | "tag"
            | "description"
          >
        >
      >;
    }
  >;
};

export type ListActiveCategoriesQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListActiveCategoriesQuery = { __typename?: "Query" } & {
  listActiveCategories?: Maybe<
    { __typename?: "CategoryConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Category" } & Pick<
            Category,
            | "id"
            | "customerID"
            | "name"
            | "image"
            | "imageURL"
            | "imageFileSize"
            | "active"
            | "thumbnail"
            | "thumbnailURL"
            | "thumbnailFileSize"
            | "tag"
            | "description"
          >
        >
      >;
    }
  >;
};

export type ListActiveCategoriesByShowQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type ListActiveCategoriesByShowQuery = { __typename?: "Query" } & {
  listActiveCategoriesByShow?: Maybe<
    { __typename?: "CategoryConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Category" } & Pick<
            Category,
            | "id"
            | "customerID"
            | "name"
            | "image"
            | "imageURL"
            | "imageFileSize"
            | "active"
            | "thumbnail"
            | "thumbnailURL"
            | "thumbnailFileSize"
            | "tag"
            | "description"
          >
        >
      >;
    }
  >;
};

export type GetCategoryQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetCategoryQuery = { __typename?: "Query" } & {
  getCategory?: Maybe<
    { __typename?: "Category" } & Pick<
      Category,
      | "id"
      | "customerID"
      | "name"
      | "image"
      | "imageURL"
      | "imageFileSize"
      | "active"
      | "thumbnail"
      | "thumbnailURL"
      | "thumbnailFileSize"
      | "tag"
      | "description"
    >
  >;
};

export type ListBrandsQueryVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type ListBrandsQuery = { __typename?: "Query" } & {
  listBrands?: Maybe<
    { __typename?: "BrandConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Brand" } & Pick<
            Brand,
            "id" | "customerID" | "name" | "productCount"
          >
        >
      >;
    }
  >;
};

export type GetBrandQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetBrandQuery = { __typename?: "Query" } & {
  getBrand?: Maybe<
    { __typename?: "Brand" } & Pick<
      Brand,
      "id" | "customerID" | "name" | "productCount"
    >
  >;
};

export type ListBrandProductsQueryVariables = Exact<{
  id: Scalars["Int"];
  customerID: Scalars["Int"];
}>;

export type ListBrandProductsQuery = { __typename?: "Query" } & {
  listBrandProducts?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "customerID"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "mainPhotoSize"
            | "issueCount"
            | "photoBucket"
            | "photoKey"
            | "warnings"
            | "originalProductName"
            | "originalProductDescription"
            | "originalProductURL"
            | "originalProductPhotoBucket"
            | "originalProductPhotoKey"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type ListActiveFontTargetsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListActiveFontTargetsQuery = { __typename?: "Query" } & {
  listActiveFontTargets?: Maybe<
    { __typename?: "FontTargetConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "FontTarget" } & Pick<
            FontTarget,
            | "id"
            | "styleClassName"
            | "displayName"
            | "description"
            | "active"
            | "added"
          >
        >
      >;
    }
  >;
};

export type ListUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListUsersQuery = { __typename?: "Query" } & {
  listUsers?: Maybe<
    { __typename?: "UserConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "User" } & Pick<
            User,
            | "id"
            | "email"
            | "enabled"
            | "status"
            | "created"
            | "email_verified"
            | "given_name"
            | "family_name"
            | "gender"
            | "phone_number"
            | "customerID"
            | "vendorID"
          >
        >
      >;
    }
  >;
};

export type ListVoxiAdminsQueryVariables = Exact<{ [key: string]: never }>;

export type ListVoxiAdminsQuery = { __typename?: "Query" } & {
  listVoxiAdmins?: Maybe<
    { __typename?: "UserConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "User" } & Pick<
            User,
            | "id"
            | "email"
            | "enabled"
            | "status"
            | "created"
            | "email_verified"
            | "given_name"
            | "family_name"
            | "gender"
            | "phone_number"
            | "customerID"
            | "vendorID"
          >
        >
      >;
    }
  >;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserQuery = { __typename?: "Query" } & {
  getUser?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      | "id"
      | "email"
      | "enabled"
      | "status"
      | "created"
      | "email_verified"
      | "given_name"
      | "family_name"
      | "gender"
      | "phone_number"
      | "customerID"
      | "vendorID"
    >
  >;
};

export type ListEmailReportSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListEmailReportSettingsQuery = { __typename?: "Query" } & {
  listEmailReportSettings?: Maybe<
    { __typename?: "EmailReportSettingConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "EmailReportSetting" } & Pick<
            EmailReportSetting,
            | "id"
            | "userID"
            | "reportType"
            | "daily"
            | "weekly"
            | "monthly"
            | "yearly"
            | "customerID"
            | "customerName"
            | "showID"
            | "showName"
            | "lastRun"
            | "lastRunTime"
          >
        >
      >;
    }
  >;
};

export type TextSearchQueryVariables = Exact<{
  appID: Scalars["String"];
  query: Scalars["String"];
}>;

export type TextSearchQuery = { __typename?: "Query" } & {
  textSearch?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "customerID"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "mainPhotoSize"
            | "issueCount"
            | "photoBucket"
            | "photoKey"
            | "warnings"
            | "originalProductName"
            | "originalProductDescription"
            | "originalProductURL"
            | "originalProductPhotoBucket"
            | "originalProductPhotoKey"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type GetShowManifestQueryVariables = Exact<{
  appID: Scalars["String"];
}>;

export type GetShowManifestQuery = { __typename?: "Query" } & {
  getShowManifest?: Maybe<
    { __typename?: "ShowManifest" } & Pick<
      ShowManifest,
      | "name"
      | "short_name"
      | "start_url"
      | "display"
      | "background_color"
      | "theme_color"
      | "permissions"
    > & {
        icons: Array<
          { __typename?: "ManifestIcon" } & Pick<ManifestIcon, "sizes" | "src">
        >;
      }
  >;
};

export type GetEpisodeMetaTagsQueryVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type GetEpisodeMetaTagsQuery = { __typename?: "Query" } & {
  getEpisodeMetaTags?: Maybe<
    { __typename?: "EpisodeMetaTags" } & Pick<
      EpisodeMetaTags,
      | "episodeID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
    >
  >;
};

export type GetProductMetaTagsQueryVariables = Exact<{
  productID: Scalars["Int"];
  appID: Scalars["String"];
}>;

export type GetProductMetaTagsQuery = { __typename?: "Query" } & {
  getProductMetaTags?: Maybe<
    { __typename?: "ProductMetaTags" } & Pick<
      ProductMetaTags,
      | "productID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
    >
  >;
};

export type GetShowFeedQueryVariables = Exact<{
  appID: Scalars["String"];
}>;

export type GetShowFeedQuery = { __typename?: "Query" } & {
  getShowFeed?: Maybe<
    { __typename?: "ShowFeed" } & Pick<
      ShowFeed,
      | "showName"
      | "description"
      | "imageURL"
      | "lastUpdated"
      | "providerName"
      | "language"
    > & {
        categories?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFeedCategory" } & Pick<
                ShowFeedCategory,
                "playlistName" | "name" | "order"
              >
            >
          >
        >;
        playlists?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFeedPlaylist" } & Pick<
                ShowFeedPlaylist,
                "name" | "itemIds"
              >
            >
          >
        >;
        movies?: Maybe<
          Array<
            Maybe<{ __typename?: "ShowFeedMovie" } & Pick<ShowFeedMovie, "id">>
          >
        >;
        series?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFeedSeries" } & Pick<ShowFeedSeries, "id">
            >
          >
        >;
        shortFormVideos?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFeedShortFormVideo" } & Pick<
                ShowFeedShortFormVideo,
                | "id"
                | "name"
                | "season"
                | "title"
                | "thumbnail"
                | "shortDescription"
                | "releaseDate"
                | "tags"
              > & {
                  content: {
                    __typename?: "ShowFeedShortFormVideoContent";
                  } & Pick<
                    ShowFeedShortFormVideoContent,
                    "dateAdded" | "duration" | "adBreaks"
                  > & {
                      videos?: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: "ShowFeedShortFormVideoContentVideo";
                            } & Pick<
                              ShowFeedShortFormVideoContentVideo,
                              "url" | "quality" | "videoType"
                            >
                          >
                        >
                      >;
                    };
                }
            >
          >
        >;
        tvSpecials?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFeedTVSpecial" } & Pick<
                ShowFeedTvSpecial,
                "id"
              >
            >
          >
        >;
      }
  >;
};

export type GetUserWishlistQueryVariables = Exact<{
  userID: Scalars["ID"];
  showID?: Maybe<Scalars["Int"]>;
}>;

export type GetUserWishlistQuery = { __typename?: "Query" } & {
  getUserWishlist?: Maybe<
    { __typename?: "ProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Product" } & Pick<
            Product,
            | "id"
            | "customerID"
            | "name"
            | "description"
            | "upc"
            | "brand"
            | "brandID"
            | "sku"
            | "active"
            | "added"
            | "deleted"
            | "approved"
            | "replacementProduct"
            | "mainPhotoURL"
            | "mainPhotoFileID"
            | "mainPhotoFileExt"
            | "mainPhotoSize"
            | "issueCount"
            | "photoBucket"
            | "photoKey"
            | "warnings"
            | "originalProductName"
            | "originalProductDescription"
            | "originalProductURL"
            | "originalProductPhotoBucket"
            | "originalProductPhotoKey"
            | "vendorID"
            | "vendorLogo"
            | "vendorLogoBucket"
            | "vendorLogoKey"
          >
        >
      >;
    }
  >;
};

export type ProductInWishlistQueryVariables = Exact<{
  productID: Scalars["Int"];
}>;

export type ProductInWishlistQuery = { __typename?: "Query" } & Pick<
  Query,
  "productInWishlist"
>;

export type GetExclusiveVideoContentQueryVariables = Exact<{
  id: Scalars["ID"];
  filter?: Maybe<GetExclusiveVideoContentFilterInput>;
}>;

export type GetExclusiveVideoContentQuery = { __typename?: "Query" } & {
  getExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type ListShowSmallBusinessVendorsQueryVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type ListShowSmallBusinessVendorsQuery = { __typename?: "Query" } & {
  listShowSmallBusinessVendors?: Maybe<
    { __typename?: "VendorConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "Vendor" } & Pick<
            Vendor,
            | "id"
            | "name"
            | "type"
            | "active"
            | "salesOwnedByUserID"
            | "balance"
            | "balanceUpdated"
            | "logo"
            | "logoFileID"
            | "logoFileExtension"
            | "logoBucket"
            | "logoKey"
            | "pixelTrackingID"
            | "createdAt"
            | "updatedAt"
          >
        >
      >;
    }
  >;
};

export type ListVendorProductsQueryVariables = Exact<{
  vendorID: Scalars["ID"];
}>;

export type ListVendorProductsQuery = { __typename?: "Query" } & {
  listVendorProducts?: Maybe<
    { __typename?: "VendorProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "VendorProduct" } & Pick<
            VendorProduct,
            "vendorID" | "productID" | "owned" | "assigned" | "unassigned"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                | "id"
                | "customerID"
                | "name"
                | "description"
                | "upc"
                | "brand"
                | "brandID"
                | "sku"
                | "active"
                | "added"
                | "deleted"
                | "approved"
                | "replacementProduct"
                | "mainPhotoURL"
                | "mainPhotoFileID"
                | "mainPhotoFileExt"
                | "mainPhotoSize"
                | "issueCount"
                | "photoBucket"
                | "photoKey"
                | "warnings"
                | "originalProductName"
                | "originalProductDescription"
                | "originalProductURL"
                | "originalProductPhotoBucket"
                | "originalProductPhotoKey"
                | "vendorID"
                | "vendorLogo"
                | "vendorLogoBucket"
                | "vendorLogoKey"
              >;
            }
        >
      >;
    }
  >;
};

export type ListVendorShowProductsQueryVariables = Exact<{
  vendorID: Scalars["ID"];
  showID: Scalars["Int"];
}>;

export type ListVendorShowProductsQuery = { __typename?: "Query" } & {
  listVendorShowProducts?: Maybe<
    { __typename?: "VendorProductConnection" } & {
      items?: Maybe<
        Array<
          { __typename?: "VendorProduct" } & Pick<
            VendorProduct,
            "vendorID" | "productID" | "owned" | "assigned" | "unassigned"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                | "id"
                | "customerID"
                | "name"
                | "description"
                | "upc"
                | "brand"
                | "brandID"
                | "sku"
                | "active"
                | "added"
                | "deleted"
                | "approved"
                | "replacementProduct"
                | "mainPhotoURL"
                | "mainPhotoFileID"
                | "mainPhotoFileExt"
                | "mainPhotoSize"
                | "issueCount"
                | "photoBucket"
                | "photoKey"
                | "warnings"
                | "originalProductName"
                | "originalProductDescription"
                | "originalProductURL"
                | "originalProductPhotoBucket"
                | "originalProductPhotoKey"
                | "vendorID"
                | "vendorLogo"
                | "vendorLogoBucket"
                | "vendorLogoKey"
              >;
            }
        >
      >;
    }
  >;
};

export type GetNotificationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetNotificationQuery = { __typename?: "Query" } & {
  getNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type ListNotificationsQueryVariables = Exact<{
  filter?: Maybe<ModelNotificationFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListNotificationsQuery = { __typename?: "Query" } & {
  listNotifications?: Maybe<
    { __typename?: "ModelNotificationConnection" } & Pick<
      ModelNotificationConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "Notification" } & Pick<
                Notification,
                | "id"
                | "userID"
                | "description"
                | "time"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type GetCubejsDashboardItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCubejsDashboardItemQuery = { __typename?: "Query" } & {
  getCubejsDashboardItem?: Maybe<
    { __typename?: "CubejsDashboardItem" } & Pick<
      CubejsDashboardItem,
      | "id"
      | "userID"
      | "layout"
      | "vizState"
      | "name"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type ListCubejsDashboardItemsQueryVariables = Exact<{
  filter?: Maybe<ModelCubejsDashboardItemFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListCubejsDashboardItemsQuery = { __typename?: "Query" } & {
  listCubejsDashboardItems?: Maybe<
    { __typename?: "ModelCubejsDashboardItemConnection" } & Pick<
      ModelCubejsDashboardItemConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "CubejsDashboardItem" } & Pick<
                CubejsDashboardItem,
                | "id"
                | "userID"
                | "layout"
                | "vizState"
                | "name"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type ListUserDashboardItemsQueryVariables = Exact<{
  userID?: Maybe<Scalars["ID"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelCubejsDashboardItemFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListUserDashboardItemsQuery = { __typename?: "Query" } & {
  listUserDashboardItems?: Maybe<
    { __typename?: "ModelCubejsDashboardItemConnection" } & Pick<
      ModelCubejsDashboardItemConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "CubejsDashboardItem" } & Pick<
                CubejsDashboardItem,
                | "id"
                | "userID"
                | "layout"
                | "vizState"
                | "name"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type ListExclusiveVideoContentsQueryVariables = Exact<{
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListExclusiveVideoContentsQuery = { __typename?: "Query" } & {
  listExclusiveVideoContents?: Maybe<
    { __typename?: "ModelExclusiveVideoContentConnection" } & Pick<
      ModelExclusiveVideoContentConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "ExclusiveVideoContent" } & Pick<
                ExclusiveVideoContent,
                | "id"
                | "legacyCustomerID"
                | "legacyCustomerIDString"
                | "legacyShowID"
                | "name"
                | "description"
                | "enabled"
                | "firstRelease"
                | "posterBucket"
                | "posterKey"
                | "videoBucket"
                | "videoKey"
                | "videoURL"
                | "videoQuality"
                | "videoType"
                | "videoWidth"
                | "videoHeight"
                | "mediaConvertJobID"
                | "mediaConvertJobStatus"
                | "duration"
                | "releaseToOTT"
                | "releaseToApp"
                | "videoToken"
                | "disableProductScroller"
                | "createdAt"
                | "updatedAt"
              > & {
                  productDefinitions?: Maybe<
                    Array<
                      {
                        __typename?: "ExclusiveVideoContentProductDefinition";
                      } & Pick<
                        ExclusiveVideoContentProductDefinition,
                        | "productID"
                        | "startTimeMilliseconds"
                        | "endTimeMilliseconds"
                      >
                    >
                  >;
                  videoProducts?: Maybe<
                    Array<
                      { __typename?: "ExclusiveVideoProduct" } & Pick<
                        ExclusiveVideoProduct,
                        | "brandName"
                        | "startTimeMilliseconds"
                        | "endTimeMilliseconds"
                      > & {
                          product: { __typename?: "Product" } & Pick<
                            Product,
                            | "id"
                            | "customerID"
                            | "name"
                            | "description"
                            | "upc"
                            | "brand"
                            | "brandID"
                            | "sku"
                            | "active"
                            | "added"
                            | "deleted"
                            | "approved"
                            | "replacementProduct"
                            | "mainPhotoURL"
                            | "mainPhotoFileID"
                            | "mainPhotoFileExt"
                            | "mainPhotoSize"
                            | "issueCount"
                            | "photoBucket"
                            | "photoKey"
                            | "warnings"
                            | "originalProductName"
                            | "originalProductDescription"
                            | "originalProductURL"
                            | "originalProductPhotoBucket"
                            | "originalProductPhotoKey"
                            | "vendorID"
                            | "vendorLogo"
                            | "vendorLogoBucket"
                            | "vendorLogoKey"
                          >;
                        }
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListExclusiveContentsByCustomerQueryVariables = Exact<{
  legacyCustomerID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListExclusiveContentsByCustomerQuery = { __typename?: "Query" } & {
  listExclusiveContentsByCustomer?: Maybe<
    { __typename?: "ModelExclusiveVideoContentConnection" } & Pick<
      ModelExclusiveVideoContentConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "ExclusiveVideoContent" } & Pick<
                ExclusiveVideoContent,
                | "id"
                | "legacyCustomerID"
                | "legacyCustomerIDString"
                | "legacyShowID"
                | "name"
                | "description"
                | "enabled"
                | "firstRelease"
                | "posterBucket"
                | "posterKey"
                | "videoBucket"
                | "videoKey"
                | "videoURL"
                | "videoQuality"
                | "videoType"
                | "videoWidth"
                | "videoHeight"
                | "mediaConvertJobID"
                | "mediaConvertJobStatus"
                | "duration"
                | "releaseToOTT"
                | "releaseToApp"
                | "videoToken"
                | "disableProductScroller"
                | "createdAt"
                | "updatedAt"
              > & {
                  productDefinitions?: Maybe<
                    Array<
                      {
                        __typename?: "ExclusiveVideoContentProductDefinition";
                      } & Pick<
                        ExclusiveVideoContentProductDefinition,
                        | "productID"
                        | "startTimeMilliseconds"
                        | "endTimeMilliseconds"
                      >
                    >
                  >;
                  videoProducts?: Maybe<
                    Array<
                      { __typename?: "ExclusiveVideoProduct" } & Pick<
                        ExclusiveVideoProduct,
                        | "brandName"
                        | "startTimeMilliseconds"
                        | "endTimeMilliseconds"
                      > & {
                          product: { __typename?: "Product" } & Pick<
                            Product,
                            | "id"
                            | "customerID"
                            | "name"
                            | "description"
                            | "upc"
                            | "brand"
                            | "brandID"
                            | "sku"
                            | "active"
                            | "added"
                            | "deleted"
                            | "approved"
                            | "replacementProduct"
                            | "mainPhotoURL"
                            | "mainPhotoFileID"
                            | "mainPhotoFileExt"
                            | "mainPhotoSize"
                            | "issueCount"
                            | "photoBucket"
                            | "photoKey"
                            | "warnings"
                            | "originalProductName"
                            | "originalProductDescription"
                            | "originalProductURL"
                            | "originalProductPhotoBucket"
                            | "originalProductPhotoKey"
                            | "vendorID"
                            | "vendorLogo"
                            | "vendorLogoBucket"
                            | "vendorLogoKey"
                          >;
                        }
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListExclusiveContentsByShowQueryVariables = Exact<{
  legacyShowID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListExclusiveContentsByShowQuery = { __typename?: "Query" } & {
  listExclusiveContentsByShow?: Maybe<
    { __typename?: "ModelExclusiveVideoContentConnection" } & Pick<
      ModelExclusiveVideoContentConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "ExclusiveVideoContent" } & Pick<
                ExclusiveVideoContent,
                | "id"
                | "legacyCustomerID"
                | "legacyCustomerIDString"
                | "legacyShowID"
                | "name"
                | "description"
                | "enabled"
                | "firstRelease"
                | "posterBucket"
                | "posterKey"
                | "videoBucket"
                | "videoKey"
                | "videoURL"
                | "videoQuality"
                | "videoType"
                | "videoWidth"
                | "videoHeight"
                | "mediaConvertJobID"
                | "mediaConvertJobStatus"
                | "duration"
                | "releaseToOTT"
                | "releaseToApp"
                | "videoToken"
                | "disableProductScroller"
                | "createdAt"
                | "updatedAt"
              > & {
                  productDefinitions?: Maybe<
                    Array<
                      {
                        __typename?: "ExclusiveVideoContentProductDefinition";
                      } & Pick<
                        ExclusiveVideoContentProductDefinition,
                        | "productID"
                        | "startTimeMilliseconds"
                        | "endTimeMilliseconds"
                      >
                    >
                  >;
                  videoProducts?: Maybe<
                    Array<
                      { __typename?: "ExclusiveVideoProduct" } & Pick<
                        ExclusiveVideoProduct,
                        | "brandName"
                        | "startTimeMilliseconds"
                        | "endTimeMilliseconds"
                      > & {
                          product: { __typename?: "Product" } & Pick<
                            Product,
                            | "id"
                            | "customerID"
                            | "name"
                            | "description"
                            | "upc"
                            | "brand"
                            | "brandID"
                            | "sku"
                            | "active"
                            | "added"
                            | "deleted"
                            | "approved"
                            | "replacementProduct"
                            | "mainPhotoURL"
                            | "mainPhotoFileID"
                            | "mainPhotoFileExt"
                            | "mainPhotoSize"
                            | "issueCount"
                            | "photoBucket"
                            | "photoKey"
                            | "warnings"
                            | "originalProductName"
                            | "originalProductDescription"
                            | "originalProductURL"
                            | "originalProductPhotoBucket"
                            | "originalProductPhotoKey"
                            | "vendorID"
                            | "vendorLogo"
                            | "vendorLogoBucket"
                            | "vendorLogoKey"
                          >;
                        }
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetShowMetaTagsQueryVariables = Exact<{
  appID: Scalars["ID"];
}>;

export type GetShowMetaTagsQuery = { __typename?: "Query" } & {
  getShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type ListShowMetaTagssQueryVariables = Exact<{
  appID?: Maybe<Scalars["ID"]>;
  filter?: Maybe<ModelShowMetaTagsFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortDirection?: Maybe<ModelSortDirection>;
}>;

export type ListShowMetaTagssQuery = { __typename?: "Query" } & {
  listShowMetaTagss?: Maybe<
    { __typename?: "ModelShowMetaTagsConnection" } & Pick<
      ModelShowMetaTagsConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowMetaTags" } & Pick<
                ShowMetaTags,
                | "appID"
                | "showID"
                | "OG_TITLE"
                | "OG_DESCRIPTION"
                | "OG_IMAGE"
                | "FAVICON"
                | "MANIFEST"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type GetVendorQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetVendorQuery = { __typename?: "Query" } & {
  getVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type ListVendorsQueryVariables = Exact<{
  filter?: Maybe<ModelVendorFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListVendorsQuery = { __typename?: "Query" } & {
  listVendors?: Maybe<
    { __typename?: "ModelVendorConnection" } & Pick<
      ModelVendorConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "Vendor" } & Pick<
                Vendor,
                | "id"
                | "name"
                | "type"
                | "active"
                | "salesOwnedByUserID"
                | "balance"
                | "balanceUpdated"
                | "logo"
                | "logoFileID"
                | "logoFileExtension"
                | "logoBucket"
                | "logoKey"
                | "pixelTrackingID"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type ListVendorsByTypeQueryVariables = Exact<{
  type?: Maybe<VendorBusinessType>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelVendorFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListVendorsByTypeQuery = { __typename?: "Query" } & {
  listVendorsByType?: Maybe<
    { __typename?: "ModelVendorConnection" } & Pick<
      ModelVendorConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "Vendor" } & Pick<
                Vendor,
                | "id"
                | "name"
                | "type"
                | "active"
                | "salesOwnedByUserID"
                | "balance"
                | "balanceUpdated"
                | "logo"
                | "logoFileID"
                | "logoFileExtension"
                | "logoBucket"
                | "logoKey"
                | "pixelTrackingID"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type GetShowFontV2QueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetShowFontV2Query = { __typename?: "Query" } & {
  getShowFontV2?: Maybe<
    { __typename?: "ShowFontV2" } & Pick<
      ShowFontV2,
      | "id"
      | "showFontLocationComposite"
      | "showID"
      | "family"
      | "location"
      | "weight"
      | "style"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type ListShowFontV2sQueryVariables = Exact<{
  filter?: Maybe<ModelShowFontV2FilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListShowFontV2sQuery = { __typename?: "Query" } & {
  listShowFontV2s?: Maybe<
    { __typename?: "ModelShowFontV2Connection" } & Pick<
      ModelShowFontV2Connection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFontV2" } & Pick<
                ShowFontV2,
                | "id"
                | "showFontLocationComposite"
                | "showID"
                | "family"
                | "location"
                | "weight"
                | "style"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type ListFontsByShowQueryVariables = Exact<{
  showID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelShowFontV2FilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListFontsByShowQuery = { __typename?: "Query" } & {
  listFontsByShow?: Maybe<
    { __typename?: "ModelShowFontV2Connection" } & Pick<
      ModelShowFontV2Connection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShowFontV2" } & Pick<
                ShowFontV2,
                | "id"
                | "showFontLocationComposite"
                | "showID"
                | "family"
                | "location"
                | "weight"
                | "style"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type GetBannerAdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetBannerAdQuery = { __typename?: "Query" } & {
  getBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type ListBannerAdsQueryVariables = Exact<{
  filter?: Maybe<ModelBannerAdFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListBannerAdsQuery = { __typename?: "Query" } & {
  listBannerAds?: Maybe<
    { __typename?: "ModelBannerAdConnection" } & Pick<
      ModelBannerAdConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "BannerAd" } & Pick<
                BannerAd,
                | "id"
                | "customerID"
                | "showID"
                | "episodeID"
                | "imageBucket"
                | "imageKey"
                | "url"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type ListBannerAdsByShowQueryVariables = Exact<{
  showID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelBannerAdFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListBannerAdsByShowQuery = { __typename?: "Query" } & {
  listBannerAdsByShow?: Maybe<
    { __typename?: "ModelBannerAdConnection" } & Pick<
      ModelBannerAdConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "BannerAd" } & Pick<
                BannerAd,
                | "id"
                | "customerID"
                | "showID"
                | "episodeID"
                | "imageBucket"
                | "imageKey"
                | "url"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type ListBannerAdsByEpisodeQueryVariables = Exact<{
  episodeID?: Maybe<Scalars["Int"]>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelBannerAdFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
}>;

export type ListBannerAdsByEpisodeQuery = { __typename?: "Query" } & {
  listBannerAdsByEpisode?: Maybe<
    { __typename?: "ModelBannerAdConnection" } & Pick<
      ModelBannerAdConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "BannerAd" } & Pick<
                BannerAd,
                | "id"
                | "customerID"
                | "showID"
                | "episodeID"
                | "imageBucket"
                | "imageKey"
                | "url"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type GetDigitalAssetLinksQueryVariables = Exact<{
  appID: Scalars["String"];
}>;

export type GetDigitalAssetLinksQuery = { __typename?: "Query" } & {
  getDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type ListDigitalAssetLinkssQueryVariables = Exact<{
  appID?: Maybe<Scalars["String"]>;
  filter?: Maybe<ModelDigitalAssetLinksFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortDirection?: Maybe<ModelSortDirection>;
}>;

export type ListDigitalAssetLinkssQuery = { __typename?: "Query" } & {
  listDigitalAssetLinkss?: Maybe<
    { __typename?: "ModelDigitalAssetLinksConnection" } & Pick<
      ModelDigitalAssetLinksConnection,
      "nextToken"
    > & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: "DigitalAssetLinks" } & Pick<
                DigitalAssetLinks,
                | "appID"
                | "legacyShowID"
                | "legacyCustomerID"
                | "android"
                | "createdAt"
                | "updatedAt"
              >
            >
          >
        >;
      }
  >;
};

export type OnCreateCustomerSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreateCustomerSubscription = { __typename?: "Subscription" } & {
  onCreateCustomer?: Maybe<
    { __typename?: "Customer" } & Pick<
      Customer,
      | "id"
      | "shortName"
      | "fullName"
      | "active"
      | "added"
      | "adminType"
      | "isVendor"
      | "emailAddressIdentity"
      | "streamingContent"
      | "displayShowReports"
      | "displayEpisodeReports"
    >
  >;
};

export type OnCreateShowSubscriptionVariables = Exact<{
  customerID: Scalars["Int"];
}>;

export type OnCreateShowSubscription = { __typename?: "Subscription" } & {
  onCreateShow?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "hideInAdmin"
      | "appID"
      | "mainMenuType"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "autoplayEnabled"
            | "autoplayAfterFingerprint"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "episodeTileType"
            | "productTileType"
            | "witAIAppID"
            | "witAIAccessToken"
            | "witAILastUpdate"
            | "googleTrackingID"
            | "googlePlayAppEnabled"
            | "googlePlayAppURL"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        channel?: Maybe<
          { __typename?: "ShowChannel" } & Pick<
            ShowChannel,
            | "rokuChannelEnabled"
            | "rokuChannelType"
            | "rokuChannelInstallURL"
            | "fireTVChannelEnabled"
            | "fireTVInstallURL"
            | "fireTVDevURL"
          >
        >;
        demographicConfiguration?: Maybe<
          { __typename?: "ShowDemographicConfiguration" } & Pick<
            ShowDemographicConfiguration,
            | "introText"
            | "ageEnabled"
            | "genderEnabled"
            | "ethnicityEnabled"
            | "educationEnabled"
            | "employmentEnabled"
            | "maritalStatusEnabled"
            | "incomeEnabled"
            | "zipcodeEnabled"
            | "emailEnabled"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "enabled"
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type OnUpdateShowSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OnUpdateShowSubscription = { __typename?: "Subscription" } & {
  onUpdateShow?: Maybe<
    { __typename?: "Show" } & Pick<
      Show,
      | "id"
      | "customerID"
      | "nameShort"
      | "nameLong"
      | "description"
      | "poster"
      | "posterURL"
      | "added"
      | "active"
      | "hideInAdmin"
      | "appID"
      | "mainMenuType"
      | "faqContent"
      | "faviconsURL"
    > & {
        appData?: Maybe<
          { __typename?: "ShowAppData" } & Pick<
            ShowAppData,
            | "appLogo"
            | "appLogoURL"
            | "primaryColor"
            | "textPrimaryColor"
            | "secondaryColor"
            | "tertiaryColor"
            | "accentColor"
            | "infoColor"
            | "headBackgroundColor"
            | "headTextColor"
            | "menuTextColor"
            | "productCaroselBackgroundColor"
            | "productButTextColor"
            | "productButBackColor"
            | "productBut2TextColor"
            | "productBut2BackColor"
            | "autoplayEnabled"
            | "autoplayAfterFingerprint"
            | "copyright"
            | "privacyPolicy"
            | "appPosterDisplayType"
            | "episodeTileType"
            | "productTileType"
            | "witAIAppID"
            | "witAIAccessToken"
            | "witAILastUpdate"
            | "googleTrackingID"
            | "googlePlayAppEnabled"
            | "googlePlayAppURL"
            | "facebookAppID"
            | "facebookPageURL"
            | "twitterUsername"
            | "iOSAppStoreID"
            | "videoGroupingName"
            | "videoGroupingNamePlural"
          >
        >;
        channel?: Maybe<
          { __typename?: "ShowChannel" } & Pick<
            ShowChannel,
            | "rokuChannelEnabled"
            | "rokuChannelType"
            | "rokuChannelInstallURL"
            | "fireTVChannelEnabled"
            | "fireTVInstallURL"
            | "fireTVDevURL"
          >
        >;
        demographicConfiguration?: Maybe<
          { __typename?: "ShowDemographicConfiguration" } & Pick<
            ShowDemographicConfiguration,
            | "introText"
            | "ageEnabled"
            | "genderEnabled"
            | "ethnicityEnabled"
            | "educationEnabled"
            | "employmentEnabled"
            | "maritalStatusEnabled"
            | "incomeEnabled"
            | "zipcodeEnabled"
            | "emailEnabled"
          >
        >;
        productRequestConfiguration?: Maybe<
          { __typename?: "ShowProductRequestConfiguration" } & Pick<
            ShowProductRequestConfiguration,
            | "enabled"
            | "titleText"
            | "introText"
            | "episodeListEnabled"
            | "episodeListText"
            | "photoGroupEnabled"
            | "photoGroupText"
            | "categoryEnabled"
            | "categoryText"
            | "emailEnabled"
            | "emailText"
            | "requestText"
            | "submitThanksText"
            | "notificationsList"
          >
        >;
      }
  >;
};

export type OnCreateEpisodeSubscriptionVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type OnCreateEpisodeSubscription = { __typename?: "Subscription" } & {
  onCreateEpisode?: Maybe<
    { __typename?: "Episode" } & Pick<
      Episode,
      | "id"
      | "showID"
      | "showName"
      | "season"
      | "seasonName"
      | "number"
      | "firstRelease"
      | "added"
      | "name"
      | "description"
      | "disclaimer"
      | "enabled"
      | "posterType"
      | "poster"
      | "posterURL"
      | "posterFileID"
      | "video"
      | "videoBucket"
      | "videoKey"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "audioFingerprintID"
      | "duration"
      | "rokuAddBreaks"
      | "releaseToOTT"
      | "releaseToApp"
      | "liveChannelID"
      | "enableHeaderButton"
      | "headerButtonText"
      | "headerButtonURL"
      | "disableProductScroller"
      | "liveStreamID"
      | "liveStreamEnabledOnStartMenuID"
      | "videoToken"
    >
  >;
};

export type OnUpdateShowEpisodeSubscriptionVariables = Exact<{
  showID: Scalars["Int"];
}>;

export type OnUpdateShowEpisodeSubscription = {
  __typename?: "Subscription";
} & {
  onUpdateShowEpisode?: Maybe<
    { __typename?: "Episode" } & Pick<
      Episode,
      | "id"
      | "showID"
      | "showName"
      | "season"
      | "seasonName"
      | "number"
      | "firstRelease"
      | "added"
      | "name"
      | "description"
      | "disclaimer"
      | "enabled"
      | "posterType"
      | "poster"
      | "posterURL"
      | "posterFileID"
      | "video"
      | "videoBucket"
      | "videoKey"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "audioFingerprintID"
      | "duration"
      | "rokuAddBreaks"
      | "releaseToOTT"
      | "releaseToApp"
      | "liveChannelID"
      | "enableHeaderButton"
      | "headerButtonText"
      | "headerButtonURL"
      | "disableProductScroller"
      | "liveStreamID"
      | "liveStreamEnabledOnStartMenuID"
      | "videoToken"
    >
  >;
};

export type OnUpdateEpisodeSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OnUpdateEpisodeSubscription = { __typename?: "Subscription" } & {
  onUpdateEpisode?: Maybe<
    { __typename?: "Episode" } & Pick<
      Episode,
      | "id"
      | "showID"
      | "showName"
      | "season"
      | "seasonName"
      | "number"
      | "firstRelease"
      | "added"
      | "name"
      | "description"
      | "disclaimer"
      | "enabled"
      | "posterType"
      | "poster"
      | "posterURL"
      | "posterFileID"
      | "video"
      | "videoBucket"
      | "videoKey"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "audioFingerprintID"
      | "duration"
      | "rokuAddBreaks"
      | "releaseToOTT"
      | "releaseToApp"
      | "liveChannelID"
      | "enableHeaderButton"
      | "headerButtonText"
      | "headerButtonURL"
      | "disableProductScroller"
      | "liveStreamID"
      | "liveStreamEnabledOnStartMenuID"
      | "videoToken"
    >
  >;
};

export type OnCreateEpisodePhotoSubscriptionVariables = Exact<{
  episodeID: Scalars["Int"];
}>;

export type OnCreateEpisodePhotoSubscription = {
  __typename?: "Subscription";
} & {
  onCreateEpisodePhoto?: Maybe<
    { __typename?: "EpisodePhoto" } & Pick<
      EpisodePhoto,
      | "id"
      | "episodeID"
      | "groupID"
      | "groupName"
      | "order"
      | "title"
      | "description"
      | "fileID"
      | "extension"
      | "url"
      | "thumbnailURL"
      | "enabled"
      | "added"
    >
  >;
};

export type OnUpdateProductSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OnUpdateProductSubscription = { __typename?: "Subscription" } & {
  onUpdateProduct?: Maybe<
    { __typename?: "Product" } & Pick<
      Product,
      | "id"
      | "customerID"
      | "name"
      | "description"
      | "upc"
      | "brand"
      | "brandID"
      | "sku"
      | "active"
      | "added"
      | "deleted"
      | "approved"
      | "replacementProduct"
      | "mainPhotoURL"
      | "mainPhotoFileID"
      | "mainPhotoFileExt"
      | "mainPhotoSize"
      | "issueCount"
      | "photoBucket"
      | "photoKey"
      | "warnings"
      | "originalProductName"
      | "originalProductDescription"
      | "originalProductURL"
      | "originalProductPhotoBucket"
      | "originalProductPhotoKey"
      | "vendorID"
      | "vendorLogo"
      | "vendorLogoBucket"
      | "vendorLogoKey"
    >
  >;
};

export type OnUpdateCategorySubscriptionVariables = Exact<{
  id: Scalars["Int"];
  customerID: Scalars["Int"];
}>;

export type OnUpdateCategorySubscription = { __typename?: "Subscription" } & {
  onUpdateCategory?: Maybe<
    { __typename?: "Category" } & Pick<
      Category,
      | "id"
      | "customerID"
      | "name"
      | "image"
      | "imageURL"
      | "imageFileSize"
      | "active"
      | "thumbnail"
      | "thumbnailURL"
      | "thumbnailFileSize"
      | "tag"
      | "description"
    >
  >;
};

export type OnResolveProductsReportSubscriptionVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OnResolveProductsReportSubscription = {
  __typename?: "Subscription";
} & {
  onResolveProductsReport?: Maybe<
    { __typename?: "ReportProductConnection" } & Pick<
      ReportProductConnection,
      "id" | "nextToken"
    > & {
        items?: Maybe<
          Array<
            { __typename?: "ReportProduct" } & Pick<
              ReportProduct,
              | "id"
              | "name"
              | "brandName"
              | "upc"
              | "sku"
              | "mainPhotoURL"
              | "mainPhotoFileId"
              | "mainPhotoFileExt"
              | "active"
              | "deleted"
              | "added"
              | "imagePreviews"
              | "detailViews"
              | "clicks"
            >
          >
        >;
      }
  >;
};

export type OnResolveVendorProductsReportSubscriptionVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OnResolveVendorProductsReportSubscription = {
  __typename?: "Subscription";
} & {
  onResolveVendorProductsReport?: Maybe<
    { __typename?: "ReportProductConnection" } & Pick<
      ReportProductConnection,
      "id" | "nextToken"
    > & {
        items?: Maybe<
          Array<
            { __typename?: "ReportProduct" } & Pick<
              ReportProduct,
              | "id"
              | "name"
              | "brandName"
              | "upc"
              | "sku"
              | "mainPhotoURL"
              | "mainPhotoFileId"
              | "mainPhotoFileExt"
              | "active"
              | "deleted"
              | "added"
              | "imagePreviews"
              | "detailViews"
              | "clicks"
            >
          >
        >;
      }
  >;
};

export type OnCreateNotificationSubscriptionVariables = Exact<{
  userID: Scalars["String"];
}>;

export type OnCreateNotificationSubscription = {
  __typename?: "Subscription";
} & {
  onCreateNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type OnUpdateNotificationSubscriptionVariables = Exact<{
  userID: Scalars["String"];
}>;

export type OnUpdateNotificationSubscription = {
  __typename?: "Subscription";
} & {
  onUpdateNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type OnDeleteNotificationSubscriptionVariables = Exact<{
  userID: Scalars["String"];
}>;

export type OnDeleteNotificationSubscription = {
  __typename?: "Subscription";
} & {
  onDeleteNotification?: Maybe<
    { __typename?: "Notification" } & Pick<
      Notification,
      "id" | "userID" | "description" | "time" | "createdAt" | "updatedAt"
    >
  >;
};

export type OnCreateExclusiveVideoContentSubscriptionVariables = Exact<{
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
}>;

export type OnCreateExclusiveVideoContentSubscription = {
  __typename?: "Subscription";
} & {
  onCreateExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type OnUpdateExclusiveVideoContentSubscriptionVariables = Exact<{
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
}>;

export type OnUpdateExclusiveVideoContentSubscription = {
  __typename?: "Subscription";
} & {
  onUpdateExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type OnDeleteExclusiveVideoContentSubscriptionVariables = Exact<{
  legacyCustomerIDString?: Maybe<Scalars["String"]>;
}>;

export type OnDeleteExclusiveVideoContentSubscription = {
  __typename?: "Subscription";
} & {
  onDeleteExclusiveVideoContent?: Maybe<
    { __typename?: "ExclusiveVideoContent" } & Pick<
      ExclusiveVideoContent,
      | "id"
      | "legacyCustomerID"
      | "legacyCustomerIDString"
      | "legacyShowID"
      | "name"
      | "description"
      | "enabled"
      | "firstRelease"
      | "posterBucket"
      | "posterKey"
      | "videoBucket"
      | "videoKey"
      | "videoURL"
      | "videoQuality"
      | "videoType"
      | "videoWidth"
      | "videoHeight"
      | "mediaConvertJobID"
      | "mediaConvertJobStatus"
      | "duration"
      | "releaseToOTT"
      | "releaseToApp"
      | "videoToken"
      | "disableProductScroller"
      | "createdAt"
      | "updatedAt"
    > & {
        productDefinitions?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoContentProductDefinition" } & Pick<
              ExclusiveVideoContentProductDefinition,
              "productID" | "startTimeMilliseconds" | "endTimeMilliseconds"
            >
          >
        >;
        videoProducts?: Maybe<
          Array<
            { __typename?: "ExclusiveVideoProduct" } & Pick<
              ExclusiveVideoProduct,
              "brandName" | "startTimeMilliseconds" | "endTimeMilliseconds"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  | "id"
                  | "customerID"
                  | "name"
                  | "description"
                  | "upc"
                  | "brand"
                  | "brandID"
                  | "sku"
                  | "active"
                  | "added"
                  | "deleted"
                  | "approved"
                  | "replacementProduct"
                  | "mainPhotoURL"
                  | "mainPhotoFileID"
                  | "mainPhotoFileExt"
                  | "mainPhotoSize"
                  | "issueCount"
                  | "photoBucket"
                  | "photoKey"
                  | "warnings"
                  | "originalProductName"
                  | "originalProductDescription"
                  | "originalProductURL"
                  | "originalProductPhotoBucket"
                  | "originalProductPhotoKey"
                  | "vendorID"
                  | "vendorLogo"
                  | "vendorLogoBucket"
                  | "vendorLogoKey"
                >;
              }
          >
        >;
      }
  >;
};

export type OnCreateShowMetaTagsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreateShowMetaTagsSubscription = {
  __typename?: "Subscription";
} & {
  onCreateShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnUpdateShowMetaTagsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnUpdateShowMetaTagsSubscription = {
  __typename?: "Subscription";
} & {
  onUpdateShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnDeleteShowMetaTagsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnDeleteShowMetaTagsSubscription = {
  __typename?: "Subscription";
} & {
  onDeleteShowMetaTags?: Maybe<
    { __typename?: "ShowMetaTags" } & Pick<
      ShowMetaTags,
      | "appID"
      | "showID"
      | "OG_TITLE"
      | "OG_DESCRIPTION"
      | "OG_IMAGE"
      | "FAVICON"
      | "MANIFEST"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnCreateVendorSubscriptionVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
}>;

export type OnCreateVendorSubscription = { __typename?: "Subscription" } & {
  onCreateVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnUpdateVendorSubscriptionVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
}>;

export type OnUpdateVendorSubscription = { __typename?: "Subscription" } & {
  onUpdateVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnDeleteVendorSubscriptionVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
}>;

export type OnDeleteVendorSubscription = { __typename?: "Subscription" } & {
  onDeleteVendor?: Maybe<
    { __typename?: "Vendor" } & Pick<
      Vendor,
      | "id"
      | "name"
      | "type"
      | "active"
      | "salesOwnedByUserID"
      | "balance"
      | "balanceUpdated"
      | "logo"
      | "logoFileID"
      | "logoFileExtension"
      | "logoBucket"
      | "logoKey"
      | "pixelTrackingID"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnCreateBannerAdSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreateBannerAdSubscription = { __typename?: "Subscription" } & {
  onCreateBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnUpdateBannerAdSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnUpdateBannerAdSubscription = { __typename?: "Subscription" } & {
  onUpdateBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnDeleteBannerAdSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnDeleteBannerAdSubscription = { __typename?: "Subscription" } & {
  onDeleteBannerAd?: Maybe<
    { __typename?: "BannerAd" } & Pick<
      BannerAd,
      | "id"
      | "customerID"
      | "showID"
      | "episodeID"
      | "imageBucket"
      | "imageKey"
      | "url"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnCreateDigitalAssetLinksSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreateDigitalAssetLinksSubscription = {
  __typename?: "Subscription";
} & {
  onCreateDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnUpdateDigitalAssetLinksSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnUpdateDigitalAssetLinksSubscription = {
  __typename?: "Subscription";
} & {
  onUpdateDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export type OnDeleteDigitalAssetLinksSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnDeleteDigitalAssetLinksSubscription = {
  __typename?: "Subscription";
} & {
  onDeleteDigitalAssetLinks?: Maybe<
    { __typename?: "DigitalAssetLinks" } & Pick<
      DigitalAssetLinks,
      | "appID"
      | "legacyShowID"
      | "legacyCustomerID"
      | "android"
      | "createdAt"
      | "updatedAt"
    >
  >;
};

export const GetShowByHostnameCustomDocument = `
    query GetShowByHostnameCustom($hostname: String!) {
  getShowByHostname(hostname: $hostname) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    appID
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      copyright
      privacyPolicy
      appPosterDisplayType
      googleTrackingID
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    productRequestConfiguration {
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const useGetShowByHostnameCustomQuery = <
  TData = GetShowByHostnameCustomQuery,
  TError = unknown
>(
  variables: GetShowByHostnameCustomQueryVariables,
  options?: UseQueryOptions<GetShowByHostnameCustomQuery, TError, TData>
) =>
  useQuery<GetShowByHostnameCustomQuery, TError, TData>(
    ["GetShowByHostnameCustom", variables],
    amplifyFetcher<
      GetShowByHostnameCustomQuery,
      GetShowByHostnameCustomQueryVariables
    >(GetShowByHostnameCustomDocument, variables),
    options
  );
export const ListAppEpisodesCustomDocument = `
    query ListAppEpisodesCustom($showID: Int!, $limit: Int) {
  listAppEpisodes(showID: $showID, limit: $limit) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      duration
      releaseToApp
      enableHeaderButton
      headerButtonText
      headerButtonURL
      videoToken
    }
  }
}
    `;
export const useListAppEpisodesCustomQuery = <
  TData = ListAppEpisodesCustomQuery,
  TError = unknown
>(
  variables: ListAppEpisodesCustomQueryVariables,
  options?: UseQueryOptions<ListAppEpisodesCustomQuery, TError, TData>
) =>
  useQuery<ListAppEpisodesCustomQuery, TError, TData>(
    ["ListAppEpisodesCustom", variables],
    amplifyFetcher<
      ListAppEpisodesCustomQuery,
      ListAppEpisodesCustomQueryVariables
    >(ListAppEpisodesCustomDocument, variables),
    options
  );
export const GetProductCustomDocument = `
    query GetProductCustom($id: Int!) {
  getProduct(id: $id) {
    id
    name
    description
    upc
    brand
    brandID
    sku
    active
    added
    deleted
    approved
    replacementProduct
    mainPhotoURL
    mainPhotoFileID
    mainPhotoFileExt
    originalProductName
    originalProductDescription
    originalProductURL
    originalProductPhotoBucket
    originalProductPhotoKey
    vendorID
    vendorLogo
    vendorLogoBucket
    vendorLogoKey
  }
}
    `;
export const useGetProductCustomQuery = <
  TData = GetProductCustomQuery,
  TError = unknown
>(
  variables: GetProductCustomQueryVariables,
  options?: UseQueryOptions<GetProductCustomQuery, TError, TData>
) =>
  useQuery<GetProductCustomQuery, TError, TData>(
    ["GetProductCustom", variables],
    amplifyFetcher<GetProductCustomQuery, GetProductCustomQueryVariables>(
      GetProductCustomDocument,
      variables
    ),
    options
  );
export const ListRelatedProductsCustomDocument = `
    query ListRelatedProductsCustom($categoryID: Int!, $showID: Int!) {
  listRelatedProducts(categoryID: $categoryID, showID: $showID) {
    items {
      id
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useListRelatedProductsCustomQuery = <
  TData = ListRelatedProductsCustomQuery,
  TError = unknown
>(
  variables: ListRelatedProductsCustomQueryVariables,
  options?: UseQueryOptions<ListRelatedProductsCustomQuery, TError, TData>
) =>
  useQuery<ListRelatedProductsCustomQuery, TError, TData>(
    ["ListRelatedProductsCustom", variables],
    amplifyFetcher<
      ListRelatedProductsCustomQuery,
      ListRelatedProductsCustomQueryVariables
    >(ListRelatedProductsCustomDocument, variables),
    options
  );
export const GetVendorCustomDocument = `
    query GetVendorCustom($id: ID!) {
  getVendor(id: $id) {
    id
    name
    type
    active
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
  }
}
    `;
export const useGetVendorCustomQuery = <
  TData = GetVendorCustomQuery,
  TError = unknown
>(
  variables: GetVendorCustomQueryVariables,
  options?: UseQueryOptions<GetVendorCustomQuery, TError, TData>
) =>
  useQuery<GetVendorCustomQuery, TError, TData>(
    ["GetVendorCustom", variables],
    amplifyFetcher<GetVendorCustomQuery, GetVendorCustomQueryVariables>(
      GetVendorCustomDocument,
      variables
    ),
    options
  );
export const CreateUserDocument = `
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    email
    enabled
    status
    created
    email_verified
    given_name
    family_name
    gender
    phone_number
    customerID
    vendorID
  }
}
    `;
export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateUserMutation,
    TError,
    CreateUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateUserMutation,
    TError,
    CreateUserMutationVariables,
    TContext
  >(
    (variables?: CreateUserMutationVariables) =>
      amplifyFetcher<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        variables
      )(),
    options
  );
export const DisableUserDocument = `
    mutation DisableUser($id: ID!) {
  disableUser(id: $id) {
    id
    email
    enabled
    status
    created
    email_verified
    given_name
    family_name
    gender
    phone_number
    customerID
    vendorID
  }
}
    `;
export const useDisableUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DisableUserMutation,
    TError,
    DisableUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    DisableUserMutation,
    TError,
    DisableUserMutationVariables,
    TContext
  >(
    (variables?: DisableUserMutationVariables) =>
      amplifyFetcher<DisableUserMutation, DisableUserMutationVariables>(
        DisableUserDocument,
        variables
      )(),
    options
  );
export const CreateCustomerDocument = `
    mutation CreateCustomer($input: CreateCustomerInput!) {
  createCustomer(input: $input) {
    id
    shortName
    fullName
    active
    added
    adminType
    isVendor
    emailAddressIdentity
    streamingContent
    displayShowReports
    displayEpisodeReports
  }
}
    `;
export const useCreateCustomerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateCustomerMutation,
    TError,
    CreateCustomerMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateCustomerMutation,
    TError,
    CreateCustomerMutationVariables,
    TContext
  >(
    (variables?: CreateCustomerMutationVariables) =>
      amplifyFetcher<CreateCustomerMutation, CreateCustomerMutationVariables>(
        CreateCustomerDocument,
        variables
      )(),
    options
  );
export const UpdateCustomerDocument = `
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    id
    shortName
    fullName
    active
    added
    adminType
    isVendor
    emailAddressIdentity
    streamingContent
    displayShowReports
    displayEpisodeReports
  }
}
    `;
export const useUpdateCustomerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCustomerMutation,
    TError,
    UpdateCustomerMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCustomerMutation,
    TError,
    UpdateCustomerMutationVariables,
    TContext
  >(
    (variables?: UpdateCustomerMutationVariables) =>
      amplifyFetcher<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
        UpdateCustomerDocument,
        variables
      )(),
    options
  );
export const CreateShowDocument = `
    mutation CreateShow($input: CreateShowInput!) {
  createShow(input: $input) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    hideInAdmin
    appID
    mainMenuType
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    channel {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
    demographicConfiguration {
      introText
      ageEnabled
      genderEnabled
      ethnicityEnabled
      educationEnabled
      employmentEnabled
      maritalStatusEnabled
      incomeEnabled
      zipcodeEnabled
      emailEnabled
    }
    productRequestConfiguration {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const useCreateShowMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateShowMutation,
    TError,
    CreateShowMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateShowMutation,
    TError,
    CreateShowMutationVariables,
    TContext
  >(
    (variables?: CreateShowMutationVariables) =>
      amplifyFetcher<CreateShowMutation, CreateShowMutationVariables>(
        CreateShowDocument,
        variables
      )(),
    options
  );
export const UpdateShowDocument = `
    mutation UpdateShow($input: UpdateShowInput!) {
  updateShow(input: $input) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    hideInAdmin
    appID
    mainMenuType
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    channel {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
    demographicConfiguration {
      introText
      ageEnabled
      genderEnabled
      ethnicityEnabled
      educationEnabled
      employmentEnabled
      maritalStatusEnabled
      incomeEnabled
      zipcodeEnabled
      emailEnabled
    }
    productRequestConfiguration {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const useUpdateShowMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateShowMutation,
    TError,
    UpdateShowMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowMutation,
    TError,
    UpdateShowMutationVariables,
    TContext
  >(
    (variables?: UpdateShowMutationVariables) =>
      amplifyFetcher<UpdateShowMutation, UpdateShowMutationVariables>(
        UpdateShowDocument,
        variables
      )(),
    options
  );
export const UpdateShowAppDataDocument = `
    mutation UpdateShowAppData($input: UpdateShowAppDataInput!) {
  updateShowAppData(input: $input) {
    appLogo
    appLogoURL
    primaryColor
    textPrimaryColor
    secondaryColor
    tertiaryColor
    accentColor
    infoColor
    headBackgroundColor
    headTextColor
    menuTextColor
    productCaroselBackgroundColor
    productButTextColor
    productButBackColor
    productBut2TextColor
    productBut2BackColor
    autoplayEnabled
    autoplayAfterFingerprint
    copyright
    privacyPolicy
    appPosterDisplayType
    episodeTileType
    productTileType
    witAIAppID
    witAIAccessToken
    witAILastUpdate
    googleTrackingID
    googlePlayAppEnabled
    googlePlayAppURL
    facebookAppID
    facebookPageURL
    twitterUsername
    iOSAppStoreID
    videoGroupingName
    videoGroupingNamePlural
  }
}
    `;
export const useUpdateShowAppDataMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowAppDataMutation,
    TError,
    UpdateShowAppDataMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowAppDataMutation,
    TError,
    UpdateShowAppDataMutationVariables,
    TContext
  >(
    (variables?: UpdateShowAppDataMutationVariables) =>
      amplifyFetcher<
        UpdateShowAppDataMutation,
        UpdateShowAppDataMutationVariables
      >(UpdateShowAppDataDocument, variables)(),
    options
  );
export const UpdateShowChannelDocument = `
    mutation UpdateShowChannel($input: UpdateShowChannelInput!) {
  updateShowChannel(input: $input) {
    rokuChannelEnabled
    rokuChannelType
    rokuChannelInstallURL
    fireTVChannelEnabled
    fireTVInstallURL
    fireTVDevURL
  }
}
    `;
export const useUpdateShowChannelMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowChannelMutation,
    TError,
    UpdateShowChannelMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowChannelMutation,
    TError,
    UpdateShowChannelMutationVariables,
    TContext
  >(
    (variables?: UpdateShowChannelMutationVariables) =>
      amplifyFetcher<
        UpdateShowChannelMutation,
        UpdateShowChannelMutationVariables
      >(UpdateShowChannelDocument, variables)(),
    options
  );
export const UpdateShowProductRequestConfigurationDocument = `
    mutation UpdateShowProductRequestConfiguration($input: UpdateShowProductRequestConfigurationInput) {
  updateShowProductRequestConfiguration(input: $input) {
    enabled
    titleText
    introText
    episodeListEnabled
    episodeListText
    photoGroupEnabled
    photoGroupText
    categoryEnabled
    categoryText
    emailEnabled
    emailText
    requestText
    submitThanksText
    notificationsList
  }
}
    `;
export const useUpdateShowProductRequestConfigurationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowProductRequestConfigurationMutation,
    TError,
    UpdateShowProductRequestConfigurationMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowProductRequestConfigurationMutation,
    TError,
    UpdateShowProductRequestConfigurationMutationVariables,
    TContext
  >(
    (variables?: UpdateShowProductRequestConfigurationMutationVariables) =>
      amplifyFetcher<
        UpdateShowProductRequestConfigurationMutation,
        UpdateShowProductRequestConfigurationMutationVariables
      >(UpdateShowProductRequestConfigurationDocument, variables)(),
    options
  );
export const UpdateShowPosterDocument = `
    mutation UpdateShowPoster($input: UpdateShowImageInput!) {
  updateShowPoster(input: $input) {
    url
  }
}
    `;
export const useUpdateShowPosterMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowPosterMutation,
    TError,
    UpdateShowPosterMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowPosterMutation,
    TError,
    UpdateShowPosterMutationVariables,
    TContext
  >(
    (variables?: UpdateShowPosterMutationVariables) =>
      amplifyFetcher<
        UpdateShowPosterMutation,
        UpdateShowPosterMutationVariables
      >(UpdateShowPosterDocument, variables)(),
    options
  );
export const UpdateShowAppLogoDocument = `
    mutation UpdateShowAppLogo($input: UpdateShowImageInput!) {
  updateShowAppLogo(input: $input) {
    url
  }
}
    `;
export const useUpdateShowAppLogoMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowAppLogoMutation,
    TError,
    UpdateShowAppLogoMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowAppLogoMutation,
    TError,
    UpdateShowAppLogoMutationVariables,
    TContext
  >(
    (variables?: UpdateShowAppLogoMutationVariables) =>
      amplifyFetcher<
        UpdateShowAppLogoMutation,
        UpdateShowAppLogoMutationVariables
      >(UpdateShowAppLogoDocument, variables)(),
    options
  );
export const AddShowMetadataDocument = `
    mutation AddShowMetadata($input: ShowMetadataItemInput!) {
  addShowMetadata(input: $input) {
    id
    showID
    name
    value
  }
}
    `;
export const useAddShowMetadataMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddShowMetadataMutation,
    TError,
    AddShowMetadataMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddShowMetadataMutation,
    TError,
    AddShowMetadataMutationVariables,
    TContext
  >(
    (variables?: AddShowMetadataMutationVariables) =>
      amplifyFetcher<AddShowMetadataMutation, AddShowMetadataMutationVariables>(
        AddShowMetadataDocument,
        variables
      )(),
    options
  );
export const DeleteShowMetadataDocument = `
    mutation DeleteShowMetadata($input: DeleteShowMetadataItemInput!) {
  deleteShowMetadata(input: $input) {
    id
    showID
    name
    value
  }
}
    `;
export const useDeleteShowMetadataMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteShowMetadataMutation,
    TError,
    DeleteShowMetadataMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteShowMetadataMutation,
    TError,
    DeleteShowMetadataMutationVariables,
    TContext
  >(
    (variables?: DeleteShowMetadataMutationVariables) =>
      amplifyFetcher<
        DeleteShowMetadataMutation,
        DeleteShowMetadataMutationVariables
      >(DeleteShowMetadataDocument, variables)(),
    options
  );
export const CreateShowSeasonDocument = `
    mutation CreateShowSeason($input: CreateShowSeasonInput!) {
  createShowSeason(input: $input) {
    showID
    season
    name
  }
}
    `;
export const useCreateShowSeasonMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateShowSeasonMutation,
    TError,
    CreateShowSeasonMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateShowSeasonMutation,
    TError,
    CreateShowSeasonMutationVariables,
    TContext
  >(
    (variables?: CreateShowSeasonMutationVariables) =>
      amplifyFetcher<
        CreateShowSeasonMutation,
        CreateShowSeasonMutationVariables
      >(CreateShowSeasonDocument, variables)(),
    options
  );
export const CreateShowDesignMenuItemDocument = `
    mutation CreateShowDesignMenuItem($input: CreateShowDesignMenuItemInput!) {
  createShowDesignMenuItem(input: $input) {
    id
    showID
    orderID
    title
    description
    icon
    iconColor
    url
    showInPullOutMenu
    showInHomeMenu
    enabled
  }
}
    `;
export const useCreateShowDesignMenuItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateShowDesignMenuItemMutation,
    TError,
    CreateShowDesignMenuItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateShowDesignMenuItemMutation,
    TError,
    CreateShowDesignMenuItemMutationVariables,
    TContext
  >(
    (variables?: CreateShowDesignMenuItemMutationVariables) =>
      amplifyFetcher<
        CreateShowDesignMenuItemMutation,
        CreateShowDesignMenuItemMutationVariables
      >(CreateShowDesignMenuItemDocument, variables)(),
    options
  );
export const UpdateShowDesignMenuItemDocument = `
    mutation UpdateShowDesignMenuItem($input: UpdateShowDesignMenuItemInput!) {
  updateShowDesignMenuItem(input: $input) {
    id
    showID
    orderID
    title
    description
    icon
    iconColor
    url
    showInPullOutMenu
    showInHomeMenu
    enabled
  }
}
    `;
export const useUpdateShowDesignMenuItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowDesignMenuItemMutation,
    TError,
    UpdateShowDesignMenuItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowDesignMenuItemMutation,
    TError,
    UpdateShowDesignMenuItemMutationVariables,
    TContext
  >(
    (variables?: UpdateShowDesignMenuItemMutationVariables) =>
      amplifyFetcher<
        UpdateShowDesignMenuItemMutation,
        UpdateShowDesignMenuItemMutationVariables
      >(UpdateShowDesignMenuItemDocument, variables)(),
    options
  );
export const DeleteShowDesignMenuItemDocument = `
    mutation DeleteShowDesignMenuItem($id: Int!) {
  deleteShowDesignMenuItem(id: $id)
}
    `;
export const useDeleteShowDesignMenuItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteShowDesignMenuItemMutation,
    TError,
    DeleteShowDesignMenuItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteShowDesignMenuItemMutation,
    TError,
    DeleteShowDesignMenuItemMutationVariables,
    TContext
  >(
    (variables?: DeleteShowDesignMenuItemMutationVariables) =>
      amplifyFetcher<
        DeleteShowDesignMenuItemMutation,
        DeleteShowDesignMenuItemMutationVariables
      >(DeleteShowDesignMenuItemDocument, variables)(),
    options
  );
export const CreateEpisodeDocument = `
    mutation CreateEpisode($input: CreateEpisodeInput!) {
  createEpisode(input: $input) {
    id
    showID
    showName
    season
    seasonName
    number
    firstRelease
    added
    name
    description
    disclaimer
    enabled
    posterType
    poster
    posterURL
    posterFileID
    video
    videoBucket
    videoKey
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    audioFingerprintID
    duration
    rokuAddBreaks
    releaseToOTT
    releaseToApp
    liveChannelID
    enableHeaderButton
    headerButtonText
    headerButtonURL
    disableProductScroller
    liveStreamID
    liveStreamEnabledOnStartMenuID
    videoToken
  }
}
    `;
export const useCreateEpisodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateEpisodeMutation,
    TError,
    CreateEpisodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateEpisodeMutation,
    TError,
    CreateEpisodeMutationVariables,
    TContext
  >(
    (variables?: CreateEpisodeMutationVariables) =>
      amplifyFetcher<CreateEpisodeMutation, CreateEpisodeMutationVariables>(
        CreateEpisodeDocument,
        variables
      )(),
    options
  );
export const UpdateEpisodeDocument = `
    mutation UpdateEpisode($input: UpdateEpisodeInput!) {
  updateEpisode(input: $input) {
    id
    showID
    showName
    season
    seasonName
    number
    firstRelease
    added
    name
    description
    disclaimer
    enabled
    posterType
    poster
    posterURL
    posterFileID
    video
    videoBucket
    videoKey
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    audioFingerprintID
    duration
    rokuAddBreaks
    releaseToOTT
    releaseToApp
    liveChannelID
    enableHeaderButton
    headerButtonText
    headerButtonURL
    disableProductScroller
    liveStreamID
    liveStreamEnabledOnStartMenuID
    videoToken
  }
}
    `;
export const useUpdateEpisodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateEpisodeMutation,
    TError,
    UpdateEpisodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateEpisodeMutation,
    TError,
    UpdateEpisodeMutationVariables,
    TContext
  >(
    (variables?: UpdateEpisodeMutationVariables) =>
      amplifyFetcher<UpdateEpisodeMutation, UpdateEpisodeMutationVariables>(
        UpdateEpisodeDocument,
        variables
      )(),
    options
  );
export const UpdateEpisodeVideoCleanupDocument = `
    mutation UpdateEpisodeVideoCleanup($id: Int!) {
  updateEpisodeVideoCleanup(id: $id)
}
    `;
export const useUpdateEpisodeVideoCleanupMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateEpisodeVideoCleanupMutation,
    TError,
    UpdateEpisodeVideoCleanupMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateEpisodeVideoCleanupMutation,
    TError,
    UpdateEpisodeVideoCleanupMutationVariables,
    TContext
  >(
    (variables?: UpdateEpisodeVideoCleanupMutationVariables) =>
      amplifyFetcher<
        UpdateEpisodeVideoCleanupMutation,
        UpdateEpisodeVideoCleanupMutationVariables
      >(UpdateEpisodeVideoCleanupDocument, variables)(),
    options
  );
export const UpdateEpisodePosterDocument = `
    mutation UpdateEpisodePoster($input: UpdateEpisodeImageInput!) {
  updateEpisodePoster(input: $input) {
    url
  }
}
    `;
export const useUpdateEpisodePosterMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateEpisodePosterMutation,
    TError,
    UpdateEpisodePosterMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateEpisodePosterMutation,
    TError,
    UpdateEpisodePosterMutationVariables,
    TContext
  >(
    (variables?: UpdateEpisodePosterMutationVariables) =>
      amplifyFetcher<
        UpdateEpisodePosterMutation,
        UpdateEpisodePosterMutationVariables
      >(UpdateEpisodePosterDocument, variables)(),
    options
  );
export const CreateEpisodePhotoDocument = `
    mutation CreateEpisodePhoto($input: CreateEpisodePhotoInput!) {
  createEpisodePhoto(input: $input) {
    id
    episodeID
    groupID
    groupName
    order
    title
    description
    fileID
    extension
    url
    thumbnailURL
    enabled
    added
  }
}
    `;
export const useCreateEpisodePhotoMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateEpisodePhotoMutation,
    TError,
    CreateEpisodePhotoMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateEpisodePhotoMutation,
    TError,
    CreateEpisodePhotoMutationVariables,
    TContext
  >(
    (variables?: CreateEpisodePhotoMutationVariables) =>
      amplifyFetcher<
        CreateEpisodePhotoMutation,
        CreateEpisodePhotoMutationVariables
      >(CreateEpisodePhotoDocument, variables)(),
    options
  );
export const DeleteEpisodePhotoDocument = `
    mutation DeleteEpisodePhoto($id: Int!) {
  deleteEpisodePhoto(id: $id)
}
    `;
export const useDeleteEpisodePhotoMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteEpisodePhotoMutation,
    TError,
    DeleteEpisodePhotoMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteEpisodePhotoMutation,
    TError,
    DeleteEpisodePhotoMutationVariables,
    TContext
  >(
    (variables?: DeleteEpisodePhotoMutationVariables) =>
      amplifyFetcher<
        DeleteEpisodePhotoMutation,
        DeleteEpisodePhotoMutationVariables
      >(DeleteEpisodePhotoDocument, variables)(),
    options
  );
export const CreateEpisodeCommercialDocument = `
    mutation CreateEpisodeCommercial($input: CreateEpisodeCommercialInput!) {
  createEpisodeCommercial(input: $input) {
    id
    episodeID
    commercialEpisodeID
    type
    spliceTime
    showID
    episodeName
    episodePoster
    episodePosterURL
    enabled
    duration
  }
}
    `;
export const useCreateEpisodeCommercialMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateEpisodeCommercialMutation,
    TError,
    CreateEpisodeCommercialMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateEpisodeCommercialMutation,
    TError,
    CreateEpisodeCommercialMutationVariables,
    TContext
  >(
    (variables?: CreateEpisodeCommercialMutationVariables) =>
      amplifyFetcher<
        CreateEpisodeCommercialMutation,
        CreateEpisodeCommercialMutationVariables
      >(CreateEpisodeCommercialDocument, variables)(),
    options
  );
export const DeleteEpisodeCommercialDocument = `
    mutation DeleteEpisodeCommercial($id: Int!, $episodeID: Int!) {
  deleteEpisodeCommercial(id: $id, episodeID: $episodeID)
}
    `;
export const useDeleteEpisodeCommercialMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteEpisodeCommercialMutation,
    TError,
    DeleteEpisodeCommercialMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteEpisodeCommercialMutation,
    TError,
    DeleteEpisodeCommercialMutationVariables,
    TContext
  >(
    (variables?: DeleteEpisodeCommercialMutationVariables) =>
      amplifyFetcher<
        DeleteEpisodeCommercialMutation,
        DeleteEpisodeCommercialMutationVariables
      >(DeleteEpisodeCommercialDocument, variables)(),
    options
  );
export const CreateProductDocument = `
    mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    customerID
    name
    description
    upc
    brand
    brandID
    sku
    active
    added
    deleted
    approved
    replacementProduct
    mainPhotoURL
    mainPhotoFileID
    mainPhotoFileExt
    mainPhotoSize
    issueCount
    photoBucket
    photoKey
    warnings
    originalProductName
    originalProductDescription
    originalProductURL
    originalProductPhotoBucket
    originalProductPhotoKey
    vendorID
    vendorLogo
    vendorLogoBucket
    vendorLogoKey
  }
}
    `;
export const useCreateProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateProductMutation,
    TError,
    CreateProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateProductMutation,
    TError,
    CreateProductMutationVariables,
    TContext
  >(
    (variables?: CreateProductMutationVariables) =>
      amplifyFetcher<CreateProductMutation, CreateProductMutationVariables>(
        CreateProductDocument,
        variables
      )(),
    options
  );
export const UpdateProductDocument = `
    mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    customerID
    name
    description
    upc
    brand
    brandID
    sku
    active
    added
    deleted
    approved
    replacementProduct
    mainPhotoURL
    mainPhotoFileID
    mainPhotoFileExt
    mainPhotoSize
    issueCount
    photoBucket
    photoKey
    warnings
    originalProductName
    originalProductDescription
    originalProductURL
    originalProductPhotoBucket
    originalProductPhotoKey
    vendorID
    vendorLogo
    vendorLogoBucket
    vendorLogoKey
  }
}
    `;
export const useUpdateProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateProductMutation,
    TError,
    UpdateProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateProductMutation,
    TError,
    UpdateProductMutationVariables,
    TContext
  >(
    (variables?: UpdateProductMutationVariables) =>
      amplifyFetcher<UpdateProductMutation, UpdateProductMutationVariables>(
        UpdateProductDocument,
        variables
      )(),
    options
  );
export const DeleteProductDocument = `
    mutation DeleteProduct($id: Int!) {
  deleteProduct(id: $id)
}
    `;
export const useDeleteProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteProductMutation,
    TError,
    DeleteProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteProductMutation,
    TError,
    DeleteProductMutationVariables,
    TContext
  >(
    (variables?: DeleteProductMutationVariables) =>
      amplifyFetcher<DeleteProductMutation, DeleteProductMutationVariables>(
        DeleteProductDocument,
        variables
      )(),
    options
  );
export const CreateProductLinkDocument = `
    mutation CreateProductLink($input: CreateProductLinkInput!) {
  createProductLink(input: $input) {
    id
    productID
    storeID
    storeName
    type
    fullURL
    status
    statusLastChecked
  }
}
    `;
export const useCreateProductLinkMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateProductLinkMutation,
    TError,
    CreateProductLinkMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateProductLinkMutation,
    TError,
    CreateProductLinkMutationVariables,
    TContext
  >(
    (variables?: CreateProductLinkMutationVariables) =>
      amplifyFetcher<
        CreateProductLinkMutation,
        CreateProductLinkMutationVariables
      >(CreateProductLinkDocument, variables)(),
    options
  );
export const DeleteProductLinkDocument = `
    mutation DeleteProductLink($id: Int!, $productID: Int!) {
  deleteProductLink(id: $id, productID: $productID)
}
    `;
export const useDeleteProductLinkMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteProductLinkMutation,
    TError,
    DeleteProductLinkMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteProductLinkMutation,
    TError,
    DeleteProductLinkMutationVariables,
    TContext
  >(
    (variables?: DeleteProductLinkMutationVariables) =>
      amplifyFetcher<
        DeleteProductLinkMutation,
        DeleteProductLinkMutationVariables
      >(DeleteProductLinkDocument, variables)(),
    options
  );
export const CreateProductCategoryDocument = `
    mutation CreateProductCategory($input: CreateProductCategoryInput!) {
  createProductCategory(input: $input) {
    items {
      productID
      categoryID
      categoryName
    }
  }
}
    `;
export const useCreateProductCategoryMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateProductCategoryMutation,
    TError,
    CreateProductCategoryMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateProductCategoryMutation,
    TError,
    CreateProductCategoryMutationVariables,
    TContext
  >(
    (variables?: CreateProductCategoryMutationVariables) =>
      amplifyFetcher<
        CreateProductCategoryMutation,
        CreateProductCategoryMutationVariables
      >(CreateProductCategoryDocument, variables)(),
    options
  );
export const DeleteProductCategoryDocument = `
    mutation DeleteProductCategory($productID: Int!, $categoryID: Int!) {
  deleteProductCategory(productID: $productID, categoryID: $categoryID)
}
    `;
export const useDeleteProductCategoryMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteProductCategoryMutation,
    TError,
    DeleteProductCategoryMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteProductCategoryMutation,
    TError,
    DeleteProductCategoryMutationVariables,
    TContext
  >(
    (variables?: DeleteProductCategoryMutationVariables) =>
      amplifyFetcher<
        DeleteProductCategoryMutation,
        DeleteProductCategoryMutationVariables
      >(DeleteProductCategoryDocument, variables)(),
    options
  );
export const CreateEpisodeProductDocument = `
    mutation CreateEpisodeProduct($input: CreateEpisodeProductInput!) {
  createEpisodeProduct(input: $input)
}
    `;
export const useCreateEpisodeProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateEpisodeProductMutation,
    TError,
    CreateEpisodeProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateEpisodeProductMutation,
    TError,
    CreateEpisodeProductMutationVariables,
    TContext
  >(
    (variables?: CreateEpisodeProductMutationVariables) =>
      amplifyFetcher<
        CreateEpisodeProductMutation,
        CreateEpisodeProductMutationVariables
      >(CreateEpisodeProductDocument, variables)(),
    options
  );
export const DeleteEpisodeProductDocument = `
    mutation DeleteEpisodeProduct($id: Int!, $episodeID: Int!) {
  deleteEpisodeProduct(id: $id, episodeID: $episodeID)
}
    `;
export const useDeleteEpisodeProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteEpisodeProductMutation,
    TError,
    DeleteEpisodeProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteEpisodeProductMutation,
    TError,
    DeleteEpisodeProductMutationVariables,
    TContext
  >(
    (variables?: DeleteEpisodeProductMutationVariables) =>
      amplifyFetcher<
        DeleteEpisodeProductMutation,
        DeleteEpisodeProductMutationVariables
      >(DeleteEpisodeProductDocument, variables)(),
    options
  );
export const CreateEpisodePhotoProductDocument = `
    mutation CreateEpisodePhotoProduct($input: CreateEpisodePhotoProductInput!) {
  createEpisodePhotoProduct(input: $input)
}
    `;
export const useCreateEpisodePhotoProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateEpisodePhotoProductMutation,
    TError,
    CreateEpisodePhotoProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateEpisodePhotoProductMutation,
    TError,
    CreateEpisodePhotoProductMutationVariables,
    TContext
  >(
    (variables?: CreateEpisodePhotoProductMutationVariables) =>
      amplifyFetcher<
        CreateEpisodePhotoProductMutation,
        CreateEpisodePhotoProductMutationVariables
      >(CreateEpisodePhotoProductDocument, variables)(),
    options
  );
export const DeleteEpisodePhotoProductDocument = `
    mutation DeleteEpisodePhotoProduct($id: Int!) {
  deleteEpisodePhotoProduct(id: $id)
}
    `;
export const useDeleteEpisodePhotoProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteEpisodePhotoProductMutation,
    TError,
    DeleteEpisodePhotoProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteEpisodePhotoProductMutation,
    TError,
    DeleteEpisodePhotoProductMutationVariables,
    TContext
  >(
    (variables?: DeleteEpisodePhotoProductMutationVariables) =>
      amplifyFetcher<
        DeleteEpisodePhotoProductMutation,
        DeleteEpisodePhotoProductMutationVariables
      >(DeleteEpisodePhotoProductDocument, variables)(),
    options
  );
export const CreateProductMetadataItemDocument = `
    mutation CreateProductMetadataItem($input: CreateProductMetadataItem) {
  createProductMetadataItem(input: $input) {
    id
    productID
    name
    value
  }
}
    `;
export const useCreateProductMetadataItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateProductMetadataItemMutation,
    TError,
    CreateProductMetadataItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateProductMetadataItemMutation,
    TError,
    CreateProductMetadataItemMutationVariables,
    TContext
  >(
    (variables?: CreateProductMetadataItemMutationVariables) =>
      amplifyFetcher<
        CreateProductMetadataItemMutation,
        CreateProductMetadataItemMutationVariables
      >(CreateProductMetadataItemDocument, variables)(),
    options
  );
export const DeleteProductMetadataItemDocument = `
    mutation DeleteProductMetadataItem($id: Int!) {
  deleteProductMetadataItem(id: $id)
}
    `;
export const useDeleteProductMetadataItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteProductMetadataItemMutation,
    TError,
    DeleteProductMetadataItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteProductMetadataItemMutation,
    TError,
    DeleteProductMetadataItemMutationVariables,
    TContext
  >(
    (variables?: DeleteProductMetadataItemMutationVariables) =>
      amplifyFetcher<
        DeleteProductMetadataItemMutation,
        DeleteProductMetadataItemMutationVariables
      >(DeleteProductMetadataItemDocument, variables)(),
    options
  );
export const UpdateBrandDocument = `
    mutation UpdateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    id
    customerID
    name
    productCount
  }
}
    `;
export const useUpdateBrandMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBrandMutation,
    TError,
    UpdateBrandMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateBrandMutation,
    TError,
    UpdateBrandMutationVariables,
    TContext
  >(
    (variables?: UpdateBrandMutationVariables) =>
      amplifyFetcher<UpdateBrandMutation, UpdateBrandMutationVariables>(
        UpdateBrandDocument,
        variables
      )(),
    options
  );
export const CreateCategoryDocument = `
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
    customerID
    name
    image
    imageURL
    imageFileSize
    active
    thumbnail
    thumbnailURL
    thumbnailFileSize
    tag
    description
  }
}
    `;
export const useCreateCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateCategoryMutation,
    TError,
    CreateCategoryMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateCategoryMutation,
    TError,
    CreateCategoryMutationVariables,
    TContext
  >(
    (variables?: CreateCategoryMutationVariables) =>
      amplifyFetcher<CreateCategoryMutation, CreateCategoryMutationVariables>(
        CreateCategoryDocument,
        variables
      )(),
    options
  );
export const UpdateCategoryDocument = `
    mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    id
    customerID
    name
    image
    imageURL
    imageFileSize
    active
    thumbnail
    thumbnailURL
    thumbnailFileSize
    tag
    description
  }
}
    `;
export const useUpdateCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCategoryMutation,
    TError,
    UpdateCategoryMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCategoryMutation,
    TError,
    UpdateCategoryMutationVariables,
    TContext
  >(
    (variables?: UpdateCategoryMutationVariables) =>
      amplifyFetcher<UpdateCategoryMutation, UpdateCategoryMutationVariables>(
        UpdateCategoryDocument,
        variables
      )(),
    options
  );
export const DeleteCategoryDocument = `
    mutation DeleteCategory($id: Int!, $customerID: Int!) {
  deleteCategory(id: $id, customerID: $customerID)
}
    `;
export const useDeleteCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteCategoryMutation,
    TError,
    DeleteCategoryMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteCategoryMutation,
    TError,
    DeleteCategoryMutationVariables,
    TContext
  >(
    (variables?: DeleteCategoryMutationVariables) =>
      amplifyFetcher<DeleteCategoryMutation, DeleteCategoryMutationVariables>(
        DeleteCategoryDocument,
        variables
      )(),
    options
  );
export const CreateStoreDocument = `
    mutation CreateStore($input: CreateStoreInput!) {
  createStore(input: $input) {
    id
    customerID
    name
    active
    added
    domainName
    searchURL
    productCount
  }
}
    `;
export const useCreateStoreMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateStoreMutation,
    TError,
    CreateStoreMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateStoreMutation,
    TError,
    CreateStoreMutationVariables,
    TContext
  >(
    (variables?: CreateStoreMutationVariables) =>
      amplifyFetcher<CreateStoreMutation, CreateStoreMutationVariables>(
        CreateStoreDocument,
        variables
      )(),
    options
  );
export const UpdateStoreDocument = `
    mutation UpdateStore($input: UpdateStoreInput!) {
  updateStore(input: $input) {
    id
    customerID
    name
    active
    added
    domainName
    searchURL
    productCount
  }
}
    `;
export const useUpdateStoreMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStoreMutation,
    TError,
    UpdateStoreMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateStoreMutation,
    TError,
    UpdateStoreMutationVariables,
    TContext
  >(
    (variables?: UpdateStoreMutationVariables) =>
      amplifyFetcher<UpdateStoreMutation, UpdateStoreMutationVariables>(
        UpdateStoreDocument,
        variables
      )(),
    options
  );
export const CreateVendorProductDocument = `
    mutation CreateVendorProduct($input: CreateVendorProductInput!) {
  createVendorProduct(input: $input)
}
    `;
export const useCreateVendorProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateVendorProductMutation,
    TError,
    CreateVendorProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateVendorProductMutation,
    TError,
    CreateVendorProductMutationVariables,
    TContext
  >(
    (variables?: CreateVendorProductMutationVariables) =>
      amplifyFetcher<
        CreateVendorProductMutation,
        CreateVendorProductMutationVariables
      >(CreateVendorProductDocument, variables)(),
    options
  );
export const DeleteVendorProductDocument = `
    mutation DeleteVendorProduct($vendorID: ID!, $productID: Int!) {
  deleteVendorProduct(vendorID: $vendorID, productID: $productID)
}
    `;
export const useDeleteVendorProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteVendorProductMutation,
    TError,
    DeleteVendorProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteVendorProductMutation,
    TError,
    DeleteVendorProductMutationVariables,
    TContext
  >(
    (variables?: DeleteVendorProductMutationVariables) =>
      amplifyFetcher<
        DeleteVendorProductMutation,
        DeleteVendorProductMutationVariables
      >(DeleteVendorProductDocument, variables)(),
    options
  );
export const CreateEmailReportSettingDocument = `
    mutation CreateEmailReportSetting($input: CreateEmailReportSettingInput!) {
  createEmailReportSetting(input: $input) {
    id
    userID
    reportType
    daily
    weekly
    monthly
    yearly
    customerID
    customerName
    showID
    showName
    lastRun
    lastRunTime
  }
}
    `;
export const useCreateEmailReportSettingMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateEmailReportSettingMutation,
    TError,
    CreateEmailReportSettingMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateEmailReportSettingMutation,
    TError,
    CreateEmailReportSettingMutationVariables,
    TContext
  >(
    (variables?: CreateEmailReportSettingMutationVariables) =>
      amplifyFetcher<
        CreateEmailReportSettingMutation,
        CreateEmailReportSettingMutationVariables
      >(CreateEmailReportSettingDocument, variables)(),
    options
  );
export const UpdateEmailReportSettingDocument = `
    mutation UpdateEmailReportSetting($input: UpdateEmailReportSettingInput!) {
  updateEmailReportSetting(input: $input) {
    id
    userID
    reportType
    daily
    weekly
    monthly
    yearly
    customerID
    customerName
    showID
    showName
    lastRun
    lastRunTime
  }
}
    `;
export const useUpdateEmailReportSettingMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateEmailReportSettingMutation,
    TError,
    UpdateEmailReportSettingMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateEmailReportSettingMutation,
    TError,
    UpdateEmailReportSettingMutationVariables,
    TContext
  >(
    (variables?: UpdateEmailReportSettingMutationVariables) =>
      amplifyFetcher<
        UpdateEmailReportSettingMutation,
        UpdateEmailReportSettingMutationVariables
      >(UpdateEmailReportSettingDocument, variables)(),
    options
  );
export const DeleteEmailReportSettingDocument = `
    mutation DeleteEmailReportSetting($id: Int!) {
  deleteEmailReportSetting(id: $id)
}
    `;
export const useDeleteEmailReportSettingMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteEmailReportSettingMutation,
    TError,
    DeleteEmailReportSettingMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteEmailReportSettingMutation,
    TError,
    DeleteEmailReportSettingMutationVariables,
    TContext
  >(
    (variables?: DeleteEmailReportSettingMutationVariables) =>
      amplifyFetcher<
        DeleteEmailReportSettingMutation,
        DeleteEmailReportSettingMutationVariables
      >(DeleteEmailReportSettingDocument, variables)(),
    options
  );
export const CreateProductRequestDocument = `
    mutation CreateProductRequest($input: CreateProductRequestInput) {
  createProductRequest(input: $input)
}
    `;
export const useCreateProductRequestMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateProductRequestMutation,
    TError,
    CreateProductRequestMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateProductRequestMutation,
    TError,
    CreateProductRequestMutationVariables,
    TContext
  >(
    (variables?: CreateProductRequestMutationVariables) =>
      amplifyFetcher<
        CreateProductRequestMutation,
        CreateProductRequestMutationVariables
      >(CreateProductRequestDocument, variables)(),
    options
  );
export const UpdateProductRequestDocument = `
    mutation UpdateProductRequest($input: UpdateProductRequestInput) {
  updateProductRequest(input: $input) {
    id
    showID
    appInstallID
    episodeID
    episodeSeason
    episodeNumber
    episodeName
    photoGroup
    category
    email
    request
    added
    ip
    status
    lastChangedByAUID
    userSub
    response
  }
}
    `;
export const useUpdateProductRequestMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateProductRequestMutation,
    TError,
    UpdateProductRequestMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateProductRequestMutation,
    TError,
    UpdateProductRequestMutationVariables,
    TContext
  >(
    (variables?: UpdateProductRequestMutationVariables) =>
      amplifyFetcher<
        UpdateProductRequestMutation,
        UpdateProductRequestMutationVariables
      >(UpdateProductRequestDocument, variables)(),
    options
  );
export const ReplyProductRequestDocument = `
    mutation ReplyProductRequest($input: ReplyProductRequestInput) {
  replyProductRequest(input: $input)
}
    `;
export const useReplyProductRequestMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ReplyProductRequestMutation,
    TError,
    ReplyProductRequestMutationVariables,
    TContext
  >
) =>
  useMutation<
    ReplyProductRequestMutation,
    TError,
    ReplyProductRequestMutationVariables,
    TContext
  >(
    (variables?: ReplyProductRequestMutationVariables) =>
      amplifyFetcher<
        ReplyProductRequestMutation,
        ReplyProductRequestMutationVariables
      >(ReplyProductRequestDocument, variables)(),
    options
  );
export const UpdateVendorLogoDocument = `
    mutation UpdateVendorLogo($input: UpdateVendorLogoInput) {
  updateVendorLogo(input: $input)
}
    `;
export const useUpdateVendorLogoMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateVendorLogoMutation,
    TError,
    UpdateVendorLogoMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateVendorLogoMutation,
    TError,
    UpdateVendorLogoMutationVariables,
    TContext
  >(
    (variables?: UpdateVendorLogoMutationVariables) =>
      amplifyFetcher<
        UpdateVendorLogoMutation,
        UpdateVendorLogoMutationVariables
      >(UpdateVendorLogoDocument, variables)(),
    options
  );
export const GeneratePresignedUrlDocument = `
    mutation GeneratePresignedUrl($input: S3SignedURLInput!) {
  generatePresignedURL(input: $input) {
    url
  }
}
    `;
export const useGeneratePresignedUrlMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    GeneratePresignedUrlMutation,
    TError,
    GeneratePresignedUrlMutationVariables,
    TContext
  >
) =>
  useMutation<
    GeneratePresignedUrlMutation,
    TError,
    GeneratePresignedUrlMutationVariables,
    TContext
  >(
    (variables?: GeneratePresignedUrlMutationVariables) =>
      amplifyFetcher<
        GeneratePresignedUrlMutation,
        GeneratePresignedUrlMutationVariables
      >(GeneratePresignedUrlDocument, variables)(),
    options
  );
export const GeneratePresignedRawMediaUrlDocument = `
    mutation GeneratePresignedRawMediaUrl($input: S3SignedURLInput!) {
  generatePresignedRawMediaURL(input: $input) {
    url
  }
}
    `;
export const useGeneratePresignedRawMediaUrlMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    GeneratePresignedRawMediaUrlMutation,
    TError,
    GeneratePresignedRawMediaUrlMutationVariables,
    TContext
  >
) =>
  useMutation<
    GeneratePresignedRawMediaUrlMutation,
    TError,
    GeneratePresignedRawMediaUrlMutationVariables,
    TContext
  >(
    (variables?: GeneratePresignedRawMediaUrlMutationVariables) =>
      amplifyFetcher<
        GeneratePresignedRawMediaUrlMutation,
        GeneratePresignedRawMediaUrlMutationVariables
      >(GeneratePresignedRawMediaUrlDocument, variables)(),
    options
  );
export const GeneratePresignedRawVideoUrlDocument = `
    mutation GeneratePresignedRawVideoUrl($input: S3SignedURLInput!) {
  generatePresignedRawVideoURL(input: $input) {
    url
  }
}
    `;
export const useGeneratePresignedRawVideoUrlMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    GeneratePresignedRawVideoUrlMutation,
    TError,
    GeneratePresignedRawVideoUrlMutationVariables,
    TContext
  >
) =>
  useMutation<
    GeneratePresignedRawVideoUrlMutation,
    TError,
    GeneratePresignedRawVideoUrlMutationVariables,
    TContext
  >(
    (variables?: GeneratePresignedRawVideoUrlMutationVariables) =>
      amplifyFetcher<
        GeneratePresignedRawVideoUrlMutation,
        GeneratePresignedRawVideoUrlMutationVariables
      >(GeneratePresignedRawVideoUrlDocument, variables)(),
    options
  );
export const ResolveProductsReportDocument = `
    mutation ResolveProductsReport($input: ReportProductConnectionInput) {
  resolveProductsReport(input: $input) {
    id
    items {
      id
      name
      brandName
      upc
      sku
      mainPhotoURL
      mainPhotoFileId
      mainPhotoFileExt
      active
      deleted
      added
      imagePreviews
      detailViews
      clicks
    }
    nextToken
  }
}
    `;
export const useResolveProductsReportMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ResolveProductsReportMutation,
    TError,
    ResolveProductsReportMutationVariables,
    TContext
  >
) =>
  useMutation<
    ResolveProductsReportMutation,
    TError,
    ResolveProductsReportMutationVariables,
    TContext
  >(
    (variables?: ResolveProductsReportMutationVariables) =>
      amplifyFetcher<
        ResolveProductsReportMutation,
        ResolveProductsReportMutationVariables
      >(ResolveProductsReportDocument, variables)(),
    options
  );
export const ResolveVendorProductsReportDocument = `
    mutation ResolveVendorProductsReport($input: ReportProductConnectionInput) {
  resolveVendorProductsReport(input: $input) {
    id
    items {
      id
      name
      brandName
      upc
      sku
      mainPhotoURL
      mainPhotoFileId
      mainPhotoFileExt
      active
      deleted
      added
      imagePreviews
      detailViews
      clicks
    }
    nextToken
  }
}
    `;
export const useResolveVendorProductsReportMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ResolveVendorProductsReportMutation,
    TError,
    ResolveVendorProductsReportMutationVariables,
    TContext
  >
) =>
  useMutation<
    ResolveVendorProductsReportMutation,
    TError,
    ResolveVendorProductsReportMutationVariables,
    TContext
  >(
    (variables?: ResolveVendorProductsReportMutationVariables) =>
      amplifyFetcher<
        ResolveVendorProductsReportMutation,
        ResolveVendorProductsReportMutationVariables
      >(ResolveVendorProductsReportDocument, variables)(),
    options
  );
export const CreateAppInstallDocument = `
    mutation CreateAppInstall($input: CreateAppInstallInput) {
  createAppInstall(input: $input)
}
    `;
export const useCreateAppInstallMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateAppInstallMutation,
    TError,
    CreateAppInstallMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateAppInstallMutation,
    TError,
    CreateAppInstallMutationVariables,
    TContext
  >(
    (variables?: CreateAppInstallMutationVariables) =>
      amplifyFetcher<
        CreateAppInstallMutation,
        CreateAppInstallMutationVariables
      >(CreateAppInstallDocument, variables)(),
    options
  );
export const UpdateAppInstallDocument = `
    mutation UpdateAppInstall($id: Int!) {
  updateAppInstall(id: $id)
}
    `;
export const useUpdateAppInstallMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateAppInstallMutation,
    TError,
    UpdateAppInstallMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateAppInstallMutation,
    TError,
    UpdateAppInstallMutationVariables,
    TContext
  >(
    (variables?: UpdateAppInstallMutationVariables) =>
      amplifyFetcher<
        UpdateAppInstallMutation,
        UpdateAppInstallMutationVariables
      >(UpdateAppInstallDocument, variables)(),
    options
  );
export const CreateAppUsageLogDocument = `
    mutation CreateAppUsageLog($input: CreateAppUsageLogInput!) {
  createAppUsageLog(input: $input) {
    id
    date
    ipAddress
    userAgent
    referer
    userID
    appID
    version
    episodeID
    productID
    linkID
    logEntry
    logData
  }
}
    `;
export const useCreateAppUsageLogMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateAppUsageLogMutation,
    TError,
    CreateAppUsageLogMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateAppUsageLogMutation,
    TError,
    CreateAppUsageLogMutationVariables,
    TContext
  >(
    (variables?: CreateAppUsageLogMutationVariables) =>
      amplifyFetcher<
        CreateAppUsageLogMutation,
        CreateAppUsageLogMutationVariables
      >(CreateAppUsageLogDocument, variables)(),
    options
  );
export const AddProductToWishlistDocument = `
    mutation AddProductToWishlist($productID: Int!) {
  addProductToWishlist(productID: $productID) {
    userID
    productID
    added
  }
}
    `;
export const useAddProductToWishlistMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddProductToWishlistMutation,
    TError,
    AddProductToWishlistMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddProductToWishlistMutation,
    TError,
    AddProductToWishlistMutationVariables,
    TContext
  >(
    (variables?: AddProductToWishlistMutationVariables) =>
      amplifyFetcher<
        AddProductToWishlistMutation,
        AddProductToWishlistMutationVariables
      >(AddProductToWishlistDocument, variables)(),
    options
  );
export const DeleteProductFromWishlistDocument = `
    mutation DeleteProductFromWishlist($productID: Int!) {
  deleteProductFromWishlist(productID: $productID)
}
    `;
export const useDeleteProductFromWishlistMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteProductFromWishlistMutation,
    TError,
    DeleteProductFromWishlistMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteProductFromWishlistMutation,
    TError,
    DeleteProductFromWishlistMutationVariables,
    TContext
  >(
    (variables?: DeleteProductFromWishlistMutationVariables) =>
      amplifyFetcher<
        DeleteProductFromWishlistMutation,
        DeleteProductFromWishlistMutationVariables
      >(DeleteProductFromWishlistDocument, variables)(),
    options
  );
export const CleanupBannerAdDocument = `
    mutation CleanupBannerAd($id: ID!) {
  cleanupBannerAd(id: $id)
}
    `;
export const useCleanupBannerAdMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CleanupBannerAdMutation,
    TError,
    CleanupBannerAdMutationVariables,
    TContext
  >
) =>
  useMutation<
    CleanupBannerAdMutation,
    TError,
    CleanupBannerAdMutationVariables,
    TContext
  >(
    (variables?: CleanupBannerAdMutationVariables) =>
      amplifyFetcher<CleanupBannerAdMutation, CleanupBannerAdMutationVariables>(
        CleanupBannerAdDocument,
        variables
      )(),
    options
  );
export const DeleteExclusiveVideoContentDocument = `
    mutation DeleteExclusiveVideoContent($input: DeleteExclusiveVideoContentInput!) {
  deleteExclusiveVideoContent(input: $input) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteExclusiveVideoContentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteExclusiveVideoContentMutation,
    TError,
    DeleteExclusiveVideoContentMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteExclusiveVideoContentMutation,
    TError,
    DeleteExclusiveVideoContentMutationVariables,
    TContext
  >(
    (variables?: DeleteExclusiveVideoContentMutationVariables) =>
      amplifyFetcher<
        DeleteExclusiveVideoContentMutation,
        DeleteExclusiveVideoContentMutationVariables
      >(DeleteExclusiveVideoContentDocument, variables)(),
    options
  );
export const DeleteNotificationDocument = `
    mutation DeleteNotification($input: DeleteNotificationInput!) {
  deleteNotification(input: $input) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteNotificationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteNotificationMutation,
    TError,
    DeleteNotificationMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteNotificationMutation,
    TError,
    DeleteNotificationMutationVariables,
    TContext
  >(
    (variables?: DeleteNotificationMutationVariables) =>
      amplifyFetcher<
        DeleteNotificationMutation,
        DeleteNotificationMutationVariables
      >(DeleteNotificationDocument, variables)(),
    options
  );
export const CreateVendorDocument = `
    mutation CreateVendor($input: CreateVendorInput!) {
  createVendor(input: $input) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const useCreateVendorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateVendorMutation,
    TError,
    CreateVendorMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateVendorMutation,
    TError,
    CreateVendorMutationVariables,
    TContext
  >(
    (variables?: CreateVendorMutationVariables) =>
      amplifyFetcher<CreateVendorMutation, CreateVendorMutationVariables>(
        CreateVendorDocument,
        variables
      )(),
    options
  );
export const DeleteVendorDocument = `
    mutation DeleteVendor($input: DeleteVendorInput!) {
  deleteVendor(input: $input) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteVendorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteVendorMutation,
    TError,
    DeleteVendorMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteVendorMutation,
    TError,
    DeleteVendorMutationVariables,
    TContext
  >(
    (variables?: DeleteVendorMutationVariables) =>
      amplifyFetcher<DeleteVendorMutation, DeleteVendorMutationVariables>(
        DeleteVendorDocument,
        variables
      )(),
    options
  );
export const CreateShowFontV2Document = `
    mutation CreateShowFontV2($input: CreateShowFontV2Input!) {
  createShowFontV2(input: $input) {
    id
    showFontLocationComposite
    showID
    family
    location
    weight
    style
    createdAt
    updatedAt
  }
}
    `;
export const useCreateShowFontV2Mutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateShowFontV2Mutation,
    TError,
    CreateShowFontV2MutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateShowFontV2Mutation,
    TError,
    CreateShowFontV2MutationVariables,
    TContext
  >(
    (variables?: CreateShowFontV2MutationVariables) =>
      amplifyFetcher<
        CreateShowFontV2Mutation,
        CreateShowFontV2MutationVariables
      >(CreateShowFontV2Document, variables)(),
    options
  );
export const UpdateShowFontV2Document = `
    mutation UpdateShowFontV2($input: UpdateShowFontV2Input!) {
  updateShowFontV2(input: $input) {
    id
    showFontLocationComposite
    showID
    family
    location
    weight
    style
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateShowFontV2Mutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowFontV2Mutation,
    TError,
    UpdateShowFontV2MutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowFontV2Mutation,
    TError,
    UpdateShowFontV2MutationVariables,
    TContext
  >(
    (variables?: UpdateShowFontV2MutationVariables) =>
      amplifyFetcher<
        UpdateShowFontV2Mutation,
        UpdateShowFontV2MutationVariables
      >(UpdateShowFontV2Document, variables)(),
    options
  );
export const DeleteShowFontV2Document = `
    mutation DeleteShowFontV2($input: DeleteShowFontV2Input!) {
  deleteShowFontV2(input: $input) {
    id
    showFontLocationComposite
    showID
    family
    location
    weight
    style
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteShowFontV2Mutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteShowFontV2Mutation,
    TError,
    DeleteShowFontV2MutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteShowFontV2Mutation,
    TError,
    DeleteShowFontV2MutationVariables,
    TContext
  >(
    (variables?: DeleteShowFontV2MutationVariables) =>
      amplifyFetcher<
        DeleteShowFontV2Mutation,
        DeleteShowFontV2MutationVariables
      >(DeleteShowFontV2Document, variables)(),
    options
  );
export const CreateCubejsDashboardItemDocument = `
    mutation CreateCubejsDashboardItem($input: CreateCubejsDashboardItemInput!) {
  createCubejsDashboardItem(input: $input) {
    id
    userID
    layout
    vizState
    name
    createdAt
    updatedAt
  }
}
    `;
export const useCreateCubejsDashboardItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateCubejsDashboardItemMutation,
    TError,
    CreateCubejsDashboardItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateCubejsDashboardItemMutation,
    TError,
    CreateCubejsDashboardItemMutationVariables,
    TContext
  >(
    (variables?: CreateCubejsDashboardItemMutationVariables) =>
      amplifyFetcher<
        CreateCubejsDashboardItemMutation,
        CreateCubejsDashboardItemMutationVariables
      >(CreateCubejsDashboardItemDocument, variables)(),
    options
  );
export const UpdateCubejsDashboardItemDocument = `
    mutation UpdateCubejsDashboardItem($input: UpdateCubejsDashboardItemInput!) {
  updateCubejsDashboardItem(input: $input) {
    id
    userID
    layout
    vizState
    name
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateCubejsDashboardItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateCubejsDashboardItemMutation,
    TError,
    UpdateCubejsDashboardItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCubejsDashboardItemMutation,
    TError,
    UpdateCubejsDashboardItemMutationVariables,
    TContext
  >(
    (variables?: UpdateCubejsDashboardItemMutationVariables) =>
      amplifyFetcher<
        UpdateCubejsDashboardItemMutation,
        UpdateCubejsDashboardItemMutationVariables
      >(UpdateCubejsDashboardItemDocument, variables)(),
    options
  );
export const DeleteCubejsDashboardItemDocument = `
    mutation DeleteCubejsDashboardItem($input: DeleteCubejsDashboardItemInput!) {
  deleteCubejsDashboardItem(input: $input) {
    id
    userID
    layout
    vizState
    name
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteCubejsDashboardItemMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteCubejsDashboardItemMutation,
    TError,
    DeleteCubejsDashboardItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteCubejsDashboardItemMutation,
    TError,
    DeleteCubejsDashboardItemMutationVariables,
    TContext
  >(
    (variables?: DeleteCubejsDashboardItemMutationVariables) =>
      amplifyFetcher<
        DeleteCubejsDashboardItemMutation,
        DeleteCubejsDashboardItemMutationVariables
      >(DeleteCubejsDashboardItemDocument, variables)(),
    options
  );
export const CreateDigitalAssetLinksDocument = `
    mutation CreateDigitalAssetLinks($input: CreateDigitalAssetLinksInput!) {
  createDigitalAssetLinks(input: $input) {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
export const useCreateDigitalAssetLinksMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateDigitalAssetLinksMutation,
    TError,
    CreateDigitalAssetLinksMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateDigitalAssetLinksMutation,
    TError,
    CreateDigitalAssetLinksMutationVariables,
    TContext
  >(
    (variables?: CreateDigitalAssetLinksMutationVariables) =>
      amplifyFetcher<
        CreateDigitalAssetLinksMutation,
        CreateDigitalAssetLinksMutationVariables
      >(CreateDigitalAssetLinksDocument, variables)(),
    options
  );
export const UpdateDigitalAssetLinksDocument = `
    mutation UpdateDigitalAssetLinks($input: UpdateDigitalAssetLinksInput!) {
  updateDigitalAssetLinks(input: $input) {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateDigitalAssetLinksMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateDigitalAssetLinksMutation,
    TError,
    UpdateDigitalAssetLinksMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateDigitalAssetLinksMutation,
    TError,
    UpdateDigitalAssetLinksMutationVariables,
    TContext
  >(
    (variables?: UpdateDigitalAssetLinksMutationVariables) =>
      amplifyFetcher<
        UpdateDigitalAssetLinksMutation,
        UpdateDigitalAssetLinksMutationVariables
      >(UpdateDigitalAssetLinksDocument, variables)(),
    options
  );
export const DeleteDigitalAssetLinksDocument = `
    mutation DeleteDigitalAssetLinks($input: DeleteDigitalAssetLinksInput!) {
  deleteDigitalAssetLinks(input: $input) {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteDigitalAssetLinksMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteDigitalAssetLinksMutation,
    TError,
    DeleteDigitalAssetLinksMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteDigitalAssetLinksMutation,
    TError,
    DeleteDigitalAssetLinksMutationVariables,
    TContext
  >(
    (variables?: DeleteDigitalAssetLinksMutationVariables) =>
      amplifyFetcher<
        DeleteDigitalAssetLinksMutation,
        DeleteDigitalAssetLinksMutationVariables
      >(DeleteDigitalAssetLinksDocument, variables)(),
    options
  );
export const CreateExclusiveVideoContentDocument = `
    mutation CreateExclusiveVideoContent($input: CreateExclusiveVideoContentInput!) {
  createExclusiveVideoContent(input: $input) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const useCreateExclusiveVideoContentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateExclusiveVideoContentMutation,
    TError,
    CreateExclusiveVideoContentMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateExclusiveVideoContentMutation,
    TError,
    CreateExclusiveVideoContentMutationVariables,
    TContext
  >(
    (variables?: CreateExclusiveVideoContentMutationVariables) =>
      amplifyFetcher<
        CreateExclusiveVideoContentMutation,
        CreateExclusiveVideoContentMutationVariables
      >(CreateExclusiveVideoContentDocument, variables)(),
    options
  );
export const UpdateExclusiveVideoContentDocument = `
    mutation UpdateExclusiveVideoContent($input: UpdateExclusiveVideoContentInput!) {
  updateExclusiveVideoContent(input: $input) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateExclusiveVideoContentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateExclusiveVideoContentMutation,
    TError,
    UpdateExclusiveVideoContentMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateExclusiveVideoContentMutation,
    TError,
    UpdateExclusiveVideoContentMutationVariables,
    TContext
  >(
    (variables?: UpdateExclusiveVideoContentMutationVariables) =>
      amplifyFetcher<
        UpdateExclusiveVideoContentMutation,
        UpdateExclusiveVideoContentMutationVariables
      >(UpdateExclusiveVideoContentDocument, variables)(),
    options
  );
export const CreateShowMetaTagsDocument = `
    mutation CreateShowMetaTags($input: CreateShowMetaTagsInput!) {
  createShowMetaTags(input: $input) {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const useCreateShowMetaTagsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateShowMetaTagsMutation,
    TError,
    CreateShowMetaTagsMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateShowMetaTagsMutation,
    TError,
    CreateShowMetaTagsMutationVariables,
    TContext
  >(
    (variables?: CreateShowMetaTagsMutationVariables) =>
      amplifyFetcher<
        CreateShowMetaTagsMutation,
        CreateShowMetaTagsMutationVariables
      >(CreateShowMetaTagsDocument, variables)(),
    options
  );
export const UpdateShowMetaTagsDocument = `
    mutation UpdateShowMetaTags($input: UpdateShowMetaTagsInput!) {
  updateShowMetaTags(input: $input) {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateShowMetaTagsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateShowMetaTagsMutation,
    TError,
    UpdateShowMetaTagsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateShowMetaTagsMutation,
    TError,
    UpdateShowMetaTagsMutationVariables,
    TContext
  >(
    (variables?: UpdateShowMetaTagsMutationVariables) =>
      amplifyFetcher<
        UpdateShowMetaTagsMutation,
        UpdateShowMetaTagsMutationVariables
      >(UpdateShowMetaTagsDocument, variables)(),
    options
  );
export const DeleteShowMetaTagsDocument = `
    mutation DeleteShowMetaTags($input: DeleteShowMetaTagsInput!) {
  deleteShowMetaTags(input: $input) {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteShowMetaTagsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteShowMetaTagsMutation,
    TError,
    DeleteShowMetaTagsMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteShowMetaTagsMutation,
    TError,
    DeleteShowMetaTagsMutationVariables,
    TContext
  >(
    (variables?: DeleteShowMetaTagsMutationVariables) =>
      amplifyFetcher<
        DeleteShowMetaTagsMutation,
        DeleteShowMetaTagsMutationVariables
      >(DeleteShowMetaTagsDocument, variables)(),
    options
  );
export const CreateNotificationDocument = `
    mutation CreateNotification($input: CreateNotificationInput!) {
  createNotification(input: $input) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const useCreateNotificationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateNotificationMutation,
    TError,
    CreateNotificationMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateNotificationMutation,
    TError,
    CreateNotificationMutationVariables,
    TContext
  >(
    (variables?: CreateNotificationMutationVariables) =>
      amplifyFetcher<
        CreateNotificationMutation,
        CreateNotificationMutationVariables
      >(CreateNotificationDocument, variables)(),
    options
  );
export const UpdateNotificationDocument = `
    mutation UpdateNotification($input: UpdateNotificationInput!) {
  updateNotification(input: $input) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateNotificationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateNotificationMutation,
    TError,
    UpdateNotificationMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateNotificationMutation,
    TError,
    UpdateNotificationMutationVariables,
    TContext
  >(
    (variables?: UpdateNotificationMutationVariables) =>
      amplifyFetcher<
        UpdateNotificationMutation,
        UpdateNotificationMutationVariables
      >(UpdateNotificationDocument, variables)(),
    options
  );
export const UpdateVendorDocument = `
    mutation UpdateVendor($input: UpdateVendorInput!) {
  updateVendor(input: $input) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateVendorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateVendorMutation,
    TError,
    UpdateVendorMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateVendorMutation,
    TError,
    UpdateVendorMutationVariables,
    TContext
  >(
    (variables?: UpdateVendorMutationVariables) =>
      amplifyFetcher<UpdateVendorMutation, UpdateVendorMutationVariables>(
        UpdateVendorDocument,
        variables
      )(),
    options
  );
export const CreateBannerAdDocument = `
    mutation CreateBannerAd($input: CreateBannerAdInput!) {
  createBannerAd(input: $input) {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const useCreateBannerAdMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateBannerAdMutation,
    TError,
    CreateBannerAdMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateBannerAdMutation,
    TError,
    CreateBannerAdMutationVariables,
    TContext
  >(
    (variables?: CreateBannerAdMutationVariables) =>
      amplifyFetcher<CreateBannerAdMutation, CreateBannerAdMutationVariables>(
        CreateBannerAdDocument,
        variables
      )(),
    options
  );
export const UpdateBannerAdDocument = `
    mutation UpdateBannerAd($input: UpdateBannerAdInput!) {
  updateBannerAd(input: $input) {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateBannerAdMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBannerAdMutation,
    TError,
    UpdateBannerAdMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateBannerAdMutation,
    TError,
    UpdateBannerAdMutationVariables,
    TContext
  >(
    (variables?: UpdateBannerAdMutationVariables) =>
      amplifyFetcher<UpdateBannerAdMutation, UpdateBannerAdMutationVariables>(
        UpdateBannerAdDocument,
        variables
      )(),
    options
  );
export const DeleteBannerAdDocument = `
    mutation DeleteBannerAd($input: DeleteBannerAdInput!) {
  deleteBannerAd(input: $input) {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const useDeleteBannerAdMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteBannerAdMutation,
    TError,
    DeleteBannerAdMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteBannerAdMutation,
    TError,
    DeleteBannerAdMutationVariables,
    TContext
  >(
    (variables?: DeleteBannerAdMutationVariables) =>
      amplifyFetcher<DeleteBannerAdMutation, DeleteBannerAdMutationVariables>(
        DeleteBannerAdDocument,
        variables
      )(),
    options
  );
export const GetDashboardReportDocument = `
    query GetDashboardReport($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getDashboardReport(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    totalSeconds
    totalBytes
    previousTotalSeconds
    previousTotalBytes
    ipAddressCount
    previousIPAddressCount
    totalViews
    totalClicks
  }
}
    `;
export const useGetDashboardReportQuery = <
  TData = GetDashboardReportQuery,
  TError = unknown
>(
  variables: GetDashboardReportQueryVariables,
  options?: UseQueryOptions<GetDashboardReportQuery, TError, TData>
) =>
  useQuery<GetDashboardReportQuery, TError, TData>(
    ["GetDashboardReport", variables],
    amplifyFetcher<GetDashboardReportQuery, GetDashboardReportQueryVariables>(
      GetDashboardReportDocument,
      variables
    ),
    options
  );
export const GetDashboardTopTenEpisodesDocument = `
    query GetDashboardTopTenEpisodes($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getDashboardTopTenEpisodes(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      ShowId
      EpisodeId
      ShowNameShort
      Season
      EpisodeNo
      EpisodeName
      EpisodePoster
      PosterFileId
      totalViews
    }
  }
}
    `;
export const useGetDashboardTopTenEpisodesQuery = <
  TData = GetDashboardTopTenEpisodesQuery,
  TError = unknown
>(
  variables: GetDashboardTopTenEpisodesQueryVariables,
  options?: UseQueryOptions<GetDashboardTopTenEpisodesQuery, TError, TData>
) =>
  useQuery<GetDashboardTopTenEpisodesQuery, TError, TData>(
    ["GetDashboardTopTenEpisodes", variables],
    amplifyFetcher<
      GetDashboardTopTenEpisodesQuery,
      GetDashboardTopTenEpisodesQueryVariables
    >(GetDashboardTopTenEpisodesDocument, variables),
    options
  );
export const GetDashboardTopTenProductsImpressionsDocument = `
    query GetDashboardTopTenProductsImpressions($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getDashboardTopTenProductsImpressions(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      productId
      BrandName
      Name
      MainPhotoURL
      MainPhotoFileId
      MainPhotoFileExt
      totalViews
    }
  }
}
    `;
export const useGetDashboardTopTenProductsImpressionsQuery = <
  TData = GetDashboardTopTenProductsImpressionsQuery,
  TError = unknown
>(
  variables: GetDashboardTopTenProductsImpressionsQueryVariables,
  options?: UseQueryOptions<
    GetDashboardTopTenProductsImpressionsQuery,
    TError,
    TData
  >
) =>
  useQuery<GetDashboardTopTenProductsImpressionsQuery, TError, TData>(
    ["GetDashboardTopTenProductsImpressions", variables],
    amplifyFetcher<
      GetDashboardTopTenProductsImpressionsQuery,
      GetDashboardTopTenProductsImpressionsQueryVariables
    >(GetDashboardTopTenProductsImpressionsDocument, variables),
    options
  );
export const GetDashboardTopTenProductsViewsDocument = `
    query GetDashboardTopTenProductsViews($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getDashboardTopTenProductsViews(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      productId
      BrandName
      Name
      MainPhotoURL
      MainPhotoFileId
      MainPhotoFileExt
      totalViews
    }
  }
}
    `;
export const useGetDashboardTopTenProductsViewsQuery = <
  TData = GetDashboardTopTenProductsViewsQuery,
  TError = unknown
>(
  variables: GetDashboardTopTenProductsViewsQueryVariables,
  options?: UseQueryOptions<GetDashboardTopTenProductsViewsQuery, TError, TData>
) =>
  useQuery<GetDashboardTopTenProductsViewsQuery, TError, TData>(
    ["GetDashboardTopTenProductsViews", variables],
    amplifyFetcher<
      GetDashboardTopTenProductsViewsQuery,
      GetDashboardTopTenProductsViewsQueryVariables
    >(GetDashboardTopTenProductsViewsDocument, variables),
    options
  );
export const GetDashboardTopTenProductsClicksDocument = `
    query GetDashboardTopTenProductsClicks($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getDashboardTopTenProductsClicks(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      productId
      BrandName
      Name
      MainPhotoURL
      MainPhotoFileId
      MainPhotoFileExt
      totalViews
    }
  }
}
    `;
export const useGetDashboardTopTenProductsClicksQuery = <
  TData = GetDashboardTopTenProductsClicksQuery,
  TError = unknown
>(
  variables: GetDashboardTopTenProductsClicksQueryVariables,
  options?: UseQueryOptions<
    GetDashboardTopTenProductsClicksQuery,
    TError,
    TData
  >
) =>
  useQuery<GetDashboardTopTenProductsClicksQuery, TError, TData>(
    ["GetDashboardTopTenProductsClicks", variables],
    amplifyFetcher<
      GetDashboardTopTenProductsClicksQuery,
      GetDashboardTopTenProductsClicksQueryVariables
    >(GetDashboardTopTenProductsClicksDocument, variables),
    options
  );
export const ListCustomersDocument = `
    query ListCustomers($limit: Int) {
  listCustomers(limit: $limit) {
    items {
      id
      shortName
      fullName
      active
      added
      adminType
      isVendor
      emailAddressIdentity
      streamingContent
      displayShowReports
      displayEpisodeReports
    }
  }
}
    `;
export const useListCustomersQuery = <
  TData = ListCustomersQuery,
  TError = unknown
>(
  variables?: ListCustomersQueryVariables,
  options?: UseQueryOptions<ListCustomersQuery, TError, TData>
) =>
  useQuery<ListCustomersQuery, TError, TData>(
    ["ListCustomers", variables],
    amplifyFetcher<ListCustomersQuery, ListCustomersQueryVariables>(
      ListCustomersDocument,
      variables
    ),
    options
  );
export const GetCustomerDocument = `
    query GetCustomer($id: Int!) {
  getCustomer(id: $id) {
    id
    shortName
    fullName
    active
    added
    adminType
    isVendor
    emailAddressIdentity
    streamingContent
    displayShowReports
    displayEpisodeReports
  }
}
    `;
export const useGetCustomerQuery = <TData = GetCustomerQuery, TError = unknown>(
  variables: GetCustomerQueryVariables,
  options?: UseQueryOptions<GetCustomerQuery, TError, TData>
) =>
  useQuery<GetCustomerQuery, TError, TData>(
    ["GetCustomer", variables],
    amplifyFetcher<GetCustomerQuery, GetCustomerQueryVariables>(
      GetCustomerDocument,
      variables
    ),
    options
  );
export const ListShowsDocument = `
    query ListShows($customerID: Int!) {
  listShows(customerID: $customerID) {
    items {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
}
    `;
export const useListShowsQuery = <TData = ListShowsQuery, TError = unknown>(
  variables: ListShowsQueryVariables,
  options?: UseQueryOptions<ListShowsQuery, TError, TData>
) =>
  useQuery<ListShowsQuery, TError, TData>(
    ["ListShows", variables],
    amplifyFetcher<ListShowsQuery, ListShowsQueryVariables>(
      ListShowsDocument,
      variables
    ),
    options
  );
export const GetShowDocument = `
    query GetShow($id: Int!) {
  getShow(id: $id) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    hideInAdmin
    appID
    mainMenuType
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    channel {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
    demographicConfiguration {
      introText
      ageEnabled
      genderEnabled
      ethnicityEnabled
      educationEnabled
      employmentEnabled
      maritalStatusEnabled
      incomeEnabled
      zipcodeEnabled
      emailEnabled
    }
    productRequestConfiguration {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const useGetShowQuery = <TData = GetShowQuery, TError = unknown>(
  variables: GetShowQueryVariables,
  options?: UseQueryOptions<GetShowQuery, TError, TData>
) =>
  useQuery<GetShowQuery, TError, TData>(
    ["GetShow", variables],
    amplifyFetcher<GetShowQuery, GetShowQueryVariables>(
      GetShowDocument,
      variables
    ),
    options
  );
export const GetShowByHostnameDocument = `
    query GetShowByHostname($hostname: String!) {
  getShowByHostname(hostname: $hostname) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    hideInAdmin
    appID
    mainMenuType
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    channel {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
    demographicConfiguration {
      introText
      ageEnabled
      genderEnabled
      ethnicityEnabled
      educationEnabled
      employmentEnabled
      maritalStatusEnabled
      incomeEnabled
      zipcodeEnabled
      emailEnabled
    }
    productRequestConfiguration {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const useGetShowByHostnameQuery = <
  TData = GetShowByHostnameQuery,
  TError = unknown
>(
  variables: GetShowByHostnameQueryVariables,
  options?: UseQueryOptions<GetShowByHostnameQuery, TError, TData>
) =>
  useQuery<GetShowByHostnameQuery, TError, TData>(
    ["GetShowByHostname", variables],
    amplifyFetcher<GetShowByHostnameQuery, GetShowByHostnameQueryVariables>(
      GetShowByHostnameDocument,
      variables
    ),
    options
  );
export const GetShowReportDocument = `
    query GetShowReport($showID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getShowReport(showID: $showID, fromDate: $fromDate, toDate: $toDate) {
    totalSeconds
    totalBytes
    ipCount
    appUsers
    totalParts
    averageViews
    minPartViews
    maxPartViews
    deviceInfo {
      deviceType
      OS
      usercount
      totalbytes
    }
    topEpisodes {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
    dateStreams {
      logDate
      logday
      totalSecs
      totalbytes
    }
    streamDataByBandwidth {
      streamKbps
      streamPart
      totalhits
    }
    streamDataByWeekday {
      dayNumber
      weekName
      totalSecs
      totalbytes
    }
    streamDataByHour {
      hourNumber
      totalSecs
      totalbytes
    }
  }
}
    `;
export const useGetShowReportQuery = <
  TData = GetShowReportQuery,
  TError = unknown
>(
  variables: GetShowReportQueryVariables,
  options?: UseQueryOptions<GetShowReportQuery, TError, TData>
) =>
  useQuery<GetShowReportQuery, TError, TData>(
    ["GetShowReport", variables],
    amplifyFetcher<GetShowReportQuery, GetShowReportQueryVariables>(
      GetShowReportDocument,
      variables
    ),
    options
  );
export const GetShowSeasonsDocument = `
    query GetShowSeasons($showID: Int!) {
  getShowSeasons(showID: $showID) {
    items {
      showID
      season
      name
    }
  }
}
    `;
export const useGetShowSeasonsQuery = <
  TData = GetShowSeasonsQuery,
  TError = unknown
>(
  variables: GetShowSeasonsQueryVariables,
  options?: UseQueryOptions<GetShowSeasonsQuery, TError, TData>
) =>
  useQuery<GetShowSeasonsQuery, TError, TData>(
    ["GetShowSeasons", variables],
    amplifyFetcher<GetShowSeasonsQuery, GetShowSeasonsQueryVariables>(
      GetShowSeasonsDocument,
      variables
    ),
    options
  );
export const GetShowDesignMenuDocument = `
    query GetShowDesignMenu($showID: Int!) {
  getShowDesignMenu(showID: $showID) {
    items {
      id
      showID
      orderID
      title
      description
      icon
      iconColor
      url
      showInPullOutMenu
      showInHomeMenu
      enabled
    }
  }
}
    `;
export const useGetShowDesignMenuQuery = <
  TData = GetShowDesignMenuQuery,
  TError = unknown
>(
  variables: GetShowDesignMenuQueryVariables,
  options?: UseQueryOptions<GetShowDesignMenuQuery, TError, TData>
) =>
  useQuery<GetShowDesignMenuQuery, TError, TData>(
    ["GetShowDesignMenu", variables],
    amplifyFetcher<GetShowDesignMenuQuery, GetShowDesignMenuQueryVariables>(
      GetShowDesignMenuDocument,
      variables
    ),
    options
  );
export const GetShowMetadataDocument = `
    query GetShowMetadata($showID: Int!) {
  getShowMetadata(showID: $showID) {
    items {
      id
      showID
      name
      value
    }
  }
}
    `;
export const useGetShowMetadataQuery = <
  TData = GetShowMetadataQuery,
  TError = unknown
>(
  variables: GetShowMetadataQueryVariables,
  options?: UseQueryOptions<GetShowMetadataQuery, TError, TData>
) =>
  useQuery<GetShowMetadataQuery, TError, TData>(
    ["GetShowMetadata", variables],
    amplifyFetcher<GetShowMetadataQuery, GetShowMetadataQueryVariables>(
      GetShowMetadataDocument,
      variables
    ),
    options
  );
export const ListEpisodesDocument = `
    query ListEpisodes($showID: Int!) {
  listEpisodes(showID: $showID) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
}
    `;
export const useListEpisodesQuery = <
  TData = ListEpisodesQuery,
  TError = unknown
>(
  variables: ListEpisodesQueryVariables,
  options?: UseQueryOptions<ListEpisodesQuery, TError, TData>
) =>
  useQuery<ListEpisodesQuery, TError, TData>(
    ["ListEpisodes", variables],
    amplifyFetcher<ListEpisodesQuery, ListEpisodesQueryVariables>(
      ListEpisodesDocument,
      variables
    ),
    options
  );
export const ListAppEpisodesDocument = `
    query ListAppEpisodes($showID: Int!, $limit: Int) {
  listAppEpisodes(showID: $showID, limit: $limit) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
}
    `;
export const useListAppEpisodesQuery = <
  TData = ListAppEpisodesQuery,
  TError = unknown
>(
  variables: ListAppEpisodesQueryVariables,
  options?: UseQueryOptions<ListAppEpisodesQuery, TError, TData>
) =>
  useQuery<ListAppEpisodesQuery, TError, TData>(
    ["ListAppEpisodes", variables],
    amplifyFetcher<ListAppEpisodesQuery, ListAppEpisodesQueryVariables>(
      ListAppEpisodesDocument,
      variables
    ),
    options
  );
export const ListOttEpisodesDocument = `
    query ListOttEpisodes($showID: Int!, $limit: Int) {
  listOttEpisodes(showID: $showID, limit: $limit) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
}
    `;
export const useListOttEpisodesQuery = <
  TData = ListOttEpisodesQuery,
  TError = unknown
>(
  variables: ListOttEpisodesQueryVariables,
  options?: UseQueryOptions<ListOttEpisodesQuery, TError, TData>
) =>
  useQuery<ListOttEpisodesQuery, TError, TData>(
    ["ListOttEpisodes", variables],
    amplifyFetcher<ListOttEpisodesQuery, ListOttEpisodesQueryVariables>(
      ListOttEpisodesDocument,
      variables
    ),
    options
  );
export const ListCustomerEpisodesDocument = `
    query ListCustomerEpisodes($customerID: Int!) {
  listCustomerEpisodes(customerID: $customerID) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
}
    `;
export const useListCustomerEpisodesQuery = <
  TData = ListCustomerEpisodesQuery,
  TError = unknown
>(
  variables: ListCustomerEpisodesQueryVariables,
  options?: UseQueryOptions<ListCustomerEpisodesQuery, TError, TData>
) =>
  useQuery<ListCustomerEpisodesQuery, TError, TData>(
    ["ListCustomerEpisodes", variables],
    amplifyFetcher<
      ListCustomerEpisodesQuery,
      ListCustomerEpisodesQueryVariables
    >(ListCustomerEpisodesDocument, variables),
    options
  );
export const ListCustomerEpisodesWithPhotoGroupsDocument = `
    query ListCustomerEpisodesWithPhotoGroups($customerID: Int!) {
  listCustomerEpisodesWithPhotoGroups(customerID: $customerID) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
}
    `;
export const useListCustomerEpisodesWithPhotoGroupsQuery = <
  TData = ListCustomerEpisodesWithPhotoGroupsQuery,
  TError = unknown
>(
  variables: ListCustomerEpisodesWithPhotoGroupsQueryVariables,
  options?: UseQueryOptions<
    ListCustomerEpisodesWithPhotoGroupsQuery,
    TError,
    TData
  >
) =>
  useQuery<ListCustomerEpisodesWithPhotoGroupsQuery, TError, TData>(
    ["ListCustomerEpisodesWithPhotoGroups", variables],
    amplifyFetcher<
      ListCustomerEpisodesWithPhotoGroupsQuery,
      ListCustomerEpisodesWithPhotoGroupsQueryVariables
    >(ListCustomerEpisodesWithPhotoGroupsDocument, variables),
    options
  );
export const GetEpisodeDocument = `
    query GetEpisode($id: Int!) {
  getEpisode(id: $id) {
    id
    showID
    showName
    season
    seasonName
    number
    firstRelease
    added
    name
    description
    disclaimer
    enabled
    posterType
    poster
    posterURL
    posterFileID
    video
    videoBucket
    videoKey
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    audioFingerprintID
    duration
    rokuAddBreaks
    releaseToOTT
    releaseToApp
    liveChannelID
    enableHeaderButton
    headerButtonText
    headerButtonURL
    disableProductScroller
    liveStreamID
    liveStreamEnabledOnStartMenuID
    videoToken
  }
}
    `;
export const useGetEpisodeQuery = <TData = GetEpisodeQuery, TError = unknown>(
  variables: GetEpisodeQueryVariables,
  options?: UseQueryOptions<GetEpisodeQuery, TError, TData>
) =>
  useQuery<GetEpisodeQuery, TError, TData>(
    ["GetEpisode", variables],
    amplifyFetcher<GetEpisodeQuery, GetEpisodeQueryVariables>(
      GetEpisodeDocument,
      variables
    ),
    options
  );
export const ListEpisodeCommercialsDocument = `
    query ListEpisodeCommercials($episodeID: Int!) {
  listEpisodeCommercials(episodeID: $episodeID) {
    items {
      id
      episodeID
      commercialEpisodeID
      type
      spliceTime
      showID
      episodeName
      episodePoster
      episodePosterURL
      enabled
      duration
    }
  }
}
    `;
export const useListEpisodeCommercialsQuery = <
  TData = ListEpisodeCommercialsQuery,
  TError = unknown
>(
  variables: ListEpisodeCommercialsQueryVariables,
  options?: UseQueryOptions<ListEpisodeCommercialsQuery, TError, TData>
) =>
  useQuery<ListEpisodeCommercialsQuery, TError, TData>(
    ["ListEpisodeCommercials", variables],
    amplifyFetcher<
      ListEpisodeCommercialsQuery,
      ListEpisodeCommercialsQueryVariables
    >(ListEpisodeCommercialsDocument, variables),
    options
  );
export const ListCommercialsDocument = `
    query ListCommercials($showID: Int!) {
  listCommercials(showID: $showID) {
    items {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
}
    `;
export const useListCommercialsQuery = <
  TData = ListCommercialsQuery,
  TError = unknown
>(
  variables: ListCommercialsQueryVariables,
  options?: UseQueryOptions<ListCommercialsQuery, TError, TData>
) =>
  useQuery<ListCommercialsQuery, TError, TData>(
    ["ListCommercials", variables],
    amplifyFetcher<ListCommercialsQuery, ListCommercialsQueryVariables>(
      ListCommercialsDocument,
      variables
    ),
    options
  );
export const GetEpisodeMetadataDocument = `
    query GetEpisodeMetadata($episodeID: Int!) {
  getEpisodeMetadata(episodeID: $episodeID) {
    items {
      id
      episodeID
      name
      value
    }
  }
}
    `;
export const useGetEpisodeMetadataQuery = <
  TData = GetEpisodeMetadataQuery,
  TError = unknown
>(
  variables: GetEpisodeMetadataQueryVariables,
  options?: UseQueryOptions<GetEpisodeMetadataQuery, TError, TData>
) =>
  useQuery<GetEpisodeMetadataQuery, TError, TData>(
    ["GetEpisodeMetadata", variables],
    amplifyFetcher<GetEpisodeMetadataQuery, GetEpisodeMetadataQueryVariables>(
      GetEpisodeMetadataDocument,
      variables
    ),
    options
  );
export const GetEpisodeReportDocument = `
    query GetEpisodeReport($showID: Int!, $episodeID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getEpisodeReport(
    showID: $showID
    episodeID: $episodeID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    totalSeconds
    totalBytes
    ipCount
    appUsers
    totalParts
    averageViews
    minPartViews
    maxPartViews
    dateStreams {
      logDate
      logday
      totalSecs
      totalbytes
    }
    streamDataByBandwidth {
      streamKbps
      streamPart
      totalhits
    }
  }
}
    `;
export const useGetEpisodeReportQuery = <
  TData = GetEpisodeReportQuery,
  TError = unknown
>(
  variables: GetEpisodeReportQueryVariables,
  options?: UseQueryOptions<GetEpisodeReportQuery, TError, TData>
) =>
  useQuery<GetEpisodeReportQuery, TError, TData>(
    ["GetEpisodeReport", variables],
    amplifyFetcher<GetEpisodeReportQuery, GetEpisodeReportQueryVariables>(
      GetEpisodeReportDocument,
      variables
    ),
    options
  );
export const GetEpisodeProductsDocument = `
    query GetEpisodeProducts($episodeID: Int!, $active: Boolean) {
  getEpisodeProducts(episodeID: $episodeID, active: $active) {
    items {
      id
      episodeID
      productID
      startTimecode
      startTimeSeconds
      startTimeMilliseconds
      endTimecode
      endTimeSeconds
      endTimeMilliseconds
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useGetEpisodeProductsQuery = <
  TData = GetEpisodeProductsQuery,
  TError = unknown
>(
  variables: GetEpisodeProductsQueryVariables,
  options?: UseQueryOptions<GetEpisodeProductsQuery, TError, TData>
) =>
  useQuery<GetEpisodeProductsQuery, TError, TData>(
    ["GetEpisodeProducts", variables],
    amplifyFetcher<GetEpisodeProductsQuery, GetEpisodeProductsQueryVariables>(
      GetEpisodeProductsDocument,
      variables
    ),
    options
  );
export const GetEpisodePhotoGroupsDocument = `
    query GetEpisodePhotoGroups($episodeID: Int!) {
  getEpisodePhotoGroups(episodeID: $episodeID) {
    items {
      id
      episodeID
      name
      order
      enabled
    }
  }
}
    `;
export const useGetEpisodePhotoGroupsQuery = <
  TData = GetEpisodePhotoGroupsQuery,
  TError = unknown
>(
  variables: GetEpisodePhotoGroupsQueryVariables,
  options?: UseQueryOptions<GetEpisodePhotoGroupsQuery, TError, TData>
) =>
  useQuery<GetEpisodePhotoGroupsQuery, TError, TData>(
    ["GetEpisodePhotoGroups", variables],
    amplifyFetcher<
      GetEpisodePhotoGroupsQuery,
      GetEpisodePhotoGroupsQueryVariables
    >(GetEpisodePhotoGroupsDocument, variables),
    options
  );
export const GetEpisodePhotosDocument = `
    query GetEpisodePhotos($episodeID: Int!) {
  getEpisodePhotos(episodeID: $episodeID) {
    items {
      id
      episodeID
      groupID
      groupName
      order
      title
      description
      fileID
      extension
      url
      thumbnailURL
      enabled
      added
    }
  }
}
    `;
export const useGetEpisodePhotosQuery = <
  TData = GetEpisodePhotosQuery,
  TError = unknown
>(
  variables: GetEpisodePhotosQueryVariables,
  options?: UseQueryOptions<GetEpisodePhotosQuery, TError, TData>
) =>
  useQuery<GetEpisodePhotosQuery, TError, TData>(
    ["GetEpisodePhotos", variables],
    amplifyFetcher<GetEpisodePhotosQuery, GetEpisodePhotosQueryVariables>(
      GetEpisodePhotosDocument,
      variables
    ),
    options
  );
export const GetEpisodePhotosByGroupDocument = `
    query GetEpisodePhotosByGroup($groupID: Int!) {
  getEpisodePhotosByGroup(groupID: $groupID) {
    items {
      id
      episodeID
      groupID
      groupName
      order
      title
      description
      fileID
      extension
      url
      thumbnailURL
      enabled
      added
    }
  }
}
    `;
export const useGetEpisodePhotosByGroupQuery = <
  TData = GetEpisodePhotosByGroupQuery,
  TError = unknown
>(
  variables: GetEpisodePhotosByGroupQueryVariables,
  options?: UseQueryOptions<GetEpisodePhotosByGroupQuery, TError, TData>
) =>
  useQuery<GetEpisodePhotosByGroupQuery, TError, TData>(
    ["GetEpisodePhotosByGroup", variables],
    amplifyFetcher<
      GetEpisodePhotosByGroupQuery,
      GetEpisodePhotosByGroupQueryVariables
    >(GetEpisodePhotosByGroupDocument, variables),
    options
  );
export const GetEpisodePhotoProductsDocument = `
    query GetEpisodePhotoProducts($episodeID: Int!) {
  getEpisodePhotoProducts(episodeID: $episodeID) {
    items {
      id
      groupID
      productID
      episodeID
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useGetEpisodePhotoProductsQuery = <
  TData = GetEpisodePhotoProductsQuery,
  TError = unknown
>(
  variables: GetEpisodePhotoProductsQueryVariables,
  options?: UseQueryOptions<GetEpisodePhotoProductsQuery, TError, TData>
) =>
  useQuery<GetEpisodePhotoProductsQuery, TError, TData>(
    ["GetEpisodePhotoProducts", variables],
    amplifyFetcher<
      GetEpisodePhotoProductsQuery,
      GetEpisodePhotoProductsQueryVariables
    >(GetEpisodePhotoProductsDocument, variables),
    options
  );
export const GetEpisodePhotoProductsByGroupDocument = `
    query GetEpisodePhotoProductsByGroup($groupID: Int!) {
  getEpisodePhotoProductsByGroup(groupID: $groupID) {
    items {
      id
      groupID
      productID
      episodeID
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useGetEpisodePhotoProductsByGroupQuery = <
  TData = GetEpisodePhotoProductsByGroupQuery,
  TError = unknown
>(
  variables: GetEpisodePhotoProductsByGroupQueryVariables,
  options?: UseQueryOptions<GetEpisodePhotoProductsByGroupQuery, TError, TData>
) =>
  useQuery<GetEpisodePhotoProductsByGroupQuery, TError, TData>(
    ["GetEpisodePhotoProductsByGroup", variables],
    amplifyFetcher<
      GetEpisodePhotoProductsByGroupQuery,
      GetEpisodePhotoProductsByGroupQueryVariables
    >(GetEpisodePhotoProductsByGroupDocument, variables),
    options
  );
export const GetEpisodeStreamsDocument = `
    query GetEpisodeStreams($episodeID: Int!, $showID: Int!) {
  getEpisodeStreams(episodeID: $episodeID, showID: $showID) {
    items {
      id
      episodeID
      enabled
      type
      bandwidth
      averageBandwidth
      codecs
      resolution
      frameRate
      targetDuration
      targetBandwidth
      filename
      partDuration
      partCount
      videoRoot
      videoToken
    }
  }
}
    `;
export const useGetEpisodeStreamsQuery = <
  TData = GetEpisodeStreamsQuery,
  TError = unknown
>(
  variables: GetEpisodeStreamsQueryVariables,
  options?: UseQueryOptions<GetEpisodeStreamsQuery, TError, TData>
) =>
  useQuery<GetEpisodeStreamsQuery, TError, TData>(
    ["GetEpisodeStreams", variables],
    amplifyFetcher<GetEpisodeStreamsQuery, GetEpisodeStreamsQueryVariables>(
      GetEpisodeStreamsDocument,
      variables
    ),
    options
  );
export const GetEpisodeStreamDocument = `
    query GetEpisodeStream($episodeID: Int!, $streamID: Int, $targetBandwidth: Int) {
  getEpisodeStream(
    episodeID: $episodeID
    streamID: $streamID
    targetBandwidth: $targetBandwidth
  ) {
    id
    episodeID
    enabled
    type
    bandwidth
    averageBandwidth
    codecs
    resolution
    frameRate
    targetDuration
    targetBandwidth
    filename
    partDuration
    partCount
    videoRoot
    videoToken
  }
}
    `;
export const useGetEpisodeStreamQuery = <
  TData = GetEpisodeStreamQuery,
  TError = unknown
>(
  variables: GetEpisodeStreamQueryVariables,
  options?: UseQueryOptions<GetEpisodeStreamQuery, TError, TData>
) =>
  useQuery<GetEpisodeStreamQuery, TError, TData>(
    ["GetEpisodeStream", variables],
    amplifyFetcher<GetEpisodeStreamQuery, GetEpisodeStreamQueryVariables>(
      GetEpisodeStreamDocument,
      variables
    ),
    options
  );
export const ListProductsDocument = `
    query ListProducts($customerID: Int!) {
  listProducts(customerID: $customerID) {
    items {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useListProductsQuery = <
  TData = ListProductsQuery,
  TError = unknown
>(
  variables: ListProductsQueryVariables,
  options?: UseQueryOptions<ListProductsQuery, TError, TData>
) =>
  useQuery<ListProductsQuery, TError, TData>(
    ["ListProducts", variables],
    amplifyFetcher<ListProductsQuery, ListProductsQueryVariables>(
      ListProductsDocument,
      variables
    ),
    options
  );
export const GetProductDocument = `
    query GetProduct($id: Int!) {
  getProduct(id: $id) {
    id
    customerID
    name
    description
    upc
    brand
    brandID
    sku
    active
    added
    deleted
    approved
    replacementProduct
    mainPhotoURL
    mainPhotoFileID
    mainPhotoFileExt
    mainPhotoSize
    issueCount
    photoBucket
    photoKey
    warnings
    originalProductName
    originalProductDescription
    originalProductURL
    originalProductPhotoBucket
    originalProductPhotoKey
    vendorID
    vendorLogo
    vendorLogoBucket
    vendorLogoKey
  }
}
    `;
export const useGetProductQuery = <TData = GetProductQuery, TError = unknown>(
  variables: GetProductQueryVariables,
  options?: UseQueryOptions<GetProductQuery, TError, TData>
) =>
  useQuery<GetProductQuery, TError, TData>(
    ["GetProduct", variables],
    amplifyFetcher<GetProductQuery, GetProductQueryVariables>(
      GetProductDocument,
      variables
    ),
    options
  );
export const ListRelatedProductsDocument = `
    query ListRelatedProducts($categoryID: Int!, $showID: Int!) {
  listRelatedProducts(categoryID: $categoryID, showID: $showID) {
    items {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useListRelatedProductsQuery = <
  TData = ListRelatedProductsQuery,
  TError = unknown
>(
  variables: ListRelatedProductsQueryVariables,
  options?: UseQueryOptions<ListRelatedProductsQuery, TError, TData>
) =>
  useQuery<ListRelatedProductsQuery, TError, TData>(
    ["ListRelatedProducts", variables],
    amplifyFetcher<ListRelatedProductsQuery, ListRelatedProductsQueryVariables>(
      ListRelatedProductsDocument,
      variables
    ),
    options
  );
export const ListShowFeaturedProductsDocument = `
    query ListShowFeaturedProducts($showID: Int!, $limit: Int) {
  listShowFeaturedProducts(showID: $showID, limit: $limit) {
    items {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useListShowFeaturedProductsQuery = <
  TData = ListShowFeaturedProductsQuery,
  TError = unknown
>(
  variables: ListShowFeaturedProductsQueryVariables,
  options?: UseQueryOptions<ListShowFeaturedProductsQuery, TError, TData>
) =>
  useQuery<ListShowFeaturedProductsQuery, TError, TData>(
    ["ListShowFeaturedProducts", variables],
    amplifyFetcher<
      ListShowFeaturedProductsQuery,
      ListShowFeaturedProductsQueryVariables
    >(ListShowFeaturedProductsDocument, variables),
    options
  );
export const ListAvailableProductsDocument = `
    query ListAvailableProducts {
  listAvailableProducts {
    items {
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      customerName
    }
  }
}
    `;
export const useListAvailableProductsQuery = <
  TData = ListAvailableProductsQuery,
  TError = unknown
>(
  variables?: ListAvailableProductsQueryVariables,
  options?: UseQueryOptions<ListAvailableProductsQuery, TError, TData>
) =>
  useQuery<ListAvailableProductsQuery, TError, TData>(
    ["ListAvailableProducts", variables],
    amplifyFetcher<
      ListAvailableProductsQuery,
      ListAvailableProductsQueryVariables
    >(ListAvailableProductsDocument, variables),
    options
  );
export const GetProductLinksDocument = `
    query GetProductLinks($productID: Int!) {
  getProductLinks(productID: $productID) {
    items {
      id
      productID
      storeID
      storeName
      type
      fullURL
      status
      statusLastChecked
    }
  }
}
    `;
export const useGetProductLinksQuery = <
  TData = GetProductLinksQuery,
  TError = unknown
>(
  variables: GetProductLinksQueryVariables,
  options?: UseQueryOptions<GetProductLinksQuery, TError, TData>
) =>
  useQuery<GetProductLinksQuery, TError, TData>(
    ["GetProductLinks", variables],
    amplifyFetcher<GetProductLinksQuery, GetProductLinksQueryVariables>(
      GetProductLinksDocument,
      variables
    ),
    options
  );
export const GetProductCategoriesDocument = `
    query GetProductCategories($productID: Int!) {
  getProductCategories(productID: $productID) {
    items {
      productID
      categoryID
      categoryName
    }
  }
}
    `;
export const useGetProductCategoriesQuery = <
  TData = GetProductCategoriesQuery,
  TError = unknown
>(
  variables: GetProductCategoriesQueryVariables,
  options?: UseQueryOptions<GetProductCategoriesQuery, TError, TData>
) =>
  useQuery<GetProductCategoriesQuery, TError, TData>(
    ["GetProductCategories", variables],
    amplifyFetcher<
      GetProductCategoriesQuery,
      GetProductCategoriesQueryVariables
    >(GetProductCategoriesDocument, variables),
    options
  );
export const GetProductSearchMetadataDocument = `
    query GetProductSearchMetadata($productID: Int!) {
  getProductSearchMetadata(productID: $productID) {
    productID
    searchData
    witAISearchData
    searchColor
    searchPeople
    lastUpdate
  }
}
    `;
export const useGetProductSearchMetadataQuery = <
  TData = GetProductSearchMetadataQuery,
  TError = unknown
>(
  variables: GetProductSearchMetadataQueryVariables,
  options?: UseQueryOptions<GetProductSearchMetadataQuery, TError, TData>
) =>
  useQuery<GetProductSearchMetadataQuery, TError, TData>(
    ["GetProductSearchMetadata", variables],
    amplifyFetcher<
      GetProductSearchMetadataQuery,
      GetProductSearchMetadataQueryVariables
    >(GetProductSearchMetadataDocument, variables),
    options
  );
export const GetProductMetadataDocument = `
    query GetProductMetadata($productID: Int!) {
  getProductMetadata(productID: $productID) {
    items {
      id
      productID
      name
      value
    }
  }
}
    `;
export const useGetProductMetadataQuery = <
  TData = GetProductMetadataQuery,
  TError = unknown
>(
  variables: GetProductMetadataQueryVariables,
  options?: UseQueryOptions<GetProductMetadataQuery, TError, TData>
) =>
  useQuery<GetProductMetadataQuery, TError, TData>(
    ["GetProductMetadata", variables],
    amplifyFetcher<GetProductMetadataQuery, GetProductMetadataQueryVariables>(
      GetProductMetadataDocument,
      variables
    ),
    options
  );
export const GetProductEpisodesDocument = `
    query GetProductEpisodes($productID: Int!) {
  getProductEpisodes(productID: $productID) {
    items {
      id
      episodeID
      productID
      startTimecode
      startTimeMilliseconds
      startTimeSeconds
      endTimecode
      endTimeMilliseconds
      endTimeSeconds
      showName
      episodeName
      episodeSeasonName
      episodeSeason
      episodeNumber
    }
  }
}
    `;
export const useGetProductEpisodesQuery = <
  TData = GetProductEpisodesQuery,
  TError = unknown
>(
  variables: GetProductEpisodesQueryVariables,
  options?: UseQueryOptions<GetProductEpisodesQuery, TError, TData>
) =>
  useQuery<GetProductEpisodesQuery, TError, TData>(
    ["GetProductEpisodes", variables],
    amplifyFetcher<GetProductEpisodesQuery, GetProductEpisodesQueryVariables>(
      GetProductEpisodesDocument,
      variables
    ),
    options
  );
export const GetProductFeaturedEpisodesDocument = `
    query GetProductFeaturedEpisodes($productID: Int!) {
  getProductFeaturedEpisodes(productID: $productID) {
    items {
      id
      episodeID
      productID
      startTimecode
      startTimeMilliseconds
      startTimeSeconds
      endTimecode
      endTimeMilliseconds
      endTimeSeconds
      showName
      episodeName
      episodeSeasonName
      episodeSeason
      episodeNumber
    }
  }
}
    `;
export const useGetProductFeaturedEpisodesQuery = <
  TData = GetProductFeaturedEpisodesQuery,
  TError = unknown
>(
  variables: GetProductFeaturedEpisodesQueryVariables,
  options?: UseQueryOptions<GetProductFeaturedEpisodesQuery, TError, TData>
) =>
  useQuery<GetProductFeaturedEpisodesQuery, TError, TData>(
    ["GetProductFeaturedEpisodes", variables],
    amplifyFetcher<
      GetProductFeaturedEpisodesQuery,
      GetProductFeaturedEpisodesQueryVariables
    >(GetProductFeaturedEpisodesDocument, variables),
    options
  );
export const GetProductEpisodePhotoGroupsDocument = `
    query GetProductEpisodePhotoGroups($productID: Int!) {
  getProductEpisodePhotoGroups(productID: $productID) {
    items {
      id
      groupID
      groupName
      showName
      episode {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
}
    `;
export const useGetProductEpisodePhotoGroupsQuery = <
  TData = GetProductEpisodePhotoGroupsQuery,
  TError = unknown
>(
  variables: GetProductEpisodePhotoGroupsQueryVariables,
  options?: UseQueryOptions<GetProductEpisodePhotoGroupsQuery, TError, TData>
) =>
  useQuery<GetProductEpisodePhotoGroupsQuery, TError, TData>(
    ["GetProductEpisodePhotoGroups", variables],
    amplifyFetcher<
      GetProductEpisodePhotoGroupsQuery,
      GetProductEpisodePhotoGroupsQueryVariables
    >(GetProductEpisodePhotoGroupsDocument, variables),
    options
  );
export const GetProductVendorDocument = `
    query GetProductVendor($productID: Int!) {
  getProductVendor(productID: $productID) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const useGetProductVendorQuery = <
  TData = GetProductVendorQuery,
  TError = unknown
>(
  variables: GetProductVendorQueryVariables,
  options?: UseQueryOptions<GetProductVendorQuery, TError, TData>
) =>
  useQuery<GetProductVendorQuery, TError, TData>(
    ["GetProductVendor", variables],
    amplifyFetcher<GetProductVendorQuery, GetProductVendorQueryVariables>(
      GetProductVendorDocument,
      variables
    ),
    options
  );
export const GetProductRequestsByShowDocument = `
    query GetProductRequestsByShow($showID: Int!, $status: Int) {
  getProductRequestsByShow(showID: $showID, status: $status) {
    items {
      id
      showID
      appInstallID
      episodeID
      episodeSeason
      episodeNumber
      episodeName
      photoGroup
      category
      email
      request
      added
      ip
      status
      lastChangedByAUID
      userSub
      response
    }
  }
}
    `;
export const useGetProductRequestsByShowQuery = <
  TData = GetProductRequestsByShowQuery,
  TError = unknown
>(
  variables: GetProductRequestsByShowQueryVariables,
  options?: UseQueryOptions<GetProductRequestsByShowQuery, TError, TData>
) =>
  useQuery<GetProductRequestsByShowQuery, TError, TData>(
    ["GetProductRequestsByShow", variables],
    amplifyFetcher<
      GetProductRequestsByShowQuery,
      GetProductRequestsByShowQueryVariables
    >(GetProductRequestsByShowDocument, variables),
    options
  );
export const GetProductRequestsByUserDocument = `
    query GetProductRequestsByUser($userID: ID) {
  getProductRequestsByUser(userID: $userID) {
    items {
      id
      showID
      appInstallID
      episodeID
      episodeSeason
      episodeNumber
      episodeName
      photoGroup
      category
      email
      request
      added
      ip
      status
      lastChangedByAUID
      userSub
      response
    }
  }
}
    `;
export const useGetProductRequestsByUserQuery = <
  TData = GetProductRequestsByUserQuery,
  TError = unknown
>(
  variables?: GetProductRequestsByUserQueryVariables,
  options?: UseQueryOptions<GetProductRequestsByUserQuery, TError, TData>
) =>
  useQuery<GetProductRequestsByUserQuery, TError, TData>(
    ["GetProductRequestsByUser", variables],
    amplifyFetcher<
      GetProductRequestsByUserQuery,
      GetProductRequestsByUserQueryVariables
    >(GetProductRequestsByUserDocument, variables),
    options
  );
export const GetProductsReportDocument = `
    query GetProductsReport($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getProductsReport(customerID: $customerID, fromDate: $fromDate, toDate: $toDate)
}
    `;
export const useGetProductsReportQuery = <
  TData = GetProductsReportQuery,
  TError = unknown
>(
  variables: GetProductsReportQueryVariables,
  options?: UseQueryOptions<GetProductsReportQuery, TError, TData>
) =>
  useQuery<GetProductsReportQuery, TError, TData>(
    ["GetProductsReport", variables],
    amplifyFetcher<GetProductsReportQuery, GetProductsReportQueryVariables>(
      GetProductsReportDocument,
      variables
    ),
    options
  );
export const GetVendorProductsReportDocument = `
    query GetVendorProductsReport($vendorID: ID!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getVendorProductsReport(
    vendorID: $vendorID
    fromDate: $fromDate
    toDate: $toDate
  )
}
    `;
export const useGetVendorProductsReportQuery = <
  TData = GetVendorProductsReportQuery,
  TError = unknown
>(
  variables: GetVendorProductsReportQueryVariables,
  options?: UseQueryOptions<GetVendorProductsReportQuery, TError, TData>
) =>
  useQuery<GetVendorProductsReportQuery, TError, TData>(
    ["GetVendorProductsReport", variables],
    amplifyFetcher<
      GetVendorProductsReportQuery,
      GetVendorProductsReportQueryVariables
    >(GetVendorProductsReportDocument, variables),
    options
  );
export const GetEngagementDeviceInfoDocument = `
    query GetEngagementDeviceInfo($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getEngagementDeviceInfo(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      deviceType
      os
      userCount
    }
  }
}
    `;
export const useGetEngagementDeviceInfoQuery = <
  TData = GetEngagementDeviceInfoQuery,
  TError = unknown
>(
  variables: GetEngagementDeviceInfoQueryVariables,
  options?: UseQueryOptions<GetEngagementDeviceInfoQuery, TError, TData>
) =>
  useQuery<GetEngagementDeviceInfoQuery, TError, TData>(
    ["GetEngagementDeviceInfo", variables],
    amplifyFetcher<
      GetEngagementDeviceInfoQuery,
      GetEngagementDeviceInfoQueryVariables
    >(GetEngagementDeviceInfoDocument, variables),
    options
  );
export const GetEngagementTopTwentyLocationsDocument = `
    query GetEngagementTopTwentyLocations($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getEngagementTopTwentyLocations(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      country
      state
      totalVisits
    }
  }
}
    `;
export const useGetEngagementTopTwentyLocationsQuery = <
  TData = GetEngagementTopTwentyLocationsQuery,
  TError = unknown
>(
  variables: GetEngagementTopTwentyLocationsQueryVariables,
  options?: UseQueryOptions<GetEngagementTopTwentyLocationsQuery, TError, TData>
) =>
  useQuery<GetEngagementTopTwentyLocationsQuery, TError, TData>(
    ["GetEngagementTopTwentyLocations", variables],
    amplifyFetcher<
      GetEngagementTopTwentyLocationsQuery,
      GetEngagementTopTwentyLocationsQueryVariables
    >(GetEngagementTopTwentyLocationsDocument, variables),
    options
  );
export const GetEngagementEpisodeViewsDocument = `
    query GetEngagementEpisodeViews($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getEngagementEpisodeViews(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      ShowId
      EpisodeId
      ShowNameShort
      Season
      EpisodeNo
      EpisodeName
      EpisodePoster
      PosterFileId
      totalViews
    }
  }
}
    `;
export const useGetEngagementEpisodeViewsQuery = <
  TData = GetEngagementEpisodeViewsQuery,
  TError = unknown
>(
  variables: GetEngagementEpisodeViewsQueryVariables,
  options?: UseQueryOptions<GetEngagementEpisodeViewsQuery, TError, TData>
) =>
  useQuery<GetEngagementEpisodeViewsQuery, TError, TData>(
    ["GetEngagementEpisodeViews", variables],
    amplifyFetcher<
      GetEngagementEpisodeViewsQuery,
      GetEngagementEpisodeViewsQueryVariables
    >(GetEngagementEpisodeViewsDocument, variables),
    options
  );
export const GetEngagementActivityByWeekdayDocument = `
    query GetEngagementActivityByWeekday($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getEngagementActivityByWeekday(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      dayNumber
      day
      totalCount
    }
  }
}
    `;
export const useGetEngagementActivityByWeekdayQuery = <
  TData = GetEngagementActivityByWeekdayQuery,
  TError = unknown
>(
  variables: GetEngagementActivityByWeekdayQueryVariables,
  options?: UseQueryOptions<GetEngagementActivityByWeekdayQuery, TError, TData>
) =>
  useQuery<GetEngagementActivityByWeekdayQuery, TError, TData>(
    ["GetEngagementActivityByWeekday", variables],
    amplifyFetcher<
      GetEngagementActivityByWeekdayQuery,
      GetEngagementActivityByWeekdayQueryVariables
    >(GetEngagementActivityByWeekdayDocument, variables),
    options
  );
export const GetEngagementActivityByDateDocument = `
    query GetEngagementActivityByDate($customerID: Int!, $fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
  getEngagementActivityByDate(
    customerID: $customerID
    fromDate: $fromDate
    toDate: $toDate
  ) {
    items {
      date
      totalCount
    }
  }
}
    `;
export const useGetEngagementActivityByDateQuery = <
  TData = GetEngagementActivityByDateQuery,
  TError = unknown
>(
  variables: GetEngagementActivityByDateQueryVariables,
  options?: UseQueryOptions<GetEngagementActivityByDateQuery, TError, TData>
) =>
  useQuery<GetEngagementActivityByDateQuery, TError, TData>(
    ["GetEngagementActivityByDate", variables],
    amplifyFetcher<
      GetEngagementActivityByDateQuery,
      GetEngagementActivityByDateQueryVariables
    >(GetEngagementActivityByDateDocument, variables),
    options
  );
export const CheckIfProductExistsDocument = `
    query CheckIfProductExists($customerID: Int!, $productURL: String!) {
  checkIfProductExists(customerID: $customerID, productURL: $productURL) {
    product {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
    storeID
  }
}
    `;
export const useCheckIfProductExistsQuery = <
  TData = CheckIfProductExistsQuery,
  TError = unknown
>(
  variables: CheckIfProductExistsQueryVariables,
  options?: UseQueryOptions<CheckIfProductExistsQuery, TError, TData>
) =>
  useQuery<CheckIfProductExistsQuery, TError, TData>(
    ["CheckIfProductExists", variables],
    amplifyFetcher<
      CheckIfProductExistsQuery,
      CheckIfProductExistsQueryVariables
    >(CheckIfProductExistsDocument, variables),
    options
  );
export const ScrapeProductUrlDocument = `
    query ScrapeProductUrl($productURL: String!) {
  scrapeProductURL(productURL: $productURL) {
    title
    description
    keywords
    brand
    sku
    imageURL
    domainName
    site_name
    url
    imageURLs
  }
}
    `;
export const useScrapeProductUrlQuery = <
  TData = ScrapeProductUrlQuery,
  TError = unknown
>(
  variables: ScrapeProductUrlQueryVariables,
  options?: UseQueryOptions<ScrapeProductUrlQuery, TError, TData>
) =>
  useQuery<ScrapeProductUrlQuery, TError, TData>(
    ["ScrapeProductUrl", variables],
    amplifyFetcher<ScrapeProductUrlQuery, ScrapeProductUrlQueryVariables>(
      ScrapeProductUrlDocument,
      variables
    ),
    options
  );
export const WalmartSearchDocument = `
    query WalmartSearch($query: String!) {
  walmartSearch(query: $query) {
    items {
      id
      parentID
      name
      msrp
      salePrice
      upc
      modelNumber
      description
      category
      productURL
      affiliateURL
      thumbnailImageURL
      imageURL
    }
  }
}
    `;
export const useWalmartSearchQuery = <
  TData = WalmartSearchQuery,
  TError = unknown
>(
  variables: WalmartSearchQueryVariables,
  options?: UseQueryOptions<WalmartSearchQuery, TError, TData>
) =>
  useQuery<WalmartSearchQuery, TError, TData>(
    ["WalmartSearch", variables],
    amplifyFetcher<WalmartSearchQuery, WalmartSearchQueryVariables>(
      WalmartSearchDocument,
      variables
    ),
    options
  );
export const ListStoresDocument = `
    query ListStores($customerID: Int!) {
  listStores(customerID: $customerID) {
    items {
      id
      customerID
      name
      active
      added
      domainName
      searchURL
      productCount
    }
  }
}
    `;
export const useListStoresQuery = <TData = ListStoresQuery, TError = unknown>(
  variables: ListStoresQueryVariables,
  options?: UseQueryOptions<ListStoresQuery, TError, TData>
) =>
  useQuery<ListStoresQuery, TError, TData>(
    ["ListStores", variables],
    amplifyFetcher<ListStoresQuery, ListStoresQueryVariables>(
      ListStoresDocument,
      variables
    ),
    options
  );
export const ListActiveStoresDocument = `
    query ListActiveStores($customerID: Int!) {
  listActiveStores(customerID: $customerID) {
    items {
      id
      customerID
      name
      active
      added
      domainName
      searchURL
      productCount
    }
  }
}
    `;
export const useListActiveStoresQuery = <
  TData = ListActiveStoresQuery,
  TError = unknown
>(
  variables: ListActiveStoresQueryVariables,
  options?: UseQueryOptions<ListActiveStoresQuery, TError, TData>
) =>
  useQuery<ListActiveStoresQuery, TError, TData>(
    ["ListActiveStores", variables],
    amplifyFetcher<ListActiveStoresQuery, ListActiveStoresQueryVariables>(
      ListActiveStoresDocument,
      variables
    ),
    options
  );
export const GetStoreDocument = `
    query GetStore($id: Int!) {
  getStore(id: $id) {
    id
    customerID
    name
    active
    added
    domainName
    searchURL
    productCount
  }
}
    `;
export const useGetStoreQuery = <TData = GetStoreQuery, TError = unknown>(
  variables: GetStoreQueryVariables,
  options?: UseQueryOptions<GetStoreQuery, TError, TData>
) =>
  useQuery<GetStoreQuery, TError, TData>(
    ["GetStore", variables],
    amplifyFetcher<GetStoreQuery, GetStoreQueryVariables>(
      GetStoreDocument,
      variables
    ),
    options
  );
export const ListCategoriesDocument = `
    query ListCategories($customerID: Int!) {
  listCategories(customerID: $customerID) {
    items {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
}
    `;
export const useListCategoriesQuery = <
  TData = ListCategoriesQuery,
  TError = unknown
>(
  variables: ListCategoriesQueryVariables,
  options?: UseQueryOptions<ListCategoriesQuery, TError, TData>
) =>
  useQuery<ListCategoriesQuery, TError, TData>(
    ["ListCategories", variables],
    amplifyFetcher<ListCategoriesQuery, ListCategoriesQueryVariables>(
      ListCategoriesDocument,
      variables
    ),
    options
  );
export const ListActiveCategoriesDocument = `
    query ListActiveCategories($customerID: Int!) {
  listActiveCategories(customerID: $customerID) {
    items {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
}
    `;
export const useListActiveCategoriesQuery = <
  TData = ListActiveCategoriesQuery,
  TError = unknown
>(
  variables: ListActiveCategoriesQueryVariables,
  options?: UseQueryOptions<ListActiveCategoriesQuery, TError, TData>
) =>
  useQuery<ListActiveCategoriesQuery, TError, TData>(
    ["ListActiveCategories", variables],
    amplifyFetcher<
      ListActiveCategoriesQuery,
      ListActiveCategoriesQueryVariables
    >(ListActiveCategoriesDocument, variables),
    options
  );
export const ListActiveCategoriesByShowDocument = `
    query ListActiveCategoriesByShow($showID: Int!) {
  listActiveCategoriesByShow(showID: $showID) {
    items {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
}
    `;
export const useListActiveCategoriesByShowQuery = <
  TData = ListActiveCategoriesByShowQuery,
  TError = unknown
>(
  variables: ListActiveCategoriesByShowQueryVariables,
  options?: UseQueryOptions<ListActiveCategoriesByShowQuery, TError, TData>
) =>
  useQuery<ListActiveCategoriesByShowQuery, TError, TData>(
    ["ListActiveCategoriesByShow", variables],
    amplifyFetcher<
      ListActiveCategoriesByShowQuery,
      ListActiveCategoriesByShowQueryVariables
    >(ListActiveCategoriesByShowDocument, variables),
    options
  );
export const GetCategoryDocument = `
    query GetCategory($id: Int!) {
  getCategory(id: $id) {
    id
    customerID
    name
    image
    imageURL
    imageFileSize
    active
    thumbnail
    thumbnailURL
    thumbnailFileSize
    tag
    description
  }
}
    `;
export const useGetCategoryQuery = <TData = GetCategoryQuery, TError = unknown>(
  variables: GetCategoryQueryVariables,
  options?: UseQueryOptions<GetCategoryQuery, TError, TData>
) =>
  useQuery<GetCategoryQuery, TError, TData>(
    ["GetCategory", variables],
    amplifyFetcher<GetCategoryQuery, GetCategoryQueryVariables>(
      GetCategoryDocument,
      variables
    ),
    options
  );
export const ListBrandsDocument = `
    query ListBrands($customerID: Int!) {
  listBrands(customerID: $customerID) {
    items {
      id
      customerID
      name
      productCount
    }
  }
}
    `;
export const useListBrandsQuery = <TData = ListBrandsQuery, TError = unknown>(
  variables: ListBrandsQueryVariables,
  options?: UseQueryOptions<ListBrandsQuery, TError, TData>
) =>
  useQuery<ListBrandsQuery, TError, TData>(
    ["ListBrands", variables],
    amplifyFetcher<ListBrandsQuery, ListBrandsQueryVariables>(
      ListBrandsDocument,
      variables
    ),
    options
  );
export const GetBrandDocument = `
    query GetBrand($id: Int!) {
  getBrand(id: $id) {
    id
    customerID
    name
    productCount
  }
}
    `;
export const useGetBrandQuery = <TData = GetBrandQuery, TError = unknown>(
  variables: GetBrandQueryVariables,
  options?: UseQueryOptions<GetBrandQuery, TError, TData>
) =>
  useQuery<GetBrandQuery, TError, TData>(
    ["GetBrand", variables],
    amplifyFetcher<GetBrandQuery, GetBrandQueryVariables>(
      GetBrandDocument,
      variables
    ),
    options
  );
export const ListBrandProductsDocument = `
    query ListBrandProducts($id: Int!, $customerID: Int!) {
  listBrandProducts(id: $id, customerID: $customerID) {
    items {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useListBrandProductsQuery = <
  TData = ListBrandProductsQuery,
  TError = unknown
>(
  variables: ListBrandProductsQueryVariables,
  options?: UseQueryOptions<ListBrandProductsQuery, TError, TData>
) =>
  useQuery<ListBrandProductsQuery, TError, TData>(
    ["ListBrandProducts", variables],
    amplifyFetcher<ListBrandProductsQuery, ListBrandProductsQueryVariables>(
      ListBrandProductsDocument,
      variables
    ),
    options
  );
export const ListActiveFontTargetsDocument = `
    query ListActiveFontTargets {
  listActiveFontTargets {
    items {
      id
      styleClassName
      displayName
      description
      active
      added
    }
  }
}
    `;
export const useListActiveFontTargetsQuery = <
  TData = ListActiveFontTargetsQuery,
  TError = unknown
>(
  variables?: ListActiveFontTargetsQueryVariables,
  options?: UseQueryOptions<ListActiveFontTargetsQuery, TError, TData>
) =>
  useQuery<ListActiveFontTargetsQuery, TError, TData>(
    ["ListActiveFontTargets", variables],
    amplifyFetcher<
      ListActiveFontTargetsQuery,
      ListActiveFontTargetsQueryVariables
    >(ListActiveFontTargetsDocument, variables),
    options
  );
export const ListUsersDocument = `
    query ListUsers {
  listUsers {
    items {
      id
      email
      enabled
      status
      created
      email_verified
      given_name
      family_name
      gender
      phone_number
      customerID
      vendorID
    }
  }
}
    `;
export const useListUsersQuery = <TData = ListUsersQuery, TError = unknown>(
  variables?: ListUsersQueryVariables,
  options?: UseQueryOptions<ListUsersQuery, TError, TData>
) =>
  useQuery<ListUsersQuery, TError, TData>(
    ["ListUsers", variables],
    amplifyFetcher<ListUsersQuery, ListUsersQueryVariables>(
      ListUsersDocument,
      variables
    ),
    options
  );
export const ListVoxiAdminsDocument = `
    query ListVoxiAdmins {
  listVoxiAdmins {
    items {
      id
      email
      enabled
      status
      created
      email_verified
      given_name
      family_name
      gender
      phone_number
      customerID
      vendorID
    }
  }
}
    `;
export const useListVoxiAdminsQuery = <
  TData = ListVoxiAdminsQuery,
  TError = unknown
>(
  variables?: ListVoxiAdminsQueryVariables,
  options?: UseQueryOptions<ListVoxiAdminsQuery, TError, TData>
) =>
  useQuery<ListVoxiAdminsQuery, TError, TData>(
    ["ListVoxiAdmins", variables],
    amplifyFetcher<ListVoxiAdminsQuery, ListVoxiAdminsQueryVariables>(
      ListVoxiAdminsDocument,
      variables
    ),
    options
  );
export const GetUserDocument = `
    query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    enabled
    status
    created
    email_verified
    given_name
    family_name
    gender
    phone_number
    customerID
    vendorID
  }
}
    `;
export const useGetUserQuery = <TData = GetUserQuery, TError = unknown>(
  variables: GetUserQueryVariables,
  options?: UseQueryOptions<GetUserQuery, TError, TData>
) =>
  useQuery<GetUserQuery, TError, TData>(
    ["GetUser", variables],
    amplifyFetcher<GetUserQuery, GetUserQueryVariables>(
      GetUserDocument,
      variables
    ),
    options
  );
export const ListEmailReportSettingsDocument = `
    query ListEmailReportSettings {
  listEmailReportSettings {
    items {
      id
      userID
      reportType
      daily
      weekly
      monthly
      yearly
      customerID
      customerName
      showID
      showName
      lastRun
      lastRunTime
    }
  }
}
    `;
export const useListEmailReportSettingsQuery = <
  TData = ListEmailReportSettingsQuery,
  TError = unknown
>(
  variables?: ListEmailReportSettingsQueryVariables,
  options?: UseQueryOptions<ListEmailReportSettingsQuery, TError, TData>
) =>
  useQuery<ListEmailReportSettingsQuery, TError, TData>(
    ["ListEmailReportSettings", variables],
    amplifyFetcher<
      ListEmailReportSettingsQuery,
      ListEmailReportSettingsQueryVariables
    >(ListEmailReportSettingsDocument, variables),
    options
  );
export const TextSearchDocument = `
    query TextSearch($appID: String!, $query: String!) {
  textSearch(appID: $appID, query: $query) {
    items {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useTextSearchQuery = <TData = TextSearchQuery, TError = unknown>(
  variables: TextSearchQueryVariables,
  options?: UseQueryOptions<TextSearchQuery, TError, TData>
) =>
  useQuery<TextSearchQuery, TError, TData>(
    ["TextSearch", variables],
    amplifyFetcher<TextSearchQuery, TextSearchQueryVariables>(
      TextSearchDocument,
      variables
    ),
    options
  );
export const GetShowManifestDocument = `
    query GetShowManifest($appID: String!) {
  getShowManifest(appID: $appID) {
    name
    short_name
    start_url
    display
    background_color
    theme_color
    permissions
    icons {
      sizes
      src
    }
  }
}
    `;
export const useGetShowManifestQuery = <
  TData = GetShowManifestQuery,
  TError = unknown
>(
  variables: GetShowManifestQueryVariables,
  options?: UseQueryOptions<GetShowManifestQuery, TError, TData>
) =>
  useQuery<GetShowManifestQuery, TError, TData>(
    ["GetShowManifest", variables],
    amplifyFetcher<GetShowManifestQuery, GetShowManifestQueryVariables>(
      GetShowManifestDocument,
      variables
    ),
    options
  );
export const GetEpisodeMetaTagsDocument = `
    query GetEpisodeMetaTags($episodeID: Int!) {
  getEpisodeMetaTags(episodeID: $episodeID) {
    episodeID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
  }
}
    `;
export const useGetEpisodeMetaTagsQuery = <
  TData = GetEpisodeMetaTagsQuery,
  TError = unknown
>(
  variables: GetEpisodeMetaTagsQueryVariables,
  options?: UseQueryOptions<GetEpisodeMetaTagsQuery, TError, TData>
) =>
  useQuery<GetEpisodeMetaTagsQuery, TError, TData>(
    ["GetEpisodeMetaTags", variables],
    amplifyFetcher<GetEpisodeMetaTagsQuery, GetEpisodeMetaTagsQueryVariables>(
      GetEpisodeMetaTagsDocument,
      variables
    ),
    options
  );
export const GetProductMetaTagsDocument = `
    query GetProductMetaTags($productID: Int!, $appID: String!) {
  getProductMetaTags(productID: $productID, appID: $appID) {
    productID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
  }
}
    `;
export const useGetProductMetaTagsQuery = <
  TData = GetProductMetaTagsQuery,
  TError = unknown
>(
  variables: GetProductMetaTagsQueryVariables,
  options?: UseQueryOptions<GetProductMetaTagsQuery, TError, TData>
) =>
  useQuery<GetProductMetaTagsQuery, TError, TData>(
    ["GetProductMetaTags", variables],
    amplifyFetcher<GetProductMetaTagsQuery, GetProductMetaTagsQueryVariables>(
      GetProductMetaTagsDocument,
      variables
    ),
    options
  );
export const GetShowFeedDocument = `
    query GetShowFeed($appID: String!) {
  getShowFeed(appID: $appID) {
    showName
    description
    imageURL
    lastUpdated
    providerName
    language
    categories {
      playlistName
      name
      order
    }
    playlists {
      name
      itemIds
    }
    movies {
      id
    }
    series {
      id
    }
    shortFormVideos {
      id
      name
      season
      title
      content {
        dateAdded
        videos {
          url
          quality
          videoType
        }
        duration
        adBreaks
      }
      thumbnail
      shortDescription
      releaseDate
      tags
    }
    tvSpecials {
      id
    }
  }
}
    `;
export const useGetShowFeedQuery = <TData = GetShowFeedQuery, TError = unknown>(
  variables: GetShowFeedQueryVariables,
  options?: UseQueryOptions<GetShowFeedQuery, TError, TData>
) =>
  useQuery<GetShowFeedQuery, TError, TData>(
    ["GetShowFeed", variables],
    amplifyFetcher<GetShowFeedQuery, GetShowFeedQueryVariables>(
      GetShowFeedDocument,
      variables
    ),
    options
  );
export const GetUserWishlistDocument = `
    query GetUserWishlist($userID: ID!, $showID: Int) {
  getUserWishlist(userID: $userID, showID: $showID) {
    items {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
}
    `;
export const useGetUserWishlistQuery = <
  TData = GetUserWishlistQuery,
  TError = unknown
>(
  variables: GetUserWishlistQueryVariables,
  options?: UseQueryOptions<GetUserWishlistQuery, TError, TData>
) =>
  useQuery<GetUserWishlistQuery, TError, TData>(
    ["GetUserWishlist", variables],
    amplifyFetcher<GetUserWishlistQuery, GetUserWishlistQueryVariables>(
      GetUserWishlistDocument,
      variables
    ),
    options
  );
export const ProductInWishlistDocument = `
    query ProductInWishlist($productID: Int!) {
  productInWishlist(productID: $productID)
}
    `;
export const useProductInWishlistQuery = <
  TData = ProductInWishlistQuery,
  TError = unknown
>(
  variables: ProductInWishlistQueryVariables,
  options?: UseQueryOptions<ProductInWishlistQuery, TError, TData>
) =>
  useQuery<ProductInWishlistQuery, TError, TData>(
    ["ProductInWishlist", variables],
    amplifyFetcher<ProductInWishlistQuery, ProductInWishlistQueryVariables>(
      ProductInWishlistDocument,
      variables
    ),
    options
  );
export const GetExclusiveVideoContentDocument = `
    query GetExclusiveVideoContent($id: ID!, $filter: GetExclusiveVideoContentFilterInput) {
  getExclusiveVideoContent(id: $id, filter: $filter) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const useGetExclusiveVideoContentQuery = <
  TData = GetExclusiveVideoContentQuery,
  TError = unknown
>(
  variables: GetExclusiveVideoContentQueryVariables,
  options?: UseQueryOptions<GetExclusiveVideoContentQuery, TError, TData>
) =>
  useQuery<GetExclusiveVideoContentQuery, TError, TData>(
    ["GetExclusiveVideoContent", variables],
    amplifyFetcher<
      GetExclusiveVideoContentQuery,
      GetExclusiveVideoContentQueryVariables
    >(GetExclusiveVideoContentDocument, variables),
    options
  );
export const ListShowSmallBusinessVendorsDocument = `
    query ListShowSmallBusinessVendors($showID: Int!) {
  listShowSmallBusinessVendors(showID: $showID) {
    items {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
}
    `;
export const useListShowSmallBusinessVendorsQuery = <
  TData = ListShowSmallBusinessVendorsQuery,
  TError = unknown
>(
  variables: ListShowSmallBusinessVendorsQueryVariables,
  options?: UseQueryOptions<ListShowSmallBusinessVendorsQuery, TError, TData>
) =>
  useQuery<ListShowSmallBusinessVendorsQuery, TError, TData>(
    ["ListShowSmallBusinessVendors", variables],
    amplifyFetcher<
      ListShowSmallBusinessVendorsQuery,
      ListShowSmallBusinessVendorsQueryVariables
    >(ListShowSmallBusinessVendorsDocument, variables),
    options
  );
export const ListVendorProductsDocument = `
    query ListVendorProducts($vendorID: ID!) {
  listVendorProducts(vendorID: $vendorID) {
    items {
      vendorID
      productID
      owned
      assigned
      unassigned
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
}
    `;
export const useListVendorProductsQuery = <
  TData = ListVendorProductsQuery,
  TError = unknown
>(
  variables: ListVendorProductsQueryVariables,
  options?: UseQueryOptions<ListVendorProductsQuery, TError, TData>
) =>
  useQuery<ListVendorProductsQuery, TError, TData>(
    ["ListVendorProducts", variables],
    amplifyFetcher<ListVendorProductsQuery, ListVendorProductsQueryVariables>(
      ListVendorProductsDocument,
      variables
    ),
    options
  );
export const ListVendorShowProductsDocument = `
    query ListVendorShowProducts($vendorID: ID!, $showID: Int!) {
  listVendorShowProducts(vendorID: $vendorID, showID: $showID) {
    items {
      vendorID
      productID
      owned
      assigned
      unassigned
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
}
    `;
export const useListVendorShowProductsQuery = <
  TData = ListVendorShowProductsQuery,
  TError = unknown
>(
  variables: ListVendorShowProductsQueryVariables,
  options?: UseQueryOptions<ListVendorShowProductsQuery, TError, TData>
) =>
  useQuery<ListVendorShowProductsQuery, TError, TData>(
    ["ListVendorShowProducts", variables],
    amplifyFetcher<
      ListVendorShowProductsQuery,
      ListVendorShowProductsQueryVariables
    >(ListVendorShowProductsDocument, variables),
    options
  );
export const GetNotificationDocument = `
    query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const useGetNotificationQuery = <
  TData = GetNotificationQuery,
  TError = unknown
>(
  variables: GetNotificationQueryVariables,
  options?: UseQueryOptions<GetNotificationQuery, TError, TData>
) =>
  useQuery<GetNotificationQuery, TError, TData>(
    ["GetNotification", variables],
    amplifyFetcher<GetNotificationQuery, GetNotificationQueryVariables>(
      GetNotificationDocument,
      variables
    ),
    options
  );
export const ListNotificationsDocument = `
    query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListNotificationsQuery = <
  TData = ListNotificationsQuery,
  TError = unknown
>(
  variables?: ListNotificationsQueryVariables,
  options?: UseQueryOptions<ListNotificationsQuery, TError, TData>
) =>
  useQuery<ListNotificationsQuery, TError, TData>(
    ["ListNotifications", variables],
    amplifyFetcher<ListNotificationsQuery, ListNotificationsQueryVariables>(
      ListNotificationsDocument,
      variables
    ),
    options
  );
export const GetCubejsDashboardItemDocument = `
    query GetCubejsDashboardItem($id: ID!) {
  getCubejsDashboardItem(id: $id) {
    id
    userID
    layout
    vizState
    name
    createdAt
    updatedAt
  }
}
    `;
export const useGetCubejsDashboardItemQuery = <
  TData = GetCubejsDashboardItemQuery,
  TError = unknown
>(
  variables: GetCubejsDashboardItemQueryVariables,
  options?: UseQueryOptions<GetCubejsDashboardItemQuery, TError, TData>
) =>
  useQuery<GetCubejsDashboardItemQuery, TError, TData>(
    ["GetCubejsDashboardItem", variables],
    amplifyFetcher<
      GetCubejsDashboardItemQuery,
      GetCubejsDashboardItemQueryVariables
    >(GetCubejsDashboardItemDocument, variables),
    options
  );
export const ListCubejsDashboardItemsDocument = `
    query ListCubejsDashboardItems($filter: ModelCubejsDashboardItemFilterInput, $limit: Int, $nextToken: String) {
  listCubejsDashboardItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      layout
      vizState
      name
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListCubejsDashboardItemsQuery = <
  TData = ListCubejsDashboardItemsQuery,
  TError = unknown
>(
  variables?: ListCubejsDashboardItemsQueryVariables,
  options?: UseQueryOptions<ListCubejsDashboardItemsQuery, TError, TData>
) =>
  useQuery<ListCubejsDashboardItemsQuery, TError, TData>(
    ["ListCubejsDashboardItems", variables],
    amplifyFetcher<
      ListCubejsDashboardItemsQuery,
      ListCubejsDashboardItemsQueryVariables
    >(ListCubejsDashboardItemsDocument, variables),
    options
  );
export const ListUserDashboardItemsDocument = `
    query ListUserDashboardItems($userID: ID, $sortDirection: ModelSortDirection, $filter: ModelCubejsDashboardItemFilterInput, $limit: Int, $nextToken: String) {
  listUserDashboardItems(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      layout
      vizState
      name
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListUserDashboardItemsQuery = <
  TData = ListUserDashboardItemsQuery,
  TError = unknown
>(
  variables?: ListUserDashboardItemsQueryVariables,
  options?: UseQueryOptions<ListUserDashboardItemsQuery, TError, TData>
) =>
  useQuery<ListUserDashboardItemsQuery, TError, TData>(
    ["ListUserDashboardItems", variables],
    amplifyFetcher<
      ListUserDashboardItemsQuery,
      ListUserDashboardItemsQueryVariables
    >(ListUserDashboardItemsDocument, variables),
    options
  );
export const ListExclusiveVideoContentsDocument = `
    query ListExclusiveVideoContents($filter: ModelExclusiveVideoContentFilterInput, $limit: Int, $nextToken: String) {
  listExclusiveVideoContents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListExclusiveVideoContentsQuery = <
  TData = ListExclusiveVideoContentsQuery,
  TError = unknown
>(
  variables?: ListExclusiveVideoContentsQueryVariables,
  options?: UseQueryOptions<ListExclusiveVideoContentsQuery, TError, TData>
) =>
  useQuery<ListExclusiveVideoContentsQuery, TError, TData>(
    ["ListExclusiveVideoContents", variables],
    amplifyFetcher<
      ListExclusiveVideoContentsQuery,
      ListExclusiveVideoContentsQueryVariables
    >(ListExclusiveVideoContentsDocument, variables),
    options
  );
export const ListExclusiveContentsByCustomerDocument = `
    query ListExclusiveContentsByCustomer($legacyCustomerID: Int, $sortDirection: ModelSortDirection, $filter: ModelExclusiveVideoContentFilterInput, $limit: Int, $nextToken: String) {
  listExclusiveContentsByCustomer(
    legacyCustomerID: $legacyCustomerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListExclusiveContentsByCustomerQuery = <
  TData = ListExclusiveContentsByCustomerQuery,
  TError = unknown
>(
  variables?: ListExclusiveContentsByCustomerQueryVariables,
  options?: UseQueryOptions<ListExclusiveContentsByCustomerQuery, TError, TData>
) =>
  useQuery<ListExclusiveContentsByCustomerQuery, TError, TData>(
    ["ListExclusiveContentsByCustomer", variables],
    amplifyFetcher<
      ListExclusiveContentsByCustomerQuery,
      ListExclusiveContentsByCustomerQueryVariables
    >(ListExclusiveContentsByCustomerDocument, variables),
    options
  );
export const ListExclusiveContentsByShowDocument = `
    query ListExclusiveContentsByShow($legacyShowID: Int, $sortDirection: ModelSortDirection, $filter: ModelExclusiveVideoContentFilterInput, $limit: Int, $nextToken: String) {
  listExclusiveContentsByShow(
    legacyShowID: $legacyShowID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListExclusiveContentsByShowQuery = <
  TData = ListExclusiveContentsByShowQuery,
  TError = unknown
>(
  variables?: ListExclusiveContentsByShowQueryVariables,
  options?: UseQueryOptions<ListExclusiveContentsByShowQuery, TError, TData>
) =>
  useQuery<ListExclusiveContentsByShowQuery, TError, TData>(
    ["ListExclusiveContentsByShow", variables],
    amplifyFetcher<
      ListExclusiveContentsByShowQuery,
      ListExclusiveContentsByShowQueryVariables
    >(ListExclusiveContentsByShowDocument, variables),
    options
  );
export const GetShowMetaTagsDocument = `
    query GetShowMetaTags($appID: ID!) {
  getShowMetaTags(appID: $appID) {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const useGetShowMetaTagsQuery = <
  TData = GetShowMetaTagsQuery,
  TError = unknown
>(
  variables: GetShowMetaTagsQueryVariables,
  options?: UseQueryOptions<GetShowMetaTagsQuery, TError, TData>
) =>
  useQuery<GetShowMetaTagsQuery, TError, TData>(
    ["GetShowMetaTags", variables],
    amplifyFetcher<GetShowMetaTagsQuery, GetShowMetaTagsQueryVariables>(
      GetShowMetaTagsDocument,
      variables
    ),
    options
  );
export const ListShowMetaTagssDocument = `
    query ListShowMetaTagss($appID: ID, $filter: ModelShowMetaTagsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
  listShowMetaTagss(
    appID: $appID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListShowMetaTagssQuery = <
  TData = ListShowMetaTagssQuery,
  TError = unknown
>(
  variables?: ListShowMetaTagssQueryVariables,
  options?: UseQueryOptions<ListShowMetaTagssQuery, TError, TData>
) =>
  useQuery<ListShowMetaTagssQuery, TError, TData>(
    ["ListShowMetaTagss", variables],
    amplifyFetcher<ListShowMetaTagssQuery, ListShowMetaTagssQueryVariables>(
      ListShowMetaTagssDocument,
      variables
    ),
    options
  );
export const GetVendorDocument = `
    query GetVendor($id: ID!) {
  getVendor(id: $id) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const useGetVendorQuery = <TData = GetVendorQuery, TError = unknown>(
  variables: GetVendorQueryVariables,
  options?: UseQueryOptions<GetVendorQuery, TError, TData>
) =>
  useQuery<GetVendorQuery, TError, TData>(
    ["GetVendor", variables],
    amplifyFetcher<GetVendorQuery, GetVendorQueryVariables>(
      GetVendorDocument,
      variables
    ),
    options
  );
export const ListVendorsDocument = `
    query ListVendors($filter: ModelVendorFilterInput, $limit: Int, $nextToken: String) {
  listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListVendorsQuery = <TData = ListVendorsQuery, TError = unknown>(
  variables?: ListVendorsQueryVariables,
  options?: UseQueryOptions<ListVendorsQuery, TError, TData>
) =>
  useQuery<ListVendorsQuery, TError, TData>(
    ["ListVendors", variables],
    amplifyFetcher<ListVendorsQuery, ListVendorsQueryVariables>(
      ListVendorsDocument,
      variables
    ),
    options
  );
export const ListVendorsByTypeDocument = `
    query ListVendorsByType($type: VendorBusinessType, $sortDirection: ModelSortDirection, $filter: ModelVendorFilterInput, $limit: Int, $nextToken: String) {
  listVendorsByType(
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListVendorsByTypeQuery = <
  TData = ListVendorsByTypeQuery,
  TError = unknown
>(
  variables?: ListVendorsByTypeQueryVariables,
  options?: UseQueryOptions<ListVendorsByTypeQuery, TError, TData>
) =>
  useQuery<ListVendorsByTypeQuery, TError, TData>(
    ["ListVendorsByType", variables],
    amplifyFetcher<ListVendorsByTypeQuery, ListVendorsByTypeQueryVariables>(
      ListVendorsByTypeDocument,
      variables
    ),
    options
  );
export const GetShowFontV2Document = `
    query GetShowFontV2($id: ID!) {
  getShowFontV2(id: $id) {
    id
    showFontLocationComposite
    showID
    family
    location
    weight
    style
    createdAt
    updatedAt
  }
}
    `;
export const useGetShowFontV2Query = <
  TData = GetShowFontV2Query,
  TError = unknown
>(
  variables: GetShowFontV2QueryVariables,
  options?: UseQueryOptions<GetShowFontV2Query, TError, TData>
) =>
  useQuery<GetShowFontV2Query, TError, TData>(
    ["GetShowFontV2", variables],
    amplifyFetcher<GetShowFontV2Query, GetShowFontV2QueryVariables>(
      GetShowFontV2Document,
      variables
    ),
    options
  );
export const ListShowFontV2sDocument = `
    query ListShowFontV2s($filter: ModelShowFontV2FilterInput, $limit: Int, $nextToken: String) {
  listShowFontV2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      showFontLocationComposite
      showID
      family
      location
      weight
      style
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListShowFontV2sQuery = <
  TData = ListShowFontV2sQuery,
  TError = unknown
>(
  variables?: ListShowFontV2sQueryVariables,
  options?: UseQueryOptions<ListShowFontV2sQuery, TError, TData>
) =>
  useQuery<ListShowFontV2sQuery, TError, TData>(
    ["ListShowFontV2s", variables],
    amplifyFetcher<ListShowFontV2sQuery, ListShowFontV2sQueryVariables>(
      ListShowFontV2sDocument,
      variables
    ),
    options
  );
export const ListFontsByShowDocument = `
    query ListFontsByShow($showID: Int, $sortDirection: ModelSortDirection, $filter: ModelShowFontV2FilterInput, $limit: Int, $nextToken: String) {
  listFontsByShow(
    showID: $showID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      showFontLocationComposite
      showID
      family
      location
      weight
      style
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListFontsByShowQuery = <
  TData = ListFontsByShowQuery,
  TError = unknown
>(
  variables?: ListFontsByShowQueryVariables,
  options?: UseQueryOptions<ListFontsByShowQuery, TError, TData>
) =>
  useQuery<ListFontsByShowQuery, TError, TData>(
    ["ListFontsByShow", variables],
    amplifyFetcher<ListFontsByShowQuery, ListFontsByShowQueryVariables>(
      ListFontsByShowDocument,
      variables
    ),
    options
  );
export const GetBannerAdDocument = `
    query GetBannerAd($id: ID!) {
  getBannerAd(id: $id) {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const useGetBannerAdQuery = <TData = GetBannerAdQuery, TError = unknown>(
  variables: GetBannerAdQueryVariables,
  options?: UseQueryOptions<GetBannerAdQuery, TError, TData>
) =>
  useQuery<GetBannerAdQuery, TError, TData>(
    ["GetBannerAd", variables],
    amplifyFetcher<GetBannerAdQuery, GetBannerAdQueryVariables>(
      GetBannerAdDocument,
      variables
    ),
    options
  );
export const ListBannerAdsDocument = `
    query ListBannerAds($filter: ModelBannerAdFilterInput, $limit: Int, $nextToken: String) {
  listBannerAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customerID
      showID
      episodeID
      imageBucket
      imageKey
      url
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListBannerAdsQuery = <
  TData = ListBannerAdsQuery,
  TError = unknown
>(
  variables?: ListBannerAdsQueryVariables,
  options?: UseQueryOptions<ListBannerAdsQuery, TError, TData>
) =>
  useQuery<ListBannerAdsQuery, TError, TData>(
    ["ListBannerAds", variables],
    amplifyFetcher<ListBannerAdsQuery, ListBannerAdsQueryVariables>(
      ListBannerAdsDocument,
      variables
    ),
    options
  );
export const ListBannerAdsByShowDocument = `
    query ListBannerAdsByShow($showID: Int, $sortDirection: ModelSortDirection, $filter: ModelBannerAdFilterInput, $limit: Int, $nextToken: String) {
  listBannerAdsByShow(
    showID: $showID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customerID
      showID
      episodeID
      imageBucket
      imageKey
      url
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListBannerAdsByShowQuery = <
  TData = ListBannerAdsByShowQuery,
  TError = unknown
>(
  variables?: ListBannerAdsByShowQueryVariables,
  options?: UseQueryOptions<ListBannerAdsByShowQuery, TError, TData>
) =>
  useQuery<ListBannerAdsByShowQuery, TError, TData>(
    ["ListBannerAdsByShow", variables],
    amplifyFetcher<ListBannerAdsByShowQuery, ListBannerAdsByShowQueryVariables>(
      ListBannerAdsByShowDocument,
      variables
    ),
    options
  );
export const ListBannerAdsByEpisodeDocument = `
    query ListBannerAdsByEpisode($episodeID: Int, $sortDirection: ModelSortDirection, $filter: ModelBannerAdFilterInput, $limit: Int, $nextToken: String) {
  listBannerAdsByEpisode(
    episodeID: $episodeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customerID
      showID
      episodeID
      imageBucket
      imageKey
      url
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListBannerAdsByEpisodeQuery = <
  TData = ListBannerAdsByEpisodeQuery,
  TError = unknown
>(
  variables?: ListBannerAdsByEpisodeQueryVariables,
  options?: UseQueryOptions<ListBannerAdsByEpisodeQuery, TError, TData>
) =>
  useQuery<ListBannerAdsByEpisodeQuery, TError, TData>(
    ["ListBannerAdsByEpisode", variables],
    amplifyFetcher<
      ListBannerAdsByEpisodeQuery,
      ListBannerAdsByEpisodeQueryVariables
    >(ListBannerAdsByEpisodeDocument, variables),
    options
  );
export const GetDigitalAssetLinksDocument = `
    query GetDigitalAssetLinks($appID: String!) {
  getDigitalAssetLinks(appID: $appID) {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
export const useGetDigitalAssetLinksQuery = <
  TData = GetDigitalAssetLinksQuery,
  TError = unknown
>(
  variables: GetDigitalAssetLinksQueryVariables,
  options?: UseQueryOptions<GetDigitalAssetLinksQuery, TError, TData>
) =>
  useQuery<GetDigitalAssetLinksQuery, TError, TData>(
    ["GetDigitalAssetLinks", variables],
    amplifyFetcher<
      GetDigitalAssetLinksQuery,
      GetDigitalAssetLinksQueryVariables
    >(GetDigitalAssetLinksDocument, variables),
    options
  );
export const ListDigitalAssetLinkssDocument = `
    query ListDigitalAssetLinkss($appID: String, $filter: ModelDigitalAssetLinksFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
  listDigitalAssetLinkss(
    appID: $appID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
    nextToken
  }
}
    `;
export const useListDigitalAssetLinkssQuery = <
  TData = ListDigitalAssetLinkssQuery,
  TError = unknown
>(
  variables?: ListDigitalAssetLinkssQueryVariables,
  options?: UseQueryOptions<ListDigitalAssetLinkssQuery, TError, TData>
) =>
  useQuery<ListDigitalAssetLinkssQuery, TError, TData>(
    ["ListDigitalAssetLinkss", variables],
    amplifyFetcher<
      ListDigitalAssetLinkssQuery,
      ListDigitalAssetLinkssQueryVariables
    >(ListDigitalAssetLinkssDocument, variables),
    options
  );
export const OnCreateCustomerDocument = `
    subscription OnCreateCustomer {
  onCreateCustomer {
    id
    shortName
    fullName
    active
    added
    adminType
    isVendor
    emailAddressIdentity
    streamingContent
    displayShowReports
    displayEpisodeReports
  }
}
    `;
export const OnCreateShowDocument = `
    subscription OnCreateShow($customerID: Int!) {
  onCreateShow(customerID: $customerID) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    hideInAdmin
    appID
    mainMenuType
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    channel {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
    demographicConfiguration {
      introText
      ageEnabled
      genderEnabled
      ethnicityEnabled
      educationEnabled
      employmentEnabled
      maritalStatusEnabled
      incomeEnabled
      zipcodeEnabled
      emailEnabled
    }
    productRequestConfiguration {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const OnUpdateShowDocument = `
    subscription OnUpdateShow($id: Int!) {
  onUpdateShow(id: $id) {
    id
    customerID
    nameShort
    nameLong
    description
    poster
    posterURL
    added
    active
    hideInAdmin
    appID
    mainMenuType
    faqContent
    faviconsURL
    appData {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
    }
    channel {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
    demographicConfiguration {
      introText
      ageEnabled
      genderEnabled
      ethnicityEnabled
      educationEnabled
      employmentEnabled
      maritalStatusEnabled
      incomeEnabled
      zipcodeEnabled
      emailEnabled
    }
    productRequestConfiguration {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
}
    `;
export const OnCreateEpisodeDocument = `
    subscription OnCreateEpisode($showID: Int!) {
  onCreateEpisode(showID: $showID) {
    id
    showID
    showName
    season
    seasonName
    number
    firstRelease
    added
    name
    description
    disclaimer
    enabled
    posterType
    poster
    posterURL
    posterFileID
    video
    videoBucket
    videoKey
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    audioFingerprintID
    duration
    rokuAddBreaks
    releaseToOTT
    releaseToApp
    liveChannelID
    enableHeaderButton
    headerButtonText
    headerButtonURL
    disableProductScroller
    liveStreamID
    liveStreamEnabledOnStartMenuID
    videoToken
  }
}
    `;
export const OnUpdateShowEpisodeDocument = `
    subscription OnUpdateShowEpisode($showID: Int!) {
  onUpdateShowEpisode(showID: $showID) {
    id
    showID
    showName
    season
    seasonName
    number
    firstRelease
    added
    name
    description
    disclaimer
    enabled
    posterType
    poster
    posterURL
    posterFileID
    video
    videoBucket
    videoKey
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    audioFingerprintID
    duration
    rokuAddBreaks
    releaseToOTT
    releaseToApp
    liveChannelID
    enableHeaderButton
    headerButtonText
    headerButtonURL
    disableProductScroller
    liveStreamID
    liveStreamEnabledOnStartMenuID
    videoToken
  }
}
    `;
export const OnUpdateEpisodeDocument = `
    subscription OnUpdateEpisode($id: Int!) {
  onUpdateEpisode(id: $id) {
    id
    showID
    showName
    season
    seasonName
    number
    firstRelease
    added
    name
    description
    disclaimer
    enabled
    posterType
    poster
    posterURL
    posterFileID
    video
    videoBucket
    videoKey
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    audioFingerprintID
    duration
    rokuAddBreaks
    releaseToOTT
    releaseToApp
    liveChannelID
    enableHeaderButton
    headerButtonText
    headerButtonURL
    disableProductScroller
    liveStreamID
    liveStreamEnabledOnStartMenuID
    videoToken
  }
}
    `;
export const OnCreateEpisodePhotoDocument = `
    subscription OnCreateEpisodePhoto($episodeID: Int!) {
  onCreateEpisodePhoto(episodeID: $episodeID) {
    id
    episodeID
    groupID
    groupName
    order
    title
    description
    fileID
    extension
    url
    thumbnailURL
    enabled
    added
  }
}
    `;
export const OnUpdateProductDocument = `
    subscription OnUpdateProduct($id: Int!) {
  onUpdateProduct(id: $id) {
    id
    customerID
    name
    description
    upc
    brand
    brandID
    sku
    active
    added
    deleted
    approved
    replacementProduct
    mainPhotoURL
    mainPhotoFileID
    mainPhotoFileExt
    mainPhotoSize
    issueCount
    photoBucket
    photoKey
    warnings
    originalProductName
    originalProductDescription
    originalProductURL
    originalProductPhotoBucket
    originalProductPhotoKey
    vendorID
    vendorLogo
    vendorLogoBucket
    vendorLogoKey
  }
}
    `;
export const OnUpdateCategoryDocument = `
    subscription OnUpdateCategory($id: Int!, $customerID: Int!) {
  onUpdateCategory(id: $id, customerID: $customerID) {
    id
    customerID
    name
    image
    imageURL
    imageFileSize
    active
    thumbnail
    thumbnailURL
    thumbnailFileSize
    tag
    description
  }
}
    `;
export const OnResolveProductsReportDocument = `
    subscription OnResolveProductsReport($id: ID!) {
  onResolveProductsReport(id: $id) {
    id
    items {
      id
      name
      brandName
      upc
      sku
      mainPhotoURL
      mainPhotoFileId
      mainPhotoFileExt
      active
      deleted
      added
      imagePreviews
      detailViews
      clicks
    }
    nextToken
  }
}
    `;
export const OnResolveVendorProductsReportDocument = `
    subscription OnResolveVendorProductsReport($id: ID!) {
  onResolveVendorProductsReport(id: $id) {
    id
    items {
      id
      name
      brandName
      upc
      sku
      mainPhotoURL
      mainPhotoFileId
      mainPhotoFileExt
      active
      deleted
      added
      imagePreviews
      detailViews
      clicks
    }
    nextToken
  }
}
    `;
export const OnCreateNotificationDocument = `
    subscription OnCreateNotification($userID: String!) {
  onCreateNotification(userID: $userID) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateNotificationDocument = `
    subscription OnUpdateNotification($userID: String!) {
  onUpdateNotification(userID: $userID) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteNotificationDocument = `
    subscription OnDeleteNotification($userID: String!) {
  onDeleteNotification(userID: $userID) {
    id
    userID
    description
    time
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateExclusiveVideoContentDocument = `
    subscription OnCreateExclusiveVideoContent($legacyCustomerIDString: String) {
  onCreateExclusiveVideoContent(legacyCustomerIDString: $legacyCustomerIDString) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateExclusiveVideoContentDocument = `
    subscription OnUpdateExclusiveVideoContent($legacyCustomerIDString: String) {
  onUpdateExclusiveVideoContent(legacyCustomerIDString: $legacyCustomerIDString) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteExclusiveVideoContentDocument = `
    subscription OnDeleteExclusiveVideoContent($legacyCustomerIDString: String) {
  onDeleteExclusiveVideoContent(legacyCustomerIDString: $legacyCustomerIDString) {
    id
    legacyCustomerID
    legacyCustomerIDString
    legacyShowID
    name
    description
    enabled
    firstRelease
    posterBucket
    posterKey
    videoBucket
    videoKey
    videoURL
    videoQuality
    videoType
    videoWidth
    videoHeight
    mediaConvertJobID
    mediaConvertJobStatus
    duration
    releaseToOTT
    releaseToApp
    videoToken
    disableProductScroller
    productDefinitions {
      productID
      startTimeMilliseconds
      endTimeMilliseconds
    }
    videoProducts {
      brandName
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      startTimeMilliseconds
      endTimeMilliseconds
    }
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateShowMetaTagsDocument = `
    subscription OnCreateShowMetaTags {
  onCreateShowMetaTags {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateShowMetaTagsDocument = `
    subscription OnUpdateShowMetaTags {
  onUpdateShowMetaTags {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteShowMetaTagsDocument = `
    subscription OnDeleteShowMetaTags {
  onDeleteShowMetaTags {
    appID
    showID
    OG_TITLE
    OG_DESCRIPTION
    OG_IMAGE
    FAVICON
    MANIFEST
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateVendorDocument = `
    subscription OnCreateVendor($id: String) {
  onCreateVendor(id: $id) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateVendorDocument = `
    subscription OnUpdateVendor($id: String) {
  onUpdateVendor(id: $id) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteVendorDocument = `
    subscription OnDeleteVendor($id: String) {
  onDeleteVendor(id: $id) {
    id
    name
    type
    active
    salesOwnedByUserID
    balance
    balanceUpdated
    logo
    logoFileID
    logoFileExtension
    logoBucket
    logoKey
    pixelTrackingID
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateBannerAdDocument = `
    subscription OnCreateBannerAd {
  onCreateBannerAd {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateBannerAdDocument = `
    subscription OnUpdateBannerAd {
  onUpdateBannerAd {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteBannerAdDocument = `
    subscription OnDeleteBannerAd {
  onDeleteBannerAd {
    id
    customerID
    showID
    episodeID
    imageBucket
    imageKey
    url
    createdAt
    updatedAt
  }
}
    `;
export const OnCreateDigitalAssetLinksDocument = `
    subscription OnCreateDigitalAssetLinks {
  onCreateDigitalAssetLinks {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
export const OnUpdateDigitalAssetLinksDocument = `
    subscription OnUpdateDigitalAssetLinks {
  onUpdateDigitalAssetLinks {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
export const OnDeleteDigitalAssetLinksDocument = `
    subscription OnDeleteDigitalAssetLinks {
  onDeleteDigitalAssetLinks {
    appID
    legacyShowID
    legacyCustomerID
    android
    createdAt
    updatedAt
  }
}
    `;
