import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.voxi.secondaryColor
    },
    requestInfoText: {
        color: theme.palette.voxi.accentColor,
        fontFamily: theme.fonts.CARD_INFO.fontFamily,
        // fontWeight: theme.fonts.CARD_INFO.fontWeight,
        // fontStyle: theme.fonts.CARD_INFO.fontStyle
    },
    button: {
        alignSelf: 'center',
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor,
        '&:hover': {
            backgroundColor: theme.palette.voxi.productBut2BackColor,
            opacity: 0.7
        }
    }
}));

export function ProductRequestCard(props) {
    const classes = useStyles();
    const history = useHistory();
    const [requestInfoText] = useState("Don't see the product you are looking for? Please submit a request for more information and we can help you find it!");

    const requestProductInfo = () => {
        let queryString = props.episodeID ? `episodeID=${props.episodeID}` : '';
        history.push(`/productrequest?${queryString}`);

        // history.push({pathname: props.location.state.prevPath, state: props.location.state});
    }

    return (
        <Grid className={classes.root} item container spacing={2} direction="column">
            <Grid item>
                <Typography className={classes.requestInfoText}>{requestInfoText}</Typography>
            </Grid>
            <Grid item>
                <Button className={classes.button} onClick={requestProductInfo}>
                    Request Product Information
              </Button>
            </Grid>
        </Grid>
    );
}

export interface ProductRequestCardProps {
    episodeID?: number
}