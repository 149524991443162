import React, { useState } from 'react';
import { Grid, Button, TextField, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProductCard } from '../shared/ProductCard';

import { Product } from '../../lib/api';
import API from '../../lib/manualApi';
const api = API.getInstance();

const useStyles = makeStyles(theme => ({
    emptyResponseContainer: {
        justifyContent: "center",
    },
    emptyResponseText : {
        padding: theme.spacing(2)
    }
}));

export default function TextSearch(props: TextSearchProps) {
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');
    const [products, setProducts] = useState<Array<Product>>([]);
    const [searching, setSearching] = useState(false);
    const [searchExecuted, setSearchExecuted] = useState(false);

    const search = async () => {
        setSearchExecuted(false);
        const appID = localStorage.getItem('voxiAppID');
        // TODO: Display an error?
        if (!appID) return;

        let queryArgs = {
            appID: appID,
            query: searchText
        }

        try {
            setSearching(true);
            const productSearchResult = await api.textSearch(queryArgs);
            setProducts(productSearchResult);
        } catch (err) {
            console.error(err);
            setProducts([]);
        }

        setSearching(false);
        setSearchText('');

        setSearchExecuted(true);
    }

    return (
        <Grid item container spacing={2} direction="column" alignItems="center">
            <Grid item>
                <TextField label="Search"
                    placeholder="Type to search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: 300 }}
                    onKeyPress={e => {
                        if (e.key === "Enter"){
                            search();
                        }
                    }}
                />
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={search}>
                    Search
                </Button>
            </Grid>
            {
                searching &&
                <Grid item>
                    <CircularProgress />
                </Grid>
            }
            <Grid item container spacing={2} alignItems="center" alignContent="center">
                {
                    products.map(product => {
                        return (
                            <Grid item container key={product.id} xs={12} sm={12} lg={6} justify="center">
                                <ProductCard key={product.id} product={product} />
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid item container spacing={2} className={classes.emptyResponseContainer}>
                {
                (searchExecuted === true && products.length < 1) ? (
                <Typography variant="h4" component="p" className={classes.emptyResponseText}>Sorry, no results found. Please try again</Typography>
                ): null
                }
            </Grid>
        </Grid>
    )
}

export interface TextSearchProps {
    showID: number
}