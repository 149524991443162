import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    disclaimer: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        margin: '20px 15% 20px 15%',
        padding: '20px',
        borderRadius: '5px',
        color: theme.palette.voxi.accentColor,
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            margin: '10px 2% 10px 2%',
            fontSize: theme.spacing(1.5),
            padding: theme.spacing(1.2)
        }
    },
}));

export function EpisodeDisclaimer(props: EpisodeDisclaimerProps) {
    const classes = useStyles();
    return (
        <Typography variant='subtitle1' className={classes.disclaimer}>
            {props.disclaimer}
        </Typography>
    )
}

export interface EpisodeDisclaimerProps {
    disclaimer: string
}