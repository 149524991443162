import React, { useState, useEffect } from 'react';
import { Route, Link, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AppBar, Avatar, Button, Drawer, Hidden, IconButton, Menu, MenuItem, Toolbar, List, ListItem, ListItemIcon, ListItemText, Typography, Grid } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { Authenticator } from '../authentication';

import HelpIcon from '@material-ui/icons/HelpOutline';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';

import { Home } from '../home/Home';
import { Episode, Episodes } from '../episodes';
import { Exclusives, ExclusiveVideo } from '../exclusives';

import { Category, Product, ProductsList, ProductRequest } from '../products';
import { SmallBusinessList, Vendor } from '../vendors';
import { BackToTopButton } from '../features/BackToTopButton';
import { PoweredByVoxiIcon } from '../shared/PoweredByVoxiIcon';
import { SnackbarProvider } from '../shared/SnackbarProvider';
import EpisodesStateProvider from '../episodes/EpisodesStateProvider';

import Demo from '../demo/Demo';
import Profile from '../profile/Profile';
import TextSearch from '../search/TextSearch';
import PrivacyPolicy from '../privacy/PrivacyPolicy';
import FAQ from '../faq/FAQ';
import Facebook from '../social/Facebook';
import Wishlist from '../wishlist/Wishlist';

import { Maybe, ShowDesignMenuItem, ShowAppData, Show } from '../../lib/api';
import API from '../../lib/manualApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup, IconDefinition, findIconDefinition, library } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, fab, far);

const api = API.getInstance();
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.voxi.primaryColor
    },
    drawer: {
        height: "100vh"
    },
    appBar: {
        backgroundColor: theme.palette.voxi.headBackgroundColor
    },
    menuButton: {
        color: theme.palette.voxi.headTextColor,
        marginRight: theme.spacing(0)
    },
    appToolbar: {
        backgroundColor: theme.palette.voxi.headBackgroundColor,
    },
    appbarActionContainer: {
        display: 'flex',
        position: 'absolute',
        right: 0,
        alignItems: 'center'
    },
    toolbar: {
        textAlign: 'center',
        ...theme.mixins.toolbar,
    },
    showPoster: {
        maxWidth: 390,
        [theme.breakpoints.down("sm")]: {
            width: '55%'
        },
        maxHeight: 60,
        width: 'auto'
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    appLogoImage: {
        width: drawerWidth
    },
    headText: {
        fontSize: '3.5vh',
        color: theme.palette.voxi.headTextColor,
        fontFamily: theme.fonts.APP_HEADER.fontFamily,
        fontWeight: theme.fonts.APP_HEADER.fontWeight,
        fontStyle: theme.fonts.APP_HEADER.fontStyle
    },
    menuIcon: {
        color: theme.palette.voxi.menuTextColor
    },
    menuText: {
        color: theme.palette.voxi.menuTextColor
    },
    avatarMenuButtonItem: {
        color: 'black',
        fontFamily: theme.fonts.BUTTON_TEXT.fontFamily
    },
    signin: {
        color: theme.palette.voxi.headTextColor,
        fontSize: '10px',
        textAlign: 'center',
        textDecoration: 'none',
    },
    avatarButton: {
        width: '50px',
        height: '50px',
        paddingLeft: '40px'
    },
    helpIcon: {
        color: theme.palette.voxi.headTextColor,
        padding: '12px',
        display: 'flex',
        alignItems: 'center'
    },
    version: {
        color: theme.palette.voxi.menuTextColor
    },
    highlightedListItem: {
        filter: 'brightness(85%)',
        backgroundColor: theme.palette.voxi.secondaryColor
    },
    appBarOffset: {
        ...theme.mixins.toolbar,
        flexGrow: 1,
        marginBottom: theme.spacing(1),
        zIndex: -1000,
        opacity: 0
    },
    poweredByVoxi: {
        width: '100px'
    }
}));

function ResponsiveDrawer(props: ResponsiveDrawerProps) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    let location = useLocation();
    const [user, setUser] = useState<any>(props.user);
    const [cookies, setCookie] = useCookies(['voxiCookie']);
    const [anchorEl, setAnchorEl] = useState(null);
    const [helpOpen, setHelpOpen] = useState(false);
    const [userInitials, setUserInitials] = useState('');
    const [mobileOpen, setMobileOpen] = useState(false);
    const [show, setShow] = useState<Maybe<Show>>(null);
    const [appData, setAppData] = useState<Maybe<ShowAppData>>(null);
    const [menuItems, setMenuItems] = useState<Array<ShowDesignMenuItem>>([]);
    const [posterURL, setPosterURL] = useState('');

    useEffect(() => {
        if (!props.user) return;

        setUser(props.user);

        let initials = '';
        if (props.user?.attributes?.given_name) {
            initials += props.user.attributes.given_name[0];
        }
        if (props.user?.attributes?.family_name) {
            initials += props.user.attributes.family_name[0];
        }
        setUserInitials(initials);
    }, [props.user])

    useEffect(() => {
        if (!props.show) return;

        setShow(props.show);
        setPosterURL(props.show.posterURL || '');

        const showAppData = props.show.appData;
        setAppData(showAppData)

        const fetchShowDesignMenu = async () => {
            const showDesignMenuResult = await api.getShowDesignMenu({ showID: props.show!.id });
            setMenuItems(showDesignMenuResult)
        }

        const checkCookieStuff = async () => {
            const cookieExpiration = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

            if (!cookies.voxiCookie) {
                const input = {
                    appID: localStorage.getItem('voxiAppID') || '',
                }
                const appInstallID = await api.createAppInstall(input);
                localStorage.setItem('appInstallID', appInstallID?.toString() || '');
                setCookie('voxiCookie', `appInstallID=${appInstallID}`, {
                    expires: cookieExpiration,
                    path: '/',
                    encode: (value) => { return value }
                })
            } else {
                const appInstallID = cookies.voxiCookie.toLowerCase().split('=')[1];
                const updateResult = await api.updateAppInstall({ id: appInstallID });
                if (!updateResult) return;

                localStorage.setItem('appInstallID', appInstallID?.toString() || '');
                setCookie('voxiCookie', `appInstallID=${appInstallID}`, {
                    expires: cookieExpiration,
                    path: '/',
                    encode: (value) => { return value }
                })
            }
        }

        fetchShowDesignMenu();
        checkCookieStuff();
    }, [props.show])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onNavigateClick = () => {
        setMobileOpen(false);
    }

    const onBackClick = () => {
        if (location.pathname === '/') return;
        history.goBack();
    }

    const onAvatarClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        setAnchorEl(null);
        await Auth.signOut();
        history.push('/signin');
    };

    const onHelpClick = () => {
        setHelpOpen(true);
    }

    const onHelpClose = () => {
        setHelpOpen(false);
    }

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <img className={classes.appLogoImage} src={appData?.appLogoURL || ''} alt="app logo" />
            </div>
            <List style={{ alignItems: 'center', alignContent: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                <ListItem button component={Link} to="/" onClick={onNavigateClick} className={`${window.location.pathname === '/' ? classes.highlightedListItem : ''}`}>
                    <ListItemIcon>
                        <FontAwesomeIcon className={classes.menuIcon} icon="home" />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText} primary="Home" />
                </ListItem>
                {
                    menuItems.map((item: ShowDesignMenuItem) => {
                        // Forcibly ignore voice search and audioid for now
                        if (item.url === '/audioid' || item.url === '/voicesearch' || !item.showInPullOutMenu) return;

                        const iconInfo: any[] = item.icon.split(' ');
                        const iconLookup: IconLookup = { prefix: iconInfo[0], iconName: iconInfo[1].replace('fa-', '') }
                        const iconDefinition: IconDefinition = findIconDefinition(iconLookup)

                        return (
                            <div key={item.id}>
                                <ListItem className={`${window.location.pathname === item.url ? classes.highlightedListItem : ''}`} button key={item.id} component={Link} to={item.url} onClick={onNavigateClick}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon className={classes.menuIcon} icon={iconDefinition} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.menuText} primary={item.title} />
                                </ListItem>
                            </div>
                        )
                    })
                }
                <ListItem style={{ justifyContent: 'center' }}>
                    <PoweredByVoxiIcon className={classes.poweredByVoxi} />
                </ListItem>
                <ListItem style={{ textAlign: 'center' }}>
                    <ListItemText className={classes.version} primary={`Version: ${process.env.REACT_APP_VERSION || '0.0.1'}`} />
                </ListItem>
            </List>
        </div>
    )

    return (
        <div className={classes.root}>
            <Dialog open={helpOpen}
                onClose={onHelpClose}
                aria-labelledby="help-dialog"
                aria-describedby="help-dialog-for-support-help-information"
            >
                <DialogTitle id="help-dialog">{'Help / Support'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="help-dialog-information">
                        For assistance with any issues please contact Voxi support at
                        <a href={`mailto:support@getvoxi.com?subject=${encodeURIComponent('Voxi Content Admin Support')}`}> support@getvoxi.com</a>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHelpClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.appToolbar}>
                    <IconButton className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    {
                        location.pathname === "/" ? null : (
                            <IconButton onClick={onBackClick}>
                                <ArrowBack className={classes.headText} />
                            </IconButton>
                        )
                    }
                    {
                        !appData?.appPosterDisplayType || appData.appPosterDisplayType === 0
                            ? <Typography className={classes.headText}>
                                {show?.nameShort || ''}
                            </Typography>
                            : <img className={classes.showPoster} src={posterURL} alt="show poster" />
                    }
                    <div className={classes.appbarActionContainer}>
                        {
                            props.authState === AuthState.SignedIn && props.user
                                ? <Button className={classes.avatarButton} onClick={onAvatarClick}>
                                    <Avatar>{userInitials}</Avatar>
                                </Button>
                                : <Link className={classes.signin} to={{ pathname: '/signin', state: { from: location.pathname } }}>
                                    <Grid container>
                                        <AccountCircleIcon />
                                    </Grid>
                                    <span style={{ position: 'absolute', right: '33%', width: '100%', fontWeight: 600 }}>SIGN IN</span>
                                </Link>
                        }
                        <Menu id="profile-menu"
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            getContentAnchorEl={null}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem className={classes.avatarMenuButtonItem} onClick={handleClose} component={Link} to={'/profile'}>Profile</MenuItem>
                            <MenuItem className={classes.avatarMenuButtonItem} onClick={handleClose} component={Link} to={'/wishlist'}>Wishlist</MenuItem>
                            <MenuItem className={classes.avatarMenuButtonItem} onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                        <Tooltip title="Help" onClick={onHelpClick}>
                            <IconButton className={classes.helpIcon}>
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="drawer items">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden xlDown implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            onBackdropClick: handleDrawerToggle
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.appBarOffset} />
                <div>
                    <main className={classes.content}>
                        <SnackbarProvider>
                            <EpisodesStateProvider>
                                <Switch>
                                    <Route exact path="/" render={(props: any) => <Home {...props} menuItems={menuItems} posterURL={show?.posterURL} appPosterDisplayType={show?.appData?.appPosterDisplayType || 0} show={show} />} />
                                    <Route exact path="/home" render={(props: any) => <Home {...props} menuItems={menuItems} posterURL={show?.posterURL} appPosterDisplayType={show?.appData?.appPosterDisplayType || 0} show={show} />} />

                                    <Route exact path="/episodes/:episodeID" render={(props: any) => <Episode {...props} />} />
                                    <Route exact path="/episodes" render={(props: any) => <Episodes {...props} show={show} />} />

                                    <Route exact path="/exclusives/:exclusiveID" render={(props: any) => <ExclusiveVideo {...props} show={show} />} />
                                    <Route exact path="/exclusives" render={(props: any) => <Exclusives {...props} show={show} />} />

                                    <Route exact path="/category/:categoryID" render={(props: any) => <Category {...props} showID={show?.id || 0} />} />
                                    <Route exact path="/productrequest" render={(props: any) => <ProductRequest {...props} showID={show?.id || 0} configuration={show?.productRequestConfiguration} />} />
                                    <Route exact path="/products/:productID" render={(props: any) => <Product {...props} showID={show?.id || 0} user={user} />} />

                                    {/* For backwards compatibility */}
                                    <Route exact path="/productlist" render={(props: any) => <ProductsList {...props} showID={show?.id || 0} />} />
                                    <Route exact path="/products" render={(props: any) => <ProductsList {...props} showID={show?.id || 0} />} />

                                    <Route exact path="/smallbusiness" render={(props: any) => <SmallBusinessList {...props} showID={show?.id || 0} />} />
                                    <Route exact path="/vendor/:vendorID" render={(props: any) => <Vendor {...props} showID={show?.id || 0} />} />

                                    <Route exact path="/textsearch" render={(props: any) => <TextSearch {...props} showID={show?.id || 0} />} />

                                    <Route exact path="/privacypolicy" render={(props: any) => <PrivacyPolicy {...props} showAppData={show?.appData} />} />
                                    <Route exact path="/faq" render={(props: any) => <FAQ {...props} faq={show?.faqContent} />} />
                                    <Route exact path="/facebook" render={(props: any) => <Facebook {...props} appID={show?.appData?.facebookAppID} pageURL={show?.appData?.facebookPageURL} />} />

                                    <Route exact path="/signin" render={(props: any) => <Authenticator {...props} from={props.location} />} />

                                    <Route exact path="/wishlist" render={(props: any) => <Wishlist {...props} showID={show?.id} user={user} />} />

                                    {/* For backwards compatibility */}
                                    <Route exact path="/userprofile" render={(props: any) => <Profile {...props} demographicConfiguration={show?.demographicConfiguration} user={user} />} />
                                    <Route exact path="/profile" render={(props: any) => <Profile {...props} demographicConfiguration={show?.demographicConfiguration} user={user} />} />

                                    <Route exact path="/demo" render={(props: any) => <Demo {...props} />} />

                                    <Route path="*" render={() => <Redirect to="/" />} />
                                </Switch>
                            </EpisodesStateProvider>
                        </SnackbarProvider>
                    </main>
                    <BackToTopButton />
                </div>
            </main>
        </div>
    );
}

export default ResponsiveDrawer;

interface ResponsiveDrawerProps {
    show: Maybe<Show>
    authState: Maybe<AuthState>
    user: any | undefined
    location?: any
}

