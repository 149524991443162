import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Card, CardActionArea, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VisibilitySensor from 'react-visibility-sensor';

import { Product } from '../../lib/api';

import { ResponsiveImage } from './ResponsiveImage';

import { generateSrcSet, ImageCategory } from '../../lib/generateSRCSet';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '95vw',
        width: '100%',
    },
    cardContent: {
        backgroundColor: theme.palette.voxi.secondaryColor
    },
    titleText: {
        color: theme.palette.voxi.accentColor
    },
    moreInfoButton: {
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor
    },
    vendorLogoContainer: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        height: '66px',
        width: 'auto',
        borderTop: '1px solid black',
        padding: theme.spacing(1)
    },
    vendorLogo: {
        height: '50px',
        maxWidth: '100%',
        backgroundColor: 'white',
    },
    cardActions: {
        padding: 0
    },
}));

export function VideoProductCard(props: ProductCardProps) {
    const classes = useStyles();
    const history = useHistory();
    const [thumbnail] = useState(props.product.mainPhotoURL?.replace('ORIGINAL', '600') || '');
    const [vendorLogo] = useState(
        props.product.vendorLogo && props.product.vendorLogoBucket 
        ? `https://s3.dualstack.us-east-2.amazonaws.com/${props.product.vendorLogoBucket}/${props.product.vendorLogoKey}`
        : null
    );

    const [loaded, setLoaded] = useState(false);

    const navigate = () => {
        history.push(`/products/${props.product.id}`);
    }

    const onVisibilityChanged = (isVisible) => {
        if (!loaded && isVisible) setLoaded(true);
    }

    const vendorLogoSrcSetObject = generateSrcSet(
        ImageCategory.PRODUCT, 
        (props.product.vendorLogo && props.product.vendorLogoBucket 
            ? `https://s3.dualstack.us-east-2.amazonaws.com/${props.product.vendorLogoBucket}/${props.product.vendorLogoKey}`
            : '')
    );

    const mainPhotoSRCSetObject = generateSrcSet(
        ImageCategory.PRODUCT, 
        props.product.mainPhotoURL?.replace('ORIGINAL', '600') || ''
    );
    // TODO: Need to navigate to stop if we click a new one

    return (
        <VisibilitySensor onChange={onVisibilityChanged} partialVisibility>
            <Card className={classes.cardRoot}>
                <CardActionArea onClick={navigate}>
                    <CardContent className={classes.cardContent}>
                        <Grid item container spacing={0} direction="row" justify="center" alignItems="center">
                            <Grid item container direction="column" xs>
                                <Typography className={classes.titleText} variant="subtitle2">{props.product.name}</Typography>
                                <Typography className={classes.titleText} variant="caption">{props.product.brand}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {
                        loaded &&
                        <ResponsiveImage src={thumbnail} srcSet={mainPhotoSRCSetObject.srcSet} sizes={mainPhotoSRCSetObject.sizes}/>
                    }
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                    <Grid item xs={12} className={classes.vendorLogoContainer}>
                    {
                        vendorLogo && loaded &&
                            <img src={vendorLogo} srcSet={vendorLogoSrcSetObject.srcSet} sizes={vendorLogoSrcSetObject.sizes} alt="" className={classes.vendorLogo}/>
                    }
                    </Grid>
                </CardActions>
            </Card>
        </VisibilitySensor>
    )
}

export interface ProductCardProps {
    product: Product
}