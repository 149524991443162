/*
    Uses the Facebook Page Plugin to render the customer page: https://developers.facebook.com/docs/plugins/page-plugin
*/

import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function Facebook(props: FacebookProps) {
    const [dataHref, setDataHref] = useState(props.pageURL);
    const [loaded, setLoaded] = useState(false);
    const instance = useRef<any>(null);

    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'), { noSsr: true });
    const [dataWidth, setDataWidth] = useState(''); // Max is 500px
    const [dataHeight, setDataHeight] = useState(''); 

    useEffect(() => {
        const facebookAppID = props.appID  ? props.appID : '322133941531048'
        setDataHref(props.pageURL);

        if (loaded) return;
        
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.defer = true;
        s.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0&appId=${facebookAppID}&autoLogAppEvents=1`;

        // @ts-ignore
        s.crossorigin = "anonymous";

        instance.current.appendChild(s);

        setLoaded(true);

        if (isDesktop) {
            setDataWidth('500'); // Max is 500px
            setDataHeight('800');
        }
    }, [props.appID, props.pageURL])

    return (
        <div style={{ textAlign: 'center', display: 'block' }}>
            <div id="fb-root"></div>
            <div ref={el => (instance.current = el)} />

            <div className="fb-page" data-href={dataHref} data-width={dataWidth} data-height={dataHeight} data-adapt-container-width="true"
                data-tabs="timeline,events" data-small-header="false" data-hide-cover="false" data-show-facepile="true"
            >
                <blockquote cite={dataHref} className="fb-xfbml-parse-ignore">
                    <a href={dataHref}>Facebook</a>
                </blockquote>
            </div>
        </div>
    )
}

export interface FacebookProps {
    appID?: string
    pageURL?: string
}