import * as Sentry from "@sentry/react";
import { CreateAppUsageLogInput } from '../lib/api';
import API from './manualApi';
const api = API.getInstance();

export interface AppUsageLog {
    episodeID?: number
    productID?: number
    linkID?: number
    logEntry?: string
    logData?: string
}

export async function writeAppLog(log: AppUsageLog) {
    const input: CreateAppUsageLogInput = {
        appID: localStorage.getItem('voxiAppID') || '',
        date: new Date().toISOString(),
        referer: window.location.href,
        appInstallID: parseInt(localStorage.getItem('appInstallID') || '0') || 0,
        appVersion: process.env.REACT_APP_VERSION || '0.0.1',
        ...log
    }

    try {
        await api.createAppUsageLog(input);
    } catch (err) {
        console.error(err);
        Sentry.captureException(err, {
            tags: {
                section: "writeApp",
            },
            extra: {
                stringified: JSON.stringify(err)
            }
        });
    }
}