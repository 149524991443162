import React, { Children, cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    responsiveImage: {
        position: 'relative',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flex: '1 0 auto'
    },
    responsiveImageImage: {
        position: 'absolute',
        width: 'auto',
        maxWidth: '100%',
        height: '100%',
        bottom: 0
    }
}));

export function ResponsiveImage(props: ResponsiveImageProps) {
    const classes = useStyles();

    return (
        <div className={classes.responsiveImage}>
            <div style={{ paddingBottom: '56.25%' }} />
            {
                <img className={classes.responsiveImageImage} alt="" sizes={props.sizes} src={props.src} srcSet={props.srcSet}/>
            }
            {
                props.children &&
                Children.map(props.children, child => cloneElement(child))
            }
        </div>
    );
}

export interface ResponsiveImageProps {
    src: any
    children?: any
    srcSet?: string
    sizes?: string
}