import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ExclusiveVideoCard } from './ExclusiveVideoCard';
import {
    Maybe,
    ExclusiveVideoContent,
    ExclusiveVideoContent as ExclusiveVideoContentType,
    Show,
    useListExclusiveContentsByShowQuery
} from '../../lib/api';

const useStyles = makeStyles(theme => ({
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: 'flex',
        justifyContent: 'center'
    }
}));

export function Exclusives(props: ExclusivesProps) {
    const classes = useStyles();

    const queryArgs = {
        legacyShowID: props.show?.id,
        filter: {
            enabled: { eq: true },
            releaseToApp: { eq: true }
        }
    };

    const { data } = useListExclusiveContentsByShowQuery<Array<ExclusiveVideoContentType>>(queryArgs, {
        enabled: !!props.show, // TODO: add !!props.user
        select: (response) => {
            const exclusives = response.listExclusiveContentsByShow?.items ?? [];
            return exclusives
                .filter(exclusive => !!exclusive)
                .sort(compareReleaseDates) as Array<ExclusiveVideoContentType>;
        }
    });

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.headerText} variant="h4">Exclusive Content</Typography>
            </Grid>
            {
                data?.map((exclusive: ExclusiveVideoContentType, index: number) => {
                    return (
                        <Grid key={exclusive.id} item container xs={12} sm={12} md={6} lg={6} justify='center'>
                            <ExclusiveVideoCard key={exclusive.id} exclusiveVideo={exclusive} index={index} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

interface ExclusivesProps {
    show: Show
}

function compareReleaseDates(a: Maybe<ExclusiveVideoContent>, b: Maybe<ExclusiveVideoContent>) {
    if (a?.firstRelease < b?.firstRelease) return 1;
    if (a?.firstRelease > b?.firstRelease) return -1;

    return 0;
}