import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProductCard } from '../shared/ProductCard';

import { Maybe, Product, useGetUserWishlistQuery } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"
    }
}));

export default function Wishlist(props: WishlistProps) {
    const classes = useStyles();
    const history = useHistory();

    const queryArgs = { userID: props.user?.attributes?.sub, showID: props.showID };
    const { data } = useGetUserWishlistQuery<Array<Product>>(queryArgs, {
        enabled: !!props.user && !!props.showID,
        select: (response) => { return response.getUserWishlist?.items ?? [] }
    });

    useEffect(() => {
        if (!props.showID) return;

        if (!props.user) {
            history.push({ pathname: '/signin', state: { from: props.location.pathname } });
            return;
        }
    }, [props.showID, props.user])

    return (
        <Grid item container spacing={2} alignContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography className={classes.headerText} variant="h4">My Wishlist</Typography>
            </Grid>
            {
                data && data.length > 0
                    ? data?.map((product: Product, index: number) => {
                        return (
                            <Grid key={product.id} item container xs={12} sm={12} md={12} lg={6} justify="center">
                                <ProductCard key={product.id}
                                    product={product}
                                />
                            </Grid>
                        )
                    })
                    : <Grid item><Typography>No products added to wishlist</Typography></Grid>
            }
        </Grid>
    )
}

export interface WishlistProps {
    showID: Maybe<number>
    user: any
    location: { pathname: string }
}