import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Maybe, ShowAppData } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    privacyPolicyContainer: {
        maxWidth: '90vw'
    },
    privacyPolicy: {
        width: '90%', 
        borderRadius: '5px', 
        justifySelf: 'center',
        alignSelf: 'center',
        maxWidth: '95vw',
    }
}));


export default function PrivacyPolicy(props: PrivacyPolicyProps) {
    const classes = useStyles();

    return (
        <div className={classes.privacyPolicyContainer}>
            <Typography variant="h4">Privacy Policy</Typography>
            <div className={classes.privacyPolicy}
                dangerouslySetInnerHTML={{ __html: props.showAppData?.privacyPolicy || '' }}
            />
        </div>
    )
}

export interface PrivacyPolicyProps {
    showAppData: Maybe<ShowAppData>
}