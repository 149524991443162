import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Card, CardMedia, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Vendor } from '../../lib/api';
import API from '../../lib/manualApi';
const api = API.getInstance();

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '95vw',
        maxHeight: '25vh',
        [theme.breakpoints.up("sm")]: {
            width: '500px'
        }
    },
    productGrid: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        minWidth: '90vw',
        maxWidth: '95vw',
        borderRadius: "borderRadius",
        border: 1,
        [theme.breakpoints.up("sm")]: {
            width: '500px'
        }
    },
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"        
    },
    titleText: {
        color: theme.palette.voxi.accentColor,
        fontSize: '18px',
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    cardInfoDiv: {
        position: 'absolute',
        backgroundColor: theme.palette.voxi.secondaryColor,
        opacity: '0.75',
        bottom: '0px',
        width: '100%'
    },
    media: {
        position: 'relative',
        height: '25vh',
        width: '100%',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    infoText: {
        color: theme.palette.voxi.infoColor,
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    }
}));

export function SmallBusinessList(props: SmallBusinessListProps) {
    const classes = useStyles();
    const [vendors, setVendors] = useState<Array<Vendor>>([]);

    useEffect(() => {
        if (!props.showID) return;

        const fetchSmallBusinesses = async () => {
            const smallBusinessesResult = await api.listShowSmallBusinessVendors({ showID: props.showID });
            const vendors = smallBusinessesResult.filter(vendor => vendor !== undefined) as Vendor[];
            setVendors(vendors);
        }

        fetchSmallBusinesses();
    }, [props.showID])

    return (
        <Grid item container spacing={2} alignContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography className={classes.headerText} variant="h4">Small Businesses</Typography>
            </Grid>
            {
                vendors.map((vendor: Vendor, index: number) => {
                    return (
                        <Grid key={vendor.id} item container xs={12} sm={12} md={12} lg={6} justify="center">
                            <VendorCard vendor={vendor} index={index} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

interface SmallBusinessListProps {
    showID: number
}

function VendorCard(props: VendorCardProps) {
    const classes = useStyles();
    const history = useHistory();
    const [thumbnail] = useState(`https://s3.dualstack.us-east-2.amazonaws.com/${props.vendor.logoBucket}/${props.vendor.logoKey}`);

    const imageLoadError = async (event) => {
        event.target.onerror = null;

        if (props.vendor.logoKey) {
            event.target.src = `https://s3.dualstack.us-east-2.amazonaws.com/voxi-media-content-${process.env.REACT_APP_STAGE}/ASSETS/PRODUCT-NOT-FOUND.PNG`;
            event.target.srcset = '';
            event.target.sizes = '';
        }
    }

    const navigate = () => {
        history.push(`/vendor/${props.vendor.id}`);
    }

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={navigate}>
                <CardMedia className={classes.media} image={thumbnail || ''} title="Vendor Logo" onError={imageLoadError} />
                <CardContent className={classes.cardInfoDiv}>
                    <Typography className={classes.titleText} variant="h5" component="p">
                        {props.vendor.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

interface VendorCardProps {
    index: number
    vendor: Vendor
}