import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Card, CardActionArea, CardContent, CardActions, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import VisibilitySensor from 'react-visibility-sensor';

import { Product } from '../../lib/api';

import { ResponsiveImage } from './ResponsiveImage';
import { ReplacementIcon } from './ReplacementIcon';

import { generateSrcSet, ImageCategory } from '../../lib/generateSRCSet';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '95vw',
        [theme.breakpoints.up("sm")]: {
            width: '592px', // 16x9 ratio,
        },
        border: '1px solid black'
    },
    cardContent: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        borderBottom: '1px solid black'
    },
    cardTitleBar: {
        justify: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: '40px',
    },
    titleText: {
        color: theme.palette.voxi.accentColor,
        fontFamily: theme.fonts.CARD_INFO.fontFamily,
        wordBreak: 'break-word'
    },
    moreInfoButton: {
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor
    },
    photoContainer: {
        position: 'relative',
        textAlign: 'center'
    },
    replacementIconGrid: {
        display: 'flex',
        justifyContent: 'center'
    },
    replacementIcon: {
        margin: 'auto',
        height: '50px'
    },
    vendorLogoContainer: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        height: '66px',
        width: 'auto',
        borderTop: '1px solid black',
        padding: theme.spacing(1)
    },
    vendorLogo: {
        height: '50px',
        maxWidth: '100%',
        backgroundColor: 'white',
    },
    cardActions: {
        padding: 0
    },
}));

export function ProductCard(props: ProductCardProps) {
    const classes = useStyles();
    const history = useHistory();
    const [thumbnail] = useState(props.product.mainPhotoURL?.replace('ORIGINAL', '600') || '');
    const [vendorLogo] = useState(props.product.vendorLogoBucket && props.product.vendorLogoKey ? `https://s3.dualstack.us-east-2.amazonaws.com/${props.product.vendorLogoBucket}/${props.product.vendorLogoKey}` : null);
    const [loaded, setLoaded] = useState(false);

    const navigate = async() => {
        document.body.style.cursor = 'progress';
        history.push(`/products/${props.product.id}`);

        if (props.onClick) props.onClick();
        document.body.style.cursor = 'default'
    }

    const onVisibilityChanged = (isVisible) => {
        if (!loaded && isVisible) setLoaded(true);
    }

    const TruncatedTitle = () => {
        let characterLimit;

        // The only time the card width is less than 590 is on cell phones. Cards on Tablets, laptops, and desktops should all be 590. We can set the character limit
        // for the cell phones with 'xs' and use the same limit for all the other devices.
        if(useMediaQuery(theme.breakpoints.down('xs'))) { characterLimit = 60}
        else { characterLimit = 120}
        return(
            <Typography className={classes.titleText} variant="subtitle2">{props.product.name.length > characterLimit ? `${props.product.name.substr(0, characterLimit - 3)}...`: props.product.name}</Typography>
        )
    }

    let mainPhotoSrcSetObject = generateSrcSet(ImageCategory.PRODUCT, props?.product?.mainPhotoURL || '');

    // TODO: Need to navigate to stop if we click a new one
    return (
        <VisibilitySensor onChange={onVisibilityChanged} partialVisibility>
            <Card className={classes.cardRoot}>
                <CardActionArea onClick={navigate}>
                    <CardContent className={classes.cardContent}>
                        <Grid item container direction="row" className={classes.cardTitleBar}>
                            <Grid item container direction="column" xs={10}>
                                <Grid item>
                                    <TruncatedTitle />
                                    <Typography className={classes.titleText} variant="caption">{props.product.brand}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} className={classes.replacementIconGrid}>
                                {
                                    props.product.replacementProduct &&
                                    <Tooltip title='This is a replacement product'>
                                        <div>
                                            <ReplacementIcon className={classes.replacementIcon} />
                                        </div>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                    {
                        loaded &&
                        <div className={classes.photoContainer}>
                            <ResponsiveImage src={thumbnail} srcSet={mainPhotoSrcSetObject.srcSet} sizes={mainPhotoSrcSetObject.sizes} />
                        </div>
                    }
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                    <Grid item xs={12} className={classes.vendorLogoContainer}>
                    {
                        vendorLogo && loaded &&
                            <img src={vendorLogo ? vendorLogo : ''} alt="" className={classes.vendorLogo}/>
                    }
                    </Grid>
                </CardActions>
            </Card>
        </VisibilitySensor>
    )
}

export interface ProductCardProps {
    product: Product
    onClick?: any
}