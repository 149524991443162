import React, { createContext, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';

const initialState: any = {
    current: null
}

const AUTO_HIDE_DURATION = 3000;

const Context = createContext(initialState);

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RenderSnack({ id, message, open, severity, handleClose }) {
    const messageId = `message-${id}`
    if (!severity) severity = 'success';
    return (
        <Snackbar open={open}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': messageId,
            }}
        >
            <Alert onClose={handleClose} severity={severity}>
                    {message}
            </Alert>
        </Snackbar>
    )
}

let uniqueId = 2

export const SnackbarProvider = ({ children }) => {
    const [{ current }, setState] = useState<any>({ current: null })

    function __createSnack(message, severity) {
        const id = uniqueId++
        const snack = { id, message, open: true, severity: severity }

        setState({ current: snack })

        return id
    }

    function createSuccessSnack(message) {
        return __createSnack(message, 'success');
    }

    function createErrorSnack(message) {
        return __createSnack(message, 'error');
    }

    function handleClose() {
        setState((currentState) => ({
            ...currentState,
            current: { ...currentState.current, open: false },
        }))
    }

    return (
        <Context.Provider value={{ createSuccessSnack, createErrorSnack }}>
            {current && <RenderSnack key={current.id} {...current} handleClose={handleClose} />}
            {children}
        </Context.Provider>
    )
}

export default Context