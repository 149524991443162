import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProductScroller } from '../episodes/ProductScroller';
import { VideoProductCard } from '../shared/VideoProductCard';

import { autotrackMedia, recordEvent, recordPersonalize, PinpointCustomEvent } from '../../lib/analytics';

import { Maybe, ExclusiveVideoContent, ExclusiveVideoProduct, useGetExclusiveVideoContentQuery } from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: "flex",
        justifyContent: 'space-around',
        flexWrap: 'nowrap',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        }
    },
    videoContainer: {
        alignItems: 'center',
        textAlign: 'left',
    },
    responsiveWrapper: {
        position: 'relative',
        paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
        width: '100%',
        maxWidth: '1280px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '95vw'
        },
    },
    videoTitle: {
        zIndex: 10,
        backgroundColor: 'black',
        justifySelf: 'center',
        opacity: '.75',
        position: 'absolute',
        textAlign: 'center',
        left: 10,
        right: 10,
        top: 0,
        paddingTop: '8px',
        margin: 'auto'
    },
    videoTitleText: {
        color: 'white'
    },
    reactPlayer: {
        maxWidth: '1280px',
        maxHeight: '720px',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    productContainer: {
        top: 0,
        overflow: 'auto',
        height: '90vh',
        justifyContent: 'flex-end',
        [theme.breakpoints.down("md")]: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: 'auto',
            maxWidth: '95vw',
            height: 'auto'
        }
    }
}));

export function ExclusiveVideo(props: ExclusiveVideoProps) {
    const classes = useStyles();
    const [exclusiveVideo, setExclusiveVideo] = useState<ExclusiveVideoContent>();
    const [allEpisodeProducts, setAllEpisodeProducts] = useState<any[]>([]);
    const [filteredEpisodeProducts, setFilteredEpisodeProducts] = useState<Array<ExclusiveVideoProduct>>([]);
    const [playing, setPlaying] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [displayEpisodeTitle, setDisplayEpisodeTitle] = useState(true);
    const [isRemoteURL, setIsRemoteURL] = useState(false);

    const queryArgs = { id: props.match.params.exclusiveID };
    const { data, isLoading } = useGetExclusiveVideoContentQuery<Maybe<ExclusiveVideoContent>, Error>(queryArgs, {
        select: (response) => { return response.getExclusiveVideoContent },
        notifyOnChangeProps: ['data', 'error']
    });

    useEffect(() => {
        if (isLoading || !data) return;

        setExclusiveVideo(data);
        if (data.videoURL?.includes('youtube')
            || data.videoURL?.includes('youtu.be')
            || data.videoURL?.includes('vimeo')) {
            setIsRemoteURL(true);
            setDisplayEpisodeTitle(false);
        }

        setAllEpisodeProducts(data.videoProducts ?? []);

        const productIDs = new Set();

        // Filter out the product IDs
        const filtered = data.videoProducts?.filter((videoProduct: ExclusiveVideoProduct) => {
            if (productIDs.has(videoProduct.product.id)) return;

            productIDs.add(videoProduct.product.id);
            return videoProduct;
        });
        setFilteredEpisodeProducts(filtered ?? []);

        recordEvent(PinpointCustomEvent.EXCLUSIVE_VIEW, {
            exclusiveID: props.match.params.exclusiveID,
            showID: data.legacyShowID
        });

        recordPersonalize(
            PinpointCustomEvent.EXCLUSIVE_VIEW,
            `exclusive_${props.match.params.exclusiveID}`,
            PinpointCustomEvent.EXCLUSIVE_VIEW
        );
    }, [props.match.params.exclusiveID, data, isLoading])

    // This useeffect must be separate so we can send an event at the end. We only want it to run once, I think? 
    // If we pass playedSeconds it calls too many times, if we pass the exclusiveVideo then it doesn't get the playedSeconds
    useEffect(() => {
        return function cleanup() {
            if (exclusiveVideo) {
                recordEvent(PinpointCustomEvent.EXCLUSIVE_VIDEO_PLAYED_SECONDS, {
                    exclusiveID: exclusiveVideo.id,
                    showID: exclusiveVideo.legacyShowID,
                    playedSeconds: playedSeconds
                });
            }
        }
    }, [])

    const onVideoProgress = (event: any) => {
        setPlayedSeconds(event.playedSeconds);
    }

    const onVideoPlay = () => {
        setPlaying(true);
        setDisplayEpisodeTitle(false);
    }

    const onVideoPause = () => {
        setPlaying(false);
        setDisplayEpisodeTitle(!isRemoteURL && true);
    }

    const onVideoMouseEnter = (event) => {
        if (event.target.attributes.autoplay) setDisplayEpisodeTitle(true);
        else if (event.target.children.length > 0) {
            let videoElement = event.target.children[0];
            if (videoElement.attributes.autoplay) setDisplayEpisodeTitle(true);
        }
    }

    const onVideoMouseLeave = (event) => {
        if (event.target.attributes.autoplay) setDisplayEpisodeTitle(false);
        else if (event.target.children.length > 0) {
            let videoElement = event.target.children[0];
            if (videoElement.attributes.autoplay) setDisplayEpisodeTitle(false);
        }
    }

    const onVideoReady = () => {
        // Right now youtube tracking isn't working...
        if (isRemoteURL) return;

        // let domElementID = isRemoteURL ? 'widget2' : 'react-exclusive-player';
        autotrackMedia('react-exclusive-player', `exclusive_${exclusiveVideo?.id}` || '');
    }

    return (
        <Grid className={classes.rootContainer} item container spacing={2}>
            <Grid className={classes.videoContainer} item container spacing={2} direction="column" xs={12} sm={12} md={12} lg={8}>
                <div className={classes.responsiveWrapper}>
                    {
                        displayEpisodeTitle &&
                        <div className={classes.videoTitle}>
                            <Typography className={classes.videoTitleText} variant="subtitle1">{exclusiveVideo?.name}</Typography>
                        </div>
                    }
                    <ReactPlayer className={classes.reactPlayer}
                        url={`${exclusiveVideo?.videoURL}` || ''}
                        playing={playing}
                        controls
                        width='100%'
                        height='100%'
                        onProgress={onVideoProgress}
                        onPlay={onVideoPlay}
                        onPause={onVideoPause}
                        onMouseEnter={onVideoMouseEnter}
                        onMouseLeave={onVideoMouseLeave}
                        onReady={onVideoReady}
                        playsinline={true}
                        config={{
                            youtube: {
                                embedOptions: {
                                    id: 'react-exclusive-player'
                                },
                                playerVars: {
                                    rel: 0
                                }
                            },
                            file: {
                                attributes: {
                                    preload: 'metadata',
                                    id: 'react-exclusive-player'
                                },
                                hlsOptions: {
                                    xhrSetup: function (xhr, url) {
                                        // https://github.com/cookpete/react-player/issues/442
                                        xhr.open('GET', url + exclusiveVideo?.videoToken);
                                    }
                                }
                            }
                        }}
                    />
                </div>
                {
                    !exclusiveVideo?.disableProductScroller && allEpisodeProducts.length > 0 &&
                    <>
                        <ProductScroller episodeProducts={allEpisodeProducts} playedSeconds={playedSeconds} />
                    </>
                }
            </Grid>
            <Grid className={classes.productContainer} item container spacing={4} xs={12} sm={12} md={12} lg={3}>
                {
                    filteredEpisodeProducts.map(episodeProduct => {
                        return (
                            <Grid key={episodeProduct.product.id} item container xs={12} sm={12} md={6} lg={12} justify="center">
                                <VideoProductCard product={episodeProduct.product} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    );
}

interface ExclusiveVideoProps {
    match: { params: { exclusiveID } }
}