import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Grid, Typography, TextField, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SelectWithLabel } from '../shared/SelectWithLabel';

import { recordEvent, PinpointCustomEvent } from '../../lib/analytics';
import {
    Maybe,
    Category,
    Episode,
    ShowProductRequestConfiguration,
    CreateProductRequestInput,
    useListAppEpisodesQuery,
    useListActiveCategoriesByShowQuery
} from '../../lib/api';

import useSnackbars from '../../hooks/useSnackbars';

import API from '../../lib/manualApi';
const api = API.getInstance();

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInputLabel-root': {
            color: theme.palette.voxi.textPrimaryColor
        },
        '& .Mui-focused': {
            marginBottom: '30px',
            color: 'red'
        }
    },
    submitButton: {
        color: theme.palette.voxi.menuTextColor,
        backgroundColor: theme.palette.voxi.secondaryColor,
        fontFamily: theme.fonts.BUTTON_TEXT.fontFamily,
        '&:hover': {
            backgroundColor: theme.palette.voxi.secondaryColor
        }
    },
    textField: {
        color: theme.palette.voxi.textPrimaryColor
    },
    textFieldInput: {
        borderColor: theme.palette.voxi.textPrimaryColor
    },
    textFieldLabel: {
        color: theme.palette.voxi.textPrimaryColor
    },
}));

export function ProductRequest(props: ProductRequestProps) {
    const classes = useStyles();
    const history = useHistory();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [episodeOptions, setEpisodeOptions] = useState<Array<any>>([]);
    const [selectedEpisode, setSelectedEpisode] = useState<any>(null);
    const [roomOptions, setRoomOptions] = useState<Array<any>>([]);
    const [selectedRoom, setSelectedRoom] = useState<any>(null);
    const [categoryOptions, setCategoryOptions] = useState<Array<any>>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [email, setEmail] = useState('');
    const [request, setRequest] = useState('');

    const showQueryArgs = { showID: props.showID }
    const listAppEpisodesResult = useListAppEpisodesQuery<Array<Episode>>(showQueryArgs, {
        enabled: !!props.showID && isLoggedIn,
        select: (result) => { return result.listAppEpisodes?.items ?? [] }
    });

    const listActiveCategoriesResult = useListActiveCategoriesByShowQuery<Array<Category>>(showQueryArgs, {
        enabled: !!props.showID && isLoggedIn,
        select: (response) => { return response.listActiveCategoriesByShow?.items ?? [] }
    });

    const requestRef = useRef(null);
    const emailRef = useRef(null);

    const fetchPhotoGroups = async (episodeID: number) => {
        const photoGroupResult = await api.getEpisodePhotoGroups({ episodeID: episodeID })

        let options = [{ name: 'Unknown / Unsure', value: -1 }]
        const opts = photoGroupResult.filter(photoGroup => photoGroup.enabled).map(photoGroup => {
            return { name: photoGroup.name, value: photoGroup.id }
        });
        
        options = options.concat(opts);

        setRoomOptions(options)
        setSelectedRoom(options[0]);
    }

    useEffect(() => {
        let options = [{ name: 'Unknown / Unsure', value: -1 }]

        if (listAppEpisodesResult.data) {
            let opts = listAppEpisodesResult.data.filter(episode => episode.enabled).map(episode => {
                const displayName = `S${episode.season}E${episode.number}: ${episode.name}`;
                return { name: displayName, value: episode.id }
            })

            options = options.concat(opts);
        }

        setEpisodeOptions(options);

        const search = props.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('episodeID');
        const episodeID = id ? parseInt(id) : -1;

        const episodeFound = options.find(episode => episode.value === episodeID);
        if (episodeFound) {
            setSelectedEpisode(episodeFound);
            fetchPhotoGroups(episodeFound.value);
        }
        else {
            setSelectedEpisode(options[0]);
            fetchPhotoGroups(options[0].value);
        }
    }, [listAppEpisodesResult])

    useEffect(() => {
        if (listActiveCategoriesResult.isLoading 
            || listActiveCategoriesResult.isError
            || !listActiveCategoriesResult.data) return;

        let options = [{ name: 'Unknown / Unsure', value: -1 }]
        let opts = listActiveCategoriesResult.data.map(category => {
            return { name: category.name, value: category.id }
        });

        options = options.concat(opts);

        setCategoryOptions(options);
        setSelectedCategory(options[0]);

    }, [listActiveCategoriesResult])

    useEffect(() => {
        if (!props.showID) return;

        const checkForUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                if (user) {
                    setEmail(user?.attributes?.email || '');
                    setIsLoggedIn(true);
                    return;
                }
            } catch (err) {
                console.error(err);
            }

            history.push({ pathname: '/signin', state: { from: `${props.location.pathname}${props.location.search}` } });
        }

        checkForUser();
    }, [props])

    const onEpisodeSelectChange = async (event) => {
        let episode = event.target.value;
        setSelectedEpisode(episode);

        if (props.configuration?.photoGroupEnabled) await fetchPhotoGroups(episode.value);
    }

    const onRoomSelectChange = (event) => {
        setSelectedRoom(event.target.value);
    }

    const onCategorySelectChange = (event) => {
        setSelectedCategory(event.target.value);
    }

    const onSubmitClick = async () => {
        if (!email) {
            // @ts-ignore
            emailRef.current.focus();
            createErrorSnack('Please make sure to fill out all of the details!')
            return
        }

        if (!request) {
            // @ts-ignore
            requestRef.current.focus()
            createErrorSnack('Please make sure to fill out all of the details!')
            return
        }

        const input: CreateProductRequestInput = {
            showID: props.showID,
            episodeName: selectedEpisode?.name || null, // TODO: This will be wrong
            episodeID: selectedEpisode?.value || null,
            added: new Date().toISOString(),
            appInstallID: parseInt(localStorage.getItem('appInstallID') || '0') || 0,
            photoGroup: selectedRoom ? selectedRoom.name : null,
            category: selectedCategory ? selectedCategory.name : null,
            email: email,
            request: request
        }

        await api.createProductRequest(input);
        await recordEvent(PinpointCustomEvent.REQUEST_PRODUCT_INFO, { showID: props.showID });

        setRequest('');
        createSuccessSnack('Product request submitted. Thank you!');
    }

    return (
        <Grid item container spacing={2} direction="column" alignContent="center">
            <Grid item>
                <Typography variant="h4">Request Product Information</Typography>
            </Grid>
            {
                props.configuration?.episodeListEnabled &&
                <Grid item>
                    <SelectWithLabel id="episode-selector"
                        title={props.configuration?.episodeListText || "Episode:"}
                        value={selectedEpisode}
                        onChange={onEpisodeSelectChange}
                        selectOptions={episodeOptions}
                        otherProps={{ fullWidth: true }}
                    />
                </Grid>
            }
            {
                props.configuration?.photoGroupEnabled && selectedEpisode?.value > 0 &&
                <Grid item>
                    <SelectWithLabel id="photo-group-selector"
                        title={props.configuration?.photoGroupText || "Room:"}
                        value={selectedRoom}
                        onChange={onRoomSelectChange}
                        selectOptions={roomOptions}
                        otherProps={{ fullWidth: true }}
                    />
                </Grid>
            }
            {
                props.configuration?.categoryEnabled &&
                <Grid item>
                    <SelectWithLabel id="category-selector"
                        title={props.configuration?.categoryText || "Category:"}
                        value={selectedCategory}
                        onChange={onCategorySelectChange}
                        selectOptions={categoryOptions}
                        otherProps={{ fullWidth: true }}
                    />
                </Grid>
            }
            {
                props.configuration?.emailEnabled &&
                <Grid item>
                    <TextField className={classes.textField}
                        variant="outlined"
                        label={props.configuration?.emailText || "Email"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        InputLabelProps={{ className: classes.textFieldLabel }}
                        InputProps={{ classes: { notchedOutline: classes.textFieldInput } }}
                        required
                        fullWidth
                        inputRef={emailRef}
                    />
                </Grid>
            }
            <Grid item>
                <TextField className={classes.textField}
                    variant="outlined"
                    label={'Please describe the item'}
                    value={request}
                    onChange={(e) => setRequest(e.target.value)}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    InputProps={{ classes: { notchedOutline: classes.textFieldInput } }}
                    multiline
                    required
                    rows={6}
                    fullWidth
                    inputRef={requestRef}
                />
            </Grid>
            <Grid item>
                {
                    email && request
                        ? <Button className={classes.submitButton} variant="contained" onClick={onSubmitClick}>
                            Submit
                        </Button>
                        : <Tooltip title='Make sure all of the required fields are filled out!'>
                            <Button className={classes.submitButton} variant="contained" onClick={onSubmitClick}>
                                Submit
                            </Button>
                        </Tooltip>
                }
            </Grid>
        </Grid>
    )
}

interface ProductRequestProps {
    showID: number
    configuration: Maybe<ShowProductRequestConfiguration>
    location: { pathname: string, search: string }
}