import { Auth } from 'aws-amplify';

export function toSentenceCase(camelCase) {
    return camelCase
        .replace(/_/g, " ")
        .replace(/([A-Z])/g, (match) => `${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
}

export async function getMyIP() {
    const response = await fetch('https://api.ipify.org?format=json')
    const obj = await response.json();
    return obj.ip
}

export async function getCurrentUserInfo() {
    return await Auth.currentUserInfo();
}