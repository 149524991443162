import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Typography, Grid, Button, FormControl, InputLabel, FilledInput, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import useSnackbars from '../../hooks/useSnackbars';

const useStyles = makeStyles((theme) => ({
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"
    },
    textField: {
        width: '25ch',
        backgroundColor: theme.palette.voxi.headBackgroundColor
    },
    changePasswordButton: {
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor
    }
}));

export default function Profile(props: ProfileProps) {
    const classes = useStyles();
    const history = useHistory();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [user, setUser] = useState<any>(null);
    const [oldPassword, setOldPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);

    useEffect(() => {
        if (!props.user) {
            history.push({pathname: '/signin', state: { from: `${props.location.pathname}${props.location.search}` } });
            return;
        }

        setUser(props.user);
    }, [])

    const changePassword = async () => {
        try {
            await Auth.changePassword(user, oldPassword, newPassword);
            createSuccessSnack('Sucessfully changed password!');

            setOldPassword('');
            setNewPassword('');
        } catch (err) {
            let errorMessage = '';
            switch (err.code) {
                case 'NotAuthorizedException':
                    // Incorrect old password
                    errorMessage = 'Incorrect old password!';
                    break;
                case 'InvalidParameterException':
                    // Password is too short
                    errorMessage = 'Password is too short!';
                    break;
                case 'InvalidPasswordException':
                    // Password does not meet the policy
                    errorMessage = 'Password does not meet the password policy!';
                    break;
                case 'LimitExceededException':
                    // Too many changes
                    errorMessage = 'Attempt limit exceeded, please try after some time!';
                    break;
                default:
                    errorMessage = 'Error changing password!';
                    break;
            }

            createErrorSnack(errorMessage);
        }
    }

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid container spacing={2} direction="column" alignContent="center" alignItems="center" justify="center">
            <Grid item>
                <Typography variant="h4" className={classes.headerText}>User Profile</Typography>
            </Grid>

            <Grid item>
                <Grid item>
                    <Typography variant="subtitle1">Name: {user?.attributes.given_name} {user?.attributes.family_name}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">Email: {user?.attributes.email}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">Phone: {user?.attributes.phone_number}</Typography>
                </Grid>
                {/* <Grid item>
                    <Typography variant="subtitle1">Gender: {user.attributes.gender}</Typography>
                    </Grid> */}
            </Grid>
            <Grid item>
                <Typography variant="h4">Change Password</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">Password must have at least 7 characters</Typography>
                <Typography variant="body1">Password must have at least one uppercase</Typography>
                <Typography variant="body1">Password must have at least one lowercase</Typography>
            </Grid>
            <Grid item>
                <FormControl className={classes.textField} variant="filled">
                    <InputLabel htmlFor="old-password">Old Password *</InputLabel>
                    <FilledInput
                        id="old-password"
                        type={showOldPassword ? 'text' : 'password'}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        inputProps={{ style: { color: 'black' } }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl className={classes.textField} variant="filled">
                    <InputLabel htmlFor="new-password">New Password *</InputLabel>
                    <FilledInput
                        id="new-password"
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        inputProps={{ style: { color: 'black' } }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Button className={classes.changePasswordButton} variant="contained" onClick={changePassword}>Change Password</Button>
            </Grid>
        </Grid>
    )
}

export interface ProfileProps {
    user: any
    location: { pathname: string, search: string }
}