import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib/types';

import * as customQueries from '../graphql/customQueries';

import { 
    getEpisode,
    getEpisodePhotoGroups,
    getShowDesignMenu,
    listFontsByShow,
    listShowSmallBusinessVendors,
    textSearch
} from '../graphql/queries';

import {
    addProductToWishlist,
    createAppInstall,
    createAppUsageLog,
    createProductRequest,
    deleteProductFromWishlist,
    updateAppInstall
} from '../graphql/mutations';

import {
    AppUsageLog,
    Episode,
    EpisodePhotoGroup,
    Maybe,
    Product,
    Query,
    Mutation,
    Show,
    ShowDesignMenuItem,
    ShowFontV2,
    UserWishlist,
    Vendor,

    QueryGetShowByHostnameArgs,
    QueryGetShowDesignMenuArgs,
    QueryListFontsByShowArgs,
    QueryGetEpisodeArgs,
    QueryGetEpisodePhotoGroupsArgs,
    CreateProductRequestInput,
    QueryTextSearchArgs,
    CreateAppInstallInput,
    MutationUpdateAppInstallArgs,
    CreateAppUsageLogInput,
    MutationAddProductToWishlistArgs,
    MutationDeleteProductFromWishlistArgs,
    QueryListShowSmallBusinessVendorsArgs,
} from '../lib/api';

let instance: Maybe<API> = undefined;

export default class API {
    protected isSignedIn: boolean = false;

    constructor() {}

    static getInstance(): API {
        if (!instance) instance = new API();
        return instance;
    }

    static updateIsSignedIn(signedIn: boolean): void {
        if (!instance) instance = new API();
        instance.isSignedIn = signedIn;
    }

    // SHOWS
    async getShowByHostname(queryArgs: QueryGetShowByHostnameArgs): Promise<Maybe<Show>> {
        const response = await this.genericQuery(customQueries.getShowByHostnameCustom, queryArgs);
        return response.data?.getShowByHostname;
    }
    async getShowDesignMenu(queryArgs: QueryGetShowDesignMenuArgs): Promise<Array<ShowDesignMenuItem>> {
        const response = await this.genericQuery(getShowDesignMenu, queryArgs);
        return response.data?.getShowDesignMenu?.items || [];
    }

    // FONTS
    async listFontsByShow(queryArgs: QueryListFontsByShowArgs): Promise<Array<Maybe<ShowFontV2>>> {
        const response = await this.genericQuery(listFontsByShow, queryArgs);
        return response.data?.listFontsByShow?.items || [];
    }

    // EPISODES
    async getEpisode(queryArgs: QueryGetEpisodeArgs): Promise<Maybe<Episode>> {
        const response = await this.genericQuery(getEpisode, queryArgs);
        return response.data?.getEpisode;
    }
    async getEpisodePhotoGroups(queryArgs: QueryGetEpisodePhotoGroupsArgs): Promise<Array<EpisodePhotoGroup>> {
        const response = await this.genericQuery(getEpisodePhotoGroups, queryArgs)
        return response.data?.getEpisodePhotoGroups?.items || [];
    }

    // PRODUCTS
    async createProductRequest(input: CreateProductRequestInput): Promise<Maybe<number>> {
        const response = await this.genericMutation(createProductRequest, input);
        return response.data?.createProductRequest;
    }

    // SEARCH
    async textSearch(queryArgs: QueryTextSearchArgs): Promise<Array<Product>> {
        const response = await this.genericQuery(textSearch, queryArgs);
        return response.data?.textSearch?.items || [];
    }

    // LOGGING
    async createAppInstall(input: CreateAppInstallInput): Promise<Maybe<number>> {
        const response = await this.genericMutation(createAppInstall, input);
        return response.data?.createAppInstall;
    }
    async updateAppInstall(queryArgs: MutationUpdateAppInstallArgs): Promise<Maybe<number>> {
        const response = await this.genericMutationWithQueryArgs(updateAppInstall, queryArgs);
        return response.data?.updateAppInstall;
    }
    async createAppUsageLog(input: CreateAppUsageLogInput): Promise<Maybe<AppUsageLog>> {
        const response = await this.genericMutation(createAppUsageLog, input);
        return response.data?.createAppUsageLog;
    }

    // WISHLIST
    async addProductToWishlist(queryArgs: MutationAddProductToWishlistArgs): Promise<Maybe<UserWishlist>> {
        const response = await this.genericMutationWithQueryArgs(addProductToWishlist, queryArgs);
        return response.data?.addProductToWishlist;
    }
    async deleteProductFromWishlist(queryArgs: MutationDeleteProductFromWishlistArgs): Promise<Maybe<number>> {
        const response = await this.genericMutationWithQueryArgs(deleteProductFromWishlist, queryArgs);
        return response.data?.deleteProductFromWishlist;
    }

    // VENDORS
    async listShowSmallBusinessVendors(queryArgs: QueryListShowSmallBusinessVendorsArgs): Promise<Array<Maybe<Vendor>>> {
        const response = await this.genericQuery(listShowSmallBusinessVendors, queryArgs);
        return response.data?.listShowSmallBusinessVendors?.items || [];
    }

    // GENERIC UTILITY
    private async genericQuery(query: string, queryArgs: any) {
        const operation = {
            authMode: this.isSignedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
            ...graphqlOperation(query, queryArgs)
        }

        return await AmplifyAPI.graphql(operation) as GraphQLResult<Query>;
    }

    private async genericMutation(mutation: string, input: any) {
        const operation = {
            authMode: this.isSignedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
            ...graphqlOperation(mutation, { input: input })
        }

        return await AmplifyAPI.graphql(operation) as GraphQLResult<Mutation>;
    }

    private async genericMutationWithQueryArgs(mutation: string, queryArgs: any) {
        const operation = {
            authMode: this.isSignedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
            ...graphqlOperation(mutation, queryArgs)
        }

        return await AmplifyAPI.graphql(operation) as GraphQLResult<Mutation>;
    }
}