import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { VideoProductCard } from '../shared/VideoProductCard';
import { ProductScroller } from './ProductScroller';
import { ProductRequestCard } from '../shared/ProductRequestCard';

import { recordEvent, autotrackMedia, PinpointCustomEvent } from '../../lib/analytics';
import { Episode, EpisodeProduct, useGetEpisodeProductsQuery } from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: "flex",
        justifyContent: 'space-around',
        flexWrap: 'nowrap',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        }
    },
    responsiveWrapper: {
        position: 'relative',
        paddingBottom: '56.25%', /* Player ratio: 100 / (1280 / 720) */
        width: '100%',
        maxWidth: '1280px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '95vw'
        },
    },
    videoTitle: {
        backgroundColor: 'black',
        justifySelf: 'center',
        opacity: '.75',
        position: 'absolute',
        textAlign: 'center',
        left: 10,
        right: 10,
        paddingTop: '8px',
        margin: 'auto'
    },
    videoTitleText: {
        color: 'white'
    },
    reactPlayer: {
        maxWidth: '1280px',
        maxHeight: '720px'
    },
    videoContainer: {
        position: 'relative',
        alignItems: 'center',
        textAlign: 'left',
        height: '100%',
        maxWidth: '100%'
    },
    productContainer: {
        top: 0,
        overflow: 'auto',
        height: '90vh',
        justifyContent: 'flex-end',
        [theme.breakpoints.down("md")]: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: 'auto',
            maxWidth: '95vw',
            height: 'auto'
        }
    }
}));

export function VideoView(props: VideoViewProps) {
    const classes = useStyles();
    const [playing, setPlaying] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [displayEpisodeTitle, setDisplayEpisodeTitle] = useState(true);

    const [uniqueEpisodeProducts, setUniqueProductsSet] = useState<Array<EpisodeProduct>>([]);

    const queryArgs = { episodeID: props.episode.id, active: true };
    const listEpisodeProductsResult = useGetEpisodeProductsQuery<Array<EpisodeProduct>>(queryArgs, {
        select: (result) => { return result.getEpisodeProducts?.items ?? [] }
    });

    useEffect(() => {
        if (listEpisodeProductsResult.data) {
            const productIDs = new Set();

            // Filter out the product IDs
            const filtered = listEpisodeProductsResult.data?.filter((product: EpisodeProduct) => {
                if (productIDs.has(product.productID)) return;

                productIDs.add(product.productID);
                return product;
            });

            setUniqueProductsSet(filtered);
        }
    }, [props.episode, listEpisodeProductsResult]);

    // This useeffect must be separate so we can send an event at the end. We only want it to run once, I think? 
    // If we pass playedSeconds it calls too many times, if we pass the props.episode then it doesn't get the playedSeconds
    useEffect(() => {
        return function cleanup() {
            if (props.episode) {
                recordEvent(PinpointCustomEvent.EPISODE_VIDEO_PLAYED_SECONDS, {
                    episodeID: props.episode.id,
                    showID: props.episode.showID,
                    playedSeconds: playedSeconds
                });
            }
        }
    }, [])

    const onVideoProgress = (event) => {
        setPlayedSeconds(event.playedSeconds);
    }

    const onVideoPlay = () => {
        setPlaying(true);
        setDisplayEpisodeTitle(false);
    }

    const onVideoPause = () => {
        setPlaying(false);
        setDisplayEpisodeTitle(true);
    }

    const onVideoMouseEnter = (event: any) => {
        if (event.target.attributes.autoplay) setDisplayEpisodeTitle(true);
        else if (event.target.children.length > 0) {
            let videoElement = event.target.children[0];
            if (videoElement.attributes.autoplay) setDisplayEpisodeTitle(true);
        }
    }

    const onVideoMouseLeave = (event: any) => {
        if (event.target.attributes.autoplay) setDisplayEpisodeTitle(false);
        else if (event.target.children.length > 0) {
            let videoElement = event.target.children[0];
            if (videoElement.attributes.autoplay) setDisplayEpisodeTitle(false);
        }
    }

    const onVideoReady = () => {
        autotrackMedia('react-episode-player', `episode_${props.episode?.id}` || '');
    }

    return (
        <Grid className={classes.rootContainer} item container spacing={2}>
            <Grid className={classes.videoContainer} item container spacing={2} direction="column" xs={12} sm={12} md={12} lg={8}>
                <Grid item className={classes.responsiveWrapper}>
                    {
                        displayEpisodeTitle &&
                        <div className={classes.videoTitle}>
                            <Typography className={classes.videoTitleText} variant="subtitle1">{props.episodeDisplayName}</Typography>
                        </div>
                    }
                    <ReactPlayer className={classes.reactPlayer}
                        url={props.episode?.video || ''}
                        playing={playing}
                        controls
                        width='100%'
                        height='100%'
                        onProgress={onVideoProgress}
                        onPlay={onVideoPlay}
                        onPause={onVideoPause}
                        onMouseEnter={onVideoMouseEnter}
                        onMouseLeave={onVideoMouseLeave}
                        onReady={onVideoReady}
                        playsinline={true}
                        config={{
                            file: {
                                attributes: {
                                    preload: 'metadata',
                                    id: 'react-episode-player'
                                },
                                hlsOptions: {
                                    xhrSetup: function (xhr: any, url: string) {
                                        // https://github.com/cookpete/react-player/issues/442
                                        xhr.open('GET', url + (props.episode.videoToken || ''));
                                    }
                                }
                            }
                        }}
                    />
                </Grid>
                {
                    !props.episode?.disableProductScroller && listEpisodeProductsResult.data && listEpisodeProductsResult.data.length > 0 &&
                    <>
                        <ProductScroller episodeProducts={listEpisodeProductsResult.data} playedSeconds={playedSeconds} />
                    </>
                }
            </Grid>
            <Grid className={classes.productContainer} item container spacing={4} xs={12} sm={12} md={12} lg={3}>
                {
                    uniqueEpisodeProducts.map(episodeProduct => {
                        return (
                            <Grid item container key={episodeProduct.id} xs={12} sm={12} md={6} lg={12} justify="center">
                                <VideoProductCard product={episodeProduct.product} />
                            </Grid>
                        )
                    })
                }
                <Grid item>
                    <ProductRequestCard episodeID={props.episode.id} />
                </Grid>
            </Grid>
        </Grid>
    )
}

interface VideoViewProps {
    episodeDisplayName: string
    episode: Episode
}