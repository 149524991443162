import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardContent, CardActions, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Maybe, EpisodeProduct, Product } from '../../lib/api';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { ResponsiveImage } from '../shared/ResponsiveImage';

import { generateSrcSet, ImageCategory } from '../../lib/generateSRCSet';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '95vw',
        magin: 'auto'
    },
    carouselContainer: {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        alignSelf: 'center',
        justifyContent: 'center',
        justifySelf: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: 'white',
    },
    carousel: {
        maxWidth: '95vw'
    },
    customSlide: {
        padding: '5px',
        marginRight: '6px',
        backgroundColor: 'white',
    },
    selectedSlide: {
        padding: '5px',
        marginRight: '6px',
        backgroundColor: theme.palette.voxi.primaryColor,
    },
    cardRoot: {
        width: '100%',
        maxWidth: '95vw',
        height: '150px',
        alignItems: 'center'
    },
    cardContent: {
        width: '100%',
        height: '70%',
        backgroundColor: 'white',
        justifyContent: 'center',
        // textAlign: 'center',
        overflow: 'scroll'
    },
    cardActions: {
        justifyContent: 'center'
    },
    productDescription: {
        maxHeight: '85px',
        overflow: 'scroll',
    },
    productCardText: {
        color: 'black'
    },
    moreInfoButton: {
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor
    },
}));

export function ProductScroller(props: ProductScrollerProps) {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState(0);
    const [currentProduct, setCurrentProduct] = useState<Maybe<Product>>(null);

    useEffect(() => {
        if (!props.episodeProducts) return;
        if (props.episodeProducts[0]) setCurrentProduct(props.episodeProducts[0].product);
    }, [props.episodeProducts])

    useEffect(() => {
        if (!props.episodeProducts || props.episodeProducts.length < 1) return;

        let secondsInMS = props.playedSeconds * 1000
        let selectedProductIndex = props.episodeProducts.findIndex(episodeProduct =>
            episodeProduct.startTimeMilliseconds <= secondsInMS
            && episodeProduct.endTimeMilliseconds > secondsInMS
        );

        if (selectedProductIndex < 0) selectedProductIndex = 0;

        setSelectedItem(selectedProductIndex);
        setCurrentProduct(props.episodeProducts[selectedProductIndex].product);
    }, [props.playedSeconds, props.episodeProducts])

    const onProductChange = (index: number) => {
        setSelectedItem(index);
        setCurrentProduct(props.episodeProducts[index].product);
    }

    return (
        <Grid className={classes.root} item container spacing={2} justify="center" alignItems="center" alignContent="center">
            <Grid item className={classes.carouselContainer} xs={12} md={6}>
                <Carousel className={classes.carousel}
                    centerMode={true}
                    showArrows={true}
                    showThumbs={false}
                    showIndicators={false}
                    autoPlay={false}
                    infiniteLoop={false}
                    selectedItem={selectedItem}
                    onChange={onProductChange}
                    onClickItem={onProductChange}
                    dynamicHeight={false}
                    swipeable={true}
                    centerSlidePercentage={40}
                >
                    {
                        props.episodeProducts.map((episodeProduct, index) => {
                            return (
                                <ProductScrollerItem key={`${episodeProduct.product.name}_${index}`} 
                                    className={classes.customSlide} 
                                    episodeProduct={episodeProduct} 
                                    index={index} 
                                    selectedItem={selectedItem} 
                                />
                            )
                        })
                    }
                </Carousel>
            </Grid>
            <Grid item xs={12} md={6}>
                <CurrentProductCard currentProduct={currentProduct} />
            </Grid>
        </Grid>
    )
}

interface ProductScrollerProps {
    episodeProducts: Array<EpisodeProduct>
    playedSeconds: number
}

function ProductScrollerItem(props) {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const [thumbnail] = useState(props.episodeProduct.product.mainPhotoURL?.replace('ORIGINAL', '200') || '');

    useEffect(() => {
        if (props.selectedItem === props.index
            || props.selectedItem + 3 > props.index) setLoaded(true);
    }, [props])

    const productSRCSetObject = generateSrcSet(ImageCategory.PRODUCT, props.episodeProduct.product.mainPhotoURL || '');

    return (
        <Grid item container
            className={props.selectedItem === props.index ? classes.selectedSlide : classes.customSlide}
        >
            {
                loaded && <ResponsiveImage src={thumbnail} srcSet={productSRCSetObject.srcSet} sizes={productSRCSetObject.sizes}/>
            }
        </Grid>
    )
}

function CurrentProductCard(props: CurrentProductCardProps) {
    const classes = useStyles();
    const history = useHistory();

    const moreInfoClicked = () => {
        history.push(`/products/${props.currentProduct?.id}`);
    }

    return (
        <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
                <Typography className={classes.productCardText} variant="subtitle2" gutterBottom>{props.currentProduct?.name}</Typography>
                <Typography className={classes.productCardText} variant="body2">{props.currentProduct?.description}</Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button className={classes.moreInfoButton} variant="outlined" size="small" onClick={moreInfoClicked}>
                    More Info
                </Button>
            </CardActions>
        </Card>
    )
}

interface CurrentProductCardProps {
    currentProduct: Maybe<Product>
}