import { Auth, Analytics } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const stage = process.env.REACT_APP_STAGE || 'dev';

/**
 * Takes an event name and set of data. Adds auth info and appID internally
 * @param eventName 
 * @param eventData 
 */
export async function recordEvent(eventName: PinpointCustomEvent, eventData: any) {
    const info = await Auth.currentCredentials();

    eventData = {
        appID: localStorage.getItem('voxiAppID') || 'unknown',
        ...eventData,
        identityID: info.identityId,
        authenticated: info.authenticated,
    }

    try {
        const currentUser = await Auth.currentUserInfo();
        if (currentUser) {
            eventData.userID = currentUser.attributes.sub;
            eventData.email = currentUser.attributes.email;
        }
    } catch (err) { }

    try {
        Analytics.record({
            name: eventName,
            attributes: eventData
        });
    } catch (err) {
        if (stage === 'local') {
            console.error(err);
            return;
        }

        Sentry.captureException(err, {
            tags: {
                type: 'PinpointAnalyticsError'
            },
            extra: {
                stringified: JSON.stringify(err),
                eventName,
                attributes: JSON.stringify(eventData)
            }
        });
    }
}

/**
 * Right now there is a bug in the personalize provider with the TIMESTAMP field
 * For now we just return and do nothing
 * @param domElementId 
 * @param itemId 
 */
export function autotrackMedia(domElementId: string, itemId: string) {
    return;
    try {
        Analytics.record({
            eventType: 'MediaAutoTrack',
            properties: {
                domElementId,
                itemId: itemId
            }
        }, 'AmazonPersonalize');
    } catch (err) {

    }
}

export function recordPersonalize(eventType: PinpointCustomEvent, itemId: string, eventValue: string) {
    Analytics.record({
        eventType: eventType,
        properties: { itemId, eventValue}
    }, 'AmazonPersonalize');
}

export enum PinpointCustomEvent {
    // PRODUCTS
    BUY_NOW="BUY_NOW",
    PRODUCT_DETAILS="PRODUCT_DETAILS",
    REQUEST_PRODUCT_INFO="REQUEST_PRODUCT_INFO",

    // EPISODES
    EPISODE_VIEW="EPISODE_VIEW",
    EPISODE_VIDEO_PLAYED_SECONDS="EPISODE_VIDEO_PLAYED_SECONDS",

    // EXCLUSIVES
    EXCLUSIVE_VIEW="EXCLUSIVE_VIEW",
    EXCLUSIVE_VIDEO_PLAYED_SECONDS="EXCLUSIVE_VIDEO_PLAYED_SECONDS",

    // WISHLIST
    ADD_TO_WISHLIST="ADD_TO_WISHLIST",
    REMOVE_FROM_WISHLIST="REMOVE_FROM_WISHLIST",

    // ADS
    BANNER_AD_CLICK="BANNER_AD_CLICK",
}