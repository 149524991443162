import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

import { MuiThemeProvider, CssBaseline, CircularProgress } from '@material-ui/core';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// issue with the @aws-amplify/analytics package if you try to use it separately?
import Amplify, { Analytics, AmazonPersonalizeProvider, Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import ResponsiveDrawer from './components/navigation/ResponsiveDrawer'
import { PoweredByVoxiIcon } from './components/shared/PoweredByVoxiIcon';

import './App.css';
import baseTheme from './theme';

import { Maybe, Show } from './lib/api';
import { toSentenceCase } from './lib/util'
import API from './lib/manualApi';
import { API as FetcherAPI } from './lib/fetcher';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import aws_exports from './aws-exports.js';

Analytics.addPluggable(new AmazonPersonalizeProvider());
Amplify.configure(aws_exports);

Analytics.autoTrack('session', {
    enable: true, // REQUIRED, turn on/off the auto tracking
    attributes: {
        appID: window.location.hostname.split('.')[0],
        referer: document.referrer
    },
    type: 'SPA'
});

const api = API.getInstance();

const version = process.env.REACT_APP_VERSION || '0.0.1';
const stage = process.env.REACT_APP_STAGE || 'dev';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: 120000, // two minutes in milliseconds
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            onError: (err) => {
                if (stage === 'local') {
                    console.error(err);
                    return;
                }

                Sentry.captureException(err, {
                    tags: {
                        type: 'ReactQueryOnError'
                    },
                    extra: {
                        stringified: JSON.stringify(err),
                        type: 'ReactQueryOnError'
                    }
                });
            }
        }
    }
});

// No need to run the error stuff for local environment
if (stage !== 'local') {
    Sentry.init({
        dsn: 'https://f3b8eadcacbe45bfb574df70178514c5@o462806.ingest.sentry.io/5466726',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.3, // We recommend adjusting this value in production, or using tracesSampler for finer control
        release: stage !== 'prod' ? `voxi-pwa@development-${version}` : `voxi-pwa@${version}`,
        environment: stage
    });
}

function App() {
    const [hostname] = useState(window.location.hostname);
    const [theme, setTheme] = useState(baseTheme);
    const [show, setShow] = useState<Maybe<Show>>(null);
    const [loading, setLoading] = useState(true);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [authState, setAuthState] = useState<AuthState>();
    const [user, setUser] = useState<any | undefined>();

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData: any) => {
            setAuthState(nextAuthState);
            setUser(authData)

            API.updateIsSignedIn(nextAuthState === AuthState.SignedIn);
            FetcherAPI.updateIsSignedIn(nextAuthState === AuthState.SignedIn);

            configurePersonalize(authData);

            if (stage === 'local') return;
            configureSentryUser(authData);
        });
    }, []);

    useEffect(() => {
        let appID = hostname.split('.')[0];
        // let appID = 'twochicksandahammer';
        // let appID = 'coopdreams';
        // let appID = 'kendev';

        window.addEventListener('error', err => {
            // prompt user to confirm refresh
            if (/Loading chunk [\d]+ failed/.test(err.message)) {
                console.log('WE GOT A CHUNK FAILURE');
                Sentry.captureException(err, {
                    tags: {
                        type: 'LoadingChunkFailed'
                    },
                    extra: {
                        stringified: JSON.stringify(err),
                    }
                });
                setErrorDialogOpen(true);
            }

            if (/Uncaught SyntaxError: Unexpected token '<'/.test(err.message)) {
                console.error('Probably a chunk failed:');
                Sentry.captureException(err, {
                    tags: {
                        section: 'Authenticator',
                        type: 'LoadingChunkFailed'
                    },
                    extra: {
                        stringified: JSON.stringify(err)
                    }
                });
                setErrorDialogOpen(true);
            }
        });

        const fetchShow = async () => {
            try {
                let user = await Auth.currentAuthenticatedUser();
                if (user) {
                    setAuthState(AuthState.SignedIn);
                    setUser(user);
                    API.updateIsSignedIn(true);
                    FetcherAPI.updateIsSignedIn(true);

                    configurePersonalize(user);

                    if (stage !== 'local') configureSentryUser(user);
                }
            } catch (err) {
                console.error(err);
            }

            const showResult = await api.getShowByHostname({ hostname: appID });
            setShow(showResult);

            document.title = showResult?.nameLong || 'Voxi';

            if (showResult?.appData) {
                // @ts-ignore
                theme.overrides!.MuiDrawer!.paper!.backgroundColor = showResult?.appData?.secondaryColor

                theme.palette.voxi.primaryColor = showResult.appData.primaryColor;
                theme.palette.voxi.textPrimaryColor = showResult.appData.textPrimaryColor;
                theme.palette.voxi.secondaryColor = showResult.appData.secondaryColor;
                theme.palette.voxi.tertiaryColor = showResult.appData.tertiaryColor;
                theme.palette.voxi.menuTextColor = showResult.appData.menuTextColor;
                theme.palette.voxi.accentColor = showResult.appData.accentColor;
                theme.palette.voxi.headBackgroundColor = showResult.appData.headBackgroundColor;
                theme.palette.voxi.headTextColor = showResult.appData.headTextColor;
                theme.palette.voxi.infoColor = showResult.appData.infoColor;
                theme.palette.voxi.productCaroselBackgroundColor = showResult.appData.productCaroselBackgroundColor;
                theme.palette.voxi.productButBackColor = showResult.appData.productButBackColor;
                theme.palette.voxi.productButTextColor = showResult.appData.productButTextColor;
                theme.palette.voxi.productBut2BackColor = showResult.appData.productBut2BackColor;
                theme.palette.voxi.productBut2TextColor = showResult.appData.productBut2TextColor;

                theme.palette.text.primary = showResult.appData.textPrimaryColor; // Primary text color over the background color (primary color);
            }

            const fontsResult = await api.listFontsByShow({ showID: showResult?.id });
            if (fontsResult) {
                fontsResult.forEach(font => {
                    // If the font location is BUTTON_TEXT, then it's easier to overwrite the styling on all button text by overwriting the MaterialUI css
                    if (font?.location === 'BUTTON_TEXT') {
                        if (theme?.overrides?.MuiButton?.root !== undefined) {
                            theme.overrides.MuiButton.root['fontFamily'] = toSentenceCase(font?.family) || 'Helvetica New';
                            theme.overrides.MuiButton.root['fontStyle'] = toSentenceCase(font?.style) || 'normal';
                            theme.overrides.MuiButton.root['fontWeight'] = toSentenceCase(font?.weight) || 'normal';
                        }
                    } else {
                        theme.fonts[`${font?.location}`].fontFamily = toSentenceCase(font?.family) || 'Helvetica New';
                        theme.fonts[`${font?.location}`].fontStyle = toSentenceCase(font?.style) || 'normal';
                        theme.fonts[`${font?.location}`].fontWeight = toSentenceCase(font?.weight) || 'normal';
                    }
                })
            }
            setTheme(theme);
            setLoading(false);

            localStorage.setItem('voxiAppID', appID);
            Analytics.autoTrack('pageView', {
                enable: true,
                eventName: 'PAGE_VIEW',
                attributes: {
                    appID: appID,
                    showID: showResult?.id
                },
                type: 'SPA',
                getUrl: () => {
                    // the default function
                    return window.location.origin + window.location.pathname;
                }
            });
        }

        fetchShow();
    }, [])

    return !loading ? (
        <div>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <CookiesProvider>
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            {errorDialogOpen && <ErrorDialogComponent open={true} />}
                            <ResponsiveDrawer show={show} authState={authState} user={user} />
                        </Router>
                    </QueryClientProvider>
                </CookiesProvider>
            </MuiThemeProvider>
        </div>
    ) : (
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', justifyItems: 'center', height: '100vh', alignItems: 'center', alignContent: 'center' }}>
                <div>
                    <CircularProgress color="secondary" size="3rem" />
                </div>
                <div>
                    <PoweredByVoxiIcon style={{ margin: 'auto', width: '65%' }} />
                </div>
            </div>
        )
}

export default App;

function configureSentryUser(authData: any) {
    if (authData) {
        const user: Sentry.User = {
            email: authData.attributes?.email || 'unknown',
            id: authData.attributes?.sub
        }
        Sentry.setUser(user);
    } else {
        Sentry.setUser(null);
    }
}

const dialogStyles = makeStyles(theme => ({
    errorDialog: {
        backgroundColor: 'white',
        color: 'black'
    },
    errorDialogButton: {
        fontWeight: 'bolder',
        border: '1px solid black',
        color: 'black'
    }
}));

function ErrorDialogComponent(props) {
    const classes = dialogStyles();
    const [open, setOpen] = useState(props.open)

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    classes: {
                        root: classes.errorDialog
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">{"An Error has occurred!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.errorDialog}>
                        Please try refreshing your browser. If the issue persists, please note that we will work to resolve it ASAP. If you would like
                            to report an issue, we encourage you to contact our support at <a href={`mailto:support@getvoxi.com?subject=${encodeURIComponent('Voxi Content Admin Support')}`}> support@getvoxi.com</a>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.location.reload()} autoFocus className={classes.errorDialogButton}>
                        Refresh
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function configurePersonalize(authData: any) {
    const userID = authData?.attributes?.sub;
    if (!userID) return;

    Analytics.record({
        eventType: 'Identify',
        properties: {
           userId: userID
        }
    }, 'AmazonPersonalize');
}