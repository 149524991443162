import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ReplacementProductRawIcon from '../../icons/replacement-product-icon.svg';


const useStyles = makeStyles((theme) => ({
    imageIcon: {
        height: '100%',
        width: '100%'
    }
}));

export function ReplacementIcon(props) {
    const classes = useStyles();

    return (
        <img className={classes.imageIcon} src={ReplacementProductRawIcon} {...props} alt="replacement product icon" />
    )
}