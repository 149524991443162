import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PoweredByVoxiRawIcon from '../../icons/powered-by-voxi-icon.svg';

const useStyles = makeStyles((theme) => ({
    imageIcon: {
        height: '100%',
        width: '100%'
    }
}));

export function PoweredByVoxiIcon(props) {
    const classes = useStyles();

    return (
        <img className={classes.imageIcon} src={PoweredByVoxiRawIcon} {...props} alt="powered by voxi" />
    )
}