import React from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    labelText: {
        color: theme.palette.voxi.textPrimaryColor,
    },
    select: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.voxi.textPrimaryColor,
        },
        '& .MuiSelect-iconOutlined': {
            color: theme.palette.voxi.textPrimaryColor,
        },
        width: 'fit-content',
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
    },
    menu: {
        '& .MuiPopover-paper': {
            backgroundColor: theme.palette.voxi.primaryColor
        }
    }
}));

export function SelectWithLabel({ id, title, value, onChange, selectOptions, otherProps }: SelectWithLabelProps) {
    const classes = useStyles();

    return (
        <>
            <InputLabel id={id} className={classes.labelText}>{title}</InputLabel>
            <Select className={classes.select}
                labelId={id}
                id={`${id}-select`}
                value={value}
                onChange={onChange}
                variant="outlined"
                margin='dense'
                MenuProps={{ className: classes.menu }}
                {...otherProps}
            >
                {
                    selectOptions.map((option) => {
                        return (
                            <MenuItem key={option.name} value={option}>{option.name}</MenuItem>
                        )
                    })
                }
            </Select>
        </>
    )
}

export interface SelectWithLabelProps {
    id: string
    title: string
    value: any
    onChange: Function | any
    selectOptions: any[]
    otherProps?: any
}