import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Grid, Box, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Maybe, ShowDesignMenuItem, Show, BannerAd, useListBannerAdsByShowQuery } from '../../lib/api';
import { PoweredByVoxiIcon } from '../shared/PoweredByVoxiIcon';

import { recordEvent, PinpointCustomEvent } from '../../lib/analytics';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup, IconDefinition, findIconDefinition, } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles((theme) => ({
    carouselGrid: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '500px'
        },
    },
    bannerImage: {
        maxWidth: '100%',
        maxHeight: '30vh',
        paddingLeft: '4px',
        paddingRight: '4px',
        alignSelf: 'center'
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '500px',
        },
        backgroundColor: theme.palette.voxi.secondaryColor,
        '&:hover': {
            borderColor: '#000000',
            border: 'solid',
            cursor: 'pointer',
        },
    },
    titleText: {
        color: theme.palette.voxi.accentColor,
        fontFamily: theme.fonts.MENU_BUTTON.fontFamily,
        fontWeight: theme.fonts.MENU_BUTTON.fontWeight,
        fontStyle: theme.fonts.MENU_BUTTON.fontStyle
    },
    infoText: {
        color: theme.palette.voxi.infoColor,
        fontFamily: theme.fonts.MENU_BUTTON.fontFamily,
        fontWeight: theme.fonts.MENU_BUTTON.fontWeight,
        fontStyle: theme.fonts.MENU_BUTTON.fontStyle
    },
    menuItemIcon: {
        borderColor: theme.palette.voxi.tertiaryColor,
        backgroundColor: theme.palette.voxi.tertiaryColor,
        width: '4.5rem',
        height: '4.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
    },
    poweredByVoxi: {
        width: '125px'
    }
}));

export function Home(props: HomeProps) {
    const classes = useStyles();
    const [menuItems, setMenuItems] = useState<Array<ShowDesignMenuItem>>(
        props.menuItems.filter((item) => item.showInHomeMenu)
    );
    const [posterURL, setPosterURL] = useState(props.posterURL || '');

    const queryArgs = { showID: props.show?.id };
    const showBannerAds = useListBannerAdsByShowQuery<Array<Maybe<BannerAd>>>(queryArgs, {
        enabled: !!props.show,
        select: (response) => { return response.listBannerAdsByShow?.items ?? [] }
    });

    const [displayCarousel, setDisplayCarousel] = useState(false);
    const [carouselItems, setCarouselItems] = useState<React.ReactElement[]>([]);

    useEffect(() => {
        if (!props.menuItems) return;

        // Forcibly ignore voice search and audioid for now
        setMenuItems(props.menuItems.filter((item) => item.showInHomeMenu && !(item.url === '/audioid' || item.url === '/voicesearch')));
        setPosterURL(props.posterURL || '');
    }, [props]);

    useEffect(() => {
        if (!props.show) return;

        const displayPoster = props.show.appData?.appPosterDisplayType === 0 ?? false;

        const display = ((showBannerAds && showBannerAds.data && showBannerAds.data.length > 0) || displayPoster) ? true : false;
        setDisplayCarousel(display);

        let imageElements: React.ReactElement[] = [];

        if (displayPoster) imageElements.push(
            <Slide key="show-poster" index={0}>
                <img src={posterURL} alt="show poster" className={classes.bannerImage} />
            </Slide>
        );

        showBannerAds.data?.forEach((bannerAd, index) => {
            if (!bannerAd) return;
            const adUrl = `https://s3.dualstack.us-east-2.amazonaws.com/${bannerAd.imageBucket}/${bannerAd.imageKey}`.replace('ORIGINAL', '480X270');

            const onBannerClick = async (banner: BannerAd) => {
                if (!banner.url) return;

                window.open(banner.url, '_blank');
                await recordEvent(PinpointCustomEvent.BANNER_AD_CLICK, {
                    showID: props.show?.id,
                    bannerID: banner.id,
                    url: banner.url
                });
            }

            const slideIndex = index + 1; // todo fix this
            imageElements.push(
                <Slide key={bannerAd.id} index={slideIndex} onClick={() => onBannerClick(bannerAd)}>
                    <img src={adUrl} alt="banner ad" className={classes.bannerImage} />
                </Slide>
            );
        });

        setCarouselItems(imageElements);
    }, [props.show, showBannerAds]);

    return (
        <Grid item container spacing={2} direction="column" alignContent="center" alignItems="center">
            {
                displayCarousel &&
                <Grid item xs={12} className={classes.carouselGrid}>
                    <CarouselProvider totalSlides={carouselItems.length}
                        naturalSlideWidth={100}
                        naturalSlideHeight={125}
                        infinite={true}
                        visibleSlides={1}
                        isIntrinsicHeight={true}
                        isPlaying={true}
                        interval={3000}
                        dragEnabled={carouselItems.length > 1}
                    >
                        <Slider>
                            {
                                carouselItems.map(element => {
                                    return element;
                                })
                            }
                        </Slider>
                        {
                            carouselItems.length > 1 &&
                            <DotGroup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                        }
                    </CarouselProvider>
                </Grid>
            }
            {
                menuItems.map((menuItem: ShowDesignMenuItem, index: number) => {
                    return (
                        <Grid item key={menuItem.id}>
                            <MenuItem key={menuItem.id} menuItem={menuItem} index={index} />
                        </Grid>
                    );
                })
            }
            <PoweredByVoxiIcon className={classes.poweredByVoxi} />
        </Grid>
    );
}

interface HomeProps {
    show: Show;
    menuItems: Array<ShowDesignMenuItem>;
    posterURL?: string;
    appPosterDisplayType: number;
}

function MenuItem(props: MenuItemProps) {
    const classes = useStyles();
    const history = useHistory();

    const iconInfo: any[] = props.menuItem.icon.split(" ");
    const iconLookup: IconLookup = {
        prefix: iconInfo[0],
        iconName: iconInfo[1].replace("fa-", ""),
    };
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup);

    const navigate = () => {
        history.push(props.menuItem.url);
    }

    return (
        <Paper className={classes.paper} onClick={navigate}>
            <Grid container spacing={2} direction="row">
                <Grid item container xs={3} direction="column" alignContent="center" alignItems="center" justify="center">
                    <Grid item>
                        <Box className={classes.menuItemIcon} borderRadius="60%" border={1}>
                            <FontAwesomeIcon icon={iconDefinition} color={props.menuItem.iconColor} size="3x" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container xs sm direction="column">
                    <Grid item xs>
                        <Typography className={classes.titleText} gutterBottom variant="subtitle1">
                            {props.menuItem.title}
                        </Typography>
                        <Typography className={classes.infoText} gutterBottom variant="body2">
                            {props.menuItem.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

interface MenuItemProps {
    menuItem: ShowDesignMenuItem;
    index: number;
}