import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { EpisodesContext } from './EpisodesStateProvider';

import { SelectWithLabel } from '../shared/SelectWithLabel';
import { EpisodeCard } from './EpisodeCard';
import { Episode as EpisodeModel, Show, ShowSeason, useListAppEpisodesCustomQuery, useGetShowSeasonsQuery } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        minWidth: '90vw',
        maxWidth: '95vw',
        maxHeight: '35vh',
        [theme.breakpoints.up("sm")]: {
            width: '350px'
        }
    },
    cardInfoDiv: {
        position: 'absolute',
        backgroundColor: theme.palette.voxi.secondaryColor,
        opacity: '0.75',
        bottom: '0px',
        width: '100%'
    },
    media: {
        position: 'relative',
        height: '35vh',
        width: '100%',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"
    },
    titleText: {
        color: theme.palette.voxi.accentColor
    },
    infoText: {
        color: theme.palette.voxi.infoColor
    },
    dropDownContainer: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
        display: 'flex',
    }
}));

export function Episodes(props: EpisodesProps) {
    const { state, dispatch } = useContext(EpisodesContext);
    const classes = useStyles();

    const queryArgs = { showID: props.show?.id };

    const listAppEpisodesResult = useListAppEpisodesCustomQuery<any, Error>(queryArgs, { 
        enabled: props.show ? true : false,
        select: (result) => { return result.listAppEpisodes?.items ?? [] }
    });

    const seasonsResult = useGetShowSeasonsQuery<Array<ShowSeason>>(queryArgs, {
        enabled: props.show ? true : false,
        select: (response) => { return response.getShowSeasons?.items ?? [] }
    });

    const [allEpisodes, setAllEpisodes] = useState<Array<EpisodeModel>>(listAppEpisodesResult.data ?? []);
    const [filteredEpisodes, setFilteredEpisodes] = useState<Array<EpisodeModel>>(listAppEpisodesResult.data ?? []);

    useEffect(() => {
        if (!props.show) return;
        dispatch({ type: 'setAllGroupOption', name: props.show.appData?.videoGroupingNamePlural || 'Seasons' });
    }, [props.show])

    useEffect(() => {
        if (listAppEpisodesResult.data) {
            const episodeData = listAppEpisodesResult.data;
            setAllEpisodes(episodeData);

            // In case it's not defined... Even though it should. But I saw a sentry error where it wasn't
            if (!state.selectedSeason || state.selectedSeason.season === -1) {
                setFilteredEpisodes(episodeData);
            } else {
                const filteredEpisodes = episodeData.filter(episode => episode.season === state.selectedSeason.season);
                setFilteredEpisodes(filteredEpisodes);
            }
        }
    }, [listAppEpisodesResult])

    useEffect(() => {
        if (seasonsResult.data) {
            const seasonsData = seasonsResult.data;
            let options: ShowSeason[] = [];
            seasonsData.forEach(season => {
                const hasEnabledEpisodes = listAppEpisodesResult.data?.find(episode => episode.season === season.season);
                if (hasEnabledEpisodes) {
                    const groupName = props.show.appData?.videoGroupingName || 'Season';
                    const displayName = season.name ? season.name : `${groupName} ${season.season}`;
                    options.push({ name: displayName, season: season.season });
                }
            });

            dispatch({ type: 'setSeasonsOptions', options: options })
        }
    }, [seasonsResult, listAppEpisodesResult])

    const onSeasonSelectChange = async (event) => {
        const season = event.target.value;
        await dispatch({ type: 'changeSeason', season: season })

        if (season.season === -1) {
            setFilteredEpisodes(allEpisodes);
            return
        }

        const filteredEpisodes = allEpisodes.filter(episode => episode.season === season.season);
        setFilteredEpisodes(filteredEpisodes);
    }

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.headerText} variant="h4">Episodes</Typography>
            </Grid>
            <Grid item container xs={12} direction="column" className={classes.dropDownContainer} alignItems="center">
                <SelectWithLabel id="seasons-selector"
                    title={`Filter By ${props.show?.appData?.videoGroupingName ?? 'Season'}:`}
                    value={state.selectedSeason}
                    onChange={onSeasonSelectChange}
                    selectOptions={state.seasonsOptions}
                />
            </Grid>
            {
                filteredEpisodes.map((episode: EpisodeModel, index: number) => {
                    return (
                        <Grid key={episode.id} item container xs={12} sm={12} md={6} lg={6} justify='center'>
                            <EpisodeCard key={episode.id} episode={episode} index={index} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

interface EpisodesProps {
    show: Show
}