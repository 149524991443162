import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button, Paper, CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { SelectWithLabel } from '../shared/SelectWithLabel';
import { ResponsiveImage } from '../shared/ResponsiveImage';
import { ReplacementIcon } from '../shared/ReplacementIcon';
import { ProductCard } from '../shared/ProductCard';

import {
    Maybe,
    Product as ProductType,
    ProductCategory,
    ProductEpisode,
    ProductLink,
    useGetProductQuery,
    useGetProductLinksQuery,
    useGetProductFeaturedEpisodesQuery,
    useProductInWishlistQuery,
    useGetProductCategoriesQuery,
    useListRelatedProductsQuery
} from '../../lib/api';

import { generateSrcSet, ImageCategory } from '../../lib/generateSRCSet';
import { recordEvent, recordPersonalize, PinpointCustomEvent } from '../../lib/analytics';
import { writeAppLog } from '../../lib/log';

import API from '../../lib/manualApi';
const api = API.getInstance();

const useStyles = makeStyles(theme => ({
    productGrid: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '95vw',
        borderRadius: "borderRadius",
        border: '1px solid black',
        [theme.breakpoints.down("sm")]: {
            minWidth: '90vw'
        },
        [theme.breakpoints.up("sm")]: {
            width: '500px'
        }
    },
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"
    },
    productImage: {
        width: '66%',
        alignSelf: 'center',
        backgroundColor: 'white',
        border: '1px solid black'
    },
    titleText: {
        color: theme.palette.voxi.accentColor,
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    brandText: {
        color: theme.palette.voxi.accentColor,
        fontSize: '10px',
        fontWeight: 600,
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    infoText: {
        color: theme.palette.voxi.infoColor,
        fontSize: '12px',
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    episodeText: {
        color: theme.palette.voxi.infoColor,
        fontSize: '10px',
        paddingLeft: '10px',
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    wishlistButton: {
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor
    },
    buyNowButton: {
        color: theme.palette.voxi.productBut2TextColor,
        backgroundColor: theme.palette.voxi.productBut2BackColor
    },
    replacementInfoContainer: {
        // backgroundColor: 'orange',
    },
    replacementIconDiv: {
        position: 'absolute',
        padding: 0,
        margin: 0,
        float: 'right',
        bottom: 0,
        right: 0
    },
    replacementIcon: {
        paddingRight: '10px',
        paddingBottom: '10px'
    },
    replacementInfo: {
        paddingLeft: '10px'
    },
    disclaimer: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        opacity: '0.6',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: '12px'
    }
}));

export function Product(props: ProductProps) {
    const classes = useStyles();

    const [user] = useState<any>(props.user);
    const [categoryOptions, setCategoryOptions] = useState<Array<any>>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [productInWishlist, setProductInWishlist] = useState<boolean>(false);

    const queryArgs = { id: props.match.params.productID };
    const getProductResult = useGetProductQuery<Maybe<ProductType>>(queryArgs, {
        select: (response) => { return response.getProduct }
    });

    const productQueryArgs = { productID: props.match.params.productID };
    const getProductLinksResult = useGetProductLinksQuery<Maybe<ProductLink>>(productQueryArgs, {
        select: (response) => {
            return response.getProductLinks?.items
                ? response.getProductLinks.items[0]
                : null
        }
    });

    const getProductFeaturedEpisodesResult = useGetProductFeaturedEpisodesQuery<Array<ProductEpisode>>(productQueryArgs, {
        select: (response) => { return response.getProductFeaturedEpisodes?.items ?? [] }
    });

    useGetProductCategoriesQuery<Array<ProductCategory>>(productQueryArgs, {
        select: (response) => { return response.getProductCategories?.items ?? [] },
        onSuccess: (productCategories) => {
            const opts = productCategories.map(category => {
                return { name: category.categoryName, value: category.categoryID }
            });
            setCategoryOptions(opts);

            if (opts && opts.length > 0) setSelectedCategory(opts[0]);
        }
    });

    const relatedQueryArgs = { categoryID: selectedCategory?.value || -1, showID: props.showID };
    const listRelatedProductsResult = useListRelatedProductsQuery<Array<ProductType>>(relatedQueryArgs, {
        enabled: categoryOptions.length > 0 && !!selectedCategory,
        select: (response) => { return response.listRelatedProducts?.items ?? [] }
    });

    // This gets whether the product is in user wishlist and sets the value
    useProductInWishlistQuery<boolean>(productQueryArgs, {
        enabled: !!props.user,
        select: (response) => { return response.productInWishlist ?? false },
        onSuccess: (res) => { setProductInWishlist(res) }
    });

    useEffect(() => {
        if (!props.match.params.productID || !props.showID) return;

        writeAppLog({ productID: props.match.params.productID });

        recordEvent(PinpointCustomEvent.PRODUCT_DETAILS, {
            productID: props.match.params.productID,
            showID: props.showID,
        });

        recordPersonalize(PinpointCustomEvent.PRODUCT_DETAILS, `product_${props.match.params.productID}`, PinpointCustomEvent.PRODUCT_DETAILS);

        return function cleanup() {
            document.body.style.cursor = "default";
        }
    }, [props.match.params.productID, props.showID])

    const onCategoryChange = async (event) => {
        const category = event.target.value
        setSelectedCategory(category);
    }

    const addToWishlistClick = async () => {
        await api.addProductToWishlist({ productID: props.match.params.productID });
        setProductInWishlist(true);

        recordEvent(PinpointCustomEvent.ADD_TO_WISHLIST, {
            productID: props.match.params.productID,
            showID: props.showID,
        });

        recordPersonalize(
            PinpointCustomEvent.ADD_TO_WISHLIST,
            `product_${props.match.params.productID}`,
            PinpointCustomEvent.ADD_TO_WISHLIST
        );
    }

    const removeFromWishlistClick = async () => {
        await api.deleteProductFromWishlist({ productID: props.match.params.productID });
        setProductInWishlist(false);

        recordEvent(PinpointCustomEvent.REMOVE_FROM_WISHLIST, {
            productID: props.match.params.productID,
            showID: props.showID,
        });

        recordPersonalize(
            PinpointCustomEvent.REMOVE_FROM_WISHLIST,
            `product_${props.match.params.productID}`,
            PinpointCustomEvent.REMOVE_FROM_WISHLIST
        );
    }

    const buyNowClick = async () => {
        if (!getProductLinksResult.data || getProductLinksResult.isError) return;

        await recordEvent(PinpointCustomEvent.BUY_NOW, {
            productID: props.match.params.productID,
            showID: props.showID,
        });

        recordPersonalize(
            PinpointCustomEvent.BUY_NOW,
            `product_${props.match.params.productID}`,
            PinpointCustomEvent.BUY_NOW
        );

        writeAppLog({ productID: props.match.params.productID, linkID: getProductLinksResult.data.id, logEntry: 'BuyNow' });
    }

    const scrollToHighlightedProduct = async () => {
        document.documentElement.style.cursor = "progress";
        await window.scrollTo({ top: 0, behavior: "smooth" });
    }

    const loadingIndicator = () => {
        getProductResult.isLoading
            ? document.documentElement.style.cursor = "progress"
            : document.documentElement.style.cursor = "default";
    }

    const renderWishlistButton = () => {
        if (!user
            || !getProductLinksResult.data?.fullURL
            || getProductLinksResult.data?.type !== 0) return null;

        return !productInWishlist
            ? (
                <Grid item>
                    <Button variant="contained" className={classes.wishlistButton} startIcon={<FavoriteBorderIcon />} onClick={addToWishlistClick}>
                        Add To Wishlist
                    </Button>
                </Grid>
            ) : (
                <Grid item>
                    <Button variant="contained" className={classes.wishlistButton} startIcon={<FavoriteIcon />} onClick={removeFromWishlistClick}>
                        Remove From Wishlist
                    </Button>
                </Grid>
            )
    }

    let vendorLogoURL = `https://s3.dualstack.us-east-2.amazonaws.com/${getProductResult.data?.vendorLogoBucket}/${getProductResult.data?.vendorLogoKey}`;
    let originalProductURL = `https://s3.dualstack.us-east-2.amazonaws.com/${getProductResult.data?.originalProductPhotoBucket}/${getProductResult.data?.originalProductPhotoKey}`;

    let mainPhotoSrcSetObject = generateSrcSet(ImageCategory.PRODUCT, getProductResult.data?.mainPhotoURL || '');
    let vendorLogoSrcSetObject = generateSrcSet(ImageCategory.PRODUCT, vendorLogoURL);
    let originalProductSrcSetObject = generateSrcSet(ImageCategory.PRODUCT, originalProductURL);

    return (
        <Grid item container spacing={2} direction="column" alignContent="center" alignItems="center">
            <Grid item>
                <Typography variant="h4" className={classes.headerText}>Product Details</Typography>
            </Grid>
            {
                getProductResult.isLoading || getProductLinksResult.isLoading
                    ? <CircularProgress />
                    : <Grid item container spacing={1} direction="column" alignContent="center">
                        <Paper className={classes.productGrid}>
                            <Grid className={classes.productImage} item >
                                <ResponsiveImage src={getProductResult.data?.mainPhotoURL} srcSet={mainPhotoSrcSetObject.srcSet} sizes={mainPhotoSrcSetObject.sizes}>
                                    {
                                        getProductResult.data?.replacementProduct &&
                                        <Tooltip title='This is a replacement product' placement='top'>
                                            <div className={classes.replacementIconDiv}>
                                                <ReplacementIcon className={classes.replacementIcon} height="62px" />
                                            </div>
                                        </Tooltip>
                                    }
                                </ResponsiveImage>
                            </Grid>
                            <Grid item style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                <Typography className={classes.titleText}>{getProductResult.data?.name || ''}</Typography>
                                <Typography className={classes.brandText}>{getProductResult.data?.brand?.toUpperCase() || ''}</Typography>
                                {
                                    getProductResult.data?.replacementProduct ?
                                        <Typography className={classes.disclaimer}>*The original product, that was featured in the Episode, is no longer available to purchase. The product listed here is a similiar product.</Typography>
                                        : null
                                }
                            </Grid>
                            <Grid item style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                <Typography className={classes.infoText}>{getProductResult.data?.description || ''}</Typography>
                            </Grid>
                            {
                                getProductFeaturedEpisodesResult.data && getProductFeaturedEpisodesResult.data.length > 0 &&
                                <Grid container spacing={1} style={{ marginTop: '5px' }}>
                                    <Grid item xs={8} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                        <Typography className={classes.titleText}>Featured In These Episodes:</Typography>
                                        {
                                            getProductFeaturedEpisodesResult.data.map(productEpisode => {
                                                let season = productEpisode.episodeSeasonName ? productEpisode.episodeSeasonName : `Season ${productEpisode.episodeSeason}`;
                                                let displayName = `${season}, Episode ${productEpisode.episodeNumber}: ${productEpisode.episodeName}`
                                                return (
                                                    <Typography key={productEpisode.id} className={classes.episodeText}>{displayName}</Typography>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid item xs={4}>
                                        {
                                            getProductResult.data?.vendorLogoBucket && getProductResult.data?.vendorLogoKey
                                                ? <img src={vendorLogoURL} srcSet={vendorLogoSrcSetObject.srcSet} sizes={vendorLogoSrcSetObject.sizes} alt='' style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                getProductResult.data?.replacementProduct && getProductResult.data.originalProductName
                                    ? <Grid container spacing={1} className={classes.replacementInfoContainer} style={{ paddingLeft: '16px', paddingRight: '16px', marginTop: '10px' }}>
                                        <Grid item xs={12} style={{ padding: 0 }}>
                                            <Typography className={classes.titleText} >{
                                                getProductResult.data?.originalProductName && getProductResult.data?.originalProductURL
                                                    ? <Link to={{ pathname: getProductResult.data.originalProductURL }} target="_blank" style={{ textDecoration: 'none' }}>
                                                        {`${getProductResult.data.originalProductName} - Original Product`}
                                                    </Link>
                                                    : getProductResult.data?.originalProductName ? `Original Product - ${getProductResult.data?.originalProductName}` : 'Unknown Product'
                                            }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {
                                                getProductResult.data?.originalProductPhotoBucket && getProductResult.data?.originalProductPhotoKey
                                                    ? <ResponsiveImage src={originalProductURL} srcSet={originalProductSrcSetObject.srcSet} sizes={originalProductSrcSetObject.sizes} />
                                                    : <img src='https://s3.us-east-2.amazonaws.com/voxi-media-content-prod/ASSETS/PRODUCT-NOT-FOUND.PNG' alt='replacement pic unavailable' style={{ maxHeight: '100%', maxWidth: '100%' }} /> // TODO: make this an asset instead
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.infoText}>{getProductResult.data?.originalProductDescription || 'No description available'}</Typography>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                        </Paper>
                    </Grid>
            }
            <Grid item container spacing={2} direction="row" alignContent="center" justify="center">
                {renderWishlistButton()}
                {
                    getProductLinksResult.data?.fullURL &&
                    <Grid item>
                        <Link to={{ pathname: getProductLinksResult.data.fullURL }} target="_blank" onClick={buyNowClick} style={{ textDecoration: 'none' }}>
                            <Button variant="contained" className={classes.buyNowButton} startIcon={getProductLinksResult.data.type === 0 ? <ShoppingCartIcon /> : <></>}>
                                {getProductLinksResult.data.type === 0 ? 'Buy Now' : 'More Info'}
                            </Button>
                        </Link>
                    </Grid>
                }
            </Grid>
            <Grid item>
                <SelectWithLabel id="category-selector"
                    title="More Like This:"
                    value={selectedCategory}
                    onChange={onCategoryChange}
                    selectOptions={categoryOptions}
                />
            </Grid>
            <Grid item container spacing={2}>
                {
                    listRelatedProductsResult.data?.map(product => {
                        return (
                            <Grid item container key={product.id} xs={12} sm={12} md={6} lg={6} justify="center">
                                <ProductCard product={product} onClick={scrollToHighlightedProduct} />
                            </Grid>
                        )
                    })
                }
            </Grid>
            {/* Just testing a method of changing the cursor icon while stuff is loading for better ux */}
            {loadingIndicator()}
        </Grid>
    )
}


interface ProductProps {
    showID: number
    user: any
    match: { params: { productID } }
}