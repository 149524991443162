import React, { createContext, useReducer, useMemo } from 'react';

const allGroupOption = { name: 'All Seasons', season: -1 };

const initialState: any = {
    allGroupOption: allGroupOption,
    selectedSeason: allGroupOption,
    seasonsOptions: []
}

export const EpisodesContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'setAllGroupOption':
            return {
                ...state,
                allGroupOption: { name: `All ${action.name}`, season: -1 }
            }
        case 'changeSeason':
            return {
                ...state,
                selectedSeason: action.season
            };
        case 'setSeasonsOptions':
            const newOptions = action.options;
            newOptions.unshift(state.allGroupOption);

            let currentSelection = newOptions.find(season => season.season === state.selectedSeason?.season);
            if (!currentSelection) currentSelection = state.allGroupOption;

            return {
                ...state,
                selectedSeason: currentSelection,
                seasonsOptions: newOptions
            }
        default:
            throw new Error('Invalid action type');
    }
}

const EpisodesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch])

    return (
        <EpisodesContext.Provider value={contextValue}>
            {children}
        </EpisodesContext.Provider>
    )
}

export default EpisodesContextProvider;