import React, { useState, useEffect } from 'react';

import { VideoView } from './VideoView';
import { PhotoCarouselView } from './PhotoCarouselView';

import { recordEvent, recordPersonalize, PinpointCustomEvent } from '../../lib/analytics';
import { writeAppLog } from '../../lib/log';

import API from '../../lib/manualApi';
const api = API.getInstance();

export function Episode(props: EpisodeProps) {
    const [view, setView] = useState<any>(null);

    useEffect(() => {
        const fetchEpisode = async () => {
            const episodeResult = await api.getEpisode({ id: props.match.params.episodeID });
            if (episodeResult && episodeResult.enabled) {
                let episodeDisplayName = `S${episodeResult.season}E${episodeResult.number}: ${episodeResult.name}`;
                if (episodeResult.seasonName) episodeDisplayName = `${episodeResult.seasonName} - ${episodeResult.name}`;

                switch (episodeResult.posterType) {
                    case 0: // Poster Only
                        break;
                    case 2: // Poster w/ Custom Video
                        setView(<VideoView episode={episodeResult} episodeDisplayName={episodeDisplayName} />)
                        break;
                    case 4: // Poster w/ Photo Carousel
                        setView(<PhotoCarouselView episodeID={props.match.params.episodeID} episodeDisplayName={episodeDisplayName} episodeDisclaimer={episodeResult.disclaimer} />)
                        break;
                    default:
                        break;
                }

                await recordEvent(PinpointCustomEvent.EPISODE_VIEW, {
                    episodeID: props.match.params.episodeID,
                    showID: episodeResult?.showID
                });

                writeAppLog({ logEntry: 'fingerprintEpisode', episodeID: props.match.params.episodeID });

                recordPersonalize(
                    PinpointCustomEvent.EPISODE_VIEW,
                    `episode_${props.match.params.episodeID}`,
                    PinpointCustomEvent.EPISODE_VIEW
                );
            }
        }

        fetchEpisode();
    }, [props.match.params.episodeID])

    return (
       view
    );
}

interface EpisodeProps {
    match: { params: { episodeID } }
}