import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";

const useStyles = makeStyles((theme) => ({
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(5rem)"
    },
    "100%": {
      opacity: .75,
      transform: "translateY(0)"
    }
  },
  arrowButton: {
    position: "fixed",
    animation: `$fadeIn .15s ease-in-out`,
    color: "white",
    backgroundColor: "grey",
    height: "3vw",
    width: "3vw",
    minHeight: 30,
    minWidth: 30,
    maxHeight: 50,
    maxWidth: 50,
    opacity: .75,
    right: "5vw",
    bottom: "8%",
    borderRadius: "50%",
    "&:hover": {
        background: "grey",
        cursor: "pointer",
        opacity: 1
    }
  },
}));

export function BackToTopButton() {
  const classes = useStyles();
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 50){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 50){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)
  // todo: Add an animation for removing the button when the page is scrolled back to the top? Maybe find a better way to make the tooltip text larger
  return showScroll ? (<Tooltip title={<h3>Back to the top</h3>}><ArrowUpwardOutlinedIcon className={classes.arrowButton} onClick={scrollTop}>Back to top</ArrowUpwardOutlinedIcon></Tooltip>) : null;
}
