import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    body: {
        backgroundColor: '#ffffff'
    },
    center: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    smartphone: {
        position: 'relative',
        width: '407px',
        height: '760px',
        margin: 'auto',
        border: '16px black solid',
        borderTopWidth: '60px',
        borderBottomWidth: '60px',
        borderRadius: '36px',
        '&::before': {
            content: '""',
            display: 'block',
            width: '60px',
            height: '5px',
            position: 'absolute',
            top: '-30px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#333',
            borderRadius: '10px'
        },
        '&::after': {
            content: '""',
            display: 'block',
            width: '35px',
            height: '35px',
            position: 'absolute',
            left: '50%',
            bottom: '-65px',
            transform: 'translate(-50%, -50%)',
            background: '#333',
            borderRadius: '50%'
        },
    },
    content: {
        width: '375px',
        height: '640px',
        background: ''
    }
}));

export default function Demo() {
    const classes = useStyles();

    useEffect(() => {
        const rootElementsArray = document.getElementsByClassName('jss2'); // makeStyles-root-2
        const mainDiv = rootElementsArray[0];
        mainDiv.classList.remove('jss2');
        mainDiv.classList.add(classes.body);

        const appBarElementsArray = document.getElementsByClassName('jss4'); // makeStyles-appBar-4
        const appBar = appBarElementsArray[0];

        // @ts-ignore
        appBar.style.visibility = 'hidden';

        return function cleanup() {
            const elementsArray = document.getElementsByClassName(classes.body);
            const mainDiv = elementsArray[0];
            mainDiv.classList.add('jss2'); // makeStyles-root-2

            const appBarElementsArray = document.getElementsByClassName('jss4'); // makeStyles-appBar-4
            const appBar = appBarElementsArray[0];

            // @ts-ignore
            appBar.style.visibility = 'inherit';
        }
    }, []);

    return (
        <div className={classes.center}>
            <div className={classes.smartphone}>
                <div className={classes.content}>
                    <iframe src="/" style={{ width: '100%', border: 'none', height: '100%' }} title="Root Application" />
                </div>
            </div>
        </div>
    )
}

export interface DemoProps { }