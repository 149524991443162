import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Card, CardMedia, CardActionArea, CardContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { generateSrcSet, ImageCategory } from '../../lib/generateSRCSet';

import { ExclusiveVideoContent } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up("sm")]: {
            width: '592px' // 16x9 ratio
        },
        [theme.breakpoints.down("sm")]: {
            width: '90vw',
            maxWidth: '592px'
        }
    },
    cardInfoDiv: {
        position: 'absolute',
        backgroundColor: theme.palette.voxi.secondaryColor,
        opacity: '0.75',
        bottom: '0px',
        width: '100%'
    },
    media: {
        position: 'relative',
        height: '35vh',
        width: '100%',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    titleText: {
        color: theme.palette.voxi.accentColor,
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    infoText: {
        color: theme.palette.voxi.infoColor,
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    descriptionText: {
        color: theme.palette.voxi.infoColor,
        maxHeight: '10%',
        overflowY: 'auto',
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    descriptionButton: {
        float: 'right',
        color: theme.palette.voxi.infoColor,
    },
    expandedDescriptionButton: {
        float: 'right',
        color: theme.palette.voxi.infoColor,
        transform: 'rotate(180deg)',
    }
}));

const rootS3URL = 'https://s3.us-east-2.amazonaws.com';

export function ExclusiveVideoCard(props: ExclusiveVideoCardProps) {
    const classes = useStyles();
    const history = useHistory();
    const [episodeDisplayName] = useState(props.exclusiveVideo.name);

    const [thumbnail] = useState(`${rootS3URL}/${props.exclusiveVideo.posterBucket}/${props.exclusiveVideo.posterKey?.replace('ORIGINAL', '640X360').replace('PNG', 'JPG')}`);
    const [expanded, setExpanded] = React.useState(false);
    const [description, setDescription] = useState('');

    const imageLoadError = async (event) => {
        event.target.onerror = null;

        if (props.exclusiveVideo.posterKey) {
            event.target.src = `${rootS3URL}/voxi-media-content-${process.env.REACT_APP_STAGE}/ASSETS/PRODUCT-NOT-FOUND.PNG`;
            event.target.srcset = '';
            event.target.sizes = '';
        }
    }

    const navigate = () => {
        history.push(`/exclusives/${props.exclusiveVideo.id}`);
    }

    const handleExpandClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!expanded) setDescription(props.exclusiveVideo.description || '');
        else setDescription('');

        setExpanded(!expanded);
    };

    const posterURLSRCSetObject = generateSrcSet(ImageCategory.EXCLUSIVE_POSTER, thumbnail);

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={navigate} disableRipple>
                <CardMedia className={classes.media}
                    component='img'
                    src={thumbnail}
                    srcSet={posterURLSRCSetObject.srcSet}
                    sizes={posterURLSRCSetObject.sizes}
                    image={thumbnail} 
                    title="Exclusive Video Poster"
                    onError={imageLoadError}
                />
                <CardContent className={classes.cardInfoDiv}>
                    <Typography className={classes.titleText} variant="h5" component="p">
                        {props.exclusiveVideo.name}
                        <IconButton className={expanded ? classes.expandedDescriptionButton : classes.descriptionButton}>
                            <ExpandLessIcon onClick={handleExpandClick} />
                        </IconButton>
                    </Typography>
                    <Typography className={classes.infoText} variant="subtitle2" component="p">
                        {episodeDisplayName}
                    </Typography>
                    <Typography className={classes.descriptionText} variant="caption" component="p">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

interface ExclusiveVideoCardProps {
    exclusiveVideo: ExclusiveVideoContent
    index: number
}