export const getShowByHostnameCustom = /* GraphQL */ `
  query GetShowByHostnameCustom($hostname: String!) {
    getShowByHostname(hostname: $hostname) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      appID
      faqContent
      faviconsURL
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        copyright
        privacyPolicy
        appPosterDisplayType
        googleTrackingID
        facebookAppID
        facebookPageURL
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
      }
      productRequestConfiguration {
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;

export const listAppEpisodesCustom = /* GraphQL */ `
  query ListAppEpisodesCustom($showID: Int!, $limit: Int) {
    listAppEpisodes(showID: $showID, limit: $limit) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        duration
        releaseToApp
        enableHeaderButton
        headerButtonText
        headerButtonURL
        videoToken
      }
    }
  }
`;

export const getProductCustom = /* GraphQL */ `
  query GetProductCustom($id: Int!) {
    getProduct(id: $id) {
      id
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
`;

export const listRelatedProductsCustom = /* GraphQL */ `
  query ListRelatedProductsCustom($categoryID: Int!, $showID: Int!) {
    listRelatedProducts(categoryID: $categoryID, showID: $showID) {
      items {
        id
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;

export const getVendorCustom = /* GraphQL */ `
  query GetVendorCustom($id: ID!) {
    getVendor(id: $id) {
      id
      name
      type
      active
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
    }
  }
`;