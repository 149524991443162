export enum ImageCategory {
    EPISODE_PHOTO,
    EPISODE_POSTER,
    EXCLUSIVE_POSTER,
    PRODUCT
}

export function generateSrcSet(category: ImageCategory, imageURL: string): CategoryURLObject {
    let categorySizes: string[] = [''];
    let sizesArray: any = [];
    let sizeScalar: number = 1;

    switch (category) {
        case ImageCategory.EPISODE_PHOTO:
        case ImageCategory.EPISODE_POSTER:
        case ImageCategory.EXCLUSIVE_POSTER:
            categorySizes = ['1920X1080', '1280X720', '640X360', '480X270', '160X90'];
            sizeScalar = 1.2;
            break;
        case ImageCategory.PRODUCT:
            categorySizes = ['600', '400', '200', '100', '50'];
            sizeScalar = 2.5;
            break;
        default:
            throw new Error(`Category ${category} has not been implemented`);
    }

    const matchString = '-ORIGINAL';

    const srcSetURLS = categorySizes.map(size => {
        // If the URL doesn't include 'ORIGINAL' for whatever reason, just keep the link as it is
        if (!imageURL.includes(matchString)) return imageURL;

        // Replace the word 'ORIGINAL' with the size, effectively grabbing a different sized image
        const sizedURL = imageURL.replace(matchString, `-${size}`);

        // Grab the width from the sizes
        const imageWidth = size.includes('X') ? size.split('X')[0] : size;

        // And push it to the categoryURLObject. This will be useful for setting the size of the srcset programatically
        sizesArray.push(imageWidth)

        // Return the string interpolated srcset
        return `${sizedURL} ${imageWidth}w`;
    })

    return {
        sizes: convertWidthsToStyles(sizesArray, sizeScalar), // Convert the widths into a string that can be used for sizes
        srcSet: srcSetURLS.join(', ') // Convert the array of URL's into a single string, so that it can be used for the srcSet
    }
}

function convertWidthsToStyles(widthArray, sizeScalar) {
    if (widthArray.length === 0) return;
    let stylesArray = widthArray.map(width => {
        let widthInt = parseInt(width);
        return `(min-width: ${widthInt * sizeScalar}px) ${width}px`
    })

    return stylesArray.join(', ');
}

interface CategoryURLObject {
    sizes: string,
    srcSet: string
}