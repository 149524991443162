import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    voxi: {
      primaryColor: React.CSSProperties['color'],
      textPrimaryColor: React.CSSProperties['color'],
      secondaryColor: React.CSSProperties['color'],
      tertiaryColor: React.CSSProperties['color'],
      accentColor: React.CSSProperties['color'],
      infoColor: React.CSSProperties['color'],
      headBackgroundColor: React.CSSProperties['color'],
      headTextColor: React.CSSProperties['color'],
      menuTextColor: React.CSSProperties['color'],
      productCaroselBackgroundColor: React.CSSProperties['color'],
      productButTextColor: React.CSSProperties['color'],
      productButBackColor: React.CSSProperties['color'],
      productBut2TextColor: React.CSSProperties['color'],
      productBut2BackColor: React.CSSProperties['color']
    },
  }
  interface PaletteOptions {
    voxi?: {
      primaryColor: React.CSSProperties['color']
      textPrimaryColor: React.CSSProperties['color']
      secondaryColor: React.CSSProperties['color']
      tertiaryColor: React.CSSProperties['color']
      accentColor: React.CSSProperties['color']
      infoColor: React.CSSProperties['color']
      headBackgroundColor: React.CSSProperties['color']
      headTextColor: React.CSSProperties['color']
      menuTextColor: React.CSSProperties['color']
      productCaroselBackgroundColor: React.CSSProperties['color']
      productButTextColor: React.CSSProperties['color']
      productButBackColor: React.CSSProperties['color']
      productBut2TextColor: React.CSSProperties['color']
      productBut2BackColor: React.CSSProperties['color']
    },
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    border: {
      borderColor: React.CSSProperties['color'],
      borderWidth: React.CSSProperties['width'],
    },

    fonts: {
      APP_HEADER: {
        fontFamily: React.CSSProperties['fontFamily'],
        fontWeight: React.CSSProperties['fontWeight']
        fontStyle: React.CSSProperties['fontStyle']
      },
      MENU_BUTTON: {
        fontFamily: React.CSSProperties['fontFamily'],
        fontWeight: React.CSSProperties['fontWeight']
        fontStyle: React.CSSProperties['fontStyle']
      },
      PAGE_TITLE_TEXT: {
        fontFamily: React.CSSProperties['fontFamily'],
        fontWeight: React.CSSProperties['fontWeight']
        fontStyle: React.CSSProperties['fontStyle']
      },
      CARD_INFO: {
        fontFamily: React.CSSProperties['fontFamily'],
        fontWeight: React.CSSProperties['fontWeight']
        fontStyle: React.CSSProperties['fontStyle']
      },
      BUTTON_TEXT: {
        fontFamily: React.CSSProperties['fontFamily'],
        fontWeight: React.CSSProperties['fontWeight']
        fontStyle: React.CSSProperties['fontStyle']
      },
      DEFAULT: {
        fontFamily: React.CSSProperties['fontFamily'],
        fontWeight: React.CSSProperties['fontWeight']
        fontStyle: React.CSSProperties['fontStyle']
      },
    },
  }
  interface ThemeOptions {
    border?: {
      borderColor: React.CSSProperties['color']
      borderWidth: React.CSSProperties['width']
    },
    fonts: {
      APP_HEADER?: {
        fontFamily?: React.CSSProperties['fontFamily'],
        fontWeight?: React.CSSProperties['fontWeight']
        fontStyle?: React.CSSProperties['fontStyle']
      },
      MENU_BUTTON?: {
        fontFamily?: React.CSSProperties['fontFamily'],
        fontWeight?: React.CSSProperties['fontWeight']
        fontStyle?: React.CSSProperties['fontStyle']
      },
      PAGE_TITLE_TEXT?: {
        fontFamily?: React.CSSProperties['fontFamily'],
        fontWeight?: React.CSSProperties['fontWeight']
        fontStyle?: React.CSSProperties['fontStyle']
      },
      CARD_INFO?: {
        fontFamily?: React.CSSProperties['fontFamily'],
        fontWeight?: React.CSSProperties['fontWeight']
        fontStyle?: React.CSSProperties['fontStyle']
      },
      BUTTON_TEXT?: {
        fontFamily?: React.CSSProperties['fontFamily'],
        fontWeight?: React.CSSProperties['fontWeight']
        fontStyle?: React.CSSProperties['fontStyle']
      },
      DEFAULT?: {
        fontFamily?: React.CSSProperties['fontFamily'],
        fontWeight?: React.CSSProperties['fontWeight']
        fontStyle?: React.CSSProperties['fontStyle']
      },
    },
  }
}

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xl;
    lg;
    md;
    sm;
    tiny;
    xs;
  }
}

// colors
const primary = "#b3294e";
const secondary = "#4829B2";
const drawer = "#282733";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const background = "#f5f5f5";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// borders
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1300; // Defaulted to 1500
const md = 960;
const sm = 600;
const tiny = 450;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    voxi: {
      primaryColor: '#1D1D1D',
      textPrimaryColor: '#FFFFFF',
      secondaryColor: '#3F4045',
      tertiaryColor: '#FFFFFF',
      accentColor: '#888888',
      infoColor: '#BBBBBB',
      headBackgroundColor: '#FFFFFF',
      headTextColor: '#000000',
      menuTextColor: '#FFFFFF',
      productCaroselBackgroundColor: '#888888',
      productButTextColor: '#444444',
      productButBackColor: '#AAAAAA',
      productBut2TextColor: '#444444',
      productBut2BackColor: '#aaaaaa',

    },

    common: {
      black,
      // @ts-ignore
      darkBlack
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background
    },
    // spacing: spacing
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      tiny,
      xs
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static"
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      }
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: drawer
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal'
      },
    },
  },
  props: {
    MuiTextField: {
      size: 'small'
    }
  },
  typography: {
    
  },
  fonts: {
    APP_HEADER: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: "normal",
    },
    MENU_BUTTON: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: "normal",
    },
    PAGE_TITLE_TEXT: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: "normal",
    },
    //
    CARD_INFO: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: "normal",
    },
    BUTTON_TEXT: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: "normal",
    },
    DEFAULT: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: "normal",
    },
  },
});

export default responsiveFontSizes(theme);