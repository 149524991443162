const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://qn2yj3dt5nch7bw2dbzxx4bhrm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_pools_id": "us-east-2_zYsecdS8p",
    "aws_user_pools_web_client_id": "365kt8hhpjsf0dfdc3spop37pg",
    "aws_cognito_identity_pool_id": "us-east-2:25a6ecca-0722-4b5d-bcdf-831ee24ec0a7",
    "aws_cognito_region": "us-east-2",
    "aws_mobile_analytics_app_id": "7670b87693184e3a807448a4c13d2f36",
    "aws_mobile_analytics_app_region": "us-east-1",
    "AmazonPersonalize": {
        "trackingId": "90b1f90d-a884-4461-93a6-34047873ce65",
        "region": "us-east-2",
        "flushSize": 10,
        "flushInterval": 5000
    }
}
    
export default awsmobile;