import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import useSnackbars from '../../hooks/useSnackbars';

import { Hub } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, AUTH_CHANNEL } from '@aws-amplify/ui-components';

// Theming:
// https://docs.amplify.aws/ui/customization/theming/q/framework/react

let formFields = [
    {
        type: 'email',
        label: 'Email Address *',
        placeholder: 'Enter your email address',
        required: true,
    },
    {
        type: 'password',
        label: 'Password  *',
        placeholder: 'Enter your password',
        required: true,
    },
    {
        type: 'given_name',
        label: 'First Name *',
        placeholder: 'Enter your first name',
        required: true,
    },
    {
        type: 'family_name',
        label: 'Last Name *',
        placeholder: 'Enter your first name',
        required: true,
    },
    {
        type: 'phone_number',
        label: 'Phone Number *',
        placeholder: '1231231234',
        required: true,
    }
]

const stage = process.env.REACT_APP_STAGE || 'dev';
if (stage === 'dev' || stage === 'local') {
    formFields.push(
        {
            type: 'gender',
            label: 'Gender *',
            placeholder: 'This is only required in development',
            required: true,
        }
    )
}

export function Authenticator(props) {
    const history = useHistory();
    const { createErrorSnack } = useSnackbars();

    const handleAuthStateChange = (nextAuthState: AuthState, data?: object) => {
        if (nextAuthState === AuthState.SignedIn) {
            history.push(props?.location?.state?.from || '/profile');
        }
    }

    useEffect(() => {
        const errorListener = (res: any) => {
            let errorMsg = '';
            switch (res.payload?.data?.code) {
                case 'UserNotFoundException': // Lump these together, otherwise cognito says user does not exist on a user not found exception
                case 'NotAuthorizedException':
                    errorMsg = 'Incorrect username or password';
                    break;
                default:
                    errorMsg = res.payload.data.message ? res.payload.data.message : null;
            }

            if (errorMsg) createErrorSnack(errorMsg);
        }

        Hub.listen(AUTH_CHANNEL, errorListener);

        return function cleanup() {
            Hub.remove(AUTH_CHANNEL, errorListener);
        }
    }, []);

    // Things to fix: Closing the dialog triggers a rerender, which causes it to open again when the error still persists, meaning the error # will always be 3 when it's not
    // fixed, causing an infinite loop

    // Once the dialog issue is fixed, possibly make it where the login fields remain visible in the background? If the infinite loop bug is fixed, there will be no background
    // when the dialog is closed 

    // Not even sure it works, as I'm not sure how to replicate the issue. I think it needs to be tested in dev

    // It seems like too many renders are happening. Might be able to cut down on rerenders?


    return (
        <Grid item container spacing={2} alignContent="center" alignItems="center" justify="center" direction="column">
            <Grid item>
                <AmplifyAuthenticator usernameAlias="email" handleAuthStateChange={handleAuthStateChange}>
                    <AmplifySignIn slot="sign-in" usernameAlias="email" headerText="Sign in to your Voxi account" />
                    <AmplifySignUp slot="sign-up" usernameAlias="email" formFields={formFields} />
                </AmplifyAuthenticator>
            </Grid>
        </Grid>
    )
}