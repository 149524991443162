import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Card, CardMedia, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useListActiveCategoriesByShowQuery, Category } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '95vw',
        maxHeight: '25vh',
        [theme.breakpoints.up("sm")]: {
            width: '500px'
        }
    },
    productGrid: {
        backgroundColor: theme.palette.voxi.secondaryColor,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        minWidth: '90vw',
        maxWidth: '95vw',
        borderRadius: "borderRadius",
        border: 1,
        [theme.breakpoints.up("sm")]: {
            width: '500px'
        }
    },
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"        
    },
    titleText: {
        color: theme.palette.voxi.accentColor,
        fontSize: '18px',
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    },
    cardInfoDiv: {
        position: 'absolute',
        backgroundColor: theme.palette.voxi.secondaryColor,
        opacity: '0.75',
        bottom: '0px',
        width: '100%'
    },
    media: {
        position: 'relative',
        height: '25vh',
        width: '100%',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    infoText: {
        color: theme.palette.voxi.infoColor,
        fontFamily: theme.fonts.CARD_INFO.fontFamily
    }
}));

export function ProductsList(props: ProductsListProps) {
    const classes = useStyles();

    const queryArgs = { showID: props.showID };
    const { data } = useListActiveCategoriesByShowQuery<Array<Category>>(queryArgs, {
        enabled: !!props.showID && props.showID > 0,
        select: (response) => { return response.listActiveCategoriesByShow?.items ?? [] }
    });

    return (
        <Grid item container spacing={2} alignContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography className={classes.headerText} variant="h4">Product Catalog</Typography>
            </Grid>
            {
                data?.map((category: Category, index: number) => {
                    return (
                        <Grid key={category.id} item container xs={12} sm={12} md={12} lg={6} justify="center">
                            <CategoryCard category={category} index={index} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

interface ProductsListProps {
    showID: number
}

function CategoryCard(props: CategoryCardProps) {
    const classes = useStyles();
    const history = useHistory();

    const imageLoadError = (event) => {
        if (props.category.imageURL) event.target.src = props.category.imageURL.replace('PNG', 'JPG');
    }

    const navigate = () => {
        history.push(`/category/${props.category.id}`);
    }

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={navigate}>
                <CardMedia className={classes.media} image={props.category.imageURL || ''} title="Episode Poster" onError={imageLoadError} />
                <CardContent className={classes.cardInfoDiv}>
                    <Typography className={classes.titleText} variant="h5" component="p">
                        {props.category.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

interface CategoryCardProps {
    category: Category
    index: number
}