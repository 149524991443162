import React, { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import '../../../node_modules/flickity/css/flickity.css';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SelectWithLabel } from '../shared/SelectWithLabel';
import { ProductCard } from '../shared/ProductCard';
import { ProductRequestCard } from '../shared/ProductRequestCard';
import { EpisodeDisclaimer } from './EpisodeDisclaimer';

import {
    Maybe,
    EpisodePhoto,
    EpisodePhotoGroup,
    useGetEpisodePhotoGroupsQuery,
    useGetEpisodePhotosQuery,
    useGetEpisodePhotoProductsQuery,
    EpisodePhotoProduct
} from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    photoContainer: {
        position: 'relative',
        textAlign: 'center',
        color: 'black'
    },
    groupPhoto: {
        maxWidth: '85vw',
        maxHeight: '30vh',
        paddingRight: '5px'
    },
    photoTextContainer: {
        position: 'absolute',
        top: '8px',
        left: '16px',
        backgroundColor: '#444444',
        opacity: .80,
        padding: "0 5px 0 5px"
    },
    photoText: {
        color: 'white',
        opacity: 1.0
    },
    requestCardContainer: {
        marginTop: theme.spacing(2),
        minWidth: '90vw',
        maxWidth: '95vw',
        alignSelf: 'center',
        [theme.breakpoints.up("sm")]: {
            width: '500px'
        }
    },
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"
    },
    arrowButton: {
        color: 'black',
        fontSize: '40px',
        border: '3px solid black',
        borderRadius: '50%',
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            fontSize: '25px',
            border: '2px solid black'
        },
        "&:hover": {
            color: "white",
            backgroundColor: "black",
            border: '3px solid white'
        }
    }
}));

const allGroupsOption = { name: 'All', id: -1, episodeID: -1, order: 0, enabled: true };

export function PhotoCarouselView(props: PhotoCarouselViewProps) {
    const classes = useStyles();
    const [episodePhotoGroups, setEpisodePhotoGroups] = useState<Array<EpisodePhotoGroup>>([allGroupsOption]);
    const [selectedGroup, setSelectedGroup] = useState<Maybe<EpisodePhotoGroup>>(allGroupsOption);
    const [filteredEpisodePhotoProducts, setFilteredEpisodePhotoProducts] = useState<Array<EpisodePhotoProduct>>([]);

    const queryArgs = { episodeID: props.episodeID };
    const getEpisodePhotoGroupsResult = useGetEpisodePhotoGroupsQuery<Array<EpisodePhotoGroup>>(queryArgs, {
        select: (response) => { return response.getEpisodePhotoGroups?.items ?? [] }
    });

    const getEpisodePhotosResult = useGetEpisodePhotosQuery<Array<EpisodePhoto>>(queryArgs, {
        select: (response) => { return response.getEpisodePhotos?.items ?? [] }
    });

    const getEpisodePhotoProductsResult = useGetEpisodePhotoProductsQuery<Array<EpisodePhotoProduct>>(queryArgs, {
        select: (response) => { return response.getEpisodePhotoProducts?.items ?? [] }
    });

    useEffect(() => {
        if (getEpisodePhotoGroupsResult.isLoading || !getEpisodePhotoGroupsResult.data) return;

        let photoGroups = getEpisodePhotoGroupsResult.data.filter(photoGroup => photoGroup.enabled);
        photoGroups.unshift(allGroupsOption);

        setEpisodePhotoGroups(photoGroups);
        setSelectedGroup(photoGroups[0]);
    }, [props.episodeID, getEpisodePhotoGroupsResult])

    useEffect(() => {
        if (getEpisodePhotoProductsResult.isLoading || !getEpisodePhotoProductsResult.data) return;

        setFilteredEpisodePhotoProducts(getEpisodePhotoProductsResult.data ?? []);
    }, [props.episodeID, getEpisodePhotoProductsResult])

    const onPhotoGroupSelectChange = (event: any) => {
        const group: EpisodePhotoGroup = event.target.value;
        setSelectedGroup(group);

        const filteredProducts = group.id === -1
            ? getEpisodePhotoProductsResult.data
            : getEpisodePhotoProductsResult.data?.filter(product => product.groupID === group.id);

        setFilteredEpisodePhotoProducts(filteredProducts ?? []);
    }

    return (
        <Grid item container spacing={2} direction="column">
            <Grid item>
                <Typography className={classes.headerText}>{props.episodeDisplayName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Flickity options={{
                    pageDots: false,
                    lazyLoad: 5,
                    dragThreshold: 100,
                    draggable: true,
                    contain: true
                }}>
                    {
                        getEpisodePhotosResult.data?.map(photo => {
                            const thumbnail = photo.url?.replace('1920X1080', '640X360') || '';
                            return (
                                <div key={photo.id} className={classes.photoContainer}>
                                    <img key={photo.id} className={classes.groupPhoto} data-flickity-lazyload={thumbnail} alt={photo.title || 'photo'} />
                                    <div className={classes.photoTextContainer}>
                                        <div className={classes.photoText}>{photo.groupName}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Flickity>
            </Grid>
            {
                props.episodeDisclaimer && <EpisodeDisclaimer disclaimer={props.episodeDisclaimer} />
            }
            <Grid item container spacing={2} direction="column" alignItems="center" justify="center">
                <SelectWithLabel id="photo-group-selector"
                    title="Filter Products By Group:"
                    value={selectedGroup}
                    onChange={onPhotoGroupSelectChange}
                    selectOptions={episodePhotoGroups}

                />
            </Grid>
            <Grid item container spacing={2} alignItems="center" alignContent="center" justify="center">
                {
                    filteredEpisodePhotoProducts.map(photoProduct => {
                        return (
                            <Grid item container key={photoProduct.id} xs={12} sm={12} lg={6} justify="center">
                                <ProductCard key={photoProduct.product.id}
                                    product={photoProduct.product}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid className={classes.requestCardContainer} item>
                <ProductRequestCard episodeID={props.episodeID} />
            </Grid>
        </Grid>
    );
}

interface PhotoCarouselViewProps {
    episodeDisplayName: string
    episodeID: number
    episodeDisclaimer: Maybe<string>
}