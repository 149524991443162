import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProductCard } from '../shared/ProductCard';

import { useGetCategoryQuery, useListRelatedProductsQuery, Maybe, Category as CategoryType, Product } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    headerText: {
        color: theme.palette.voxi.textPrimaryColor,
        fontFamily: theme.fonts.PAGE_TITLE_TEXT.fontFamily,
        fontWeight: theme.fonts.PAGE_TITLE_TEXT.fontWeight,
        fontStyle: theme.fonts.PAGE_TITLE_TEXT.fontStyle,
        display: "flex",
        justifyContent: "center"
    },
    loading: {

    }
}));

export function Category(props: CategoryProps) {
    const classes = useStyles();

    const getCategoryQueryArgs = { id: props.match.params.categoryID };
    const getCategoryResult = useGetCategoryQuery<Maybe<CategoryType>>(getCategoryQueryArgs, {
        select: (response) => { return response.getCategory }
    });

    const listRelatedQueryArgs = { categoryID: props.match.params.categoryID, showID: props.showID };
    const listRelatedProductsResult = useListRelatedProductsQuery<Array<Product>>(listRelatedQueryArgs, {
        enabled: !!props.showID && props.showID > 0,
        select: (response) => { return response.listRelatedProducts?.items ?? [] }
    });

    if (getCategoryResult.isLoading) return (
        <Grid container spacing={2} justify="center">
            <CircularProgress />
        </Grid>
    )

    return (
        <Grid item container spacing={2} direction="column">
            <Grid item>
                <Typography className={classes.headerText} variant="h4">{`${getCategoryResult?.data?.name || ''}`}</Typography>
            </Grid>
            <Grid item container spacing={2}>
                {
                    listRelatedProductsResult?.data?.map(product => {
                        return (
                            <Grid item container key={product.id} xs={12} sm={12} md={6} lg={6} justify="center">
                                <ProductCard product={product} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

interface CategoryProps {
    showID: number
    match: { params: { categoryID } }
}