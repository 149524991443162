import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Maybe } from '../../lib/api';

const useStyles = makeStyles(theme => ({
    privacyPolicy: {
        width: '75%',
        borderRadius: '5px',
        justifySelf: 'center',
        alignSelf: 'center',
    }
}));

export default function FAQ(props: FAQProps) {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h4">FAQ</Typography>
            <div className={classes.privacyPolicy}
                dangerouslySetInnerHTML={{ __html: props.faq || '' }}
            />
        </>
    )
}

export interface FAQProps {
    faq: Maybe<string>
}